/**
 * Components -> ScrollForm -> Sidebar
 */

import React, { FC, useMemo, memo, ReactElement, useState, useEffect } from 'react';
import classNames from 'classnames';
import { Card } from 'components/Card';
import { Skeleton } from 'components/Skeleton';
import { Item } from './components';
import { IScrollFormSection } from '../../types';
import css from './Sidebar.module.scss';

type Props = {
  skeleton?: boolean;
  items: IScrollFormSection[];
  activeId: string | null;
  onItemClick: (id: string, e: React.MouseEvent<HTMLElement>) => void;
  renderStart?: ReactElement;
  showRenderStartBelow?: boolean;
  extra?: ReactElement;
}

const Sidebar: FC<Props> = ({ items, activeId, skeleton, onItemClick, renderStart, showRenderStartBelow = false, extra }) => {
  const renderItems = useMemo(() => items.map(({ label, id, progress }) => (
    <Item
      active={id === activeId}
      key={id}
      label={label}
      id={id}
      progress={progress}
      onClick={onItemClick}
    />
  )), [activeId, items]);

  /** Check if sidebar is at the top of the page */
  const [isSticky, setIsSticky] = useState(false);
  useEffect(() => {
    const sidebar = document?.getElementById('scrollFormSidebar');
    const handleEvent = () => {
      setIsSticky(sidebar ? sidebar?.offsetTop >= 90 : false);
    };
    window.addEventListener('scroll', handleEvent);
    window.addEventListener('load', handleEvent);
    return () => window.removeEventListener('scroll', handleEvent);
  }, [isSticky]);

  return (
    <div className={classNames(css.root, {[css.hasSkeleton]: skeleton })} id="scrollFormSidebar">
      <Card className={css.inner}>
        <ul className={css.items}>
          {skeleton ?  (
            <Skeleton
              active
              className={css.skeleton}
              paragraph
            />
          ) : renderItems}
        </ul>
      </Card>
      {showRenderStartBelow ?
        <div className={classNames(css.renderStartBelow, { [css.isVisible]: isSticky })}>{renderStart}</div>
        : null
      }
      {extra ?
        <div className={classNames(css.extra, { [css.isVisible]: isSticky })}>{extra}</div>
        : <></>
      }
    </div>
  );
};

export default memo(Sidebar);
