import React from 'react';

export const TalentReviewLogLightIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.144531" y="56.5537" width="50" height="50" rx="10" transform="rotate(-45 0.144531 56.5537)"
      fill="#FFA600"/>
    <rect x="21.1445" y="56.5537" width="50" height="50" rx="10" transform="rotate(-45 21.1445 56.5537)"
      fill="#D9D9D9"/>
    <rect x="41.1445" y="56.5537" width="50" height="50" rx="10" transform="rotate(-45 41.1445 56.5537)"
      fill="#E65957"/>
    <circle cx="56.446" cy="13.4997" r="8" transform="rotate(-47.6683 56.446 13.4997)" fill="#4A1FFF"/>
    <circle cx="56.446" cy="98.4997" r="8" transform="rotate(-47.6683 56.446 98.4997)" fill="#4A1FFF"/>
  </svg>
);
