import { parse, format, isValid } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { utcToZonedTime } from 'date-fns-tz';
import { startCase } from 'lodash';

export const formatValue = (value?: number, format?: string | null, formatLarge = false, decimalPlaces = 2): string => {
  if (!value && value !== 0) return '';
  const decimalValue = formatDecimal(value, decimalPlaces);
  const formattedValue = formatLarge ? formatLargeNumber(decimalValue) : decimalValue.toLocaleString();
  switch (format) {
    case '$':
      return `$${formattedValue}`;
    case 'x':
      return `${formattedValue}x`;
    case '%':
      return `${formattedValue}%`;
    case 'days':
      return `${formattedValue} days`;
    default:
      return formattedValue.toString();

  }
};

export const formatLargeNumber = (num: number): string => {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + 'M';
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + 'K';
  } else {
    return num.toFixed(1).toString();
  }
};

export const formatDecimal = (value: number, decimalPlaces: number): number => {
  const factor = Math.pow(10, decimalPlaces);
  return Math.round(value * factor) / factor;
};

const parseGMTDate: (dateString: string) => Date | null = (dateString: string) => {
  try {
    // If the input string does not contain a time, append '00:00:00' to it
    if (dateString.split(' ').length === 1) dateString = `${dateString} 00:00:00`;
    // Attempt to split the input string
    const parts = dateString.split(/\s+/);

    if (parts.length !== 2) {
      throw new Error('Invalid date format. Expected \'YYYY-MM-DD HH:mm:ss\'');
    }

    const [fullDate, time] = parts;

    // Extract year, month, day from the full date
    const dateParts = fullDate.split('-');
    if (dateParts.length !== 3) {
      throw new Error('Invalid date format. Expected \'YYYY-MM-DD\'');
    }
    const [y, mo, d] = dateParts.map(Number);

    // Extract hours, minutes, seconds from the time
    const timeParts = time.split(':');
    if (timeParts.length !== 3) {
      throw new Error('Invalid time format. Expected \'HH:mm:ss\'');
    }
    const [h, m, s] = timeParts.map(Number);

    const year = Number(y);
    const month = Number(mo);
    const day = Number(d);
    // const day = Number(d.split(' ')[0]);
    const hours = Number(h);
    const minutes = Number(m);
    const seconds = Number(s);

    // Parse the timezone offset
    const offset = timeParts[timeParts.length - 1];
    const sign = offset[0];
    const hoursOffset = parseInt(offset.slice(1, 3));
    // const minutesOffset = parseInt(offset.slice(4));

    // Create a Date object in the local timezone
    const localDate = new Date(year, month - 1, day, hours, minutes, seconds);

    // Adjust for timezone offset
    localDate.setHours(localDate.getHours() + (
      sign === '+'
        ? hoursOffset
        : sign === '-'
          ? -hoursOffset
          : -7
    ));

    // Convert to UTC
    const gmtDate = new Date(Date.UTC(
      year,
      month - 1,
      day,
      localDate.getUTCHours(),
      localDate.getUTCMinutes(),
      localDate.getUTCSeconds()
    ));

    return gmtDate;
  } catch (error) {
    console.error('Error parsing date:', error.message, dateString);
    return null;
  }
};

/**
 * The formatScoreboardsDate function formats a date string based on the specified type and converts it to the 'America/Los_Angeles' timezone.
 *
 * @param {string} date - The input date string to be formatted.
 * @param {string} [type] - The type of formatting to apply.
 *                          Supported types are:
 *                          - 'axis_value': Formats the date as 'MM/dd/yyyy'.
 *                          - 'timestamp': Formats the date as 'MMMM do yyyy, h:mm a 'PST''.
 *                          - 'timeframe': Formats the date as 'MMM d, yyyy'.
 *                          If no type is provided, defaults to 'MM/dd/yyyy'.
 * @returns {string | Date} The formatted date string or Date object.
 */
export const formatScoreboardsDate = (date?: string, type?: string, chartName?: string): string | Date | any => {
  if (!date || !isValid(new Date(date))) return '';
  // The input date string is in UTC time, so we need to convert it to the user's local timezone's representation of the UTC time
  // For example, 2024-08-30 12:47:29 (UTC) should be  2024-08-30 05:47:29 (Pacific Daylight Time)
  const localDate = parseGMTDate(date);

  const timeZone = 'UTC';

  // Determine the date format based on the input length
  const dateFormat = date.length > 10 ? 'yyyy-MM-dd HH:mm:ss' : 'yyyy-MM-dd';
  if (!localDate) return '';

  switch (type) {
    case 'axis_value':
      return chartName === 'MonthlyChart' || chartName === 'LongTermChart'
        ? format(utcToZonedTime(localDate, timeZone), 'MM/dd', { locale: enUS })
        : format(utcToZonedTime(localDate, timeZone), 'MM/dd/yyyy', { locale: enUS });
    case 'timestamp':
      return format(utcToZonedTime(localDate, timeZone), 'MMMM do yyyy, h:mm a \'UTC\'', { locale: enUS });
    case 'timeframe': {
      return format(utcToZonedTime(localDate, timeZone), 'MMM d, yyyy', { locale: enUS });
    }

    case 'editKPIsTimestamp':
      return format(utcToZonedTime(parse(date, dateFormat, localDate), timeZone), 'MMMM dd, yyyy, hh:mm:ss a \'UTC\'', { locale: enUS });
    case 'tooltip':
      return format(utcToZonedTime(parse(date, dateFormat, localDate), timeZone), 'MMMM d, yyyy', { locale: enUS });
    default:
      return format(utcToZonedTime(parse(date, dateFormat, localDate), timeZone), 'MM/dd/yyyy', { locale: enUS });
  }
};

export const formatTitle = (title?: string): string => {
  if (!title) return '';
  return startCase(title.replace(/_/g, ' '));
};

export const formatRangeLabel = (range: string, from: string, to: string): string =>  {
  return range === 'custom'
    ? `From ${formatScoreboardsDate(from, 'axis_value')} - ${formatScoreboardsDate(to, 'axis_value')}`
    : formatTitle(range);
};

export const hexToRgba = (hex: string, alpha: number): string => {
  const match = hex.match(/\w\w/g);
  if (!match) {
    return hex;
  }
  const [r, g, b] = match.map(x => parseInt(x, 16));
  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

export const formatYAxisTick = (tickValue: number): string => {
  if (tickValue >= 1000000) {
    return (tickValue / 1000000).toFixed(1) + 'M';
  } else if (tickValue >= 1000) {
    return (tickValue / 1000).toFixed(1) + 'K';
  }
  return tickValue.toString();
};
