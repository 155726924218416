/**
* Get user
*/

import { baseApi } from 'api/crudGraphQL';
import { Projection } from 'api/entityGraphQL';
import { User } from 'features/entitiesRedux';
import { GetUsersResult } from './getUsers';
import tagTypes from './tagTypes';

export type GetUserQuery = {
  id: number;
  name?: string;
  projection: Projection;
};

export type GetUserResult = User;

export const { useGetUserQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<GetUserResult, GetUserQuery>({
      transformResponse: (response: { users: GetUsersResult }) => response.users?.data?.[0],
      providesTags: (user) => {
        return user ? [
          { id: user.id, type: tagTypes.USER },
        ] : [tagTypes.USER];
      },
      query: ({
        projection = { id: true, name: true },
        id,
      }) => ({
        body: {
          query: {
            users: {
              __args: {
                id,
                limit: 1,
                page: 1,
              },
              data: projection,
            }
          }
        }
      })
    })
  })
});