import { startsWith, pickBy, startCase, merge } from 'lodash';


export interface SalesforceDataToSync {
  nova_monthly_gross_profit: string | number | null;
  salesforce_monthly_gross_profit: string | number | null;
  nova_length: string | number | null;
  salesforce_length: string | number | null;
  nova_status: string | number | null;
  salesforce_status: string | number | null;
  nova_close_date: string | number | null;
  salesforce_close_date: string | number | null;
  nova_start_date: string | number | null;
  salesforce_start_date: string | number | null;
  nova_lost_reason: string | number | null;
  salesforce_lost_reason: string | number | null;
  nova_type_of_deal: string | number | null;
  salesforce_type_of_deal: string | number | null;
  nova_rollover_date: string | number | null;
  salesforce_rollover_date: string | number | null;
  nova_rainmaker_link: string | number | null;
  salesforce_rainmaker_link: string | number | null;
  nova_follow_up_date: string | number | null;
  salesforce_follow_up_date: string | number | null;
  nova_disqualified_reason: string | number | null;
  salesforce_disqualified_reason: string | number | null;
  nova_lost_deal_explanation: string | number | null;
  salesforce_lost_deal_explanation: string | number | null;
  nova_stage: string | null;
  salesforce_stage: string | null;
  nova_contract_channels: string | null;
  salesforce_contract_channels: string | null;
  nova_pillar:  string | null;
  salesforce_pillar:  string | null;
}

type ArrayType = Partial<{ from: string | number | null, to: string | number | null, field: string }>

export const separateArrayByType = (data: SalesforceDataToSync): ArrayType[] => {
  const nova: ArrayType[] = [];
  const salesforce: ArrayType[] = [];
  pickBy(data, (value, key) => {
    if (startsWith(key, 'nova_')) {
      /** update to current name */
      if (key === 'nova_rainmaker_link') key = 'nova_blueprint_link';
      nova.push({ to: value, field: startCase(key.slice(5)) });
    }
    if (startsWith(key, 'salesforce_')) {
      salesforce.push({ from: value });
    }
  });
  const unfiltered = merge(nova, salesforce);
  return unfiltered.filter((item: ArrayType) => item.from !== null || item.to !== null);
};
