import React from 'react';
import { Link } from 'react-router-dom';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { AlertRibbon, NebulaButton } from 'features/intelligence/components/library-components';
import { AD_NETWORKS, ECOMMERCE, GOOGLE_ANALYTICS, GOOGLE_ANALYTICS_4 } from '../../constants';
import css from './MissingIntegrations.module.scss';

type MissingIntegrationsProps = {
  clientId: number | undefined;
  missingIntegrations: string[];
  showGA?: boolean;
}

const MissingIntegrations = ({ clientId, missingIntegrations,showGA }: MissingIntegrationsProps): JSX.Element => {
  /**
   * Assigns appropriate label based on if the client has the required integration
   * @param integrationType
   * @returns {JSX.Element} Check mark or X
   */

  const getIcon = (integrationType: string) => {
    return missingIntegrations.includes(integrationType) ? <CloseOutlined /> : <CheckOutlined />;
  };

  return (
    <AlertRibbon
      className={css.root}
      type="warning"
      title="Missing Integrations"
      content={
        <div>
          <p className={css.description}>In order to generate a forecast, your client must have connections to Google Analytics,
              an e-Commerce store, and an ad-networks integration. </p>
          <ul className={css.statusList}>
            {showGA && <li>{getIcon(GOOGLE_ANALYTICS)}Google Analytics</li>}
            <li>{getIcon(GOOGLE_ANALYTICS_4)}Google Analytics 4</li>
            <li>{getIcon(ECOMMERCE)}eCommerce store (BigCommerce, Magento, Shopify, or Salesforce Commerce Cloud)</li>
            <li>{getIcon(AD_NETWORKS)}Ad Networks integration (Facebook, Google Ads or Funnel.io)</li>
          </ul>
        </div>
      }
      extra={
        <Link to={`/power-view/accounts/${clientId}/integrations/add`}>
          <NebulaButton size='medium' buttonType='tertiary'>
            <div className={css.buttonLabel}>
              Add Integrations
            </div>
          </NebulaButton>
        </Link>
      }
    />
  );
};

export default MissingIntegrations;
