/**
* Get Notifications
*/

import { Pagination, Projection } from 'api/entityGraphQL/types';
import { Notification } from 'features/entitiesRedux/models/notification';
import { baseApi, cleanArguments, paginationProjection } from '..';

export type GetNotificationsQuery = {
  limit?: number;
  name?: string;
  projection: Projection;
  logging?: boolean;
};

export type GetNotificationsResult = Pagination & {
  data: Notification[]
};

export const GET_NOTIFICATIONS_ENDPOINT_KEY = 'getNotifications';

export const { useGetNotificationsQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_NOTIFICATIONS_ENDPOINT_KEY]: build.query<GetNotificationsResult, GetNotificationsQuery>({
      transformResponse: (response: { notifications: GetNotificationsResult }) => response.notifications,
      query: ({
        projection = { id: true },
        ...args
      }) => {
        const __args: Partial<GetNotificationsQuery> = {
          ...args,
        };

        return {
          body: {
            query: {
              notifications: {
                __args: cleanArguments(__args),
                data: projection,
                ...paginationProjection
              }
            }
          }
        };
      }
    }),
  }),
});
