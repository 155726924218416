export const CohortsTimeRangeValues: Record<number, string> = {
  1001: 'custom date range',
  1002: 'all time',
  1003: 'year to date',
  1004: 'the last calendar year',
  1005: 'the last 12 months',
  1006: 'last year to date',
  1007: 'the last 6 months',
  1008: 'the last 90 days',
  1009: 'the last 60 days',
  1010: 'the last month',
};

export const CustomerInsightsTimeRangeValues: Record<number, string> = {
  1001: 'custom date range',
  1002: 'all time',
  1003: 'the last 365 days',
  1004: 'the last 180 days',
  1005: 'the last 90 days',
  1006: 'the last 60 days',
  1007: 'the last 30 days',
};
