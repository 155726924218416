export const scrollTo = (
  left: number,
  top: number,
  behavior: 'smooth' | 'auto' | undefined = 'smooth'
) => {
  if (window) {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top,
        left,
        behavior,
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(left, top);
    }
  }
};
