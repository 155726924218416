import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';
import css from './CustomLink.module.scss';

export interface CustomLinkProps {
  label: string;
  to: string;
  button?: boolean;
  animate?: boolean;
  primary?: boolean;
  disabled?: boolean;
}

const CustomLink = forwardRef(
  (
    {
      label,
      to,
      button = false,
      primary = false,
      animate = false,
      disabled = false,
    }: CustomLinkProps,
    ref: any
  ) => {

    return (
      <Link
        to={to}
        className={classNames(
          css.root,
          { [css.btn]: button },
          { [css.animate]: animate },
          { [css.primary]: primary },
          { [css.disabled]: disabled },
        )}>
        <span>{label}</span>
      </Link>
    );
  }
);

CustomLink.displayName = 'CustomLink';

export default CustomLink;
