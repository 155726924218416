import React, { forwardRef } from 'react';
import { Select as AntdSelect } from 'antd';
import { SelectProps } from 'antd/lib/select';
import classNames from 'classnames';
import { Option, OptionProps } from './components/Option';
import {SkeletonInput} from '../SkeletonInput';
import css from './Select.module.scss';

export interface Props extends SelectProps<any> {
  className?: string;
  block?: boolean;
  skeleton?: boolean;
}

export interface SelectInterface extends React.ForwardRefExoticComponent<Props & React.RefAttributes<HTMLInputElement>> {
  Option: React.FunctionComponent<OptionProps>;
  Group: typeof AntdSelect.OptGroup;
}

const Select = forwardRef(function Select({ className, block, skeleton = false, ...props }: Props, ref: any) {
  // Show skeleton when loading is true
  if (skeleton) return <SkeletonInput className={css.skeleton} loading={false} active={true} />;

  return (
    <AntdSelect {...props} className={classNames(className, css.root, {[css.block]: block })} ref={ref} />
  );
}) as SelectInterface;

Select.Option = Option;
Select.Group = AntdSelect.OptGroup;

export default Object.assign(Select, { Option, Group: AntdSelect.OptGroup });
