/**
 * Audits -> DiscoveryForm -> useInitialValues
 */

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { flatten, unionBy } from 'lodash';
import moment from 'moment';
import { getTopicNotes } from 'features/audits/utils';
import { Audit } from 'features/entitiesRedux/models/audit';
import { Service } from 'features/entitiesRedux/models/service';
import { emptyContact } from './emptyFieldSets';
import { Question, selectDiscoveryPage } from '../../auditsSlice';

export type DiscoveryFormValues = { [key: string] : any };

const useInitialValues: (audit?: Audit) => DiscoveryFormValues = (audit) => {
  const discovery = audit?.discovery;
  const topics = audit?.discovery?.topics;
  const discoveryPage = useSelector(selectDiscoveryPage);

  // get all services available for selection
  const availableServices = discoveryPage?.departments.reduce<Service[]>((acc, curr) => [...acc, ...curr.services], []);
  // get services with in_survey = "yes"
  const selectedServices = audit?.discovery?.services?.filter((service: any) => service?.in_survey === 'yes');
  // combine both so we can set it in the form
  const mergedServices = unionBy(selectedServices, availableServices, 'id').sort((a, b) => (a.id > b.id ? 1 : -1));

  const initialValues: DiscoveryFormValues = useMemo(() => {
    /** Get all the field names for this discovery */
    const questionFieldNames =
         // first get a flattened array of all question objects from each topic
         flatten(topics?.map(topic => topic?.questions))
           // loop through the questions to get just the group_name and type
           .map((question: Question) => {
             return {
               name: question?.group_name,
               type: question?.type,
               topic_id: question?.topic?.id,
               format: question?.format,
             };
           })
           // filter down to only truthy values for question.name
           .filter(q => !!q.name);

    /** Get existing answers from discovery_questions table */
    const discoveryQuestions = audit?.discovery?.questions;

    /** Re-shape discoveryQuestions to work with our initialValuesBuilder object */
    const discoveryQuestionsMap: {[key: string]: any} | undefined = discoveryQuestions?.reduce((acc, curr) => {
      return {
        ...acc,
        [curr.group_name]: curr.answer,
      };
    }, {} as {[key: string]: any});

    /** Manually add special case fields/values (not coming from discoveryQuestionsMap) */
    const initialValuesBuilder: DiscoveryFormValues = {
      client_id: audit?.client?.id,
      client_name_original: audit?.client?.name,
      client_name: audit?.client?.name,
      client_logo: audit?.client?.logo,
      client_website: audit?.client?.website,
      business_type_id: audit?.discovery?.business_type?.id,
      industry_id: audit?.client?.industry?.id,
      lead_source_id: audit?.client?.lead_source?.id,
      pillar_id: audit?.pillar?.id,
      audit_name: audit?.name,
      contacts: audit?.client?.contacts?.length ? audit?.client?.contacts : [emptyContact],
      salesforce_opportunity_id: audit?.salesforce_opportunity_id,
      // competitors: audit?.discovery?.competitors?.length ? audit?.discovery?.competitors : [emptyCompetitor],
      // goals: audit?.discovery?.goals?.length ? audit?.discovery?.goals : [emptyGoal],
      // sales_channels: audit?.discovery?.channels?.length ? audit?.discovery?.channels : [emptyChannel],
      // lead_channels: audit?.discovery?.lead_channels?.length ? audit?.discovery?.lead_channels : [emptyLeadChannel],
      services: mergedServices || [],
      // considered_services: audit?.discovery?.considered_services?.map(service => service?.id),
      // decision_maker_ids: audit?.client?.contacts?.filter(contact => contact.is_decision_maker === 'yes').map(contact => contact?.id),
      // question_preferred_sales_channels: discoveryQuestions?.filter(q => {
      //   return q?.group_name === 'question_preferred_sales_channels';
      // })[0]?.answer?.split(','),
      user_score: audit?.discovery?.user_score,
      departments: audit?.discovery?.departments,
    };

    /** Dynamically add fields/values coming from discoveryQuestionsMap */
    for (let i = 0; i < questionFieldNames.length; i++) {
      const key = questionFieldNames[i].name;

      // if key already exists then don't overwrite it
      if (key in initialValuesBuilder) continue;

      if (questionFieldNames[i].type === 'date' && discoveryQuestionsMap != undefined) {
        // need to parse date field values before passing to the form
        initialValuesBuilder[key] = discoveryQuestionsMap[key] ? moment(discoveryQuestionsMap[key]) : undefined;
      } else if (questionFieldNames[i].format === 'topic_notes') {
        // handle fields for discovery_topic notes
        initialValuesBuilder[key] = getTopicNotes(topics, questionFieldNames[i].topic_id);
      } else if (discoveryQuestionsMap != undefined) {
        initialValuesBuilder[key] = discoveryQuestionsMap[key];
      }
    }

    return initialValuesBuilder;
  }, [audit, topics, discovery]);

  return initialValues;
};

export default useInitialValues;
