import React from 'react';
import loadable from '@loadable/component';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';

const LoadableComponent = loadable(
  () => import('./CyclesListPage'),
  {
    fallback: <PageSkeleton />,
  }
);

export { LoadableComponent as CyclesListPage };
