
import React, { memo, useState, useEffect, useRef, RefObject, useCallback, useMemo } from 'react';
import { useLocation, useHistory, useParams } from 'react-router-dom';
import { LoadableComponent } from '@loadable/component';
import { Box, Grid } from '@mui/material';
import { ArrowLeftIcon, ArrowRightIcon, Container, Card, Button, PageSpinner, Alert, Typography, InfoTooltip } from '@sprnova/nebula';
import { Action } from 'api/accessControl/Action';
import { Resource } from 'api/accessControl/Resource';
import { useGetClientQuery } from 'api/crudGraphQL/clients/getClient';
import { useGetClientsQuery } from 'api/crudGraphQL/clients/getClients';
import { CustomerInsightsDefaultProjection } from 'api/crudGraphQL/customer_insights/customerInsightDefaultProjection';
import { useGetCustomerInsightsQuery } from 'api/crudGraphQL/customer_insights/getCustomerInsights';
import { CustomerInsightType } from 'api/crudGraphQL/customer_insights/types';
import { useGetMeQuery } from 'api/crudGraphQL/me/getMe';
import { AccessControl } from 'components/AccessControl';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { PageHero } from 'layouts/components';
import isEmpty from 'lodash/isEmpty';
import moment from 'moment-timezone';
import { useQueryParams, StringParam } from 'use-query-params';
import exportCSV from 'utils/actions/exportCSV';
import { exportPDF } from 'utils/actions/exportPDF';
import CIFilters from './components/CIFilters';
import { ClientIndustryLink } from './components/ClientIndustryLink';
import { ExportDropdownButton } from './components/ExportDropdownButton';
import { NoDataForTimePeriod } from './components/NoDataForTimePeriod';
import WidgetCard from './components/WidgetCard';
import { CustomerInsightsWidgets } from './constants';
import { formatDateRange, formatValue } from './CustomerInsightsUtils';
import { useCIFilters, CIFiltersProvider } from './hooks/useCustomerInsightsFilters';
import { useDateRanges } from './hooks/useDateRanges';
import { ConsumerInsightDatesType } from './types';
import { AboutThisTool } from '../components/AboutThisTool/AboutThisTool';
import { NebulaButton } from '../components/library-components';
import type { Tab as AnchorlinkGroupTab } from '../components/library-components/AnchorlinkGroup/AnchorlinkGroup';
import { Sidebar } from '../components/library-components/Sidebar/Sidebar';
import css from './CustomerInsightsPage.module.scss';

type Tab = {
  ref: RefObject<HTMLDivElement>;
  infoTab?: string | JSX.Element;
  component?: LoadableComponent<any>,
} & AnchorlinkGroupTab;

const defaultLtv = { id: 1004, label: 'States' };

export const CustomerInsightsPage = memo(function CustomerInsightsPage() {
  const [queryParams, setQueryParams] = useQueryParams({
    customer_insight_id: StringParam,
  });
  const [customerInsightId, setCustomerInsightId] = useState<string | undefined | null>(queryParams.customer_insight_id);
  const [customerInsight, setCustomerInsight] = useState<CustomerInsightType>();
  const [customerInsightsTitle, setCustomerInsightsTitle] = useState<string>('Customer Insights');

  const [ltvLabel, setLtvLabel] = useState<string>(defaultLtv.label);
  const [scrollToList, setScrollToList] = useState<boolean>(false);
  const topRef = useRef<HTMLDivElement>(null);
  const printRef: React.Ref<HTMLDivElement> = useRef(null);
  // info icon popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const defaultFilters = {
    limit: 10,
    page: 1,
    group_id: defaultLtv.id,
  };
  const { filters, setFilters } = useCIFilters(defaultFilters);

  const [exporting, setExporting] = useState<boolean>(false);
  const [creatingPDF, setCreatingPDF] = useState<boolean>(false);

  const location = useLocation();
  const history = useHistory();

  //useEffect to set customer_insight_id from value in query param
  useEffect(() => {
    setCustomerInsightId(queryParams.customer_insight_id);
    if (!queryParams.customer_insight_id) {
      setCustomerInsight(undefined);
    }
  }, [queryParams.customer_insight_id]);

  const { data: account } = useGetMeQuery({
    projection: {
      id: true,
      name: true,
      email: true
    }
  });

  /**
   * Pass in client data with has_cohort_data so we can make a single query
   * and always know if a client has data or not
   */
  const { data: clientsRequest, isLoading: clientIsLoading, isFetching: clientIsFetching } = useGetClientsQuery({
    limit: -1,
    projection: {
      id: true,
      name: true,
      industry: {
        name: true
      },
      fivetran_connectors: {
        data_source: {
          slug: true
        },
        data_feed_created: true
      }
    }
  });

  const clientName = useMemo(() => {
    if (clientsRequest?.data && filters.client_id) {
      const client = clientsRequest.data.find((client) => client.id === filters.client_id);
      return client?.name;
    }
  }, [clientsRequest, filters.client_id]);
  const clientIndustry = useMemo(() => {
    if (clientsRequest?.data && filters.client_id) {
      const client = clientsRequest.data.find((client) => client.id === filters.client_id);
      return client?.industry?.name;
    }
  }, [clientsRequest, filters.client_id]);

  const skip = useMemo(() =>
    clientIsLoading || !filters.client_id || !filters.date_range_id,
  [clientIsLoading, filters.client_id, filters.date_range_id]);

  const skipLtv = useMemo(() => skip || !filters.group_id, [skip, filters.group_id]);

  const { consumer_insight_ltvs, ...rest } = CustomerInsightsDefaultProjection;

  const { data: customerInsightsLtv, isLoading: ltvLoading, isFetching: ltvFetching } = useGetCustomerInsightsQuery({
    ...filters,
    projection: {
      id: false,
      consumer_insight_ltvs
    }
  }, { skip: skip || skipLtv });
  const { data: customerInsights, isLoading, isFetching } = useGetCustomerInsightsQuery({
    ...filters,
    group_id: undefined,
    projection: rest
  }, { skip });

  const insights: Record<string, any> = useMemo(() => {
    let data = {};
    let ltvGlobalDates = {};
    if (customerInsights) {
      data = { ...data, ...customerInsights?.data?.[0] };
      const consumerInsightDates = (customerInsights?.data?.[0]?.consumer_insight_dates as unknown as ConsumerInsightDatesType[]);
      ltvGlobalDates = consumerInsightDates.filter((date: ConsumerInsightDatesType) => date.category === 'ltvs');
    }
    if (customerInsightsLtv) {
      data = { ...data, ...customerInsightsLtv?.data?.[0], ltv_global_dates: ltvGlobalDates };
    }
    return data;
  }, [customerInsightsLtv, customerInsights]);

  const { dateRanges, customerInsightsDateRangesError } = useDateRanges();

  /**
   * Mixpanel event tracking
   */
  const mixpanel = useMixpanel();

  const handleMixpanelEvents = useCallback((type?: 'PDF' | 'CSV' | WidgetNameType) => {
    try {
      let options: Record<string, any> = {
        client: clientName,
        clientId: filters.client_id,
        userName: account?.name,
        userId: account?.id,
        dateRangeId: filters.date_range_id,
        dateRange: dateRanges?.filter((range) => range.id === filters.date_range_id)?.[0]?.label,
        startDate: filters.start_date,
        endDate: filters.end_date,
        ltvTrait: ltvLabel,
      };
      let trackTitle: string;
      if (!type) {
        trackTitle = 'Run Customer Insights';
      } else {
        if (type !== 'PDF' && type !== 'CSV') {
          trackTitle = 'Customer Insights Click View More';
          options = { ...options, widget: type };
        } else {
          trackTitle = `Export Customer Insights ${type}`;
        }
      }
      if (options.clientId && options.dateRangeId) {
        console.log(`🛤 Track: ${trackTitle}`, options);
        if (mixpanel?.track) {
          mixpanel.track(trackTitle, { options });
        }
      }
      if (customerInsightsDateRangesError) {
        console.error('Failed to fetch customer insights date ranges.', { clientId: filters.client_id });
      }
    } catch (error) {
      console.error('Error sending mixpanel event', error);
    }
  }, [clientName, filters.client_id, filters.date_range_id, filters.start_date, filters.end_date, account?.name, account?.id, dateRanges, ltvLabel, customerInsightsDateRangesError, mixpanel]);

  useEffect(() => {
    if (filters.client_id && filters.date_range_id) {
      handleMixpanelEvents();
    }
  }, [filters.client_id, filters.date_range_id, handleMixpanelEvents]);

  const isInsightsDataLoading = useMemo((): Record<string, boolean> => {
    const ltv = ltvLoading || ltvFetching;
    const rest = isLoading || isFetching;
    return {
      ltv,
      rest,
      both: ltv || rest
    };
  }, [ltvLoading, ltvFetching, isLoading, isFetching]);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const hasNoData: boolean = useMemo(() => {
    if (isEmpty(insights)) return true;
    return isEmpty([
      ...insights?.consumer_insight_ltvs?.insights ?? [],
      ...insights?.consumer_insight_family_behaviors?.insights ?? [],
      ...insights?.consumer_insight_finance_wealths?.insights ?? [],
      ...insights?.consumer_insight_interests?.insights ?? [],
      ...insights?.consumer_insight_real_states?.insights ?? [],
      ...insights?.consumer_insight_life_stages?.insights ?? []
    ]);
  }, [insights]);

  //format title for customer insight page
  const displayConsumerInsightsHeaderTitle = useCallback((name: string | undefined): string => {
    return name ? `${name}'s Customer Insights` : 'Customer Insights';
  }, []);

  //useEffect to change url query params
  useEffect(() => {
    if (filters.client_id && filters.date_range_id) {
      setCustomerInsightsTitle(displayConsumerInsightsHeaderTitle(clientName));
    }
  }, [filters.client_id, filters.date_range_id, displayConsumerInsightsHeaderTitle, clientName, history]);

  /**
   * This array controls both the tabs (links) and their respective widget;
   */
  // useRef can't be used in a useMemo
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const widgets: Tab[] = ([
    {
      title: CustomerInsightsWidgets.ltvTitle,
      id: CustomerInsightsWidgets.ltvId,
      ref: useRef(null),
    },
    {
      title: CustomerInsightsWidgets.familyBehaviorsTitle,
      id: CustomerInsightsWidgets.familyBehaviorsId,
      ref: useRef(null),
    },
    {
      title: CustomerInsightsWidgets.interestsTitle,
      id: CustomerInsightsWidgets.interestsId,
      ref: useRef(null),
      infoTab: `Top interests are calculated by participants answering a survey on whether or not
      they are interested in a subject. In cases where a participant chooses
      not to respond to a specific question, it is interpreted as indicating
      a lack of interest in that particular subject and reflected in the percentage below.`
    },
    {
      title: CustomerInsightsWidgets.realEstateTitle,
      id: CustomerInsightsWidgets.realEstateId,
      ref: useRef(null),
    },
    {
      title: CustomerInsightsWidgets.lifeStagesTitle,
      id: CustomerInsightsWidgets.lifeStagesId,
      ref: useRef(null),
    },
    {
      title: CustomerInsightsWidgets.financeWealthsTitle,
      id: CustomerInsightsWidgets.financeWealthsId,
      ref: useRef(null),
    },
  ]);

  type WidgetNameType = typeof widgets[number]['id'];

  //the functions for the view more button on customerinsights overview page (Button functionalitytaht triggers routing)
  const goToDetailedPage = useCallback((id: string): void => {
    const { pathname, search } = location;
    const newSearch = `${search}&customer_insight_id=${id}`;
    handleMixpanelEvents(id);
    history.push(`${pathname}${newSearch}`);
  }, [location, handleMixpanelEvents, history]);

  //autoscroll to top when come back from detailed page
  const goToTopRef = (): void => {
    if (printRef.current) {
      printRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const backToOverview = useCallback(() => {
    setQueryParams({ customer_insight_id: undefined });
    goToTopRef();
  }, [setQueryParams]);

  const viewMoreButton = useCallback((count: number, id: string) => {
    const handleViewMoreClick = () => {
      // Set the scrollToList value to true
      setScrollToList(true);
      // Call the goToDetailedPage function
      goToDetailedPage(id);
    };

    return (
      <div style={{ paddingTop: '24px' }}>
        <NebulaButton buttonType='tertiary' onClick={handleViewMoreClick} width='100%'>
          {`View ${count - 5} more results `}
        </NebulaButton>
      </div>
    );
  }, [goToDetailedPage]);

  const seeMoreButton = useCallback((id: string) => {
    const handleSeeMoreClick = () => {
      // Set the scrollToList value to true
      setScrollToList(false);
      // Call the goToDetailedPage function
      goToDetailedPage(id);
    };

    return (
      <NebulaButton
        buttonType='text'
        onClick={handleSeeMoreClick}
        width='100%'
        icon={<ArrowRightIcon />}
        icon_position='right'
        style={{ padding: '0' }}
      >
        See More
      </NebulaButton>
    );
  }, [goToDetailedPage]);

  const formatArray = useCallback((array: any[]) => {
    return array.map((item: any) => [
      `"${item.display || item.label}"`,
      `"${item.ltv
        ? `${item.format}${formatValue(item.format, item.ltv)}`
        : item.format === '#'
          ? `${formatValue(item.format, item.percentage)}yrs`
          : `${formatValue(item.format, item.percentage)}${item.format}`}"`,
      `"${item.ltv_benchmark
        ? `${item.format}${formatValue(item.format, item.ltv_benchmark)}`
        : item.format === '#'
          ? `${formatValue(item.format, item.benchmark_percentage)}yrs`
          : `${formatValue(item.format, item.benchmark_percentage)}${item.format}`}"`
    ]).join('\n');
  }, []);

  const exportToCSV = useCallback(async () => {
    setExporting(true);
    handleMixpanelEvents('CSV');
    let csvData = [];
    const index2 = ['title', 'value', 'compared to'];
    if (customerInsightId) {
      let title = widgets.filter(widget => widget.id === customerInsightId)[0].title;
      if (title === 'Lifetime Value by') {
        title = `${title} ${ltvLabel}`;
      }
      csvData = [title, index2, formatArray(insights[customerInsightId]?.insights)];
    } else {
      csvData = widgets.map((widget, i) => {
        let title = widget.title;
        if (title === 'Lifetime Value by') {
          title = `${title} ${ltvLabel}`;
        }
        return [
          i > 0 ? `\n\n"${title}"` : `"${title}"`,
          index2,
          formatArray(insights[widget.id]?.insights)
        ].join('\n');
      });
    }

    await exportCSV({
      data: csvData,
      moduleName: 'Customer Insights',
      clientName: clientName,
    }).finally(() => setExporting(false));
  }, [clientName, handleMixpanelEvents, formatArray, widgets, insights, customerInsightId, ltvLabel]);

  const renderPDFTitle = useCallback(() => (
    <div
      style={{
        fontFamily: 'Inter',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal'
      }}
    >
      {
        clientName
          ? <div
            style={{
              fontSize: '48px',
            }}
          >
            {clientName} Customer Insights
          </div>
          : <></>
      }
      <div style={{ fontSize: '24px', paddingLeft: '8px' }}>
        {
          widgets.find(widget => widget.id === customerInsightId)?.title || 'Overview'
        }
          &nbsp;
        {
          formatDateRange(filters)
        }
      </div>
    </div>
  ), [clientName, widgets, filters, customerInsightId]);

  const exportToPDF = useCallback(() => {
    setExporting(true);
    handleMixpanelEvents('PDF');
    const element = printRef.current?.cloneNode(true) as HTMLElement;
    element.style.cssText = `
      max-width: 100% !important;
      margin: 0 auto !important;
    `;
    const firstDiv = element.getElementsByTagName('div')[0];
    firstDiv.style.margin = '0px';
    firstDiv.style.maxWidth = '100%';

    const date = `${new Date().toLocaleDateString('en-us', {})}`;
    setCreatingPDF(true);
    exportPDF(
      element,
      {
        title: renderPDFTitle(),
        saveFileName: `"${clientName ? `${clientName}'s Customer Insights` : 'Customer Insights'} ${date}"`,
      }
    ).then(() => {
      setCreatingPDF(false);
      element.remove();
    }).finally(() => setExporting(false));
  }, [clientName, handleMixpanelEvents, renderPDFTitle]);

  //render widgets on the overviewpage
  const renderWidgets = useCallback((): any => {
    if (clientIsLoading || isInsightsDataLoading.both && hasNoData) return <PageSpinner size='large' label='Loading customer insights' />;
    return widgets.map((widget, index) => {
      const { ref, ...rest } = widget;
      const componentData = insights[rest.id]
        ? {
          title: rest.title,
          values: rest.id === 'consumer_insight_ltvs'
            ? {
              ...insights[rest.id],
              ltv_global_dates: insights.ltv_global_dates
            }
            : insights[rest.id]
        }
        : undefined;
      return (
        <Box key={index} sx={{ mt: index === 0 ? '0' : '24px' }} ref={ref}>
          <WidgetCard
            data={componentData?.values}
            type='brief'
            title={componentData?.title}
            info={rest.infoTab}
            extra={componentData?.values?.insights?.length > 5 ? viewMoreButton(componentData?.values?.insights?.length, rest.id) : <></>}
            seeMore={seeMoreButton(rest.id)}
            isLoading={componentData?.title === CustomerInsightsWidgets.ltvTitle && isInsightsDataLoading.ltv}
            setLtvLabel={setLtvLabel}
          />
        </Box>
      );
    });
  }, [clientIsLoading, isInsightsDataLoading, widgets, insights, viewMoreButton, seeMoreButton, hasNoData]);

  //render a specific customer insight widget
  const renderWidget = useCallback((): any => {
    if (clientIsLoading || isInsightsDataLoading.both && hasNoData) return <PageSpinner size='large' label='Loading customer insights' />;
    const title = widgets.filter(widget => widget.id === customerInsightId)[0].title;
    const componentData = customerInsightId && insights[customerInsightId]
      ? (
        customerInsightId === 'consumer_insight_ltvs'
          ? { ...insights[customerInsightId], ltv_global_dates: insights.ltv_global_dates }
          : { ...insights[customerInsightId] }
      )
      : undefined;
    return (
      <>
        <Box sx={{ mt: '0' }}>
          {customerInsightId && insights[customerInsightId] &&
            <WidgetCard
              data={componentData}
              type='detailed'
              title={title}
              clientName={clientName ?? undefined}
              scrollToList={scrollToList}
              isLoading={title === CustomerInsightsWidgets.ltvTitle && isInsightsDataLoading.ltv}
              setLtvLabel={setLtvLabel}
            />
          }
        </Box>
      </>
    );
  }, [clientIsLoading, isInsightsDataLoading.both, isInsightsDataLoading.ltv, hasNoData, widgets, customerInsightId, insights, clientName, scrollToList]);


  useEffect(() => {
    if (!customerInsightId || customerInsightId === 'undefined') {
      renderWidgets();
    }

  }, [customerInsightId, insights, renderWidgets, widgets]);

  const renderCategoriesCardContent = useMemo((): JSX.Element => {
    const tabs = widgets.map((widget) => {
      if (widget.title === CustomerInsightsWidgets.ltvTitle) {
        return {
          id: widget.id,
          title: 'Lifetime Value',
          ref: widget.ref,
        };
      }
      return widget;
    });

    return (
      <Sidebar
        type='navigation'
        title='Categories'
        tabs={tabs}
        sticky
        extra={
          <div style={{ width: '100%', marginTop: '16px' }}>
            <ExportDropdownButton links={[
              { name: 'CSV', handler: exportToCSV },
              { name: 'PDF', handler: exportToPDF }
            ]} />
          </div>
        }
      />
    );
  }, [widgets, exportToCSV, exportToPDF]);

  //format updated_at time stamp
  const displayUpdatedTimestamp = useMemo(() => {
    if (insights && insights?.updated_at) {
      return `Data last updated ${moment(insights?.updated_at).tz('America/Los_Angeles').format('MMMM Do YYYY, h:mm:ss a')}`;
    }
    else {
      return 'Not Updated Yet';
    }
  }, [insights]);

  const renderBackToOverview = useCallback(() => {
    const title = widgets.filter(widget => widget.id === customerInsightId)[0].title;
    return (
      <Sidebar
        type='navigation'
        sticky
        extra={
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
              alignItems: 'flex-start'
            }}
          >
            <h3
              style={{
                fontFamily: 'Inter',
                fontSize: '20px',
                fontWeight: 600,
                lineHeight: '24px',
                paddingBottom: '6px'
              }}
            >
              {title === CustomerInsightsWidgets.ltvTitle ? 'Lifetime Value' : title}
            </h3>
            <ExportDropdownButton links={[
              { name: 'CSV', handler: exportToCSV },
              { name: 'PDF', handler: exportToPDF }
            ]} />
          </div>
        }
      />
    );
  }, [widgets, exportToCSV, exportToPDF, customerInsightId]);

  //render hero component w/ date filter
  const renderCustomerInsightsHero = useMemo((): JSX.Element => (
    <div className={css.cardContentWrapper} style={{ marginBottom: filters.client_id ? '10px' : '0' }}>
      <Grid component='div' direction='column' display='flex' container>
        <div style={{ display: 'flex', marginBottom: '20px', justifyContent: 'space-between', alignItems: 'center' }}>
          <div style={{ display: 'flex'}}>
            <Typography variant='h2' style={{ paddingRight: '8px' }}>
              Gather Customer Insights
            </Typography>
            <InfoTooltip
              title='In order to populate data please select both a client and a time period. The benchmark data is based on the client&apos;s assigned industry, if the industry gets changed in the client&apos;s profile please wait 24 hours for the data to be reflected.'
            />
          </div>
          <Typography fontStyle='italic' color='#2C2C2C'>
            All fields below are required.
          </Typography>
        </div>
        <CIFilters />
      </Grid>
    </div>
  ), [filters.client_id]);

  const renderSidebarContent = useCallback((): JSX.Element => {
    if (filters.client_id && filters.date_range_id && !customerInsightId) {
      return (
        <Grid sx={{ position: 'sticky', top: 0, paddingTop: '32px' }}>
          <Card
            title={
              <Typography variant='h4'>
                Categories
              </Typography>
            }
          >
            {renderCategoriesCardContent}
          </Card>
        </Grid>
      );
    } else if (filters.client_id && filters.date_range_id && customerInsightId) {
      return (
        <Grid sx={{ position: 'sticky', top: 0, paddingTop: '32px' }}>
          <Card
            title={
              <Button
                disableRipple
                size='small'
                startIcon={<ArrowLeftIcon />}
                onClick={backToOverview}
              >
                Back to overview
              </Button>
            }
          >
            {renderBackToOverview()}
          </Card>
        </Grid>
      );
    } else {
      return (
        <div></div>
      );
    }
  }, [filters.client_id, filters.date_range_id, customerInsightId, renderCategoriesCardContent, backToOverview, renderBackToOverview]);

  const renderWidgetsContent = useCallback((): JSX.Element => {
    const styles = isInsightsDataLoading.both && hasNoData ? {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      marginLeft: '24px',
    } : { marginLeft: '24px' };

    if (filters.client_id && filters.date_range_id && !customerInsightId) {
      return (
        <Grid sx={styles}>
          {renderWidgets()}
        </Grid>
      );
    } else if (filters.client_id && filters.date_range_id && customerInsightId) {
      return (
        <Grid sx={styles}>
          {renderWidget()}
        </Grid>
      );
    } else {
      return (
        <div></div>
      );
    }
  }, [isInsightsDataLoading, filters.client_id, filters.date_range_id, customerInsightId, renderWidgets, renderWidget, hasNoData]);

  const renderAboutThisTool = useMemo(() => (
    <AboutThisTool
      cards={[
        {
          title: 'What is nova Intelligence Customer Insights?',
          content: (
            <div>
              The nova Intelligence Customer Insights tool, powered by Data Axle, unlocks a plethora of customer data for you that can be utilized to accurately show your clients who their customers are, what they do, trends, and much more.
            </div>
          )
        },
        {
          title: 'What are the benefits of using Customer Insights?',
          content: (
            <div>
              <p>Benefits include, but aren’t limited to:</p>
              <ul>
                <li>Clients being able to identify, predict and address the factors that influence their sales and profits.</li>
                <li>Gathering accurate data that can be used to develop strategies around client campaigns.</li>
                <li>Identifying opportunities in customer categories that you didn’t know existed.</li>
              </ul>
            </div>
          )
        },
        {
          title: 'How is Customer Insights data being pulled?',
          content: (
            <div>
              The nova Intelligence Customer Insights tool is powered by Data Axle. Data Axle empowers us with a database of over 280 million customer records that can be utilized to develop strategies, campaigns, and ultimately affect the bottom line of your clients.
            </div>
          )
        }
      ]}
    />
  ), []);

  // Checking if any of the ids have Salesforce Commerce Cloud connectors
  const playbookAdsDataHasSFCC = clientsRequest?.data.some(client =>
    client.id === filters.client_id && client?.fivetran_connectors?.some(connector => connector?.data_source?.slug === 'salesforce-commerce-cloud' && connector?.data_feed_created)
  );

  return (
    <Container
      maxWidth={false}
      hasVerticalPadding={false}
      disableHorizontalPadding
    >
      <AccessControl action={[Action.read]} resource={Resource.customerInsights}>
        <CIFiltersProvider filters={{ filters, setFilters }}>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              paddingBottom: `calc(${window.innerHeight}px * .1)`,
            }}
            ref={topRef}
          >
            {/* pass empty anchorlinks to remove unecessary bottom padding after hero header */}
            <PageHero title={customerInsightsTitle} anchorLinks={<></>}>
              <>
                {filters.client_id && <ClientIndustryLink industry={clientIndustry} client_id={filters.client_id} />}
                {/* TODO: Uncomment when we have the correct "updated at" timestamp */}
                {/* <UpdatedAtTimestamp timestamp={displayUpdatedTimestamp}/> */}
                <Card paddingTop='0px !important'>
                  {renderCustomerInsightsHero}
                  {playbookAdsDataHasSFCC && (
                    <Alert severity="warning" sx={{ mt: '20px' }}>
                      Salesforce Commerce Cloud refunds data is currently missing. LTV values may not be 100% accurate. We are working on this.
                    </Alert>
                  )}
                </Card>
              </>
            </PageHero>

            <Container hasVerticalPadding>
              {(!(filters.client_id && filters.date_range_id) && !customerInsightId) &&
                <Grid container direction='row' sx={{ mt: '32px' }}>
                  <Box>{renderAboutThisTool}</Box>
                </Grid>
              }

              {(hasNoData &&
              !isInsightsDataLoading.both &&
              filters.date_range_id &&
              filters.client_id)
                ?
                <NoDataForTimePeriod label='customer insights' />
                :
                <Grid container direction='row'>
                  <Grid
                    item
                    xs={3}
                    sx={{ position: 'sticky', top: 0 }}
                  >
                    {renderSidebarContent()}
                  </Grid>
                  <Grid
                    item
                    container
                    direction="column"
                    xs={9}
                    ref={printRef}
                    sx={{
                      paddingTop: '32px'
                    }}
                  >
                    {renderWidgetsContent()}
                  </Grid>
                </Grid>
              }
            </Container>
          </div>
        </CIFiltersProvider>
      </AccessControl>
    </Container>
  );
});

export const FormattedTitle = memo((): JSX.Element => {
  const { id } = useParams<{ [x: string]: string }>();

  const { data: client, isLoading, error } = useGetClientQuery({
    id: Number(id),
    projection: {
      id: true,
      name: true,
    }
  });
  if (error) return <>{client?.id}</>;
  return !isLoading ? <>{client?.name + '\'s Customer Insights'}</> : <>...</>;
});

FormattedTitle.displayName = 'FormattedTitle';
