import { useEffect, useState } from 'react';
import { Projection, novaGraphQLClient } from 'api/entityGraphQL';
import { fetchForecastById } from './actions';
import { Forecast } from './forecast';
import { selectForecastById } from './selectors';
import { useEntityByParamId } from '../_entity/hooks';

export const useForecastById = (
  forecastId: number | undefined,
  projection?: Projection
): {
  loading: boolean,
  error: string,
  forecast: Partial<Forecast>
} => {
  const [forecast, setForecast] = useState<Partial<Forecast>>({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        if (forecastId) {
          const { forecasts } = await novaGraphQLClient.fetchForecastById(parseInt(String(forecastId)), { projection });
          setError('');
          setForecast(forecasts[0]);
          setLoading(false);
        }
      } catch(error) {
        setLoading(false);
        setError(error?.response?.errors?.length ? error?.response?.errors[0]?.message : 'Unknown error');
      }
    })();
  }, [forecastId, projection]);

  return { forecast, loading, error };
};

export const useForecastByParamId = (projection?: Projection): Forecast =>
  useEntityByParamId(selectForecastById, fetchForecastById, { projection }, 'forecastId');
