import { EntityId } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { fieldsAdapter } from './adapter';
import { selectEntitiesDenormalized } from '../_entity/selectors';

export const selectChurnsByIds = (churnIds: EntityId[]) => (state: RootState) => {
  const input = { dashboard_churns: churnIds };
  return selectEntitiesDenormalized(state, input).dashboard_churns;
};

export const selectRapportsByIds = (rapportIds: EntityId[]) => (state: RootState) => {
  const input = { dashboard_rapports: rapportIds };
  return selectEntitiesDenormalized(state, input).dashboard_rapports;
};

export const selectPacesByIds = (paceIds: EntityId[]) => (state: RootState) => {
  const input = { dashboard_paces: paceIds };
  return selectEntitiesDenormalized(state, input).dashboard_paces;
};

export const selectChurnById = (churnId: number) => (state: RootState) => {
  return selectChurnsByIds([churnId])(state)[0];
};

export const selectRapportById = (rapportId: number) => (state: RootState) => {
  return selectRapportsByIds([rapportId])(state)[0];
};

export const churnSelectors = fieldsAdapter.getSelectors(
  (state: RootState) => state.entities.data.dashboard_churns
);

export const rapportSelectors = fieldsAdapter.getSelectors(
  (state: RootState) => state.entities.data.dashboard_rapports
);

export const paceSelectors = fieldsAdapter.getSelectors(
  (state: RootState) => state.entities.data.dashboard_paces
);

export const selectAllChurns = (state: RootState) => {
  const churnIds = churnSelectors.selectIds(state);
  return selectChurnsByIds(churnIds)(state);
};

export const selectAllRapports = (state: RootState) => {
  const rapportIds = rapportSelectors.selectIds(state);
  return selectRapportsByIds(rapportIds)(state);
};

export const selectAllPaces = (state: RootState) => {
  const paceIds = paceSelectors.selectIds(state);
  return selectPacesByIds(paceIds)(state);
};