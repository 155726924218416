import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, Controller, DatePicker, Divider, Radio, Select, Stack } from '@sprnova/nebula';
import { useGetClientsQuery } from 'api/crudGraphQL/clients/getClients';
import { useGetCustomerInsightDateRangesQuery } from 'api/crudGraphQL/customer_insights/getCustomerInsightDateRanges';
import { useFormWithQueryParams } from 'components/nebula/Form/useFormWithQueryParams';
import VirtualizedListBox from 'components/VirtualizedAutocomplete/VirtualizedListBox';
import { format } from 'date-fns';
import { DateParam, StringParam, NumberParam, JsonParam, useQueryParams } from 'use-query-params';
import { useCIFiltersContext } from '../hooks/useCustomerInsightsFilters';

const CIFilters = (): JSX.Element => {
  const [queryParams] = useQueryParams({
    period: StringParam,
  });

  const [period, setPeriod] = useState<string>(queryParams.period || 'time');
  const [disabled, setDisabled] = useState<boolean>(true);
  const { setFilters } = useCIFiltersContext();

  const defaultValues = {
    client: undefined,
    period,
    date_range_id: undefined,
    start_date: undefined,
    end_date: undefined,
  };

  const { control, getValues, watch, formState: { errors } } = useFormWithQueryParams({
    defaultValues,
    queryParamsConfig: {
      client: JsonParam,
      period: StringParam,
      date_range_id: NumberParam,
      start_date: DateParam,
      end_date: DateParam,
    }
  });

  const startDate = getValues('start_date');
  const endDate = getValues('end_date');

  const boxStyles = {
    justifyContent: 'space-between',
    flexGrow: 1,
    sx: {
      width: '50%',
      // remove width on smaller screens
      '@media (max-width: 1199px)': {
        width: '100%',
      },
    },
  };

  const buttonStyles = {
    display: 'flex',
    justifyContent: 'end',
    sx: {
      paddingTop: '20px',
    },
  };

  const { data: getClientsData, isLoading: isClientsLoading } = useGetClientsQuery(
    {
      limit: -1,
      has_consumer_insights: 1,
      projection: {
        id: true,
        name: true,
      }
    }
  );

  const { data: getCIDateRangeData, isLoading: isCIDateRangesLoading } = useGetCustomerInsightDateRangesQuery({
    projection: {
      id: true,
      range: true,
    }
  });

  const dateRanges = useMemo(() => {
    return getCIDateRangeData
      ?.filter((dateRange) => dateRange.range !== 'Custom Date')
      .map((dateRange) => ({
        id: dateRange.id,
        label: dateRange.range,
      })) || [];
  }, [getCIDateRangeData]);

  const clients = useMemo(() => (
    getClientsData?.data?.map((client) => ({
      label: client.name,
      id: client.id,
      value: client.id,
    })) || []
  ), [getClientsData?.data]);

  const disabledCheck = useCallback((values?: Record<string, unknown>) => {
    const { period, client, date_range_id, start_date, end_date } = values || getValues() || {};
    // Check if time period or start/end dates are set
    const hasTimePeriodOrDates = period === 'time' && !!date_range_id
      || period === 'range' && (!!start_date || !!end_date);
    // Check if client is selected
    const hasClient = client;
    setDisabled(!hasTimePeriodOrDates || !hasClient);
  }, [getValues]);

  useEffect(() => {
    // initial disabled check
    disabledCheck();
  }, []);

  useEffect(() => {
    // watch for changes in period
    // useFormWithQueryParams should also handle the radios so the query params are synced
    const subscription = watch((values) => {
      setPeriod(values.period);
      // handle disabled check
      disabledCheck(values);
    });
    return (): void => subscription.unsubscribe();
  }, [disabledCheck, watch]);

  const renderClient = useMemo(() => {
    return (
      <div style={{paddingTop: '55px'}}>
        <Controller
          control={control}
          name='client'
          render={({ field: { onChange, ...field } }) => (
            <Autocomplete
              {...field}
              id='client-select'
              label='Client'
              limitTags={2}
              onChange={(event, newValue) => {
                onChange(newValue);
              }}
              options={clients}
              skeleton={isClientsLoading}
              ListboxComponent={VirtualizedListBox}
              error={errors?.client?.message}
            />
          )}
        />
      </div>
    );
  }, [clients, control, errors, isClientsLoading]);

  const renderPeriod = useMemo(() => {
    if (period === 'time') {
      return (
        <Controller
          control={control}
          name='date_range_id'
          render={({ field }) => (
            <Select
              {...field}
              id='date_range_id-select'
              label='Time Period'
              error={errors?.date_range_id?.message}
            >
              {dateRanges.map((option) => (
                <Select.Item value={option.id} key={`creative-affinity-filter-${option.label}`}>{option.label}</Select.Item>
              ))}
            </Select>
          )}
        />
      );
    }
    if (period === 'range') {
      return (
        <Stack direction='row' spacing={2} flexGrow={1}>
          <div style={{ width: '50%' }}>
            <Controller
              control={control}
              name='start_date'
              render={({ field }) => (
                <DatePicker
                  {...field}
                  id='start-date-select'
                  label='Start Date'
                  disableFuture
                  maxDate={endDate}
                  slotProps={{
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                />
              )}
            />
          </div>
          <div style={{ width: '50%' }}>
            <Controller
              control={control}
              name='end_date'
              render={({ field }) => (
                <DatePicker
                  {...field}
                  id='end-date-select'
                  label='End Date'
                  minDate={startDate}
                  slotProps={{
                    actionBar: {
                      actions: ['clear']
                    }
                  }}
                />
              )}
            />
          </div>
        </Stack>
      );
    }
  }, [control, dateRanges, endDate, errors, period, startDate]);

  const handleSubmit = useCallback(() => {
    const { client, date_range_id, start_date, end_date } = getValues();
    const formatStr = 'yyyy-MM-dd';
    setFilters({
      type: 'add',
      value: {
        start_date: period === 'range' && start_date
          ? format(start_date, formatStr)
          : undefined,
        end_date: period === 'range' && end_date
          ? format(end_date, formatStr)
          : undefined,
        date_range_id: period === 'time' ? date_range_id : 1001,
        client_id: client.id,
      }
    });
  }, [getValues, period, setFilters]);

  const renderSubmitButton = useMemo(() => {
    return (
      <Button
        variant='primary'
        onClick={handleSubmit}
        disabled={disabled}
        size='large'
      >
        Gather Customer Insights
      </Button>
    );
  }, [handleSubmit, disabled]);
  return (
    <>
      <Stack
        direction={{ xs: 'column', lg: 'row'}}
        divider={<Divider orientation="vertical" flexItem />}
        justifyContent='space-between'
        spacing={2}
        useFlexGap
        alignItems='stretch'
      >
        <Box {...boxStyles}>
          {renderClient}
        </Box>
        <Box {...boxStyles}>
          <Stack>
            <Controller
              control={control}
              name='period'
              render={({ field: { onChange, ...field } }) => (
                <Radio.Group
                  {...field}
                  row
                  id='type-radio'
                  sx={{ mb: '20px' }}
                  onChange={(event, newValue) => {
                    onChange(newValue);
                  }}
                >
                  <Radio label='Time Period' value='time' />
                  <Radio label='Custom Range' value='range' />
                </Radio.Group>
              )}
            />
            {renderPeriod}
          </Stack>
        </Box>
      </Stack>
      <Box {...buttonStyles}>
        {renderSubmitButton}
      </Box>
    </>
  );
};
export default memo(CIFilters);
