/**
 * Get services
 */


import { Projection } from 'api/entityGraphQL';
import { Service } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi } from '../baseApi';
import { cleanArguments } from '../utils';

export type GetServicesQuery = {
  id?: number;
  name?: string;
  department_id?: number;
  pricing_version?: string;
  projection: Projection;
}

export type GetServicesResult = Service[];

export const { useGetServicesQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getServices: build.query<GetServicesResult, GetServicesQuery>({
      transformResponse: (response: { services: GetServicesResult }) => response.services,
      providesTags: (services = []) => {
        return [
          tagTypes.SERVICES,
          ...services.map(service => ({
            id: service.id,
            type: tagTypes.SERVICE
          }))
        ];
      },
      query: ({
        projection = { id: true, name: true },
        name,
        ...args
      }) => {
        const __args: Partial<GetServicesQuery> = args;

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              services: {
                __args: cleanArguments(__args),
                ...projection
              },
            }
          }
        };
      }
    })
  })
});
