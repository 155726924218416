/**
 * SectionBuilder -> Fields -> LeadChannelsField
 *
 * Dynamic FormList for adding multiple lead gen channels (b2b/b2c)
 */

import React, { FC } from 'react';
import { MinusCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import {
  Button,
  FormItem,
  FormList,
  InputNumber, Tooltip,
} from 'components';
import { SelectLeadChannel } from './components';
import { Channel } from '../../../../../../../entitiesRedux';
import { formatPercentInput } from '../../../../../../utils';
import { FieldProps } from '../../types';
import css from '../fieldgroup_shared.module.scss';

const LeadChannelsField: FC<FieldProps> = ({ name, form }) => {
  const discoveryLeadChannels = form.getFieldValue([name])?.filter((c: Channel) => !!c?.name) || [];
  return (
    <div className={css.root}>
      <FormList name={name}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field) => (
                <Row key={field.key} align="top" justify="space-between">
                  <Col xs={23} className={css.column_fieldset}>
                    <Row gutter={[12, 0]}>
                      <Col xs={24} lg={12}>
                        <FormItem
                          name={[field.name, 'name']}
                          label="Lead generation channel"
                          fieldKey={field.fieldKey}
                        >
                          <SelectLeadChannel
                            placeholder="Select a lead generation channel"
                            discoveryLeadChannels={discoveryLeadChannels}
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} lg={12}>
                        <FormItem
                          name={[field.name, 'percent']}
                          label="Percentage of sales"
                          fieldKey={field.fieldKey}
                        >
                          <InputNumber
                            formatter={formatPercentInput}
                            precision={2}
                            max={100}
                          />
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={1} className={css.column_delete}>
                    <Tooltip title="Delete">
                      <MinusCircleOutlined
                        className={css.delete}
                        onClick={() => remove(field.name)}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              ))}
              <FormItem>
                <Button type="text" header icon={<PlusCircleFilled />} onClick={() => add()}>
                  Add lead generation channel
                </Button>
              </FormItem>
            </div>
          );
        }}
      </FormList>
    </div>
  );
};

export default LeadChannelsField;
