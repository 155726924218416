import React from 'react';

export const TalentHedgehogLightIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="52.3511" y="75.8252" width="16" height="56" rx="8" transform="rotate(-126.749 52.3511 75.8252)"
      fill="#D9D9D9"/>
    <circle cx="86.1001" cy="40.5" r="8" fill="#FFA600"/>
    <rect x="63.7568" y="46.5723" width="16" height="56" rx="8" transform="rotate(138.702 63.7568 46.5723)"
      fill="#D9D9D9"/>
    <rect x="60.7778" y="107.5" width="16" height="100" rx="8" transform="rotate(-180 60.7778 107.5)" fill="#4A1FFF"/>
    <circle cx="26" cy="15.7998" r="8" fill="#E65957"/>
  </svg>
);
