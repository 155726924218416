import React from 'react';
import { Menu as AntdMenu } from 'antd';
import { MenuItemProps } from 'antd/lib/menu/MenuItem';
import classNames from 'classnames';
import css from './MenuItem.module.scss';

interface Props extends MenuItemProps {
  className?: string;
}

const MenuItem = ({ className, ...props }: Props) => {
  return (
    <AntdMenu.Item
      {...props}
      // rootPrefixCls="ant-menu-item"
      className={classNames(className, css.root)}
    />
  );
};

export default MenuItem;
