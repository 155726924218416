/* eslint-disable complexity */
/**
 * Strategies -> Create/edit form
 */

import React, { FC, useMemo, useEffect, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Form as AntdForm,
  Row,
  Col,
  Card,
  Divider,
} from 'antd';
import { Action, Role } from 'api/accessControl';
import { useGetBusinessTypesQuery } from 'api/crudGraphQL/business_types/getBusinessTypes';
import { useGetUsersQuery } from 'api/crudGraphQL/users/getUsers';
import { fetchClientByIdQuery, novaGraphQLClient } from 'api/entityGraphQL';
import classNames from 'classnames';
import { FormNav } from 'components/FormNav';
import { MagicOutlined } from 'components/Icon';
import { Radio } from 'components/Radio';
import { RadioGroup } from 'components/RadioGroup';
import moment from 'moment';
import pluralize from 'pluralize';
import { useQueryParam } from 'use-query-params';
import { useClientById, formatError, LABELS_TYPES } from 'utils';
import { ClientSalesforceSettings } from 'features/clients/ClientOverviewPage/components';
import {
  Client,
  Resource,
  Rule,
  StrategyFormValues,
  Task,
  selectErrorByActionType,
  Strategy,
  useAuditById,
  Pillar,
  Audit,
  User
} from 'features/entitiesRedux';
import { useAccount } from 'features/global/hooks';
import LostOrWonModal, {
  shouldShowLostOrWonModal,
} from 'features/strategies/components/LostOrWonModal';
import {
  message,
  AccessControl,
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  FormItem,
  SelectUser,
  SelectPillar,
  SelectClient,
  SelectLeadSource,
  SelectPartner,
  SelectAppraisal,
  StickyButton,
  Spin,
  Popconfirm,
  Select,
  Option,
  SelectSalesforceOpportunity,
  Switch,
  Tooltip,
  DatePicker,
} from 'components';
import {
  ClientContactFields,
  CommissionFields,
  Departments,
  RecommendedServices,
  SalesforceSyncAlert,
  SalesforceSyncModal,
} from './components';
import css from './StrategyForm.module.scss';
import { MAX_CONTRACT_LENGTH } from 'features/strategies/constants';

 type Types = {
   initialValues?: StrategyFormValues;
   departments_ids?: (undefined | number)[];
   isEdit?: boolean;
   onDelete?: () => void;
   onSubmit: (values: Record<string, unknown>, client?: Partial<Client>) => void;
   submitButtonLabel?: string;
   strategy?: Partial<Strategy>;
   openSyncModal?: boolean;
   setOpenSyncModal?: React.Dispatch<React.SetStateAction<boolean>>;
   isFetchingStrategy?: boolean;
 };

const StrategyForm: FC<Types> = ({
  initialValues,
  departments_ids,
  isEdit,
  onDelete,
  onSubmit,
  submitButtonLabel = 'Create',
  strategy,
  openSyncModal,
  setOpenSyncModal,
  isFetchingStrategy = false
}) => {
  const { account } = useAccount();
  const error = useSelector(
    selectErrorByActionType([
      'entities/updateStrategy',
      'entities/fetchStrategyById',
    ])
  );

  const [defaultClientId] = useQueryParam<string>('clientId');
  const [defaultClientName] = useQueryParam<string>('clientName');
  const [excludedDepartmentIds] = useState<number[]>([1021, 1028, 1026]); // AM, ES, Consulting departments
  const [form] = AntdForm.useForm();
  const [initialLeadSourceId, setInitialLeadSourceId] = useState<number>();
  const [openLostModal, setOpenLostModal] = useState<boolean>(false);
  const [disableTypeOfLead, setDisableTypeOfLead] = useState<boolean>(false);
  const [showEmployeeLeadSelect, setShowEmployeeLeadSelect] = useState<boolean>(
    false
  );
  const [localClient, setLocalClient] = useState<Partial<Client>>(
    initialValues?.client ? initialValues?.client : {}
  );
  const months = useMemo(
    () => Array.from(new Array(MAX_CONTRACT_LENGTH)).map((_, i) => i + 1),
    []
  );
  const [defaultAuditId] = useQueryParam<string>('appraisal_id');
  const audit =
     Number.isInteger(Number(defaultAuditId)) &&
     useAuditById(Number(defaultAuditId));
  const recommendedServices = audit?.rules
    ?.reduce((acc: any, rule: Rule) => acc.concat(rule.tasks), [])
    .sort(
      (a: Task, b: Task) => a.service.department.id - b.service.department.id
    );

  /**
    * Remove AM,ES, and Consulting departments from selectable departments.
    * AM/ES cannot be edited directly by the users on Blueprints.
=    */
  const departmentsFiltered = departments_ids?.filter((value) => {
    return value && !excludedDepartmentIds?.includes(value);
  });


  // Ant form requires some minor tweaks for the initial values
  const formatValues = useCallback(
    (values: Partial<StrategyFormValues> = {}) => {
      const audit_id = values?.audit
        ? values.audit.id
        : Number.isInteger(Number(defaultAuditId))
          ? Number(defaultAuditId)
          : null;

      const client_id = {
        value: values?.client_id
          ? values.client_id
          : defaultClientId
            ? parseInt(defaultClientId)
            : defaultAuditId
              ? audit?.client?.id
              : undefined,
        label: values?.client_name ?
          values.client_name :
          defaultClientName ?
            defaultClientName :
            audit?.client?.name ?
              audit?.client?.name :
              undefined
      };

      return {
        ...values,
        audit_id,
        date: values?.date ? moment(values.date) : undefined,
        client_id,
        commission_flat_fee: values?.commission_flat_fee !== 0 ? values?.commission_flat_fee : null
      };
    },
    [defaultAuditId, defaultClientId, audit?.client?.id, audit?.client?.name, defaultClientName]
  );

  /**
   * If creating a new blueprint, set the client id to null to start so
   * validation will work properly on a blank value
   */
  useEffect(() => {
    if (!isEdit) {
      form.setFieldsValue({
        client_id: null
      });
    }
  }, [form, isEdit]);

  // If 'create' mode and we have an audit, then check all recommended tasks by default
  useEffect(() => {
    if (!isEdit) {
      const checkedServices = recommendedServices?.map((t: Task) => t?.id);
      form.setFieldsValue({ tasks: checkedServices });
    }
  }, [isEdit, form, recommendedServices]);

  // Set the current user as the author if no author is selected yet
  useEffect(() => {
    if (account && !form.getFieldValue('author_id')) {
      form.setFieldsValue({ author_id: account.id });
    }
  }, [account, form]);

  // Format values and submit
  const handleOnFinish = (values: any) => {
    const formattedValues = {
      lost_reason_type: initialValues?.lost_reason_type || null,
      follow_up_date: initialValues?.follow_up_date || null,
      detailed_reason: initialValues?.detailed_reason || null,
      ...values,
      account_manager_id: values?.account_manager_id || null,
      author_id: values?.author_id,
      executive_sponsor_id: values?.executive_sponsor_id || null,
      lead_source_employee_id: values?.lead_source_employee_id || null,
      partner_id: values.partner_id || null,
      client_id: values?.client_id ? parseInt(values?.client_id?.value) : null,
      length: parseInt(values.length, 10),
      date: moment(values.date).format('YYYY-MM-DD'),
      tasks: values?.tasks?.length
        ? values?.tasks?.map((task: Task) => ({ id: task }))
        : [],
      audit_id: values.audit_id
        ? values.audit_id
        : defaultAuditId
          ? Number(defaultAuditId)
          : null,
      salesforce_opportunity_id: values.salesforce_opportunity_id || null,
      salesforce_is_primary: values?.salesforce_is_primary,
      guaranteed_term: values?.guaranteed_term ? parseInt(values?.guaranteed_term) : null,
      commission_percent_adjustment: typeof values?.commission_percent_adjustment === 'number'
        ? values?.commission_percent_adjustment
        : strategy?.commission_percent_adjustment
          ? strategy?.commission_percent_adjustment
          : 0,
      ad_spend: values?.ad_spend ? values?.ad_spend : null,
    };

    const filtered = novaGraphQLClient.filterUndefinedNullEmpty(
      formattedValues,
      // Can be reset/cleared:
      ['audit_id', 'departments', 'salesforce_opportunity_id', 'commission_flat_fee', 'commission_percent_adjustment', 'guaranteed_term', 'ad_spend', 'account_manager_id', 'executive_sponsor_id']
    );

    if (!values?.partner_id) {
      onSubmit({ partner_id: null, ...filtered }, localClient);
    } else {
      onSubmit(filtered, localClient);
    }
  };

  // Validation failed
  const handleFinishFailed = () => {
    message.error('One or more fields failed to validate. Please try again.');
  };

  const handleSelectClient = useCallback(
    async (client: Partial<Client>) => {
      if (client) {
        setInitialLeadSourceId(client?.lead_source?.id);
        // When client is selected, data about the client is fetched from the database and stored in localClient
        const { clients } = await fetchClientByIdQuery(
          parseInt(String(client?.id))
        );
        const currentClient = clients[0];
        setLocalClient(currentClient);
        const nextValues = {
          client_id: {
            value: client?.id ? client?.id : null,
            label: client?.name,
          },
          client_name: client?.name,
          account_manager_id: client?.account_manager?.id
            ? client?.account_manager?.id
            : null,
          executive_sponsor_id: client?.executive_sponsor?.id
            ? client?.executive_sponsor?.id
            : null,
          lead_source_id: client?.lead_source?.id
            ? client?.lead_source?.id
            : null,
          business_type_id: client?.business_type?.id
            ? client?.business_type?.id
            : undefined,
        };
        form.setFieldsValue(nextValues);
      }
    },
    [form]
  );

  /**
   * When an appraisal is selected, set the opportunity field to the appraisal's opportunity
   * @param appraisal - appraisal object
   */
  const handleSelectAppraisal = (appraisal:Partial<Audit> | undefined) => {
    if (appraisal) {
      form?.setFieldsValue({salesforce_opportunity_id: appraisal?.salesforce_opportunity_id});
      setOpportunitySelected(!!appraisal?.salesforce_opportunity_id);
    }
  };

  const handleDisable = () => {
    const pillarId = form.getFieldValue('pillar_id');
    if (pillarId === 1001 || pillarId === 1002 || pillarId === 1004) {
      switch (pillarId) {
        case 1001:
        case 1002:
          setDisableTypeOfLead(true);
          break;
        case 1004:
          setDisableTypeOfLead(false);
          break;
      }
      form.setFieldsValue({ lead_source_id: 1001 });
    } else {
      setDisableTypeOfLead(false);
      if (initialValues?.lead_source_id) setInitialLeadSourceId(initialValues?.lead_source_id);
      form.setFieldsValue({ lead_source_id: initialLeadSourceId });
    }
  };

  // Show form item for employee lead name only if the type of lead is employee generated
  useEffect(() => {
    if (form.getFieldValue('lead_source_id') === 1001) {
      setShowEmployeeLeadSelect(true);
    } else {
      setShowEmployeeLeadSelect(false);
    }
  }, [form]);

  /** if we have a clientId or appraisal_id in the URL then set some field values */
  const defaultClient = defaultClientId
    ? useClientById(Number(defaultClientId)).client
    : defaultAuditId
      ? useClientById(Number(audit?.client?.id)).client
      : undefined;

  useEffect(() => {
    if (defaultClient) {
      handleSelectClient(defaultClient);
    }
  }, [defaultClient, handleSelectClient]);

  /**
    * Salesforce
    *
    * Special handling of fields for salesforce_opportunity_id and salesforce_is_primary
    */
  // local state flag to track whether the client is linked to a Salesforce Account
  const [
    salesforceAccountSelected,
    setSalesforceAccountSelected,
  ] = useState<boolean>(!!localClient?.salesforce_client_id);
  // local state flag to track whether a selection is made for the Salesforce Opportunity
  const [opportunitySelected, setOpportunitySelected] = useState<boolean>(
    !!form?.getFieldValue('salesforce_opportunity_id')
  );
  // get all strategies for the current client
  const clientStrategies = useClientById(localClient?.id)?.client?.strategies;
  // get the Salesforce Opportunity ID from the form
  const salesforceOpportunityId = form.getFieldValue(
    'salesforce_opportunity_id'
  );

  // const [users, setUsers] =

  const { data: usersRequest, isFetching: usersIsFetching } = useGetUsersQuery({
    page: 1,
    limit: 9999,
    projection: { id: true, name: true },
  });

  const users = useMemo(() => {
    if (usersRequest?.data) {
      return usersRequest.data;
    }
    return [];
  }, [usersRequest]);

  // if we have a salesforce opportunity ID then update flag in local state
  useEffect(() => {
    if (salesforceOpportunityId) {
      setOpportunitySelected(true);
    } else {
      setOpportunitySelected(false);
    }
  }, [form, salesforceOpportunityId]);

  // Edit mode only -> if selected opportunity has no primary deals, then set salesforce_is_primary = true in the form
  useEffect(() => {
    // if we have a salesforce_opportunity_id in the form
    if (isEdit && salesforceOpportunityId) {
      const linkedStrategies = clientStrategies?.filter(
        (strategy) =>
          strategy?.salesforce_opportunity_id === salesforceOpportunityId
      );

      // if salesforce opportunity has no linked strategies, then set this one as primary
      if (Array.isArray(linkedStrategies) && linkedStrategies?.length === 0) {
        form?.setFieldsValue({ salesforce_is_primary: true });
      }

      // if salesforce opportunity has at least one linked strategy...
      if (linkedStrategies?.length) {
        const primaryDeals = linkedStrategies?.filter(
          (s) => s?.salesforce_is_primary
        );

        // if there are no primary deals, then set this one as primary by default
        if (primaryDeals?.length === 0) {
          form?.setFieldsValue({ salesforce_is_primary: true });
        }

        // if there is a primary deal and it has the same ID as the current strategy, then check as Primary Deal by default
        if (
          primaryDeals?.length &&
           primaryDeals?.[0]?.id === initialValues?.id
        ) {
          form?.setFieldsValue({ salesforce_is_primary: true });
        }
      }
    }
  }, [form, clientStrategies, salesforceOpportunityId, isEdit, initialValues?.id]);

  // if the client has a salesforce_account_id then update flag in local state
  useEffect(() => {
    if (localClient?.salesforce_client_id) {
      setSalesforceAccountSelected(true);
    } else {
      setSalesforceAccountSelected(false);
    }
  }, [localClient]);

  const handleSelectSalesforceAccount = (salesforce_account_id?: string | null) => {
    if (salesforce_account_id) setSalesforceAccountSelected(true);
    setLocalClient({ ...localClient, salesforce_client_id: salesforce_account_id });
  };

  const handleUnselectSalesforceAccount = () => {
    setSalesforceAccountSelected(false);
    setLocalClient({ ...localClient, salesforce_client_id: null });
  };

  // Selecting an Opportunity will enable the Primary Deal field
  const handleSelectOpportunity = (val: string) => {
    if (val) {
      setOpportunitySelected(true);
      form?.setFieldsValue({ salesforce_opportunity_id: val });
      if (!isEdit) {
        // on Create page, set the value for Primary Deal to true by default
        form?.setFieldsValue({ salesforce_is_primary: true });
      }
    }
  };

  const handleUnselectOpportunity = () => {
    form?.setFieldsValue({
      salesforce_opportunity_id: null,
      salesforce_is_primary: false,
    });
    setOpportunitySelected(false);
  };

  const renderClientSalesforceSettings = (
    <ClientSalesforceSettings
      client={localClient}
      isDisabled={!localClient?.id}
      onSelectAccount={handleSelectSalesforceAccount}
      onUnselectAccount={handleUnselectSalesforceAccount}
    />
  );

  const renderSelectOpportunity = (
    <SelectSalesforceOpportunity
      block
      salesforceClientId={localClient?.salesforce_client_id}
      allowClear
      onSelect={handleSelectOpportunity}
      onClear={handleUnselectOpportunity}
      skeleton={isFetchingStrategy}
      disabled={
        !localClient?.salesforce_client_id || !salesforceAccountSelected
      }
    />
  );

  // Open lost reason modal if the blueprint is set to primary
  // and it has a status of "lost" to enable the user to set a lost reason
  const onPrimaryChange = (isPrimary: boolean) => {
    if (
      isPrimary &&
       initialValues &&
       shouldShowLostOrWonModal({
         status: initialValues?.status,
         salesforce_opportunity_id: form.getFieldValue(
           'salesforce_opportunity_id'
         ),
         salesforce_is_primary: isPrimary,
       })
    ) {
      setOpenLostModal(true);
    }
  };

  const renderPrimaryToggle = (
    <Switch
      disabled={!opportunitySelected || isFetchingStrategy}
      onChange={onPrimaryChange}
    />
  );

  // Lost modal
  const initialLostModalValues = useMemo(
    () => ({
      lost_reason_type: initialValues?.lost_reason_type,
      follow_up_date: initialValues?.follow_up_date,
      detailed_reason: initialValues?.detailed_reason,
    }),
    [initialValues]
  );

  const handleUpdateLostReason = async (
    values: Pick<
       StrategyFormValues,
       'lost_reason_type' | 'detailed_reason' | 'follow_up_date' | 'status'
     >
  ) => {
    await onSubmit({
      ...values,
      salesforce_is_primary: true,
      salesforce_opportunity_id: form.getFieldValue(
        'salesforce_opportunity_id'
      ),
    });

    setOpenLostModal(false);
  };

  // Fetch the client on load so we get the latest data
  useEffect(() => {
    (async () => {
      if (localClient?.id) {
        try {
          const { clients } = await fetchClientByIdQuery(
            parseInt(String(localClient?.id))
          );
          const client = clients[0];
          setLocalClient(client);
        } catch (error) {
          console.error(error);
        }
      }
    })();
  }, []);

  // For the edit form
  const client = initialValues?.client;

  // Only display commission-related fields for super-admin, execs, accounting and the Blueprint owner
  const permittedRoles = [
    Role.superAdmin,
    Role.cLevel,
    Role.vpLevel,
    Role.accounting,
  ];
  const showCommissionFields = permittedRoles.some(el => account?.roles?.length && account?.roles?.indexOf(el) > -1);

  // Gets Business Types
  const { data: business_types = [] } = useGetBusinessTypesQuery({
    projection: {
      name: true,
      id: true
    }
  });

  return (

    <div className={css.root}>
      <FormNav sections={['General Blueprint Information','Contract Details','Departments','Team','Billing Contact Information']}
        ids={['General_Blueprint_Information','Contract_Details','Departments','Team','Billing_Contact_Information']}  />

      <Form
        className={css.form}
        layout="vertical"
        name="createOrEditStrategyForm"
        initialValues={formatValues(initialValues)}
        onFinish={handleOnFinish}
        form={form}
        scrollToFirstError
        onFinishFailed={handleFinishFailed}
      >

        <Card className={css.card}>
          <section id='General_Blueprint_Information' className={css.section}>

            General Blueprint Information
          </section>
          <Row gutter={30}>
            <Col xs={{ span: 24 }} lg={{ span: 24 }}>
              {isEdit ? (
                <FormItem label="Client">
                  {client?.name}
                  <Link to={`/power-view/accounts/${client?.id}/edit`}>
                    {' '}
                     &nbsp;(edit client)
                  </Link>
                </FormItem>
              ) : (
                <FormItem
                  label="Client"
                  name="client_id"
                  rules={[
                    { required: !isEdit, message: 'Please select a client' },
                  ]}
                >
                  <SelectClient
                    onSelectClient={handleSelectClient}
                    skeleton={isFetchingStrategy}
                    placeholder="XYZ Company"
                    //autoFocus
                    notFoundText={{
                      description: (
                        <span>
                           No client with that name exists in{' '}
                          <strong>nova</strong>. Create a new client in{' '}
                          <strong>nova</strong> in order to create a Blueprint.
                        </span>
                      ),
                      buttonText: 'Add New Client',
                    }}
                    labelInValue
                  />
                </FormItem>
              )}
              <FormItem name="client_name" noStyle>
                <Input type="hidden" />
              </FormItem>
              <FormItem
                label="Blueprint Description"
                name="name"
                //rules={[{ required: true, message: 'Please enter a name' }]}
                help="Ex) Paid Media - 6/12/22 start date - resign"
                rules={[
                  { required: !isEdit},
                ]}
              >
                <Input
                  skeleton={isFetchingStrategy}
                  // autoFocus={!isEdit}
                />
              </FormItem>
              {/* Client cannot be changed for now */}
              <Row>
                <Col xs={24} lg={24}>
                  <FormItem label="Link to appraisal" name="audit_id">
                    <SelectAppraisal
                      onSelectAppraisal={handleSelectAppraisal}
                      allowClear
                      clientId={localClient?.id || client?.id}
                      disabled={!localClient?.id && !client?.id}
                      skeleton={isFetchingStrategy}
                      disableFetchOnSearch
                    />
                  </FormItem>
                </Col>

                <Col xs={24} lg={24}>
                  <div className={css.clientSalesforceSettings}>
                    {!localClient?.id ? (
                      <Tooltip
                        title="Please select a client for this Blueprint first"
                        placement="top"
                      >
                        <span>{renderClientSalesforceSettings}</span>
                      </Tooltip>
                    ) : (
                      renderClientSalesforceSettings
                    )}
                  </div>
                </Col>

                <Col xs={24} lg={24}>
                  <FormItem
                    label="Link to Salesforce Opportunity"
                    name="salesforce_opportunity_id"
                  >
                    {!localClient?.salesforce_client_id ? (
                      <Tooltip
                        title="Please link to a Salesforce Account first"
                        placement="top"
                      >
                        <div style={{ width: '100%' }}>
                          {renderSelectOpportunity}
                        </div>
                      </Tooltip>
                    ) : (
                      renderSelectOpportunity
                    )}
                  </FormItem>
                </Col>
              </Row>

              {opportunitySelected && (
                <Row>
                  <Col xs={24} lg={16}>
                    <FormItem
                      label="Set as primary Blueprint for this Opportunity"
                      name="salesforce_is_primary"
                      help='The primary Blueprint will be used to update Salesforce information like status and gross profit.
                      Any time you edit the blueprint and want to the changes reflected in Salesforce, press the "Sync Now" button.'
                    >
                      {renderPrimaryToggle}
                    </FormItem>
                  </Col>
                </Row>
              )}

              <section id='Contract_Details' className={css.section}>
                Contract Details
              </section>

              <FormItem
                label={LABELS_TYPES.BUSINESS_UNIT_FIRST_LETTERS_CAPITALIZED}
                name="business_type_id"
                rules={[
                  { required: true, message: `Please select a ${LABELS_TYPES.BUSINESS_UNIT}` },
                ]}
              >
                <RadioGroup
                >
                  {business_types.map((business_type: Pillar) => (
                    <Radio key={business_type.id} value={business_type.id}>
                      {business_type.name}
                    </Radio>
                  ))}
                </RadioGroup>
              </FormItem>

              <FormItem
                label="Which Pillar is this for?"
                name="pillar_id"
                rules={[{ required: true, message: 'Please select a pillar' }]}
              >
                <SelectPillar onSelect={handleDisable} skeleton={isFetchingStrategy} />
              </FormItem>
              <FormItem
                label="Type of Lead"
                name="lead_source_id"
                rules={[
                  {
                    required: !disableTypeOfLead,
                    message: 'Please select a lead type',
                  },
                ]}
              >
                <SelectLeadSource
                  onChange={(e) => {
                    if (e === 1001) {
                      setShowEmployeeLeadSelect(true);
                    } else {
                      setShowEmployeeLeadSelect(false);
                    }
                  }}
                  disabled={disableTypeOfLead}
                  skeleton={isFetchingStrategy}
                  excludeSlugs={!isEdit ? ['factorial-lead'] : []}
                />
              </FormItem>

              <FormItem
                label="Employee Lead Name"
                name="lead_source_employee_id"
                style={showEmployeeLeadSelect ? {} : { display: 'none' }}
                rules={[
                  {
                    required: showEmployeeLeadSelect,
                    message:
                       'Please select the employee that generated the lead',
                  },
                ]}
              >
                <SelectUser
                  allowClear
                  skeleton={isFetchingStrategy || usersIsFetching}
                  users={users}
                />
              </FormItem>
              <FormItem
                label="Type of Contract"
                name="type"
                rules={[
                  { required: true, message: 'Please select a blueprint type' },
                ]}
              >
                <Select
                  disabled={isEdit}
                  skeleton={isFetchingStrategy}
                >
                  <Option value="retainer">Retainer</Option>
                  <Option value="project">Project</Option>
                </Select>
              </FormItem>

              <FormItem
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.type !== currentValues.type
                }
              >

                {() => (
                  <FormItem
                    label="Length of Contract"
                    name="length"
                    rules={[
                      {
                        required: true,
                        message: 'Please specify the length of the contract',
                      },
                    ]}
                  >
                    <Select
                      skeleton={isFetchingStrategy}
                    >
                      {months.map((month) => (
                        <Option value={month} key={month}>
                          {pluralize('month', month, true)}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                )}
              </FormItem>

              <FormItem
                label="Start Date"
                name="date"
                rules={[
                  { required: true, message: 'Please select a start date' },
                ]}
              >
                <DatePicker format="MM/DD/YYYY" style={{ width: '100%' }} skeleton={isFetchingStrategy} />
              </FormItem>

              <FormItem
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.length !== currentValues.length
                }
              >
                {({ getFieldValue }) => (
                  <FormItem
                    label="Guaranteed Term"
                    name="guaranteed_term"
                    rules={[
                      { required: true, message: 'Please select the guaranteed term' },
                    ]}
                  >
                    <Select
                      skeleton={isFetchingStrategy}
                      allowClear
                      disabled={getFieldValue('length') == undefined}
                    >
                      {months.slice(0, getFieldValue('length')).map((month) => (
                        <Option value={month} key={month}>
                          {pluralize('month', month, true)}
                        </Option>
                      ))}
                    </Select>
                  </FormItem>
                )}
              </FormItem>

              <FormItem
                label="Does this contract contain ad spend overage fees OR performance pricing fees?"
                name="ad_spend"
                rules={[
                  { required: strategy?.status === 'won', message: 'Please make a selection' },
                ]}
              >
                <RadioGroup skeleton={isFetchingStrategy}>
                  <Radio value="yes">Yes</Radio>
                  <Radio value="no">No</Radio>
                </RadioGroup>
              </FormItem>

              <section id='Departments' className={css.section}>
                Departments
              </section>

              <Departments isEdit={isEdit} skeleton={isFetchingStrategy} departments_ids={departmentsFiltered}/>

              <section id='Team' className={css.section}>
                Team
              </section>

              <FormItem label="Team Members" name="users">
                <SelectUser
                  allowClear
                  mode='multiple'
                  skeleton={isFetchingStrategy || usersIsFetching}
                  users={users}
                />
              </FormItem>
              <FormItem label="Account Manager" name="account_manager_id">
                <SelectUser
                  /*
                  TODO: Add role when available
                  roles={[Role.accountManager]}
                  */
                  allowClear
                  skeleton={isFetchingStrategy || usersIsFetching}
                  users={users}
                />
              </FormItem>
              <FormItem
                label="Sales Rep."
                name="author_id"
                rules={[
                  { required: true, message: 'Please select a sales rep' },
                ]}
              >
                <SelectUser
                  /*
                  TODO: Add role when available
                  roles={[Role.accountDirector]}
                  */
                  allowClear
                  skeleton={isFetchingStrategy || usersIsFetching}
                  users={users}
                />
              </FormItem>
              {showCommissionFields ?
                <CommissionFields
                  strategy={strategy}
                  isEdit={isEdit}
                  isLoading={isFetchingStrategy}
                /> : <></>
              }
              <FormItem label="Account Director" name="executive_sponsor_id">
                <SelectUser
                  /*
                  TODO: Add role when available
                  roles={[Role.accountDirector]}
                  */
                  allowClear
                  skeleton={isFetchingStrategy || usersIsFetching}
                  users={users}
                />
              </FormItem>
              <FormItem label="Referral partner?" name="partner_id">
                <SelectPartner allowClear skeleton={isFetchingStrategy} />
              </FormItem>
              {isEdit && strategy?.commission_version === 2 ?
                <FormItem label="Flat Fee Commission" name="commission_flat_fee">
                  <InputNumber
                    type="currency"
                    formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={value => `${value}`.replace(/\$\s?|(,*)/g, '')}
                    skeleton={isFetchingStrategy}
                  />
                </FormItem> : <></>
              }
            </Col>
          </Row>

          <section id='Billing_Contact_Information' className={css.section}>
            Billing Contact Information
          </section>
          <ClientContactFields
            isLoading={isFetchingStrategy}
            isDealWon={strategy?.status === 'won'}
          />

          {!isEdit && defaultAuditId ? (
            <AccessControl
              action={Action.read}
              resource={Resource.recommendedServices}
              showWarning={false}
            >
              <>
                <Divider orientation="left" className={css.divider}>
                  <MagicOutlined /> <span>Recommended Services</span>
                </Divider>
                <Row>
                  <Col xs={24}>
                    <FormItem name="tasks">
                      <RecommendedServices
                        recommendedServices={recommendedServices}
                        audit={audit}
                        newBlueprint={true}
                      />
                    </FormItem>
                  </Col>
                </Row>
              </>
            </AccessControl>
          ) : (
            <></>
          )}

          {error && <Alert message={formatError(error)} />}
          <div className={classNames(css.footer, css.sticky)}>
            <SalesforceSyncAlert sync={strategy?.salesforce_is_primary && strategy?.sync_to_salesforce} setOpenSyncModal={setOpenSyncModal} />
            {onDelete && (
              <AccessControl
                action={[Action.delete]}
                resource={Resource.strategy}
                showWarning={false}
              >
                <Popconfirm
                  title="Are you sure you want to delete this blueprint?"
                  onConfirm={onDelete}
                  okText="Yes"
                  cancelText="No"
                >
                  <Button danger type="text" className={css.deleteButton}>
                     Delete
                  </Button>
                </Popconfirm>
              </AccessControl>
            )}
            <StickyButton
              htmlType="submit"
              disabled={isFetchingStrategy}
              loading={<Spin loading={isFetchingStrategy} />}
            >
              {submitButtonLabel}
            </StickyButton>
          </div>
        </Card>
      </Form>
      <LostOrWonModal
        loading={isFetchingStrategy}
        open={openLostModal}
        onUpdate={handleUpdateLostReason}
        isCancellable={false}
        initialValues={initialLostModalValues}
        status={initialValues?.status}
      />
      <SalesforceSyncModal
        id={strategy?.id}
        loading={isFetchingStrategy}
        open={openSyncModal}
        setOpenSyncModal={setOpenSyncModal}
        sync={strategy?.sync_to_salesforce}
        salesforceOpportunityId={strategy?.salesforce_opportunity_id}
        salesforceIsPrimary={strategy?.salesforce_is_primary}
      />
    </div>
  );
};

export default StrategyForm;
