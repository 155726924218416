import { format, startOfMonth } from 'date-fns';
import { HorizonChannel, HorizonFunnel } from 'features/entitiesRedux/models/horizon';
import { BudgetTableValue } from './BudgetTable';

// Ensure the date is in the format we expect on the server - e.g. 01-01-23, 02-01-23
export const formatDateToFirstInMonth = (date: Date): string => format(
  startOfMonth(date),
  'yyyy-MM-dd'
);

// This is a quick fix to a timezone issue that was causing the date to be off by a day when calling new Date(date) with the yyyy-MM-dd format
// More info: https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
export const getNormalizedDate = (dateStr: string): Date => {
  const date = new Date(dateStr);
  return new Date( date.getTime() + Math.abs(date.getTimezoneOffset() * 60000) );
};

/**
* Copy/paste functionality
 */

/**
 * Parse copied values from ExceL/Google sheets to a 2D matrix
 * where the rows are dates and the cells are the values for each funnel/channel
 */
export const parsePastedValue = (value: string): number[][] => value
  .split('\n')
  .map(row => row
    .split('\t')
    .map(num => {
      // Parsing empty rows as 0 for now
      if (num === '') {
        return 0;
      }
      // Remove all non-numeric characters
      return Number(parseFloat(num.replace(/[^0-9.-]+/g,'')).toFixed(2));
    }));

/**
* Checks if the pasted value is a valid format
* Must be a copy/paste from Excel or Google Sheets
*/
export const isValidPaste = (value: string): boolean => {
  try {
    const parsed = parsePastedValue(value);

    // Ensure that all parsed values are numbers
    if (parsed.flat(2).some(isNaN)) {
      return false;
    }
    return true;
  } catch (e) {
    return false;
  }
};


/**
  * getBudgetTableMatrix
 *
 * This function generates a 2D matrix of budget table values where each row is a month and each cell is a funnel/channel.
 * This is used to match copy pasted data matrix to the correct funnel/channel
 */
type BudgetTableMatrixCell = Pick<BudgetTableValue, 'funnel' | 'channel' | 'date'>;

export const getBudgetTableMatrix = (
  dates: string[],
  channels: HorizonChannel[],
  funnels: HorizonFunnel[]
): BudgetTableMatrixCell[][] => dates.reduce<BudgetTableMatrixCell[][]>((acc, date) => [
  ...acc,
  channels.reduce<BudgetTableMatrixCell[]>((accChannels, channel) => [
    ...accChannels,
    ...funnels.reduce<BudgetTableMatrixCell[]>((accFunnels, funnel) => [
      ...accFunnels,
      {
        funnel,
        channel,
        date
      }
    ], [])
  ], [])
], []);
