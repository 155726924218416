import { Projection } from 'api/entityGraphQL';
import tagTypes from './tagTypes';
import { baseApi } from '../baseApi';
import { cleanArguments } from '../utils';

export type LTVGroupType = {
  id: number;
  name: string;
};
export type GetCustomerInsightsLTVGroupQuery = {
  id?: number;
  name?: string;
  projection?: Projection;
};
export type GetCustomerInsightsLTVGroupResult = LTVGroupType[];

const GET_CUSTOMER_INSIGHTS_LTV_GROUP_ENDPOINT_KEY = 'getCustomerInsightsLTVGroup';
export const { useGetCustomerInsightsLTVGroupQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_CUSTOMER_INSIGHTS_LTV_GROUP_ENDPOINT_KEY]: build.query<GetCustomerInsightsLTVGroupResult, GetCustomerInsightsLTVGroupQuery>({
      transformResponse: (response: { consumer_insight_ltv_group: GetCustomerInsightsLTVGroupResult }) => response.consumer_insight_ltv_group,
      providesTags: (groups = []) => [
        tagTypes.CONSUMER_INSIGHTS_LTV_GROUP,
        ...groups.map((group: LTVGroupType) => ({
          id: group.id,
          type: tagTypes.CONSUMER_INSIGHTS_LTV_GROUP
        }))
      ],
      query: ({
        projection = { id: true, name: true },
        id,
        name,
      }) => {
        const __args: Partial<GetCustomerInsightsLTVGroupQuery> = {
          id,
          name,
        };

        if (name && name !== '') {
          __args.name = `*${name}*`;
        } else {
          __args.name = undefined;
        }

        return {
          body: {
            query: {
              consumer_insight_ltv_group: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    }),
  })
});
