
import { CustomerInsightsTimeRangeValues } from '../constants';


export const formatDateRange = (filters: {
  date_range_id?: number;
  start_date?: string;
  end_date?: string;
}): string => {
  if (filters.date_range_id === undefined) {
    return 'from date range not specified';
  } else if (filters.date_range_id === 1001) { // 1001 is the value for custom date range using start_date and end_date
    if (filters.start_date && filters.end_date) {
      return `from ${filters.start_date} to ${filters.end_date}`;
    } else if (filters.start_date) {
      return `from ${filters.start_date} to now`;
    } else if (filters.end_date) {
      return `till ${filters.end_date}`;
    } else {
      return 'from date range not specified';
    }
  } else {
    return `from ${CustomerInsightsTimeRangeValues[filters.date_range_id]}`;
  }
};

export const formatValue = (type: string, value: number | null | undefined): number | null => {
  if (value === null || value === undefined) { // == null checks for both null and undefined
    return null;
  }

  switch (type) {
    case '$':
      // Round the value to the nearest whole number
      return Math.round(value);
    case '%':
      // First multiply the value by 100, then round it to the nearest whole number
      return Math.round(value * 100);
    case '#':
      // For '#', round it to 2 digits after the decimal point
      return Math.round(value * 100) / 100;
    default:
      // Return original value if an unsupported type is provided
      return value;
  }
};
