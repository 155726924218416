/**
 * Get Scoreboard (report) data sources
 */
import { Projection } from 'api/entityGraphQL';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments } from '..';

export type GetScoreboardDataSourcesQuery = {
  report_id: number;
  datasource_id?: number;
  datasource_slug?: string;
  projection?: Projection;
}

export type GetScoreboardDataSourcesResult = {
  client_id: number;
  data_source_id: number;
  data_source_name: string;
  data_source_slug: string;
  last_refresh_status: string;
  report_id: number;
  warehouse_source: string;
}[];

export const GET_SCOREBOARD_DATASOURCES_ENDPOINT_KEY = 'getScoreboardDataSources';

export const { useGetScoreboardDataSourcesQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_SCOREBOARD_DATASOURCES_ENDPOINT_KEY]: build.query<GetScoreboardDataSourcesResult, GetScoreboardDataSourcesQuery>({
      transformResponse: (response: { report_datasources: GetScoreboardDataSourcesResult}) => response.report_datasources,
      providesTags: (report) => {
        return report ? [
          { id: report?.[0]?.report_id, type: tagTypes.DATA_SOURCE }
        ] : [tagTypes.DATA_SOURCES];
      },
      query: ({
        report_id,
        datasource_id,
        datasource_slug,
        projection = { id: true },
      }) => {

        return {
          body: {
            query: {
              report_datasources: {
                __args: cleanArguments({
                  report_id,
                  datasource_id,
                  datasource_slug
                }),
                ...projection
              }
            }
          }
        };
      }
    }),
  }),
});
