import React, { forwardRef, useMemo } from 'react';
import { GetClientsQuery, useGetClientsQuery } from 'api/crudGraphQL/clients/getClients';
import { NumberParam, useQueryParams } from 'use-query-params';
import { Client } from 'features/entitiesRedux';
import { Dropdown, DropdownType } from './library-components/Dropdown/Dropdown';

type SelectClientProps = {
  handleSelect?: (value: any) => void;
  clearClientIdFromPath?: (value: any) => void;
  returnClientObject?: boolean;
  withQueryParams?: boolean;
  isProvidedDataLoading?: boolean;
  args?: Partial<GetClientsQuery>;
  name?: string;
  type?: 'autocomplete' | 'select';
} & Partial<DropdownType>;
/**
 * @param {boolean} returnClientObject For use in handleSelect; returns client values defined in projection if true; default = false
 * @param {function} handleSelect Select handler
 * @param {boolean} withQueryParams Sets client_id query param when client is selected; default = false
 * @param {boolean} isProvidedDataLoading Provided client data is loading
 * @param {GetClientsQuery} args Override client args
 * @returns clients dropdown
 */
export const SelectClient = forwardRef(({
  handleSelect,
  defaultValue,
  returnClientObject = false,
  withQueryParams = false,
  clearClientIdFromPath,
  args,
  name,
  type = 'autocomplete',
  label = 'Select Client',
  ...props
}: SelectClientProps, ref: any) => {
  const [_, setQueryParams] = useQueryParams({
    client_id: NumberParam
  });

  /**
   * RTK fetch client
   * Skips if clientsData is passed as prop
   */
  const { data: clientsRequest, isLoading } = useGetClientsQuery({
    limit: 9999,
    projection: { id: true, name: true },
    /** Override default limit and projection with passed args */
    ...(args ?? {}),
  });

  const clients = useMemo(() => {
    if (clientsRequest) {
      return clientsRequest.data.map(client => ({ id: client.id, label: client.name }));
    }
    /** Otherwise returns empty array */
    return [];
  }, [clientsRequest]);

  /** Sets default value (ex: setQueryParams on load) */
  if (defaultValue) {
    defaultValue = {
      id: defaultValue,
      label: clients?.filter(client => client?.id === defaultValue)?.[0]?.label
    };
  } else {
    defaultValue = undefined;
  }

  const handleChange = (event: React.SyntheticEvent, clientObject: Client) => {
    if(clientObject == null || clientObject == undefined) {
      if (typeof clearClientIdFromPath === 'function') {
        clearClientIdFromPath(clientObject);
      }
    }
    else if (clientObject !== undefined && clientObject !== null && Object.hasOwn(clientObject, 'id')) {
      const clientId = clientObject.id;
      if (clientId || typeof clientId === 'number') {
        if (withQueryParams) {
          setQueryParams({
            client_id: clientId
          });
        }
        if (handleSelect) {
          if (returnClientObject) {
            /** Passes entire client object */
            const client = clients?.filter((client: Partial<Client>) => client.id == clientId)[0];
            return handleSelect(client);
          } else {
            /** Or passes ID */
            handleSelect(clientId);
          }
        }
      }
    } else if (withQueryParams) {
      setQueryParams({
        client_id: undefined
      });
    }
  };

  const { isProvidedDataLoading, ...rest } = props;

  return (
    <Dropdown
      {...rest}
      loading={isLoading}
      value={isLoading === true ? '' : defaultValue}
      ref={ref}
      name={name}
      type={type}
      handleSelect={handleChange}
      options={clients}
      label={label}
    />
  );
});

SelectClient.displayName = 'SelectClient';