import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { FormItem } from 'components/FormItem';
import css from './StickyButton.module.scss';

type StickyButtonProps = {
  type?: string | any;
  htmlType?: string | any;
  onClick?: any;
  className?: string;
  children?: any;
  disabled?: boolean;
  loading?: any;
  visible?: boolean;
  floatRight?: boolean;
  containerClass?: string;
  position?: 'left' | 'center' | 'right';
};
export const StickyButton = ({
  type,
  htmlType,
  onClick,
  className,
  children,
  disabled,
  loading,
  floatRight,
  visible = true,
  containerClass = '.ant-tabs-tabpane-active',
  position = 'right',
}: StickyButtonProps): JSX.Element => {
  const isElementInViewport = (el?: any) => {
    const rect = el.getBoundingClientRect();
    const activeTab =
      document
        ?.querySelector(containerClass)
        ?.getBoundingClientRect().bottom || 0;
    return rect.top > -1 && activeTab <= window.innerHeight;
  };

  const [buttonStatus, setButtonStatus] = useState(false);
  useEffect(() => {
    if (visible) {
      const handleEvent = () => {
        setButtonStatus(
          !isElementInViewport(document.querySelector('.sticky-container'))
        );
      };
      window.addEventListener('scroll', handleEvent);
      window.addEventListener('load', handleEvent);
      return () => window.removeEventListener('scroll', handleEvent);
    }
  }, [buttonStatus, visible]);

  /** Don't render anything if visible prop is false */
  if (!visible) return <></>;

  return (
    <FormItem
      className={
        classNames(
          css.root, 
          'sticky-container', 
          className, 
          position,
          {
            [css.stickyContainer]: buttonStatus,
          }
        )}
    >
      <Button
        type={type}
        htmlType={htmlType}
        disabled={disabled}
        onClick={() => onClick}
        className={classNames(
          css.button,
          {
            [css.floatRight]: floatRight,
            [css.stickyButton]: buttonStatus,
          //'sticky-save-btn': buttonStatus
          }, 'sticky-save-btn')}
      >
        <>{children}</>
        <>{loading}</>
      </Button>
    </FormItem>
  );
};
