
import React, { useCallback } from 'react';
import { List, ListItemText, Typography} from '@mui/material';
import { InfoTooltip, styled } from '@sprnova/nebula';
import { Tag } from 'features/intelligence/components/library-components/Tag/Tag';
import { WidgetSubCard } from './WidgetSubcard';
import { formatValue } from '../CustomerInsightsUtils';

const benchmarkColors: { [key: string]: string } = {
  'on track': '#0288D1',
  'above': '#2E7D32',
  'below': '#ED6C02',
};

//styling
const Primary = styled('div')(() => ({
  color: '#000000',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 600,
  lineHeight: '19px',
  display: 'flex',
}));

const Secondary = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  paddingTop: '10px',
  color: '#000000',
  fontFamily: 'Inter',
  fontSize: '14px',
  fontWeight: 500,
  lineHeight: '16px',
}));

const MatchPercentage = styled(Typography)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 400,
  fontFamily: 'Inter',
  fontStyle: 'italic',
  color: '#575757',
  lineHeight: '22px',
  position: 'absolute',
  right: '0',
  paddingRight: '16px',
}));

type InsightListProps = {
  data?: any;
  height?: number;
}

type StatusTabType = {
  status: 'on track' | 'above' | 'below' | 'N/A';
  color?: 'blue' | 'green' | 'orange' | 'gray';
  width: string;
}

const InsightList = ({data=[], height}: InsightListProps): JSX.Element => {

  //calculate and setup state and color for each insight statistics value

  const setStatus = useCallback((value?: number | null, benchmark?: number | null): StatusTabType => {
    if (value == null || benchmark == null) { // == null checks for both null and undefined
      return { status: 'N/A', color: 'gray', width: '83px' };
    }
    if (Math.abs(value - benchmark) <= 5) {
      return { status: 'on track', color: 'blue', width: '83px' };
    } else if (value - benchmark > 5) {
      return { status: 'above', color: 'green', width: '63px' };
    } else {
      return { status: 'below', color: 'orange', width: '63px' };
    }
  }, []);

  const renderContent = useCallback((children: JSX.Element, statusTab: StatusTabType) => {
    return (
      <span>
        Compared to&nbsp;
        <span
          style={{
            color: benchmarkColors[statusTab.status],
            display: 'inline-flex',
            alignItems: 'center',
            margin: 0,
            fontWeight: 600
          }}
        >
          {children}
        </span>
        &nbsp;on average based on competitors in your industry.
      </span>
    );
  }, []);

  return (
    <List sx={{
      width: '100%',
      maxWidth: '100%',
      height: '100%',
      bgcolor: 'background.paper',
      overflow: 'hidden',
      padding: '0px',
      borderRadius: '8px',
    }}
    >
      {data?.map((row: any, index: number) => {
        const widgetValueType: string = row?.format;
        const benchmark =
        formatValue(widgetValueType,
          row?.benchmark_percentage !== undefined && row?.benchmark_percentage !== null
            ?
            row?.benchmark_percentage
            :
            row?.ltv_benchmark);
        const statistics =
        formatValue(widgetValueType,
          row?.percentage !== undefined && row?.percentage !== null
            ?
            row?.percentage
            :
            row?.ltv);
        const statusTab = setStatus(statistics, benchmark);

        return (
          <WidgetSubCard
            key={`insightsList-${row}-${index}`}
            index={index+1}
            statistics={statistics}
            valueType={widgetValueType}
            color={benchmarkColors[statusTab.status]}
            height='82px'
          >
            <ListItemText
              primary={
                <Primary>
                  {row?.display}
                  {row?.definition &&
                  <InfoTooltip
                    iconSize="small"
                    content={row?.definition}
                    sx={{ marginLeft: '10px' }}
                  />
                  }
                  {row?.match_percentage &&
                    <MatchPercentage>
                      Customer Percentage: {(row?.match_percentage * 100).toFixed(0)}%
                    </MatchPercentage>
                  }
                </Primary>
              }
              secondary={
                <Secondary>
                  <Tag
                    color={statusTab.color}
                    tagName={statusTab.status}
                    width={statusTab.width}
                    margin='0 8px 0 0'
                  />
                  {(() => {
                    switch (widgetValueType) {
                      case '$':
                        return renderContent(<>{widgetValueType}{benchmark}</>, statusTab);
                      case '#':
                        return renderContent(<>{benchmark}yrs</>, statusTab);
                      default:
                        return renderContent(<>{benchmark}{widgetValueType}</>, statusTab);
                    }
                  })()}
                </Secondary>
              }
              secondaryTypographyProps={{ component: 'div' }}
            />
          </WidgetSubCard>

        );
      })}
    </List>
  );
};

export default InsightList;
