import React from 'react';

export const TalentMyReviewLightIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="65.2111" cy="55.7555" r="8" transform="rotate(-47.6683 65.2111 55.7555)" fill="#4A1FFF"/>
    <rect x="25.9399" y="86.5459" width="16" height="78.7945" rx="8" transform="rotate(-90.1086 25.9399 86.5459)"
      fill="#D9D9D9"/>
    <rect x="89.0903" y="25.4541" width="16" height="59.9354" rx="8" transform="rotate(0.172814 89.0903 25.4541)"
      fill="#D9D9D9"/>
    <rect x="19.9097" y="41.4541" width="16" height="85" rx="8" transform="rotate(-90 19.9097 41.4541)" fill="#D9D9D9"/>
    <path
      d="M6.91017 44.042V86.382H49.2502C49.2502 86.3819 49.2502 86.382 49.2502 86.382C49.2502 62.9983 30.2938 44.042 6.91017 44.042C6.91022 44.042 6.91012 44.042 6.91017 44.042Z"
      fill="#E65957"/>
    <ellipse cx="18.7406" cy="18.7406" rx="18.7406" ry="18.7406" transform="matrix(-1 0 0 1 44.3911 25.4541)"
      fill="#FFA600"/>
    <path fillRule="evenodd" clipRule="evenodd"
      d="M6.91016 44.0553V44.335C6.98535 54.6208 15.3468 62.9358 25.6502 62.9358C30.5388 62.9358 34.9902 61.064 38.3268 57.998C30.5789 49.4279 19.3735 44.043 6.91033 44.043H6.91025C6.91022 44.0471 6.91019 44.0512 6.91016 44.0553Z"
      fill="#A94882"/>
  </svg>
);
