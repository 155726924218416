import React, { ReactNode } from 'react';

type PinterestLogicReturn = {
  isPinterest: boolean;
  pinterestDialogTitle: string;
  defaultAttribution: string;
};

/**
 * Custom hook for handling Pinterest-specific logic based on the datasource slug.
 *
 * @param {string} [datasourceSlug] - The slug representing the datasource. When set to 'paid-pinterest', the hook will activate Pinterest-specific logic.
 *
 * @returns {PinterestLogicReturn} An object containing:
 * - `isPinterest` (boolean): Flag indicating if the current datasource is Pinterest.
 * - `pinterestDialogTitle` (string): The title for the Pinterest dialog.
 * - `defaultAttribution` (string): The default attribution string for Pinterest.
 */
export function usePinterestLogic(datasourceSlug?: string): PinterestLogicReturn {
  const isPinterest = datasourceSlug === 'paid-pinterest';
  const pinterestDialogTitle = 'Pinterest Attribution Window';
  const defaultAttribution = '711';

  return { isPinterest, pinterestDialogTitle, defaultAttribution };
}
