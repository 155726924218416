import React from 'react';
import { Grid, Typography } from '@mui/material';

/**
 * ComingSoonStatus Component
 *
 * This component renders a small badge-like display indicating that a feature or
 * item is 'Coming Soon'. It's typically used to denote features or integrations
 * that are planned or in development but not yet available.
 *
 * @returns {JSX.Element} The 'Coming Soon' status badge.
 */
const ComingSoonStatus = () => {
  return (
    <Grid container sx={{backgroundColor: 'rgba(217, 217, 217, 1)', padding: '8px 12px', borderRadius: '16px' }}>
      <Typography variant='h6' sx={{ fontFamily: 'Inter', fontWeight: 600, padding: '0 10px', fontSize: '10px' }}>
        COMING SOON
      </Typography>
    </Grid>
  );
};

export default ComingSoonStatus;
