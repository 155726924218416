/**
 * Entities -> Clients -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { Client } from './client';
import { compareField } from '../../utils/compareField';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const clientsAdapter = createEntityAdapter<Client>({
  selectId: (client) => client.id,
  sortComparer: compareField('name'),
});
