/**
 * Entities -> Departments -> Reducers
 */

import { departmentsAdapter } from './adapter';
import { EntitiesState } from '../../types';

export default {
  'deleteDepartment/fulfilled'(state: EntitiesState, action: { payload:  number }) {
    state.isLoading = false;
    departmentsAdapter.removeOne(state.data.departments, action.payload);
  }
};
