import loadable from '@loadable/component';

const LoadableComponent = loadable(
  () => import('./FormatEntity')
  /* , {
  fallback: <div>Loading...</div>,
  } */
);

export { LoadableComponent as FormatEntity };
