import React from 'react';
import classNames from 'classnames';
import Popover from 'components/Popover/Popover';
import { Placement } from './ScoreBreakdownPopover';
import { Department } from '../../../../entitiesRedux';
import { AppraisalScoreStatusTag } from '../../../components';
import css from './ScoreBreakdownPopover.module.scss';

interface OpportunityScoreProps {
  score?: number;
  departments?: Department[];
  children?: React.ReactNode;
  placement: Placement;
}

export const OpportunityScore = ({
  score,
  departments,
  children,
  placement = 'bottom',
}: OpportunityScoreProps): JSX.Element => {
  const departmentScores = departments?.map((department: Department) => {
    return {
      name: department?.name,
      opportunity: department?.opportunity
    };
  });

  const getScoreColorClass = () => {
    let colorClass = 'unknown';
    if (score) {
      if (score < 3.4) {
        colorClass = 'low';
      } else if (score >= 3.4 && score < 6.7) {
        colorClass = 'medium';
      } else if (score >= 6.7) {
        colorClass = 'high';
      }
    }
    return colorClass;
  };

  return (
    <Popover
      title={
        <div className={css.scoreDept}>
          <strong className={css.scoreDeptName} style={{ verticalAlign: 'middle' }}>
            Opportunity Score
          </strong>
          <AppraisalScoreStatusTag type="opportunity-score" departments={departments} />
        </div>
      }
      content={
        <div className={css.scoreDept}>
          <ul className={css.scoreList}>
            {departmentScores?.length ?
              departmentScores?.map((dept: { name: string, opportunity: string }, index: number) => (
                <li
                  className={css.scoreListItem}
                  key={`dept-${dept?.name}-${index}`}
                >
                  <span className={css.departmentName}>{dept?.name}</span>
                  <span className={classNames(css.scoreValue, css.departmentOpportunity, { [css[dept?.opportunity]]: true })}>
                    {dept?.opportunity ? dept?.opportunity : 'Pending'}
                  </span>
                </li>
              )) : <span className={css.emptyText}>Analyst survey does not have any channels yet</span>}
          </ul>
          {departmentScores?.length ?
            <div className={css.scoreFooter}>
              <span className={css.left}>Score:</span>
              <span className={css.right}>
                <span className={classNames(css.score, { [css[getScoreColorClass()]]: true })}>{score}</span>
                <span className={classNames(css.total, { [css.hasScore]: !!score })}>/10</span>
              </span>
            </div>
            : <></>
          }
        </div>
      }
      trigger={'hover'}
      placement={placement}
      arrowPointAtCenter
    >
      <div>{children}</div>
    </Popover>
  );
};
