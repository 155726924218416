/**
 * Entities -> Departments -> Selectors
 */

import { EntityId } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { departmentsAdapter } from './adapter';
import { selectEntitiesDenormalized } from '../_entity/selectors';

export const departmentsSelectors = departmentsAdapter.getSelectors((state: RootState) => state.entities.data.departments);

export const selectAllDepartments = (state: RootState) => {
  const departmentIds = departmentsSelectors.selectIds(state);
  return selectDepartmentsByIds(departmentIds)(state);
};

export const selectDepartmentsByIds = (departmentIds: EntityId[]) => (state: RootState) => {
  const input = { departments: departmentIds };
  return selectEntitiesDenormalized(state, input).departments;
};

export const selectDepartmentById = (departmentId: number) => (state: RootState) => {
  return selectDepartmentsByIds([departmentId])(state)[0];
};
