/**
 * Get Salesforce Opportunity Stages
 */
import { Projection } from 'api/entityGraphQL';
import { OpportunityStage } from 'features/entitiesRedux/models/types';
import tagTypes from './tagTypes';
import { baseApi } from '../baseApi';
import { cleanArguments } from '../utils';

export type GetOpportunityStagesQuery = {
  id?: number;
  module?: string;
  projection: Projection;
}

export type GetOpportunityStagesResult = OpportunityStage[];

export const { useGetOpportunityStagesQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getOpportunityStages: build.query<GetOpportunityStagesResult, GetOpportunityStagesQuery>({
      transformResponse: (response: { opportunity_stages: GetOpportunityStagesResult }) => response.opportunity_stages,
      providesTags: (opportunity_stages = []) => {
        return [
          tagTypes.OPPORTUNITY_STAGES,
          ...opportunity_stages.map(opportunity_stage => ({
            id: opportunity_stage.id,
            type: tagTypes.OPPORTUNITY_STAGE
          }))
        ];
      },
      query: ({
        projection = { id: true, name: true, slug: true },
        ...args
      }) => {
        const __args: Partial<GetOpportunityStagesQuery> = args;

        return {
          body: {
            query: {
              opportunity_stages: {
                __args: cleanArguments(__args),
                ...projection
              }
            }
          }
        };
      }
    })
  })
});
