/**
* Create nova Intelligence Product Demos
*/

import tagTypes from './tagTypes';
import { baseApi } from '../..';

export type CreateNovaIntelligenceProductDemosMutation = {
  client_id: number;
};

export type CreateNovaIntelligenceProductDemosResult = string;

export const CREATE_NOVA_INTELLIGENCE_PRODUCT_DEMOS_ENDPOINT_KEY = 'createNovaIntelligenceProductDemos';

export const { useCreateNovaIntelligenceProductDemosMutation, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [CREATE_NOVA_INTELLIGENCE_PRODUCT_DEMOS_ENDPOINT_KEY]: build.mutation<CreateNovaIntelligenceProductDemosResult, CreateNovaIntelligenceProductDemosMutation>({
      transformResponse: (response: { createNovaIntelligenceProductDemos: string }) => response.createNovaIntelligenceProductDemos,
      invalidatesTags: result => [
        tagTypes.INTELLIGENCE_PRODUCTS
      ],
      query: ({ ...values }) => {
        const __args: Partial<CreateNovaIntelligenceProductDemosMutation> = {
          ...values,
        };
        return {
          body: {
            mutation: {
              createNovaIntelligenceProductDemos: {
                __args,
              }
            }
          }
        };
      }
    }),
  }),
});
