
import React, { FC, memo, ReactNode, useEffect, useState } from 'react';
import { Badge, AppHeader, BellOutlinedIcon, Box, Dropdown, PositionerAnchorProps, Link, styled, ArrowRightIcon, Button, Spinner, PageSpinner, EmptyIcon } from '@sprnova/nebula';
import { Divider, Tabs } from 'antd';
import { useGetNotificationsQuery } from 'api/crudGraphQL/notifications/getNotifications';
import { useUpdateNotificationMutation } from 'api/crudGraphQL/notifications/updateNotification';
import { useUpdateNotificationsMutation } from 'api/crudGraphQL/notifications/updateNotifications';
import moment from 'moment';
import useMixpanelTrack from 'utils/hooks/useMixpanelTrack';
import { Notification } from 'features/entitiesRedux/models/notification';
import { useAccount } from 'features/global';
import NotificationIcon from './NotificationIcon';
import css from './NotificationsDropdown.module.scss';

const StyledBadge = styled(Badge)(() => ({
  'span.MuiBadge-badge': {
    right: '-10px'
  }
}));

const NotificationsDropdown: FC = () => {
  const [nestedNotifications, setNestedNotifications] = useState<Notification[][]>();
  const [newNotificationsCount, setNewNotificationsCount] = useState<number>();
  const [allNotifications, setAllNotifications] = useState<Notification[]>();
  const [isCompleted, setIsCompleted] = useState<boolean>(false);
  const [notificationsIndex, setNotificationsIndex] = useState<number>(10);
  const [markOneAsReadLoading, setMarkOneAsReadLoading] = useState<boolean>(false);

  const { data: notificationsData, refetch: refetchNotifications, isFetching: isFetchingNotifications, isLoading: isLoadingNotifications} = useGetNotificationsQuery({
    logging: false,
    projection: {
      created_at: true,
      date: true,
      end_date: true,
      entity: {
        id: true
      },
      id: true,
      is_new: true,
      text: true,
      link_text: true,
      title: true,
      type: {
        id: true,
        name: true,
        created_at: true,
        updated_at: true,
        color: true,
        slug: true,
      },
      url: true,
      updated_at: true,
    }
  },
  {
    pollingInterval: 30000, // 30 seconds
    refetchOnMountOrArgChange: true
  });

  const [updateNotificationRequest] = useUpdateNotificationMutation();
  const [updateNotificationsRequest, { isLoading: markAllAsReadLoading }] = useUpdateNotificationsMutation();

  /**
   * Mixpanel event tracking
   */
  const mixpanel = useMixpanelTrack();
  const { account } = useAccount();

  /**
   * Sends mixpanel events to mixpanel dashboard when user clicks the notification center
   * with info about the current user
   */
  const handleTrackNotificationCenterMixpanelEvent = (): void => {
    try {
      const options = {
        title: account?.name,
        userId: account?.id,
        userName: account?.name,
      };
      if (mixpanel) {
        mixpanel('Notification Center clicked', options);
      }
    } catch (err) {
      console.error('Mixpanel error', err);
    }
  };

  /**
   * Sends mixpanel events to mixpanel dashboard when user clicks a notification alert
   * with info about the current user, the notification, and the entity
   * @param notification notification object based on which notification user selects
   */
  const handleTrackNotificationAlertMixpanelEvent = (notification: Notification): void => {
    try {
      const options = {
        title: notification?.title,
        userId: account?.id,
        userName: account?.name,
        notificationId: notification?.id,
        notificationText: notification?.text,
        entityId: notification?.entity?.id
      };
      if (mixpanel) {
        mixpanel('Notification Alert clicked', options);
      }
    } catch (err) {
      console.error('Mixpanel error', err);
    }
  };

  /**
   * Group notifications into separate arrays based on the date they were created
   * Today, Past 30 days, Past 60 days, Over 60 days if needed
   * @param existingNotifications notifications based on user that is logged in
   */
  const nestNotifications = (existingNotifications: Notification[]): void => {
    const currentTimeInMilliseconds = moment().valueOf();
    const todayInMilliseconds = currentTimeInMilliseconds - (1 * 24 * 60 * 60 * 1000);
    const thirtyDaysInMilliseconds = currentTimeInMilliseconds - (30 * 24 * 60 * 60 * 1000);
    const sixtyDaysInMilliseconds = currentTimeInMilliseconds - (60 * 24 * 60 * 60 * 1000);

    const notificationsToday = existingNotifications?.filter((notification) => {
      return todayInMilliseconds <= moment(notification?.date).valueOf();
    });
    const notificationsBetweenTodayAndThirtyDays = existingNotifications?.filter((notification) => {
      return thirtyDaysInMilliseconds <= moment(notification?.date)?.valueOf() && todayInMilliseconds > moment(notification?.date)?.valueOf();
    });
    const notificationsBetweenThirtyAndSixtyDays = existingNotifications?.filter((notification) => {
      return sixtyDaysInMilliseconds <= moment(notification?.date)?.valueOf() && thirtyDaysInMilliseconds > moment(notification?.date)?.valueOf();
    });

    setNestedNotifications([[...notificationsToday], [...notificationsBetweenTodayAndThirtyDays], [...notificationsBetweenThirtyAndSixtyDays]]);
  };

  const filterNewNotifications = (existingNotifications: Notification[]): void => {
    const newNotificationFiltered = existingNotifications?.filter(notification => notification?.is_new === 1);
    setNewNotificationsCount(newNotificationFiltered?.length);
  };

  useEffect(() => {
    if (notificationsData) {
      const sixtyDaysInMilliseconds = moment().valueOf() - (60 * 24 * 60 * 60 * 1000);
      const notificationsLastSixtyDays = notificationsData?.data?.filter(notification => sixtyDaysInMilliseconds <= moment(notification?.date)?.valueOf());
      if (notificationsLastSixtyDays?.length) {
        if (notificationsIndex >= notificationsLastSixtyDays?.length) {
          setIsCompleted(true);
        }
        setAllNotifications(notificationsLastSixtyDays);
        nestNotifications(notificationsLastSixtyDays?.slice(0, notificationsIndex));
        filterNewNotifications(notificationsLastSixtyDays);
      }
    }
  }, [notificationsData, notificationsIndex]);

  /**
   * Display 10 more notifications to the user and check if all notifications have been displayed
   */
  const loadMore = (): void => {
    if (allNotifications) {
      const lastIndex = notificationsIndex + 10;
      setNotificationsIndex(lastIndex);
      nestNotifications(allNotifications?.slice(0, lastIndex));
      setIsCompleted(lastIndex >= allNotifications?.length);
    }
  };

  /**
   * Handles marking all notifications as read
   */
  const handleMarkAllAsRead = async (): Promise<void> => {
    await updateNotificationsRequest({ is_new: false }).unwrap();
    refetchNotifications();
  };

  /**
   * Open a single notification and mark it as read
   * @param notification
   */
  const openNotificationUrl = async(notification: Notification) => {
    // only mark as read if the notification is new
    if(notification.is_new === 1) {
      setMarkOneAsReadLoading(true);
      await updateNotificationRequest({ id: notification?.id, is_new: false }).unwrap();
      setMarkOneAsReadLoading(false);
    }
    handleTrackNotificationAlertMixpanelEvent(notification);
    window.open(notification.url, '_self');
  };

  const handleNotificationCenterClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
    e.preventDefault();
    handleTrackNotificationCenterMixpanelEvent();
  };

  const renderAnchor = ({ onClick, ...props }: PositionerAnchorProps): ReactNode => {
    const handleClick = (e: React.MouseEvent<HTMLElement, MouseEvent>): void => {
      handleNotificationCenterClick(e);
      refetchNotifications();
      onClick?.();
    };

    return (

      <AppHeader.Button
        icon={<BellOutlinedIcon />}
        label={
          <StyledBadge badgeContent={newNotificationsCount} color="error">
            Notifications
          </StyledBadge>
        }
        onClick={handleClick}
        {...props}
      />

    );
  };

  const getNotificationWithLinkText = (notification: Notification, allNotifications: Notification[]): ReactNode => {
    return <>
      <div
        className={css.pageFlex}
      >
        <div className={css.notificationIcon} style={{ background: notification?.type?.color }}>
          <NotificationIcon slug={notification?.type?.slug} />
        </div>
        <div className={css.pageWidth}>
          {getNotificationTextSections(notification)}
          <Button
            endIcon={<ArrowRightIcon />}
            onClick={() => {
              openNotificationUrl(notification);
            }}
            size="small"
          >
            {notification.link_text}
          </Button>
        </div>
        {getNotificationDot(notification)}
      </div>
      {getNotificationDivider(notification, allNotifications)}
    </>;
  };

  const getNotificationTextSections = (notification: Notification): ReactNode => {
    return (<>
      <div>
        {notification?.title}
      </div>
      <div className={css.notificationText}>
        {notification?.text}
      </div>
    </>);
  };

  const getNotificationDot = (notification: Notification): ReactNode => {
    return notification.is_new === 1 ?
      <div className={css.newNotification} >
        <span className={css.notificationDot} />
      </div>
      : <></>;
  };

  const getNotificationDivider = (notification: Notification, allNotifications: Notification[]): ReactNode => {
    return (notification === allNotifications[notificationsIndex - 1] || notification === allNotifications[allNotifications.length - 1]) ?
      <div className={css.footer} />
      :
      <Divider className={css.divider} />;

  };

  const getNotificationWithoutLinkText = (notification: Notification, allNotifications: Notification[]): ReactNode => {
    return <Link
      onClick={() => openNotificationUrl(notification)}
      style={{ display: 'block', marginTop: '12px' }}
    >
      <div
        className={css.pageFlex}
      >
        <div className={css.notificationIcon} style={{ background: notification?.type?.color }}>
          <NotificationIcon slug={notification?.type?.slug} />
        </div>
        <div className={css.pageWidth}>
          {getNotificationTextSections(notification)}
        </div>
        {getNotificationDot(notification)}

      </div>
      {getNotificationDivider(notification, allNotifications)}
    </Link>;
  };

  const getNotificationContent = (notification: Notification, allNotifications: Notification[]): ReactNode => {
    let content;
    if (notification.link_text) {
      content = getNotificationWithLinkText(notification, allNotifications);
    }
    else {
      content = getNotificationWithoutLinkText(notification, allNotifications);
    }

    return content;
  };

  const renderContent = (): ReactNode => (
    <Box sx={{ minWidth: 500 }}>
      <div className={css.header}>
        Notifications
      </div>
      <div className={css.menu}>
        <Tabs defaultActiveKey='accounts' >
          <Tabs.TabPane tab='Accounts' key="accounts">
            {allNotifications?.length ?
              <div className={css.notificationCenter}>
                <div className={css.markReadButton}>
                  <Button
                    onClick={handleMarkAllAsRead}
                    startIcon={markAllAsReadLoading ? <Spinner size="large" /> : null}
                    disabled={markAllAsReadLoading ? true : false}
                  >
                      Mark all as read
                  </Button>
                </div>
                {isFetchingNotifications || markOneAsReadLoading ? <Spinner size='large' sx={{marginLeft: '20px'}}/> : null}
                {isLoadingNotifications ? <PageSpinner label="Hold Tight! Loading your notifications!" /> : null}
                {!isLoadingNotifications && nestedNotifications?.map((notifications) => {
                  return notifications?.map((notification, key) => {
                    return (
                      <div key={key}>
                        <div className={css.timeInterval}>
                          {notification === nestedNotifications[0][0] && 'TODAY'}
                          {notification === nestedNotifications[1][0] && 'LAST 30 DAYS'}
                          {notification === nestedNotifications[2][0] && 'LAST 60 DAYS'}
                        </div>
                        {getNotificationContent(notification, allNotifications)}
                      </div>
                    );
                  });
                })}
                <div className={css.loadMore}>
                  {isCompleted ? (
                    <></>
                  ) : (
                    <Button onClick={loadMore} className={css.loadMoreButton}>
                      Load More
                    </Button>
                  )}
                </div>
              </div>
              :
              <div className={css.noNotificationsContainer}>
                <div className={css.noNotifications}>
                  <EmptyIcon sx={{fontSize: '96px', width: '100%'}} />
                  <div className={css.noNotificationsText}>
                    You have no account notifications from the last 60 days
                  </div>
                </div>
              </div>
            }
          </Tabs.TabPane>
        </Tabs>
      </div>
    </Box>
  );

  return (
    <Dropdown placement="bottom-end" anchor={renderAnchor}>
      {renderContent}
    </Dropdown>
  );
};

export default memo(NotificationsDropdown);
