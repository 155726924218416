/**
* Get Playbook Ads
*/

import { Projection } from 'api/entityGraphQL/types';
import { PlaybookPlatform } from 'features/entitiesRedux/models/creative_playbook';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments } from '..';

export type GetPlaybookPlatformsQuery = {
  id?: number;
  name?: string;
  isActive?: boolean;
  projection?: Projection;
};

export type GetPlaybookPlatformsResult = PlaybookPlatform[]

export const GET_PLAYBOOK_PLATFORMS_ENDPOINT_KEY = 'getPlaybookPlatforms';

export const { useGetPlaybookPlatformsQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_PLAYBOOK_PLATFORMS_ENDPOINT_KEY]: build.query<GetPlaybookPlatformsResult, GetPlaybookPlatformsQuery>({
      transformResponse: (response: { playbook_platforms: GetPlaybookPlatformsResult }) => response.playbook_platforms,
      query: ({
        projection = { id: true },
        name,
        id,
      }) => ({
        body: {
          query: {
            playbook_platforms: {
              __args: cleanArguments({
                id,
                name: name ? `*${name}*` : undefined,
              }),
              ...projection
            }
          }
        }
      })
    }),
  }),
});
