import { createAsyncThunk } from '@reduxjs/toolkit';
import { fetchContactsQuery, fetchContactByIdQuery, updateContactMutation, createContactMutation, deleteContactMutation } from 'api/entityGraphQL';
import { Contact } from './contact';
import {
  ActionFetchManyPaginatedConfig,
  ActionFetchPaginatedResponse,
  ActionFetchOneConfig,
} from '..';
import { normalizeEntities } from '../../utils/normalizeEntities';

/** Fetch Many */
export const fetchContactsPaginated = createAsyncThunk(
  'entities/fetchContactsPaginated',
  async ({
    filter: {
      id,
      name,
      client_id,
    } = {},
    ...config
  }: ActionFetchManyPaginatedConfig): Promise<ActionFetchPaginatedResponse> => {
    // Grabs all the names in the database that contain consecutive instances of the letters inputted
    const regex = `*${String(name).replace(/\s+/g, '*')}*`;
    const { contacts, ...pagination } = await fetchContactsQuery(
      {
        args: {
          id,
          name: name ? regex : undefined,
          client_id,

        },
        ...config
      }
    );
    return { ...normalizeEntities({ contacts }), pagination };
  }
);

/** Fetch One */
export const fetchContactById = createAsyncThunk(
  'entities/fetchContactById',
  async ({ id, projection }: ActionFetchOneConfig) => {
    const { contacts } = await fetchContactByIdQuery(id, {
      projection,
    });
    return { ...normalizeEntities({ contacts }) };
  }
);

/** Update */
export const updateContact = createAsyncThunk(
  'entities/updateContact',
  async (contact: Partial<Contact> & { id: number }) => {
    const response = await updateContactMutation({
      id: contact.id,
      name: contact.name,
      title: contact.title,
      email: contact.email,
      phone: contact.phone,
      is_decision_maker: contact.is_decision_maker,
      notes: contact.notes,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Create */
export const createContact = createAsyncThunk(
  'entities/createContact',
  async (contact: Partial<Contact> & { client_id: number }) => {
    const response = await createContactMutation({
      client_id: contact.client_id,
      name: contact.name,
      title: contact.title,
      email: contact.email,
      phone: contact.phone,
      is_decision_maker: contact.is_decision_maker,
      notes: contact.notes,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Delete */
export const deleteContact = createAsyncThunk(
  'entities/deleteContact',
  async (id: number) => {
    const response = await deleteContactMutation(id);
    return { ...normalizeEntities(response) };
  }
);
