/**
* Get analyst question groups with incomplete criteria
*/

import { baseApi } from 'api/crudGraphQL';
import tagTypes from './tagTypes';
import { AnalystQuestionGroup } from './types';
import { cleanArguments } from '../utils';

export const GET_INCOMPLETE_ANALYST_QUESTION_GROUPS_ENDPOINT_KEY = 'getIncompleteAnalystQuestionGroups';

export type GetIncompleteAnalystQuestionGroupsQuery = {
  department_id: number;
}

export type GetAnalystQuestionGroupsResult = AnalystQuestionGroup[];

export const { 
  useGetIncompleteAnalystQuestionGroupsQuery,
  useLazyGetIncompleteAnalystQuestionGroupsQuery,
  util,
} = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_INCOMPLETE_ANALYST_QUESTION_GROUPS_ENDPOINT_KEY]: build.query<GetAnalystQuestionGroupsResult, GetIncompleteAnalystQuestionGroupsQuery>({
      transformResponse: (response: { analyst_question_groups: { data: GetAnalystQuestionGroupsResult } }) => {
        const questionGroups = response?.analyst_question_groups?.data ?? [];
        return questionGroups.filter(questionGroup => questionGroup.status !== 'deleted' && !questionGroup?.questions?.length);
      },
      keepUnusedDataFor: 0,
      providesTags: [tagTypes.ANALYST_SURVEY_INCOMPLETE_QUESTION_GROUPS],
      query: ({ department_id }) => {
        return {
          body: {
            query: {
              analyst_question_groups: {
                __args: cleanArguments({
                  department_id,
                  revision: null,
                  limit: 999,
                  page: 1,
                }),
                data: {
                  name: true,
                  id: true,
                  status: true,
                  business_type: {
                    name: true,
                  },
                  questions: {
                    name: true,
                    id: true,
                  }
                }
              },
            }
          }
        };
      },
    }),
  }),
});
