import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';
import { Button } from '@sprnova/nebula';
import Modal from 'antd/lib/modal/Modal';
import { AppDispatch } from 'app/store';
import { parseResultActionError } from 'utils';
import { IntegrationRequestMissingAlert } from 'features/clients/components';
import { Client, createClientIntegrationRequest, DataSource, IntegrationRequest, User } from 'features/entitiesRedux';
import { message } from 'components';

interface RequestIntegrationButtonProps {
  userId: User['id'];
  clientId?: Client['id'];
  clientName?: Client['name'];
  dataSourceId: DataSource['id'];
  integrationRequests?: IntegrationRequest[];
  clientUsers?: Client['users']
}

const RequestIntegrationButton = ({
  userId,
  clientId,
  clientName,
  dataSourceId,
  integrationRequests = [],
  clientUsers = []
}: RequestIntegrationButtonProps): JSX.Element => {
  const alreadyRequested = !!(integrationRequests?.find((request: IntegrationRequest) => {
    return request?.data_source_id === dataSourceId;
  }));

  /**
   * Modal.
   */
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

  /**
   * Track appraisal ID for redirect
   */
  const fromAppraisal = window.location.href.indexOf('/appraisals') > -1;
  const { id: appraisalId } = useParams<{ [x: string]: string }>();

  /**
   * Submission handler.
   */
  const history = useHistory();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const dispatch: AppDispatch = useDispatch();

  const handleSubmit = async () => {
    /** If we are on the /appraisals page, save appraisal ID and client ID in local storage */
    if (fromAppraisal && appraisalId) {
      localStorage.setItem('fromAppraisalId', appraisalId);
      localStorage.setItem('fromAppraisalClientId', String(clientId));
    }

    if (integrationRequests?.length > 0 || clientUsers?.length > 0) {
      setSubmitting(true);

      try {
        const resultAction = await dispatch(
          createClientIntegrationRequest({
            client_id: clientId || 0,
            data_source_id: dataSourceId,
            requested_user_id: userId
          })
        );

        if (createClientIntegrationRequest.fulfilled.match(resultAction)) {
          message.success('Data request has been successfully sent to client');
          history.push(`/power-view/accounts/${clientId}/integrations`);
        }

        if (createClientIntegrationRequest.rejected.match(resultAction)) {
          message.error(parseResultActionError(resultAction?.error?.message));
          console.error('Error inviting new client: ', resultAction);
        }

      } catch(error) {
        message.error('An unknown error occurred. Please try again or contact us through intercom.');
        console.error('Error inviting new client: ', error);
      }

      setSubmitting(false);
    } else {
      setIsModalVisible(true);
    }
  };

  return (
    <>
      <Button
        variant="secondary"
        onClick={handleSubmit}
        size="small"
        disabled={alreadyRequested || submitting}
      >
        <>
          {submitting ? 'Sending...' : alreadyRequested ? 'Requested Access' : 'Request Access'}
        </>
      </Button>

      <Modal
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        className={'requestIntegrationButtonModal'}
      >
        <IntegrationRequestMissingAlert
          alreadyHaveInvite={!!(clientUsers?.length > 0)}
          clientId={clientId}
          clientName={clientName}
          userType={'internal'}
        />
      </Modal>
    </>
  );
};

export { RequestIntegrationButton };
