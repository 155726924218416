import { createAsyncThunk } from '@reduxjs/toolkit';
import { Pagination, novaGraphQLClient } from 'api/entityGraphQL';
import { Projection, FetchReportsFilter } from 'api/entityGraphQL/types';
import { getClient } from 'api/entityGraphQL/utils';
import { Report } from './report';
import { Widget } from './widget';
import {
  ActionFetchManyPaginatedConfig,
  ActionFetchPaginatedResponse,
  YesNo,
} from '..';
import { normalizeEntities } from '../../utils';

type FetchReportsPaginatedConfig = ActionFetchManyPaginatedConfig & {
  filter?: FetchReportsFilter;
  projection?: Projection;
  pagination?: Pagination;
};
/** Fetch many */
export const fetchReportsPaginated = createAsyncThunk(
  'entities/fetchReportsPaginated',
  async ({
    filter: { name, account_manager_id, executive_sponsor_id, business_type_id } = {},
    projection,
    pagination,
  }: FetchReportsPaginatedConfig): Promise<ActionFetchPaginatedResponse> => {
    const {
      reports,
      ...paginationResponse
    } = await novaGraphQLClient.fetchReports({
      args: {
        name: name ? `*${String(name).replace(/\s+/g, '*')}*` : undefined,
        account_manager_id,
        executive_sponsor_id,
        business_type_id,
      },
      projection,
      pagination,
    });
    return {
      ...normalizeEntities({ reports }),
      pagination: paginationResponse,
    };
  }
);
/** Fetch all */
export const fetchReports = createAsyncThunk(
  'entities/fetchReports',
  async () => {
    const response = await novaGraphQLClient.fetchReports({});
    return response;
  }
);
/** Fetch one */
export const fetchReportById = createAsyncThunk(
  'entities/fetchReportById',
  async (
    { id, ...config }: { id: number; projection?: Projection }
  ) => {
    const { reports } = await getClient().fetchReportById(id, {
      ...config,
    });
    return { ...normalizeEntities({ reports }) };
  }
);

export const fetchAccountTypes = createAsyncThunk(
  'entities/fetchAccountTypes',
  async () => {
    const response = await novaGraphQLClient.fetchAccountTypes({});
    return response;
  }
);

export const fetchReportIndicatorsEarliestDate = createAsyncThunk(
  'entities/fetchReportIndicatorsEarliestDate',
  async ({ id, indicator_id, projection }: { id: number, indicator_id?: number, projection?: Projection }) => {
    const response = await getClient().fetchReportIndicatorsEarliestDate({ id, indicator_id, projection });
    return response;
  }
);

export const fetchReportIndicators = createAsyncThunk(
  'entities/fetchReportIndicators',
  async () => {
    const response = await novaGraphQLClient.fetchReportIndicators({});
    return response;
  }
);

export const createReportIndicator = createAsyncThunk(
  'entities/createReportIndicator',
  async (report: Partial<Report> & { report_id: number, indicator_id: number, report_widget_id?: number, settings?: string }) => {
    const response = await novaGraphQLClient.createReportIndicator({
      report_id: report.report_id,
      indicator_id: report.indicator_id,
      report_widget_id: report.report_widget_id,
      settings: report.settings
    }, { projection: { id: true, name: true, settings: true } });
    return { ...normalizeEntities(response) };
  }
);

export const deleteReportIndicator = createAsyncThunk(
  'entities/deleteReportIndicator',
  async (report: Partial<Report> & { report_id: number, indicator_id: number }) => {
    const response = await novaGraphQLClient.deleteReportIndicator({
      report_id: report.report_id,
      indicator_id: report.indicator_id,
    }, { projection: { id: true, name: true } });
    return { ...normalizeEntities(response) };
  }
);

export const createReportAccount = createAsyncThunk(
  'entities/createReportAccount',
  async (report: Partial<Report> & { report_id: number, client_account_id?: number, client_account_ids?: number[] }) => {
    const response = await novaGraphQLClient.createReportAccount({
      report_id: report.report_id,
      client_account_id: report.client_account_id,
      client_account_ids: report.client_account_ids
    });
    return { ...normalizeEntities(response) };
  }
);

export const updateReportAccount = createAsyncThunk(
  'entities/updateReportAccount',
  async (report: Partial<Report> & { report_id: number, client_account_id: number }) => {
    const response = await novaGraphQLClient.updateReportAccount({
      report_id: report.report_id,
      client_account_id: report.client_account_id
    });
    return { ...normalizeEntities(response) };
  }
);

export const deleteReportAccount = createAsyncThunk(
  'entities/deleteReportAccount',
  async (report: Partial<Report> & { report_id: number, client_account_id: number }) => {
    const response = await novaGraphQLClient.deleteReportAccount({
      report_id: report.report_id,
      client_account_id: report.client_account_id
    });
    return { ...normalizeEntities(response) };
  }
);



export const createReport = createAsyncThunk(
  'entities/createReport',
  async (report: Partial<Report> & { client_id: number, excluded_data_source_ids: number[] }) => {
    const response = await novaGraphQLClient.createReport({
      client_id: report.client_id,
      name: report.name,
      business_type_id: report.business_type_id,
      website: report.website,
      logo: report.logo,
      excluded_data_source_ids: report.excluded_data_source_ids,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Update */
export const updateReportWidget = createAsyncThunk(
  'entities/updateReportWidget',
  async (
    widget: Partial<Widget> & {
      id: number;
      is_active?: YesNo;
      title?: string;
      settings?: string;
      row?: number;
      position?: number;
      size?: number;
      indicator_ids?: number[];
    }
  ) => {
    const response = await novaGraphQLClient.updateReportWidget({
      id: widget.id,
      is_active: widget.is_active,
      title: widget.title,
      settings: widget.settings,
      row: widget.row,
      position: widget.position,
      size: widget.size,
      indicator_ids: widget.indicator_ids
    });
    return { ...normalizeEntities(response) };
  }
);
export const updateReportBusinessType = createAsyncThunk(
  'entities/updateReport',
  async (report: Partial<Report> & { id: number, business_type_id: number }) => {
    const response = await novaGraphQLClient.updateReport({
      id: report?.id,
      business_type_id: report?.business_type_id
    });
    return { ...normalizeEntities(response) };
  }
);
export const updateReport = createAsyncThunk(
  'entities/updateReport',
  async (report: Partial<Report> & { id: number, excluded_data_source_ids: number[] }) => {
    const response = await novaGraphQLClient.updateReport({
      id: report?.id,
      name: report?.name,
      business_type_id: report?.business_type_id,
      website: report?.website,
      logo: report?.logo,
      goal_name_filter: report?.goal_name_filter,
      refreshed_at: report?.refreshed_at,
      excluded_data_source_ids: report?.excluded_data_source_ids,
    });
    return { ...normalizeEntities(response) };
  }
);
export const updateReportSection = createAsyncThunk(
  'entities/updateReportSection',
  async (reportStatus: Report['statuses'][0]) => {
    const response = await novaGraphQLClient.updateReportSection({
      id: reportStatus.id,
      title: reportStatus.title,
      content: reportStatus.content,
      order: reportStatus.order,
    });
    return { ...normalizeEntities(response) };
  }
);
export const updateReportIndicator = createAsyncThunk(
  'entities/updateReportIndicator',
  async (report: { report_id: number, indicator_id: number }) => {
    const response = await novaGraphQLClient.updateReportIndicator({
      report_id: report.report_id,
      indicator_id: report.indicator_id,
    });
    return { ...normalizeEntities(response) };
  }
);
export const updateReportStatus = createAsyncThunk(
  'entities/updateReportStatus',
  async (reportStatus: Report['statuses'][0]) => {
    const response = await novaGraphQLClient.updateReportStatus({
      id: reportStatus.id,
      date: reportStatus.date,
      // name: reportStatus.name,
      headline: reportStatus.headline,
      status: reportStatus.status,
      auto_save: reportStatus.auto_save,
      is_draft: reportStatus.is_draft,
      publish_date: reportStatus.publish_date,
      sections: reportStatus.sections,
    });
    return { ...normalizeEntities(response) };
  }
);
export const createReportSection = createAsyncThunk(
  'entities/createReportSection',
  async (reportStatus: Report['statuses'][0] & { report_id: number }) => {
    const response = await novaGraphQLClient.createReportSection({
      report_status_id: reportStatus.report_status_id,
      section_id: reportStatus.section_id,
      title: reportStatus.title,
      content: reportStatus.content,
      order: reportStatus.order,
    });
    return { ...normalizeEntities(response) };
  }
);
export const updateReportWidgetData = createAsyncThunk(
  'entities/updateReportWidgetData',
  async (reportWidget: Report['widgets'][0]) => {
    const response = await novaGraphQLClient.updateReportWidgetData({
      id: reportWidget.id,
      title: reportWidget.title,
      content: reportWidget.content,
    });
    return { ...normalizeEntities(response) };
  }
);
export const createReportWidgetData = createAsyncThunk(
  'entities/createReportWidgetData',
  async (reportWidget: Report['widgets'][0] & { report_widget_id: number }) => {
    const response = await novaGraphQLClient.createReportWidgetData({
      report_widget_id: reportWidget.report_widget_id,
      title: reportWidget.title,
      content: reportWidget.content,
    });
    return { ...normalizeEntities(response) };
  }
);
export const createReportWidget = createAsyncThunk(
  'entities/createReportWidget',
  async (reportWidget: Report['widgets'][0] & { report_id: number }) => {
    const response = await novaGraphQLClient.createReportWidget({
      report_id: reportWidget.report_id,
      widget_id: reportWidget.widget_id,
      title: reportWidget.title,
      position: reportWidget.position,
      indicator_ids: reportWidget.indicator_ids,
      is_active: reportWidget.is_active,
      settings: reportWidget.settings,
    });
    return { ...normalizeEntities(response) };
  }
);
export const saveReportWidgetData = createAsyncThunk(
  'entities/saveReportWidgetData',
  async (reportWidget: Report['widgets'][0] & { report_id: number }) => {
    const response = await novaGraphQLClient.saveReportWidgetData({
      report_widget_id: reportWidget.report_widget_id,
      data: reportWidget.data,
      delete_missing: reportWidget.delete_missing,
    });
    return { ...normalizeEntities(response) };
  }
);
export const createReportStatus = createAsyncThunk(
  'entities/createReportStatus',
  async (reportStatus: Report['statuses'][0] & { report_id: number } & { projection: Projection }) => {
    const response = await novaGraphQLClient.createReportStatus({
      report_id: reportStatus.report_id,
      date: reportStatus.date,
      // name: reportStatus.name,
      headline: reportStatus.headline,
      status: reportStatus.status,
      sections: reportStatus?.sections?.map((section: any) => {
        return {
          id: section.id,
          content: section.content || '',
          order: section.order >= 0 ? section.order : 99,
          title: section.title || section.name || null,
        };
      }),
      auto_save: reportStatus.auto_save,
      is_draft: reportStatus.is_draft,
      publish_date: reportStatus.publish_date,
    }, { ...reportStatus.projection });
    return { ...normalizeEntities(response) };
  }
);
export const cloneReportStatus = createAsyncThunk(
  'entities/cloneReportStatus',
  async (id: number) => {
    const response = await novaGraphQLClient.cloneReportStatus(id);
    return { ...normalizeEntities(response) };
  }
);
export const deleteReportSection = createAsyncThunk(
  'entities/deleteReportSection',
  async (id: number) => {
    const response = await novaGraphQLClient.deleteReportSection(id);
    return { ...normalizeEntities(response) };
  }
);
export const deleteReportStatus = createAsyncThunk(
  'entities/deleteReportStatus',
  async (id: number) => {
    const response = await novaGraphQLClient.deleteReportStatus(id);
    return { ...normalizeEntities(response) };
  }
);

export const deleteReportWidget = createAsyncThunk(
  'entities/deleteReportWidget',
  async (
    { id }: { id?: number; }
  ) => {
    const response = await novaGraphQLClient.deleteReportWidget({
      id: id,
    });
    return { ...normalizeEntities(response) };
  }
);

export const fetchReportStatus = createAsyncThunk(
  'entities/fetchReportStatus',
  async (reportStatus: Report['statuses'][0] & { report_id: number }) => {
    const response = await novaGraphQLClient.fetchReportStatus({
      report_id: reportStatus.report_id,
      limit: reportStatus.limit || 10,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Goal Pacing */
export const deletePacingGoal = createAsyncThunk(
  'entities/deletePacingGoal',
  async (reportStatus: Report['pacing_goal'][0] & { report_id: number }) => {
    const response = await novaGraphQLClient.deletePacingGoal({
      id: reportStatus.id,
    });
    return { ...normalizeEntities(response) };
  }
);
export const createPacingGoal = createAsyncThunk(
  'entities/createPacingGoal',
  async (reportStatus: Report['pacing_goal'][0] & { report_id: number }) => {
    const response = await novaGraphQLClient.createPacingGoal({
      report_id: reportStatus.report_id,
      type: reportStatus.type,
      start: reportStatus.start,
      term: reportStatus.term,
      metric: reportStatus.metric,
      value: reportStatus.value,
      months: reportStatus.months,
      goal_channel_filter: reportStatus.goal_channel_filter,
      goal_source_filter: reportStatus.goal_source_filter,
    });
    return { ...normalizeEntities(response) };
  }
);
export const updatePacingGoal = createAsyncThunk(
  'entities/updatePacingGoal',
  async (reportStatus: Report['pacing_goal'][0] & { id: number }) => {
    const response = await novaGraphQLClient.updatePacingGoal({
      id: reportStatus.id,
      type: reportStatus.type,
      start: reportStatus.start,
      term: reportStatus.term,
      metric: reportStatus.metric,
      value: reportStatus.value,
      months: reportStatus.months,
      goal_channel_filter: reportStatus.goal_channel_filter,
      goal_source_filter: reportStatus.goal_source_filter,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Delete */
export const deleteReport = createAsyncThunk(
  'entities/deleteReport',
  async (id: number) => {
    const response = await novaGraphQLClient.deleteReport(id);
    return { ...normalizeEntities(response) };
  }
);
