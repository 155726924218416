import { useMemo } from 'react';
import { useGetCustomerInsightDateRangesQuery } from 'api/crudGraphQL/customer_insights/getCustomerInsightDateRanges';

export const useDateRanges = () => {
  const { data: customerInsightsDateRanges, isLoading: dateRangesIsLoading, error: customerInsightsDateRangesError } = useGetCustomerInsightDateRangesQuery({
    projection: { id: true, range: true },
  });

  const dateRanges = useMemo(() => {
    if (customerInsightsDateRanges) {
      return customerInsightsDateRanges.map((range) => ({
        id: range.id,
        label: range.range,
      }));
    }
    return [];
  }, [customerInsightsDateRanges]);

  return { dateRanges, dateRangesIsLoading, customerInsightsDateRangesError };
};
