import React, { memo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button, Dialog, Grid, Select, TextField, Typography, useSnackbar } from '@sprnova/nebula';
import { useCreateFivetranConnectorMutation } from 'api/crudGraphQL/fivetran/createFivetranConnector';
import NebulaDatasourceLogo from 'components/NebulaDatasourceLogo/NebulaDatasourceLogo';
import { InternalGoogleDialogProps } from '../InternalGoogleDialogProps';

const InternalGoogleSearchConsoleDialog = ({client, openDialog, renderButton, onClose, buttonChildren = 'Connect'}: InternalGoogleDialogProps): JSX.Element => {
  const [internalOpen, setInternalOpen] = useState(false);
  const isControlled = openDialog !== undefined; // Check if the dialog is controlled externally
  const open = isControlled ? openDialog : internalOpen;
  const [createFivetranConnector, {isLoading: createConnectorLoading}] = useCreateFivetranConnectorMutation();

  // Snackbar for error message display
  const { addSnackbar } = useSnackbar();

  // Handlers for opening and closing the dialog
  const handleOpen = (): void => {
    if (!isControlled) {
      setInternalOpen(true);
    }
  };

  const handleClose = (): void => {
    if (!isControlled) {
      setInternalOpen(false);
    }
    onClose?.(); // Notify parent if controlled
  };

  // useForm hook for handling form submission
  const { control, handleSubmit, watch } = useForm({
    defaultValues: {
      email: '',
      site_url: '',
    },
  });

  // Watch form values
  const email = watch('email');
  const site_url = watch('site_url');

  // Conditions for disabled buttons
  const disableButtons = !email || !site_url || createConnectorLoading;

  // Submit handler
  const onSubmit = async (data: { email: string, site_url: string }): Promise<void> => {
    if(email && site_url && client?.group_id && client?.id) {
      try {
        await createFivetranConnector({
          group_id: client?.group_id,
          service: 'google-search-console',
          is_created_by_client: false,
          attributions: '',
          auth_email: data.email,
          site_url: site_url,
        });

        addSnackbar({
          variant: 'success',
          message: 'Connector successfully created',
          persist: false,
        });

        window.location.href = `/power-view/accounts/${client.id}/integrations`;
      } catch (error) {
        addSnackbar({
          variant: 'error',
          message: `Failed to create connector. Error: ${error}`,
          persist: false,
        });
      }
    }
  };

  return (
    <>
      {!openDialog && renderButton && (
        <Button variant="primary" onClick={handleOpen} size='medium'>
          {buttonChildren}
        </Button>
      )}
      <Dialog
        open={open}
        onClose={handleClose}
        title={
          <Grid container alignItems="center" spacing={1}>
            <Grid item>
              <NebulaDatasourceLogo slug="google-search-console" />
            </Grid>
            <Grid item>
              <Typography variant='h3'>Google Search Console</Typography>
            </Grid>
          </Grid>
        }
        size="sm"
        primaryButtonProps={{
          children: createConnectorLoading ? 'Loading...' : 'Submit',
          type: 'submit',
          onClick: handleSubmit(onSubmit),
          disabled: disableButtons
        }}
        secondaryButtonProps={{ children: 'Cancel', onClick: handleClose, disabled: disableButtons }}
      >
        <Grid container rowSpacing={2}>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Select
                  {...field}
                  id="email"
                  label="Email"
                  helperText="Select a Power Digital email"
                  placeholder="Choose email"
                >
                  <Select.Item value="ecom">ecomm@powerdigitalmarketinginc.com</Select.Item>
                  <Select.Item value="analytics">analytics@powerdigitalmarketinginc.com</Select.Item>
                  <Select.Item value="contact">contact@powerdigitalmarketinginc.com</Select.Item>
                  <Select.Item value="b2b">b2b@powerdigitalmarketinginc.com</Select.Item>
                  <Select.Item value="b2c">b2c@powerdigitalmarketinginc.com</Select.Item>
                </Select>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="site_url"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  id="site_url"
                  label="Site URL"
                  helperText="Input site URL"
                  placeholder="Site URL"
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography>Please validate these values are correct before submitting, incorrect values may result in an incomplete connector created.</Typography>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default memo(InternalGoogleSearchConsoleDialog);
