/**
 * Entities -> Reports -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { Report } from './report';
import { compareField } from '../../utils/compareField';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const reportsAdapter = createEntityAdapter<Report>({
  selectId: (report) => report.id,
  sortComparer: compareField('name'),
});
