import loadable from '@loadable/component';

const LoadableComponent = loadable(
  () => import('./SurveyScorecardPage')
  /* , {
    fallback: <div>Loading...</div>,
    } */
);

export { LoadableComponent as SurveyScorecardPage };
