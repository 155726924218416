/* eslint-disable complexity */
import React, { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, formatCurrency, Grid, Skeleton } from '@sprnova/nebula';
import { useGetScoreboardPublicQuery } from 'api/crudGraphQL/public/scoreboards/getScoreboardPublic';
import { useGetScoreboardQuery } from 'api/crudGraphQL/scoreboards/getScoreboard';
import { format, parseISO } from 'date-fns';
import { useFilterContext } from 'features/scoreboards/utils/Filters';
import CreateMonthlyGoalDrawer from './CreateMonthlyGoalDrawer';
import EditMonthlyGoalDrawer from './EditMonthlyGoalDrawer';
import MonthlyChart from './MonthlyChart';
import { pacingGoalsProjection } from '../../../projections';
import GoalCards from '../GoalCards';
import GoalTitle from '../GoalTitle';
import GoalView from '../GoalView';
import GridFormatting from '../GridFormatting';
import NoGoals from '../NoGoals';

export type MonthlyProps = {
  isClient: boolean;
};

const Monthly = ({ isClient }: MonthlyProps): JSX.Element => {
  const [openCreateGoalDrawer, setOpenCreateGoalDrawer] = React.useState(false);
  const [openEditGoalDrawer, setOpenEditGoalDrawer] = React.useState(false);

  const { id } = useParams<{ [x: string]: string }>();
  const { filter } = useFilterContext();

  const { data: pacingGoalCheck, isLoading: pacingGoalCheckLoading } = (
    isClient ? useGetScoreboardPublicQuery : useGetScoreboardQuery
  )(
    {
      id: parseInt(id) || 0,
      projection: {
        pacing_goals: {
          id: true,
        },
      },
      goalPacingArgs: {
        type: 'monthly',
        limit: 1,
      }
    },
    { skip: !id }
  );
  const { data, isFetching } = (
    isClient ? useGetScoreboardPublicQuery : useGetScoreboardQuery
  )(
    {
      id: parseInt(id) || 0,
      projection: {
        pacing_goals: pacingGoalsProjection,
      },
      goalPacingArgs: {
        type: 'monthly',
        limit: 10,
        id: filter?.monthly?.id,
      },
    },
    { skip: !id || !filter?.monthly?.id }
  );

  const pacingGoal = useMemo(() => {
    if (!data) return;
    return data.pacing_goals[0];
  }, [data]);

  const {
    refreshed_at: last_refreshed,
    metric_name,
    indicator
  } = pacingGoal || {};
  const formatType = indicator?.format;
  const goalCardsProps = {
    isLoading: isFetching,
    metric: pacingGoal?.metric,
    metric_name: metric_name,
    format: pacingGoal?.indicator.format,
    actual_value: pacingGoal?.current_value,
    est_value: pacingGoal?.prediction,
    goal_for_value: pacingGoal?.percent_of_goal,
    goal_pace_value: pacingGoal?.percent_on_pace,
    actual_platforms: pacingGoal?.current_platform,
    est_platforms: pacingGoal?.prediction_platform,
    goal_for_platforms: pacingGoal?.percent_of_goal_platform,
    goal_pace_platforms: pacingGoal?.percent_on_pace_platform,
  };

  const renderTitle = useMemo(() => {
    if (!pacingGoal) return <></>;
    if (isFetching)
      return <Skeleton width="450px" height="40px" />;
    const {
      metric_name,
      value,
      start,
      indicator: { format: type },
    } = pacingGoal;
    const formattedValue =
      type === '$' ? formatCurrency(value) : value.toLocaleString();
    const title = `Monthly Goal for ${metric_name} - ${formattedValue}`;
    const formattedDate = format(parseISO(start), 'MMMM yyyy');
    return <GoalTitle title={title} date={formattedDate} />;
  }, [pacingGoal, isFetching]);

  const renderChart = useMemo(() => {
    if (isFetching || !pacingGoal)
      return <Skeleton height={350} variant="rectangular" width="100%" />;
    return (
      <MonthlyChart
        monthlyData={pacingGoal?.data}
        lastUpdated={last_refreshed}
        format={formatType}
        metricName={metric_name}
      />
    );
  }, [
    pacingGoal,
    last_refreshed,
    formatType,
    metric_name,
    isFetching
  ]);

  if (!pacingGoalCheckLoading && !pacingGoalCheck?.pacing_goals?.length) {
    return (
      <NoGoals
        type='monthly'
        isClient={isClient}
        openDrawer={openCreateGoalDrawer}
        onToggleDrawer={() => {
          setOpenCreateGoalDrawer(!openCreateGoalDrawer);
        }}
      />
    );
  }
  return (
    <>
      <Box>
        <GridFormatting
          view={<GoalView type="monthly" isClient={isClient} />}
          title={renderTitle}
          create={
            <CreateMonthlyGoalDrawer
              open={openCreateGoalDrawer}
              onToggle={() => {
                setOpenCreateGoalDrawer(!openCreateGoalDrawer);
              }}
              isLoading={isFetching}
            />
          }
          edit={
            <EditMonthlyGoalDrawer
              open={openEditGoalDrawer}
              onToggle={() => {
                setOpenEditGoalDrawer(!openEditGoalDrawer);
              }}
              onAlertClick={() => {
                setOpenEditGoalDrawer(false);
                setOpenCreateGoalDrawer(true);
              }}
              isLoading={isFetching}
            />
          }
          isClient={isClient}
        />
        <Grid padding="40px 0">
          <GoalCards {...goalCardsProps} />
        </Grid>
      </Box>
      <Box>{renderChart}</Box>
    </>
  );
};

export default memo(Monthly);
