/**
 * AddTasksModal -> SelectionList -> Item
 */

import React, { FC } from 'react';
import css from './Item.module.scss';

type Props = {
  label: string;
  children: any;
}

const Item: FC<Props> = ({ label, children }) => {
  return (
    <li className={css.root}>
      <h3 className={css.label}>{label}</h3>
      {children ? (
        <ul className={css.items}>
          {children}
        </ul>
      ) : null}
    </li>
  );
};

export default Item;