/**
 * SelectPartner
 */

import React, { forwardRef, useMemo } from 'react';
import { GetPartnersQuery, useGetPartnersQuery } from 'api/crudGraphQL/partners/getPartners';
import { Partner } from 'features/entitiesRedux';
import Select, { Props as SelectProps } from '../Select/Select';

interface SelectPartnersProps extends SelectProps {
  args?: Partial<GetPartnersQuery>;
}

const SelectPartner = forwardRef(({ skeleton, args, ...props}: SelectPartnersProps, ref: any) => {

  /**
   * RTK fetch Partners
   */
  const { data: partnersRequest, isLoading } = useGetPartnersQuery({
    projection: { 
      id: true,
      name: true,
      percent: true,
      email: true,
      phone: true,
      company: true,
    },
    limit: 9999,
    sort: ['company'],
    /** Override default projection with passed args */
    ...(args ?? {}),
  });

  const partners = useMemo(() => {
    if (partnersRequest?.data) {
      return partnersRequest.data;
    }
    return [];
  }, [partnersRequest]);

  const formatOptionText = (partner: Partial<Partner>) => {
    let text = `Partner #${partner.id}`;
    if (partner.company && partner.name) {
      text = `${partner.company} - ${partner.name}`;
    }
    if (!partner.company && partner.name) {
      text = partner.name;
    }
    if (partner.company && !partner.name) {
      text = partner.company;
    }
    return text;
  };

  return (
    <Select
      showSearch
      skeleton={isLoading || skeleton}
      optionFilterProp="children"
      filterOption={(input, option) => option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
      ref={ref}
      {...props}
    >
      {partners.map((partner: Partial<Partner>) => (
        <Select.Option key={partner.id} value={partner.id || ''}>
          {formatOptionText(partner)}
        </Select.Option>
      ))}
    </Select>
  );
});

SelectPartner.displayName = 'SelectPartner';

export default SelectPartner;
