import React, { memo, useCallback, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActionMenu, Alert, AlertTitle, Button, Drawer, DrawerFooter, DrawerView, DropdownButton, EditIcon, useForm, useSnackbar, ArrowRightIcon, Grid } from '@sprnova/nebula';
import { useGetScoreboardQuery } from 'api/crudGraphQL/scoreboards/getScoreboard';
import { useUpdatePacingGoalMutation } from 'api/crudGraphQL/scoreboards/pacing_goals/updatePacingGoal';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import Trash from 'components/nebula/icons/Trash';
import { useAccount } from 'features/global';
import { track } from 'features/scoreboards/components/mixpanelEvents';
import { useFilterContext } from 'features/scoreboards/utils/Filters';
import MonthlyGoalForm from './MonthlyGoalForm';
import DeleteGoalModal from '../DeleteGoalModal';

type EditMonthlyGoalDrawerProps = {
  open: boolean;
  onToggle: () => void;
  onAlertClick: () => void;
  isLoading: boolean;
};
const EditMonthlyGoalDrawer = ({ open, onToggle, onAlertClick, isLoading }: EditMonthlyGoalDrawerProps): JSX.Element => {
  const { addSnackbar } = useSnackbar();
  const { id } = useParams<{ [x: string]: string }>();
  const { filter, setFilter } = useFilterContext();
  const [openDeleteModal, toggleDeleteModal] = useReducer((state) => !state, false);
  const [submitting, setSubmitting] = useState(false);
  const mixpanel = useMixpanel();
  const { account } = useAccount();

  const { data } = useGetScoreboardQuery({
    id: parseInt(id),
    projection: {
      id: false,
      pacing_goals: {
        id: true,
        metric_name: true,
        goal_value: true
      }
    },
    goalPacingArgs: {
      type: 'monthly',
      limit: 1,
      id: filter?.monthly?.id
    }
  }, { skip: !id || !filter?.monthly?.id });

  const [updatePacingGoal] = useUpdatePacingGoalMutation();

  const {
    control,
    reset,
    trigger,
    getValues,
  } = useForm<any>({
    defaultValues: {
      metric: '',
      goal: undefined
    },
    values: {
      metric: data?.pacing_goals?.[0]?.metric_name,
      goal: data?.pacing_goals?.[0]?.goal_value
    }
  });

  const handleSubmit = useCallback(async (e): Promise<void> => {
    e?.preventDefault();
    trigger();
    try {
      setSubmitting(true);
      const { metric, goal } = getValues();
      const result = await updatePacingGoal({
        id: parseInt(filter?.monthly?.id),
        type: 'monthly',
        metric: metric.id,
        value: parseInt(goal)
      }).unwrap();
      addSnackbar({
        variant: 'success',
        message: 'Monthly goal updated',
        persist: false
      });
      setFilter({ type: 'add', value: { monthly: { id: result.id, type: 'edit' } } });
      reset();
      onToggle();
      const { id: accountId, name } = account ?? {};
      track({
        mixpanel, type: 'edit-pacing-goal', options: {
          monthly_pacing_metric: metric.id,
          monthly_pacing_id: filter?.monthly?.id,
          scoreboard_id: id,
          user_id: accountId,
          user_name: name
        }
      });
    } catch (error) {
      addSnackbar({
        variant: 'success',
        message: 'Error updating monthly goal',
        persist: false
      });
      console.error('Error creating goal', error);
    } finally {
      setSubmitting(false);
    }
  }, [trigger, getValues, updatePacingGoal, filter?.monthly?.id, addSnackbar, setFilter, reset, onToggle, account, mixpanel, id]);

  const renderForm = useMemo(() => (
    <DrawerView
      component='form'
      onSubmit={handleSubmit}
      title='Edit Monthly Goal'
      onClose={onToggle}
      footer={
        <DrawerFooter
          primaryButtonProps={{
            children: 'Save',
            type: 'submit',
            disabled: isLoading || submitting
          }}
          secondaryButtonProps={{
            children: 'Cancel',
            onClick: (): void => {
              reset();
              onToggle();
            },
            disabled: isLoading || submitting
          }}
        />
      }
    >
      <Alert
        severity="warning"
        action={
          <Button
            className="tertiary"
            color="primary"
            endIcon={<ArrowRightIcon />}
            size="small"
            variant="outlined"
            sx={{ width: '240px' }}
            onClick={onAlertClick}
          >
            Create New Monthly Goal
          </Button>
        }
        sx={{ mb: 4 }}
      >
        <AlertTitle>
          This field is uneditable
        </AlertTitle>
        In order to change the Metric you must create a New Monthly Goal
      </Alert>
      <MonthlyGoalForm control={control} trigger={trigger} defaultMetricValue={data?.pacing_goals?.[0]?.metric_name} />
    </DrawerView>
  ), [handleSubmit, onToggle, isLoading, submitting, onAlertClick, control, trigger, data?.pacing_goals, reset]);

  return (
    <>
      <DropdownButton
        placement='bottom-end'
        size='large'
        variant='tertiary'
        startIcon={<EditIcon />}
        disabled={isLoading}
        label='Edit Monthly Goal'
        sx={{ minWidth: '250px' }}
      >
        <ActionMenu sx={{ minWidth: '250px' }}>
          <ActionMenu.Item
            startIcon={<EditIcon />}
            onClick={onToggle}
          >
            Edit Goal
          </ActionMenu.Item>
          <ActionMenu.Item
            startIcon={<Trash />}
            onClick={toggleDeleteModal}
          >
            Delete Goal
          </ActionMenu.Item>
        </ActionMenu>
      </DropdownButton>
      <Drawer open={open} onClose={onToggle} isBlank>
        {renderForm}
      </Drawer>
      <DeleteGoalModal
        open={openDeleteModal}
        toggle={toggleDeleteModal}
        type='monthly'
        goalId={filter?.monthly?.id}
      />
    </>
  );
};

export default memo(EditMonthlyGoalDrawer);
