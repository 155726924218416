import React, { memo, useReducer, useState } from 'react';
import { Alert, Button, Dialog, Grid, TextField, TrashIcon, useForm, useSnackbar } from '@sprnova/nebula';
import { useCreateFunnelConnectorMutation } from 'api/crudGraphQL/funnel/createFunnelConnector';
import { useRefetch } from 'features/clients/ClientIntegrationsPage/utils/ClientRefetchContext';
import { Client, DataSource, ExternalClient } from 'features/entitiesRedux';

type FunnelConnectIntegrationButtonProps = {
  /** The datasource for the integrations. */
  datasource: DataSource;
   /** The client associated with the integrations. */
  client: Client | ExternalClient;
   /** The ID of the user creating the integrations. */
  userId: number;
}

/**
 * FunnelConnectIntegrationDialog Component
 *
 * This component allows users to create new funnel integrations by adding multiple schema names
 * and submitting them via a GraphQL mutation.
 */
const FunnelConnectIntegrationDialog = ({datasource, client, userId}: FunnelConnectIntegrationButtonProps): JSX.Element => {
  // State to manage the list of integrations, initialized with one empty string
  const [integrationList, setIntegrationList] = useState<string[]>(['']);
  const { addSnackbar } = useSnackbar();
  const { refetch } = useRefetch();  // Hook to refetch integration data.

  // Reducer to toggle the dialog open/close state
  const [open, toggle] = useReducer((open) => !open, false);

  // Create funnel connector mutation
  const [createFunnelConnector, { isLoading }] = useCreateFunnelConnectorMutation();

  // Form submission handler
  const onSubmit = async (): Promise<void> => {
    try {
      // Prepare payload
      const payload = integrationList.map(integrationSchemaName => ({
        client_id: client.id,
        data_source_id: datasource.id,
        schema_name: integrationSchemaName,
        created_by: userId,
      }));

      // Trigger the mutation and wait for the result
      const result = await createFunnelConnector({ connectors: payload }).unwrap();

      // Handle error
      const hasError = result.some((message: string) => message.includes('Failed'));

      if (hasError) {
        addSnackbar({
          variant: 'error',
          message: 'Failed to add all integration(s). Please check the logs or try again.',
        });
      } else {
        addSnackbar({
          variant: 'success',
          message: 'Integration(s) successfully added. You can view or edit them from the manage integrations tab.',
        });
        // Refetch data
        await refetch();
      }
    } catch (error) {
      // Handle unexpected errors
      console.error('Error occurred while submitting:', error);
      addSnackbar({
        variant: 'error',
        message: 'An unexpected error occurred. Please try again later.',
      });
    } finally {
      // Always clear/toggle the modal and reset the state
      toggle();
      setIntegrationList(['']);
    }
  };

  // Initialize form submission
  const { handleSubmit } = useForm();

  // Function to add a new integration item to the list
  const addIntegrationListItem = (): void => {
    const increasedIntegrationList = [...integrationList, ''];
    setIntegrationList(increasedIntegrationList);
  };

  // Function to delete an integration item by its index
  const deleteIntegrationListItem = (index: number): void => {
    const decreasedIntegrationList = integrationList.filter((_, i) => i !== index);
    setIntegrationList(decreasedIntegrationList.length === 0 ? [''] : decreasedIntegrationList);
  };

  // Function to update an integration item by its index
  const updateIntegrationListItem = (value: string, index: number): void => {
    const listToUpdate = [...integrationList];
    listToUpdate[index] = value;
    setIntegrationList(listToUpdate);
  };

  // Function to render all integration list items dynamically
  const renderIntegrationListItems = (): JSX.Element[] => {
    return integrationList.map((integration, index) => (
      <Grid container item display='flex' flexDirection='row' key={index} wrap='nowrap' gap={4}>
        <TextField
          id="schema_name"
          label="Funnel Schema Name"
          value={integration}
          onChange={(event) => updateIntegrationListItem(event.target.value, index)}
        />
        <Button variant='tertiary' size='medium' onClick={() => deleteIntegrationListItem(index)}>
          <TrashIcon />
        </Button>
      </Grid>
    ));
  };
  return (
    <>
      {/* Button to open the dialog */}
      <Button variant="primary" onClick={toggle}>
        Connect
      </Button>

      {/* Dialog for managing funnel integrations */}
      <Dialog
        open={open}
        onClose={toggle}
        title={`Create ${datasource.name} Integration`}
        size="md"
        primaryButtonProps={{
          children: isLoading ? 'Creating integration...' : 'Submit',
          type: 'submit',
          onClick: handleSubmit(onSubmit),
          disabled: isLoading || !integrationList[0]
        }}
        secondaryButtonProps={{ children: 'Cancel', onClick: toggle }}
      >
        {/* Main content container */}
        <Grid container rowSpacing={2} >
          <Grid container item gap={3}>
            <Alert severity="warning" style={{borderRadius: 0, width: '100%', padding: '8px 24px'}}>
              {'If you\'re unsure of the Funnel Schema Name, please contact support via Intercom for assistance.'}
            </Alert>
            {/* Render integration list dynamically */}
            {renderIntegrationListItems()}
          </Grid>

          {/* Button to add a new integration list item */}
          <Grid item xs={12}>
            <Button variant="tertiary" sx={{ width: '100%' }} onClick={addIntegrationListItem}>
              + Add Funnel Schema Name
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default memo(FunnelConnectIntegrationDialog);
