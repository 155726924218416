import { EntityId } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { clientsAdapter } from './adapter';
import { Client } from './client';
import { selectEntitiesDenormalized } from '../_entity/selectors';

export const clientSelectors = clientsAdapter.getSelectors(
  (state: RootState) => state.entities.data.clients
);

export const selectClientsByIds = (clientIds: EntityId[]) => (
  state: RootState
) => {
  const input = { clients: clientIds };
  return selectEntitiesDenormalized(state, input).clients;
};

export const selectClientById = (clientId: number) => (state: RootState) => {
  const clients: Client[] = selectClientsByIds([clientId])(state);
  return clients[0];
};

export const selectAllClients = () => (state: RootState) => {
  const clientIds = clientSelectors.selectIds(state);
  return selectClientsByIds(clientIds)(state);
};