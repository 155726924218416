/**
 * Update strategy task
 */

import { Projection } from 'api/entityGraphQL';
import { Strategy } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments } from '../../..';
import strategyTagTypes from '../../tagTypes';
import infoTagTypes from '../information/tagTypes';
import tasksTagTypes from '../tasks/tagTypes';

export type UpdateStrategyTaskMutation = {
  strategy_id: number;
  task_id: number;
  name?: string;
  notes?: string;
  order?: number;
  units?: number;
  hours?: number;
  projection?: Projection;
};

export type UpdateStrategyTaskResult = Strategy;

export const UPDATE_STRATEGY_TASK_ENDPOINT_KEY = 'updateStrategyTask';

export const { useUpdateStrategyTaskMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_STRATEGY_TASK_ENDPOINT_KEY]: build.mutation<UpdateStrategyTaskResult, UpdateStrategyTaskMutation>({
      transformResponse: (response: { updateStrategyTask: Strategy }) => response.updateStrategyTask,
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<UpdateStrategyTaskMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              updateStrategyTask: {
                __args: cleanArguments(__args),
                id: true
              }
            }
          }
        };
      }
    })
  })
});
