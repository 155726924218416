/**
 * Entities -> Clients -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { ExternalClient } from './external_client';
import { compareField } from '../../utils/compareField';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const externalClientsAdapter = createEntityAdapter<ExternalClient>({
  selectId: (external_client) => external_client.id,
  sortComparer: compareField('name'),
});
