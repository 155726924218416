import React, { useState } from 'react';
import { Button, Preview, Form } from './components';
import { AddNotesMode, AddNotesContext } from './context';

interface AddNotesProps {
  onSave?: (notes: string) => void;
  notes?: string;
  loading?: boolean;
  submitting?: boolean;
  error?: string | null;
  mode?: AddNotesMode;
  children: React.ReactNode;
}
const AddNotes = ({
  onSave,
  notes: defaultNotes,
  loading = false,
  submitting = false,
  error = null,
  mode: defaultMode = 'preview',
  children,
}: AddNotesProps) => {
  const [mode, setMode] = useState<AddNotesMode>(defaultMode);
  const [notes, setNotes] = useState<string | undefined>(defaultNotes);

  function handleOnSave(newNotes: string) {
    // update local state
    setNotes(newNotes);

    // call hook passed from parent (api calls)
    if (onSave) {
      onSave(newNotes);
    }

    setMode('preview');
  }
  function handleOnCancel() {
    setMode('preview');
  }
  function handleOnEdit() {
    setMode('form');
  }

  return (
    <AddNotesContext.Provider
      value={{
        mode,
        notes,
        hasNotes: !!notes,
        onSave: handleOnSave,
        onCancel: handleOnCancel,
        onEdit: handleOnEdit,
        loading,
        submitting,
        error,
      }}
    >
      <>{children}</>
    </AddNotesContext.Provider>
  );
};

AddNotes.Button = Button;
AddNotes.Preview = Preview;
AddNotes.Form = Form;

export { AddNotes };
