export enum TabNames {
  campaigns = 'campaigns',
  campaign = 'campaign',
  campaignTitle = 'Campaign',
  adsets = 'adsets',
  adset = 'adset',
  adsetTitle = 'Ad Set',
  ads = 'ads',
  ad = 'ad',
  adTitle = 'Ad',
}

export const CreativeAffinityKeys = {
  industry: 'Industry',
  score: 'Affinity Score',
  format: 'Format',
  campaign_ltv: 'LTV',
  spend: 'Spend',
  ltv: 'LTV',
  cac: 'CAC',
  ad_spend: 'Ad Spend',
  roas: 'ROAS',
  revenue: 'Revenue',
  cpa: 'CPA',
  stop_rate: 'Stop Rate',
  hold_rate: 'Hold Rate',
  ctr: 'CTR',
  cvr: 'CVR',
  cpc: 'CPC',
  cpm: 'CPM',
  ctv: 'CTV',
  clicks: 'Clicks',
  impressions: 'Impressions',
  conversions: 'Conversions',
  // results: 'Results',
  retention_rate: 'Retention Rate',
  none: 'None',
};

export const CreativeAffinityValueTypes = {
  clicks: 'number',
  client_last_refreshed: 'date',
  conversions: 'number',
  cpa: 'currency',
  ad_cpa: 'currency',
  cpc: 'currency',
  cpm: 'currency',
  ctr: 'percent',
  cvr: 'percent',
  ad_cvr: 'percent',
  ltv: 'currency',
  cac: 'currency',
  data: 'string',
  formats: 'array',
  hold_rate: 'percent',
  impressions: 'number',
  last_refreshed: 'date',
  name: 'string',
  objective: 'string',
  results: 'number',
  retention_rate: 'percent',
  revenue: 'currency',
  roas: 'decimal',
  reach: 'number',
  three_second_video_views: 'number',
  six_second_video_views: 'number',
  spend: 'currency',
  status: 'string',
  stop_rate: 'percent',
  total_adsets: 'number',
  total_ads: 'number',
  two_second_video_views: 'number',
  type: 'string',
  video_thruplays: 'number',
  video_views_p100: 'number',
  score: 'number',
  ecomm_matched_metric: 'matched_metric',
};
