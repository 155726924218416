import { CaseReducer } from '@reduxjs/toolkit';
import { surveysAdapter } from 'features/talent/adapter';
import { adapterSetAll } from './adapterSetAll';
import { adapterUpsertOrSetAll } from './adapterUpsertOrSetAll';
import {
  auditsAdapter,
  clientsAdapter,
  contactsAdapter,
  datasourcesAdapter,
  departmentsAdapter,
  partnersAdapter,
  reportsAdapter,
  servicesAdapter,
  strategiesAdapter,
  tasksAdapter,
  usersAdapter,
  organizationsAdapter,
  forecastsAdapter,
  alertsAdapter,
  externalClientsAdapter,
} from '../models';
import { dashboardsAdapter } from '../models/dashboard';
import { fieldsAdapter } from '../models/field/adapter';
import { rulesAdapter } from '../models/rule';

export const setEntities: CaseReducer = (state, action) => {
  if (action.payload !== null && typeof action.payload.entities === 'object') {
    state.isLoading = false;
    state.error = null;

    /** Clients */
    adapterUpsertOrSetAll(
      clientsAdapter,
      state.data?.clients,
      action.payload.entities?.clients
    );
    /** External Clients */
    adapterUpsertOrSetAll(
      externalClientsAdapter,
      state.data?.external_clients,
      action.payload.entities?.external_clients
    );
    /** Data Sources */
    adapterUpsertOrSetAll(
      datasourcesAdapter,
      state.data.datasources,
      action.payload.entities.datasources
    );
    /** Departments */
    adapterUpsertOrSetAll(
      departmentsAdapter,
      state.data.departments,
      action.payload.entities.departments
    );
    /** Organizations */
    adapterUpsertOrSetAll(
      organizationsAdapter,
      state.data.organizations,
      action.payload.entities.organizations
    );
    /** Services */
    adapterUpsertOrSetAll(
      servicesAdapter,
      state.data.services,
      action.payload.entities.services
    );
    /** Contacts */
    adapterUpsertOrSetAll(
      contactsAdapter,
      state.data.contacts,
      action.payload.entities.contacts
    );
    /** Audits */
    adapterUpsertOrSetAll(
      auditsAdapter,
      state.data.audits,
      action.payload.entities.audits
    );
    /** Users */
    adapterUpsertOrSetAll(
      usersAdapter,
      state.data.users,
      action.payload.entities.users
    );
    /** Strategies */
    adapterUpsertOrSetAll(
      strategiesAdapter,
      state.data.strategies,
      action.payload.entities.strategies
    );
    /** Partners */
    adapterUpsertOrSetAll(
      partnersAdapter,
      state.data.partners,
      action.payload.entities.partners
    );
    /** Tasks */
    adapterSetAll(
      tasksAdapter,
      state.data.tasks,
      action.payload.entities.tasks
    );
    /** Reports */
    adapterUpsertOrSetAll(
      reportsAdapter,
      state.data.reports,
      action.payload.entities.reports
    );
    /** Rules */
    adapterUpsertOrSetAll(
      rulesAdapter,
      state.data.rules,
      action.payload.entities.rules
    );
    /** Surveys */
    adapterUpsertOrSetAll(
      surveysAdapter,
      state.data.surveys,
      action.payload.entities.surveys
    );
    /** Forecasts */
    adapterUpsertOrSetAll(
      forecastsAdapter,
      state.data.forecasts,
      action.payload.entities.forecasts
    );
    /** Alerts */
    adapterUpsertOrSetAll(
      alertsAdapter,
      state.data.alerts,
      action.payload.entities.alerts
    );
    /** Dashboards */
    adapterUpsertOrSetAll(
      dashboardsAdapter,
      state.data.dashboards,
      action.payload.entities.dashboards
    );
    /** Churns */
    adapterUpsertOrSetAll(
      fieldsAdapter,
      state.data.dashboard_churns,
      action.payload.entities.dashboard_churns
    );
    /** Rapports */
    adapterUpsertOrSetAll(
      fieldsAdapter,
      state.data.dashboard_rapports,
      action.payload.entities.dashboard_rapports
    );
    /** Pace */
    adapterUpsertOrSetAll(
      fieldsAdapter,
      state.data.dashboard_paces,
      action.payload.entities.dashboard_paces
    );
  }
};
