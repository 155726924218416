/**
 * Get development status
 */

import { Projection } from 'api/entityGraphQL';
import tagTypes from './tagTypes';
import { DevelopmentStatus } from './types';
import { baseApi } from '../..';

export type GetDevelopmentStatusesQuery = {
   projection: Projection;
  }

export type GetDevelopmentStatusesResult = DevelopmentStatus[];

export const { useGetDevelopmentStatusesQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getDevelopmentStatuses: build.query<GetDevelopmentStatusesResult, GetDevelopmentStatusesQuery>({
      transformResponse: (response: { development_statuses: GetDevelopmentStatusesResult }) => {
        return response.development_statuses;
      },
      providesTags: (development_statuses = []) => {
        return [
          tagTypes.DEVELOPMENT_STATUSES,
          ...development_statuses.map(developmentStatus => ({
            id: developmentStatus.id,
            type: tagTypes.DEVELOPMENT_STATUS
          }))
        ];
      },
      query: ({
        projection = { id: true, name: true },
      }) => {

        return {
          body: {
            query: {
              development_statuses: {
                ...projection
              },
            }
          }
        };
      },
    }),
  }),
});
