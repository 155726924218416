/**
 * Entities -> Rules -> Hooks
 */

import { useState, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchRuleById, fetchRules } from './actions';
import { FetchRulesFilter, Rule } from './rule';
import { selectAllRules, selectRuleById } from './selectors';
import { useEntityByParamId } from '../_entity/hooks';

/**
 * Get paginated rules
 */
export const useRules = ({
  projection = undefined,
}: {
  filter?: FetchRulesFilter;
  projection?: any;
} = {}): {
  rules: Rule[];
  loading: boolean;
  error: string | undefined;
} => {
  const dispatch = useDispatch();
  const rules = useSelector(selectAllRules);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>('');

  const getRules = useCallback(async () => {
    try {
      setLoading(true);
      const resultAction: any = await dispatch(fetchRules({ projection }));
      if (fetchRules.fulfilled.match(resultAction)) {
        setError('');
        setLoading(false);
      }
      if (fetchRules.rejected.match(resultAction)) {
        setLoading(false);
        setError(resultAction?.error?.message);
      }
    } catch {
      setError('Failed to fetch rules');
    }
  }, [dispatch, projection]);

  useEffect(() => {
    getRules();
  }, [getRules]);

  return {
    rules,
    error,
    loading,
  };
};

/**
 * Get rule by param ID
 */
export const useRuleByParamId = () => useEntityByParamId(selectRuleById, fetchRuleById);
