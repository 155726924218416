import React, { memo } from 'react';
import { Button } from '@sprnova/nebula';
import isExternalUser from 'utils/helpers/isExternalUser';
import { useDataConnectorActions } from 'features/clients/ClientIntegrationsPage/components/DataAccessRequestsTab/components/FivetranIntegrationRequestList/components/components/hooks/useDataConnectorActions';
import { LoadingIndicator } from 'features/clients/ClientIntegrationsPage/components/ManageIntegrationsTab/components/StyledComponents';
import { RequestIntegrationButton } from 'features/clients/components';
import { Client, DataSource, ExternalClient } from 'features/entitiesRedux';
import ComingSoonStatus from './ComingSoonStatus';
import DialogIntegrationButton from './DialogIntegrationButton';

/**
 * Props for the ConnectIntegrationButton component.
 */
type FivetranConnectIntegrationButtonProps = {
  /** ID of the group associated with the integration.  */
  groupId?: string;
  /** ID of the user initiating the connection (required).*/
  userId: number;
  /** Selected attributions for connector configuration.*/
  attributions?: string;
  /** Client details including ID and name.*/
  client?: Client | ExternalClient;
  /**  The datasource associated with the integration. */
  datasource: DataSource;
  /** Whether the integration requires dialog interaction.*/
  requiresDialog?: boolean;

  /** The content or children to render inside the button. */
  children?: string | React.ReactNode
};

/**
 * FivetranConnectIntegrationButton Component
 *
 * Dynamically renders a button or appropriate UI for connecting a Fivetran integration based on the
 * user's role, datasource status, and configuration requirements.
 *
 * @returns {JSX.Element} - Renders a "Connect" button, request button, dialog button, or status indicator.
 */
const FivetranConnectIntegrationButton = ({ groupId, datasource, userId, attributions, client, requiresDialog = false, children = 'Connect'}: FivetranConnectIntegrationButtonProps): JSX.Element => {
  // Custom hook to handle logic creating connector
  const { handleConnect, isLoading } = useDataConnectorActions(undefined, userId);
  const isClient = isExternalUser();

  // Early returns
  if(datasource.fivetran_status === 'coming_soon') return <ComingSoonStatus />;
  if(isLoading) return <LoadingIndicator />;

  return (
    <>
      {!isClient && datasource.is_requestable && userId && (
        <RequestIntegrationButton
          userId={userId}
          clientId={client?.id}
          clientName={client?.name}
          dataSourceId={datasource.id}
          integrationRequests={client?.integration_requests?.requests}
          clientUsers={client?.users}
        />
      )}

      {requiresDialog ?
        <DialogIntegrationButton
          datasource={datasource}
          userId={userId}
          client={client}
        /> :
        <Button onClick={() => handleConnect(groupId, datasource?.slug, attributions)} variant='primary' color='primary' size='small'>
          {children}
        </Button>
      }
    </>
  );
};

export default memo(FivetranConnectIntegrationButton);
