import { Funnel } from 'features/intelligence/horizon/components/BudgetTable/components';

export const playbookAdsProjection = {
  id: true,
  name: true,
  formats: true,
  type: true,
  funnel: true,
  adset: {
    id: true,
    name: true,
    funnel: true,
    last_refreshed: true,
    campaign: {
      id: true,
      name: true,
      funnel: true,
      last_refreshed: true,
      client: {
        id: true,
        name: true,
        logo: true,
        business_type: {
          name: true,
        },
        industry: {
          id: true,
          name: true
        },
        playbook_platform_match_rates: {
          platform: {
            id: true,
            name: true,
            slug: true,
          },
          match_rate: true,
        },
      }
    },
  },
  platform: {
    id: true,
    name: true,
    slug: true,
    image_url: true,
  },
  images: {
    id: true,
    url: true,
    thumbnail: true,
    type: true,
    domain_url: true,
  },
  videos: {
    id: true,
    url: true,
    thumbnail: true,
    type: true,
    domain_url: true,
  },
  carousels: {
    id: true,
    type: true,
    identifier: true,
    assets: {
      id: true,
      parent: true,
      url: true,
      thumbnail: true,
      type: true,
      domain_url: true,
    },
  },
  first_asset: {
    domain_url: true,
    url: true,
    thumbnail: true,
  },
  first_asset_usage: {
    ad_ids: true,
    asset: {
      domain_url: true,
      url: true,
    }
  },
  title: true,
  status: true,
  body: true,
  spend: true,
  roas: true,
  revenue: true,
  stop_rate: true,
  hold_rate: true,
  retention_rate: true,
  score: true,
  ctr: true,
  cpc: true,
  cpm: true,
  cpa: true,
  cvr: true,
  clicks: true,
  impressions: true,
  conversions: true,
  slug: true,
  updated_at: true,
  created_at: true,
  ecomm_matched_metric: {
    id: true,
    ltv: true,
    cac: true,
  },
  last_refreshed: true,
};
