import { Projection } from 'api/entityGraphQL';

export const CustomerInsightsDefaultProjection: Projection = {
  id: true,
  updated_at: true,
  client: {
    id: true,
    name: true,
  },
  workspace_id: true,
  integrations: {
    id: true,
    data_source: {
      id: true,
      name: true
    },
    schema_name: true,
    connector_id: true,
  },
  consumer_insight_dates: {
    id: true,
    category: true,
    start_date: true,
    end_date: true,
  },
  consumer_insight_family_behaviors: {
    dates: {
      category: true,
      start_date: true,
      end_date: true,
    },
    insights: {
      id: true,
      label: true,
      format: true,
      definition: true,
      display: true,
      percentage: true,
      benchmark_percentage: true,
    }
  },
  consumer_insight_finance_wealths: {
    dates: {
      category: true,
      start_date: true,
      end_date: true,
    },
    insights: {
      id: true,
      label: true,
      format: true,
      definition: true,
      display: true,
      percentage: true,
      benchmark_percentage: true,
    }
  },
  consumer_insight_interests: {
    dates: {
      category: true,
      start_date: true,
      end_date: true,
    },
    insights: {
      id: true,
      label: true,
      format: true,
      definition: true,
      display: true,
      percentage: true,
      benchmark_percentage: true,
    }
  },
  consumer_insight_ltvs: {
    dates: {
      category: true,
      start_date: true,
      end_date: true,
    },
    insights: {
      id: true,
      label: true,
      format: true,
      definition: true,
      display: true,
      ltv: true,
      ltv_benchmark: true,
      match_percentage: true,
    }
  },
  consumer_insight_real_states: {
    dates: {
      category: true,
      start_date: true,
      end_date: true,
    },
    insights: {
      id: true,
      label: true,
      format: true,
      definition: true,
      display: true,
      percentage: true,
      benchmark_percentage: true,
    }
  },
  consumer_insight_life_stages: {
    dates: {
      category: true,
      start_date: true,
      end_date: true,
    },
    insights: {
      id: true,
      label: true,
      format: true,
      definition: true,
      display: true,
      percentage: true,
      benchmark_percentage: true,
    }
  },
};
