import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Container } from '@sprnova/nebula';
import { PageHero } from 'layouts/components';
import {
  updateContact,
  deleteContact,
  useContactByParamId,
  Contact,
  selectContactById,
} from 'features/entitiesRedux';
import { selectError, selectIsLoading } from 'features/entitiesRedux/selectors';
import {
  Card,
  Skeleton,
  Alert,
  message,
  notification,
  // notification,
  // message,
} from 'components';
import { ContactForm } from '../ContactForm';
import css from './EditContactPage.module.scss';

type FormattedTitleProps = {
  formattedTitle?: string | number;
};

const formatContactTitle = ({
  contact,
  isLoading,
}: {
  contact?: Contact;
  isLoading: boolean;
}) => {
  return !contact && isLoading ? (
    <Skeleton title={{ width: 200 }} paragraph={false} active />
  ) : contact ? (
    (() => {
      if (contact.name) return `${contact.name}`; // Try the name field
      return `Contact #${contact.id}`; // Fallback to the id
    })()
  ) : (
    '...'
  );
};

const EditContactPage: React.FC<FormattedTitleProps> = () => {
  /**
   * Routing
   */
  const history = useHistory();

  /**
   * Redux State
   */
  const error = useSelector(selectError);
  const isLoading = useSelector(selectIsLoading);

  /**
   * Load variables from path
   */
  const contact = useContactByParamId();

  /**
   * Format variables
   */
  const title = formatContactTitle({ contact, isLoading });

  /**
   * Form handler
   */
  const dispatch = useDispatch();
  const handleSubmit = async (values: object) => {
    // console.log("📗 EditContactPage.handleSubmit.values", values);
    if (values && Object.prototype.hasOwnProperty.call(values, 'id')) {
      await dispatch(
        updateContact({ id: (values as { id: number }).id, ...values })
      );
      message.success('Contact saved');
    }
  };
  const onDelete = async () => {
    await dispatch(deleteContact(contact.id));
    history.push('/contacts');
    notification.success({
      message: 'Contact deleted',
      description: (
        <span>
          <em>{title}</em> was successfully deleted
        </span>
      ),
    });
  };

  return (
    <>
      <PageHero
        title={title}
        description="Edit contact"
      />
      <Container hasVerticalPadding>
        <Card className={css.root}>
          {error && <Alert message={error} type="error" className={css.error} />}
          <ContactForm
            contact={contact || undefined}
            onSubmit={handleSubmit}
            onDelete={onDelete}
            isLoading={isLoading}
          />
          {/* <pre>{JSON.stringify(contact, null, 2)}</pre> */}
        </Card>
      </Container>
    </>
  );
};

export const FormattedTitle = () => {
  const { id: idRaw = '' } = useParams<{ [x: string]: any }>();
  const id = Number.parseInt(idRaw);
  const contact = useSelector(selectContactById(id));
  const isLoading = !contact;
  return <span>{formatContactTitle({ contact, isLoading })}</span>;
};

export default EditContactPage;
