import React, { FormEvent, memo, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Drawer, DrawerFooter, SortableItemType, SortableList, Spinner, Switch, useSnackbar } from '@sprnova/nebula';
import { useGetMeQuery } from 'api/crudGraphQL/me/getMe';
import { useGetScoreboardQuery } from 'api/crudGraphQL/scoreboards/getScoreboard';
import { useGetWidgetsQuery } from 'api/crudGraphQL/scoreboards/getWidgets';
import { Widget } from 'api/crudGraphQL/scoreboards/types';
import { ScoreboardWidgetInput, useUpdateScoreboardWidgetsMutation } from 'api/crudGraphQL/scoreboards/updateScoreboardWidgets';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { track } from 'features/scoreboards/components/mixpanelEvents';
import { widgetsProjection } from './projections';

type CustomizeWidgetsDrawerProps = {
  open: boolean;
  toggle: () => void;
};
const CustomizeWidgetsDrawer = ({ open, toggle }: CustomizeWidgetsDrawerProps): JSX.Element => {
  const { id } = useParams<{ [x: string]: string }>();
  const [submitting, setSubmitting] = useState(false);
  const { addSnackbar } = useSnackbar();
  const [items, setItems] = useState<Widget[]>([]);
  const mixpanel = useMixpanel();

  const { data: account } = useGetMeQuery({
    projection: {
      id: true,
      name: true,
      email: true,
    }
  });

  const { data } = useGetWidgetsQuery({
    projection: widgetsProjection,
  });

  const { data: widgetsData } = useGetScoreboardQuery({
    id: parseInt(id),
    projection: {
      id: true,
      widgets: {
        id: true,
        name: true,
        slug: true,
        is_active: true,
        settings: true,
        size: true,
      },
    },
  }, { skip: !id });

  useEffect(() => {
    if (widgetsData) {
      setItems(widgetsData.widgets);
    }
  }, [widgetsData]);

  const handleChange = (newItems:  SortableItemType[]): void => setItems(newItems as Widget[]);
  const [updateScoreboardWidgets] = useUpdateScoreboardWidgetsMutation();

  const onSubmit = useCallback(async (e?: FormEvent<HTMLDivElement>): Promise<void> => {
    e?.preventDefault();
    try {
      setSubmitting(true);
      if (!widgetsData || !items) return;
      await updateScoreboardWidgets({
        report_id: widgetsData.id,
        widgets: items.map((item) => ({
          is_active: item.is_active,
          widget_id: item.id,
          title: item.name,
          settings: item.settings,
          size: item.size,
        } as ScoreboardWidgetInput)),
      });
      addSnackbar({
        message: 'Updated widgets',
        variant: 'success',
        persist: false,
      });
      const { id, name } = account ?? {};
      track({ mixpanel, type: 'customize-widgets', options: { updated_widgets: items, user_id: id, user_name: name } });
    } catch (error) {
      console.error(error);
      addSnackbar({
        message: 'Error updating widgets',
        variant: 'error',
        persist: false,
      });
    } finally {
      setSubmitting(false);
      toggle();
    }
  }, [account, addSnackbar, items, mixpanel, toggle, updateScoreboardWidgets, widgetsData]);

  const renderList = useMemo(() => {
    if (!data) return <></>;
    return (
      <SortableList
        id="CustomizeWidgetsDrawer-sortable-list"
        items={items}
        onChange={handleChange}
      >
        {items.map((item) => (
          <SortableList.Item
            label={item.name}
            key={item.id}
            id={item.id}
            end={
              <Switch
                defaultChecked={item.is_active === 'yes'}
                onChange={(_, checked): void => {
                  setItems(items.map(i =>
                    i.id === item.id ? { ...i, is_active: checked ? 'yes' : 'no' } : i
                  ));
                }}
              />
            }
          />
        ))}
      </SortableList>
    );
  }, [data, items]);

  return (
    <Drawer
      open={open}
      onClose={toggle}
      title="Settings"
      component="form"
      onSubmit={onSubmit}
      footer={
        <DrawerFooter
          primaryButtonProps={{
            type: 'submit',
            children: 'Apply',
            size: 'large',
            disabled: submitting,
            startIcon: submitting && <Spinner size='small' />
          }}
          secondaryButtonProps={{
            children: 'Cancel',
            onClick: toggle,
            size: 'large',
            variant: 'text',
            disabled: submitting,
          }}
        />
      }
    >
      {renderList}
    </Drawer>
  );
};

export default memo(CustomizeWidgetsDrawer);
