/**
 * Get Scoreboard
 */
import { Projection } from 'api/entityGraphQL';
import tagTypes from './tagTypes';
import { Scoreboard } from './types';
import { baseApi, cleanArguments } from '..';

export type Order = 'date' | 'headline' | 'status' | 'created_at' | 'updated_at' | 'publish_date';
export type StatusArgs = {
  limit?: number;
  order?: Order | `-${Order}`;
  exclude_draft?: boolean;
}
export type IndicatorArgs = {
  report_widget_id?: number;
  range?: 'last_week' | 'last_month' | 'last_7_days' | 'last_30_days' | 'last_90_days' | 'month_to_date' | 'last_365_days' | 'year_to_date' | 'all_time';
  from?: string;
  to?: string;
  type?: 'day' | 'week' | 'month' | 'year';
  total?: 'yes' | 'no';
  compared?: 'previous_period' | 'previous_year';
};
export type GoalPacingArgs = {
  id?: number;
  type?: 'monthly' | 'long_term';
  limit?: number;
};
export type GetScoreboardQuery = {
  id: number;
  statusArgs?: StatusArgs;
  indicatorArgs?: IndicatorArgs;
  goalPacingArgs?: GoalPacingArgs;
  projection?: Projection;
}

export type GetScoreboardResult = Scoreboard;

export const GET_SCOREBOARD_ENDPOINT_KEY = 'getScoreboard';

export const { useGetScoreboardQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_SCOREBOARD_ENDPOINT_KEY]: build.query<GetScoreboardResult, GetScoreboardQuery>({
      transformResponse: (response: { report: GetScoreboardResult}) => response.report,
      providesTags: (report) => {
        return report ? [
          { id: report.id, type: tagTypes.SCOREBOARD }
        ] : [tagTypes.SCOREBOARDS];
      },
      query: ({
        id,
        statusArgs,
        indicatorArgs,
        goalPacingArgs,
        projection = { id: true },
      }) => {
        if (id === 0) {
          id = 1;
        }

        if (statusArgs) {
          /**
           * Add status args to projection
           */
          projection = {
            ...projection,
            statuses: {
              __args: cleanArguments(statusArgs),
              id: true,
              ...projection.statuses,
            }
          };
        }

        if (indicatorArgs) {
          /**
           * Add indicator args to projection
           */
          projection = {
            ...projection,
            indicators: {
              __args: cleanArguments(indicatorArgs),
              id: true,
              ...projection.indicators,
            }
          };
        }

        if (goalPacingArgs) {
          /**
           * Add goal pacing args to projection
           */
          projection = {
            ...projection,
            pacing_goals: {
              __args: cleanArguments(goalPacingArgs),
              id: true,
              ...projection.pacing_goals,
            }
          };
        }

        return {
          body: {
            query: {
              report: {
                __args: cleanArguments({ id }),
                ...projection
              }
            }
          }
        };
      }
    }),
  }),
});
