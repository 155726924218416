import { Projection } from 'api/entityGraphQL/types';

export const salesforceOpportunityProjection: Projection = {
  id: true,
  name: true,
  stage: true,
  display_value: true,
};

export const getNovaOpportunitiesProjection: Projection = {
  id: true,
  client: {
    id: true,
  },
  salesforce_id: true,
  salesforce_name: true,
  strategy: {
    id: true,
    client: {
      id: true,
      name: true,
    },
    date: true,
    pillar: {
      id: true,
      name: true
    },
    pricing_version: {
      id: true,
      slug: true,
    },
    name: true,
  }
};

export const getClientsProjection: Projection = {
  id: true,
  name: true,
  salesforce_client_id: true,
  salesforce_client_name: true,
  business_type: {
    id: true,
    name: true,
    slug: true,
  },
};

export const getAuditsProjection: Projection = {
  id: true,
  name: true,
  client: {
    id: true,
    name: true
  }
};

export const getPartnersProjection: Projection = {
  id: true,
  name: true,
  percent: true,
  email: true,
  phone: true,
  company: true,
};

export const getMeProjection: Projection = {
  id: true,
  accounts: {
    type: true
  }
};
