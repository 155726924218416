import React from 'react';
import { Skeleton as AntdSkeleton } from 'antd';
import { SkeletonProps } from 'antd/lib/skeleton';
import classNames from 'classnames';
import css from './Skeleton.module.scss';

interface Props extends SkeletonProps {
  className?: string;
}

const Skeleton = ({ className, ...props }: Props) => {
  return (
    <AntdSkeleton {...props} className={classNames(className, css.root)} />
  );
};

export default Skeleton;
