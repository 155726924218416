import React, { Component } from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Alert, Row } from 'antd';
import { Role } from 'api/accessControl';
import classNames from 'classnames';
import { useAccount } from 'features/global/hooks';
import { Button, notification } from 'components';
import { ErrorPage } from './ErrorPage';
import css from './ErrorBoundary.module.scss';

const ErrorMessage = ({ eventId }: any) => {
  const { account } = useAccount();
  const history = useHistory();
  const currentDate = new Date().toISOString().split('T')[0];

  const copyToClipboard = (id: number) => {
    navigator.clipboard.writeText(id.toString()).then(() =>
      notification.success({
        message: 'Sentry Event ID copied to clipboard',
      })
    );
  };

  const sentryLink = `https://sentry.io/organizations/power-digital/issues/?query=user.username:${account?.name} event.timestamp:${currentDate}`;
  return (
    <div className={css.errorWrapper}>
      <Alert
        message={<ErrorPage />}
        description={
          <>
            <span className={css.title}>Something went wrong... &nbsp;</span>
            <div
              className={css.subtitle}
              onClick={() => copyToClipboard(eventId)}
            >
              Event ID: <code>{eventId}</code>
            </div>
            <Row>
              <Button onClick={() => history.go(-1)} inline>
                Go Back
              </Button>
              <Button onClick={() => history.push('/')} inline>
                Go home
              </Button>
            </Row>
            { account?.roles &&
            account?.roles?.includes('super-admin' as Role) ?
              <Row>
                <Button type="link" inline>
                  <a target="_blank" rel="noopener noreferrer" href={sentryLink}>
                  View in Sentry.io
                  </a>
                </Button>
              </Row> : <></> }
          </>
        }
        type="error"
      />
    </div>
  );
};

export class ErrorBoundary extends Component<{}, any> {
  render(): JSX.Element {
    return (
      <div className={classNames(css.root, { [css.root_mobile]: isMobile })}>
        <Sentry.ErrorBoundary
          fallback={(e) => <ErrorMessage eventId={e.eventId}/>}
        >
          <div>{this.props.children}</div>
        </Sentry.ErrorBoundary>
      </div>
    );
  }
}
