import { createAsyncThunk } from '@reduxjs/toolkit';
import { Role } from 'api/accessControl';
import {
  novaGraphQLClient,
  Pagination,
  Projection,
} from 'api/entityGraphQL';
import { normalizeEntities } from 'features/entitiesRedux/utils';
import { selectAllUsers } from './selectors';
import { UpdateUserArgs } from '../discovery/discovery';
import {
  ActionFetchManyPaginatedConfig,
  ActionFetchPaginatedResponse,
  ActionFetchOneConfig,
} from '../types';
import { User } from '.';

/** Fetch Many */
export type FetchUsersFilter = {
  id?: number;
  name?: string;
  department_id?: number;
  roles?: string[];
  page?: number;
  limit?: number;
};
type FetchUsersPaginatedConfig = ActionFetchManyPaginatedConfig & {
  filter?: FetchUsersFilter;
  projection?: Projection;
  pagination?: Pagination;
};
export const fetchUsersPaginated = createAsyncThunk(
  'entities/fetchUsersPaginated',
  async ({
    filter: { name, department_id, roles } = {},
    projection,
    pagination, // { page, limit }
  }: FetchUsersPaginatedConfig): Promise<ActionFetchPaginatedResponse> => {
    const { users, ...rest } = await novaGraphQLClient.fetchUsers({
      args: {
        name: name ? `*${String(name).replace(/\s+/g, '*')}*` : undefined,
        department_id,
        roles
      },
      projection,
      pagination,
    });
    return {
      ...normalizeEntities({ users }),
      pagination: rest,
    };
  }
);

/** Fetch One */
export const fetchUserById = createAsyncThunk(
  'entities/fetchUserById',
  async ({ id, projection }: ActionFetchOneConfig) => {
    const { users } = await novaGraphQLClient.fetchUserById(id, { projection });
    return { ...normalizeEntities({ users }) };
  }
);

/** Update */
export const updateUser = createAsyncThunk(
  'entities/updateUser',
  async ({
    projection,
    ...args
  }: {
    projection?: Projection;
  } & UpdateUserArgs) => {
    const response = await novaGraphQLClient.updateUser(args, {
      projection,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Delete */
export const deleteUser = createAsyncThunk(
  'entities/deleteUser',
  async ({ id }: { id: number }, { getState }: { getState: any }) => {
    await novaGraphQLClient.deleteUser(id);
    const users = selectAllUsers()(getState());
    const updatedUsers = users.filter((user: User) => user.id !== id);
    return { ...normalizeEntities({ users: updatedUsers }) };
  }
);
