/**
 * Delete service
 */

import { Projection } from 'api/entityGraphQL';
import { Service } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi } from '../baseApi';

export type DeleteServiceMutation = {
  projection?: Projection;
  id: number;
}

export type DeleteServiceResult = Service;

export const { useDeleteServiceMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    deleteService: build.mutation<DeleteServiceResult, DeleteServiceMutation>({
      invalidatesTags: [tagTypes.SERVICES],
      query: ({ id, projection = {} }) => ({
        body: {
          mutation: {
            deleteService: {
              __args: {
                id
              },
              ...projection,
              id: true
            }
          }
        }
      })
    }),
  }),
});
