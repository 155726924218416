/**
 * SectionBuilder -> Fields -> GoalsField
 *
 * Dynamic FormList for adding multiple KPI goals
 */

import React, { FC } from 'react';
import { MinusCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Col, Row } from 'antd';
import {
  Button,
  FormItem,
  FormList,
  InputNumber, Tooltip
} from 'components';
import { SelectGoal } from './components';
import { Goal } from '../../../../../../../entitiesRedux';
import { formatCurrencyInput } from '../../../../../../utils';
import { FieldProps } from '../../types';
import css from '../fieldgroup_shared.module.scss';

const GoalsField: FC<FieldProps> = ({ name, form }) => {
  const discoveryGoals = form.getFieldValue([name])?.filter((goal: Goal) => !!goal?.name) || [];
  return (
    <div className={css.root}>
      <FormList name={name}>
        {(fields, { add, remove }) => {
          return (
            <div>
              {fields.map((field) => (
                <Row key={field.key} align="top" justify="space-between">
                  <Col xs={23} className={css.column_fieldset}>
                    <Row gutter={[12, 0]}>
                      <Col xs={24} lg={12}>
                        <FormItem
                          name={[field.name, 'type']}
                          label="Type"
                          fieldKey={field.fieldKey}
                        >
                          <SelectGoal
                            placeholder="Select a goal type"
                            discoveryGoals={discoveryGoals}
                          />
                        </FormItem>
                      </Col>
                      <Col xs={24} lg={12}>
                        <FormItem
                          name={[field.name, 'metric']}
                          label="Amount"
                          fieldKey={field.fieldKey}
                        >
                          <InputNumber
                            formatter={formatCurrencyInput}
                            type="currency"
                            precision={2}
                          />
                          {/*<Input placeholder="Enter a metric for this goal" />*/}
                        </FormItem>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={1} className={css.column_delete}>
                    <Tooltip title="Delete">
                      <MinusCircleOutlined
                        className={css.delete}
                        onClick={() => remove(field.name)}
                      />
                    </Tooltip>
                  </Col>
                </Row>
              ))}
              <FormItem>
                <Button type="text" header icon={<PlusCircleFilled />} onClick={() => add()}>
                  Add goal
                </Button>
              </FormItem>
            </div>
          );
        }}
      </FormList>
    </div>
  );
};

export default GoalsField;
