import React from 'react';
import loadable from '@loadable/component';
import { Skeleton } from 'antd';

const LoadableComponent = loadable(() => import('./NotificationsMenu'), {
  fallback: (
    <Skeleton
      avatar={{ shape: 'circle' }}
      title={false}
      paragraph={false}
      active
    />
  )
});

export { LoadableComponent as NotificationsMenu };
