/**
 * Entities -> Forecast -> Actions
 */

import { createAsyncThunk } from '@reduxjs/toolkit';
import { Projection, novaGraphQLClient } from 'api/entityGraphQL';
import {
  CreateForecastFormValues,
  CreateForecastNeedleMoversArgs,
  UpdateForecastArgs,
  UpdateForecastChannelArgs,
  UpdateForecastMonthArgs,
} from './forecast';
import { normalizeEntities } from '../../utils';

/** Fetch one */
export const fetchForecastById = createAsyncThunk(
  'entities/fetchForecastById',
  async ({ id, ...config }: { id: number; projection?: Projection }) => {
    const { forecasts } = await novaGraphQLClient.fetchForecastById(id, config);
    return { ...normalizeEntities({ forecasts }) };
  }
);

/** Create Forecast */
export const createForecast = createAsyncThunk(
  'entities/createForecast',
  async (
    forecast: CreateForecastFormValues
  ) => {
    const response = await novaGraphQLClient.createForecast({
      audit_id: parseInt(String(forecast.audit_id), 10),
      name: forecast.name,
      length: forecast.length,
      date: forecast.date,
      channel_ids: forecast.channel_ids,
      ad_spend_lift: forecast.ad_spend_lift,
      efficiency_lift: forecast.efficiency_lift,
      model_id: forecast.model_id,
    });

    return { ...normalizeEntities(response) };
  }
);

/** Update Forecast */
export const updateForecast = createAsyncThunk(
  'entities/updateForecast',
  async (
    { projection, ...args }: UpdateForecastArgs & { projection?: Projection },
  ) => {
    const response = await novaGraphQLClient.updateForecast(args, { projection });
    return { ...normalizeEntities(response) };
  }
);

/** Update Forecast Channel */
export const updateForecastChannel = createAsyncThunk(
  'entities/updateForecastChannel',
  async (
    args: UpdateForecastChannelArgs & {
      projection?: Projection
    },
  ) => {
    const forecastChannel = await novaGraphQLClient.updateForecastChannel(args);
    return { forecast_id: args.forecast_id, ...forecastChannel};
  }
);

/** Create Forecast Channel */
export const createForecastChannel = createAsyncThunk(
  'entities/createForecastChannel',
  async (
    args: UpdateForecastChannelArgs,
  ) => {
    const forecastChannel = await novaGraphQLClient.createForecastChannel(args);
    return { forecast_id: args.forecast_id, ...forecastChannel};
  }
);

/** Update Forecast Month */
export const updateForecastMonth = createAsyncThunk(
  'entities/updateForecastMonth',
  async (args: UpdateForecastMonthArgs & {
    // forecast_channel_id is relevant when updating forecast.channel.months
    // Note: Only used to update the values in the redux state (see forecast reducers)
    forecast_channel_id?: number;
  }) => {
    const updatedForecastMonths = await novaGraphQLClient.updateForecastMonth(args);
    return { ...args, months: updatedForecastMonths };
  }
);

/** Bulk Create Forecast Needle Movers */
export const createNeedleMovers = createAsyncThunk(
  'entities/createNeedleMovers',
  async ({ needle_movers, forecast_id, forecast_channel_id, forecast_month_id }: {
    needle_movers: CreateForecastNeedleMoversArgs[];
    forecast_id: number;
    forecast_month_id?: number | null;
    forecast_channel_id?: number | null;
  }) => {
    const response = await novaGraphQLClient.createForecastNeedleMovers(needle_movers);
    return { needle_movers: response, forecast_id, forecast_channel_id, forecast_month_id };
  }
);

/** Bulk Delete Forecast Needle Movers */
export const deleteNeedleMovers = createAsyncThunk(
  'entities/deleteNeedleMovers',
  async ({ needle_mover_ids, forecast_id, forecast_channel_id, forecast_month_id }: {
    needle_mover_ids: number[];
    forecast_id: number;
    forecast_month_id?: number | null;
    forecast_channel_id?: number | null;
  }) => {
    const needle_movers = await novaGraphQLClient.deleteForecastNeedleMovers(needle_mover_ids);
    return { needle_movers, forecast_id, forecast_channel_id, forecast_month_id, needle_mover_ids };
  }
);
