import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button, Grid, PlusIcon, useFieldArray, useForm } from '@sprnova/nebula';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { capitalize } from 'lodash';
import { plural, singular } from 'pluralize';
import { useAccount } from 'features/global';
import { ControllerColumn } from './ControllerColumn';
import { TabNames } from '../../utils/constants';

type ComparisonRepeaterProps = {
  compareValues: any;
  activeTab: string | number | null | undefined;
  setIds?: Record<string, React.Dispatch<React.SetStateAction<any>>>;
  toggle?: React.DispatchWithoutAction;
};
export const ComparisonRepeater = ({ activeTab, compareValues, setIds, toggle }: ComparisonRepeaterProps): JSX.Element => {
  const [error, setError] = useState<string>();

  const defaultFields: any = useMemo(() => {
    let fields: any = { client_id: undefined };
    if (activeTab === TabNames.campaigns) fields = { ...fields, campaign_id: undefined };
    if (activeTab === TabNames.adsets) fields = { ...fields, campaign_id: undefined, adset_id: undefined };
    if (activeTab === TabNames.ads) fields = { ...fields, campaign_id: undefined, adset_id: undefined, ad_id: undefined };
    return fields;
  }, [activeTab]);

  const [fieldsLength, setFieldsLength] = useState<number>(0);

  const handleDefaultValues = useCallback((index) => {
    switch (activeTab) {
      case TabNames.campaigns: {
        const { id, name, client } = compareValues?.[index] ?? {};
        return { client_id: { id: client?.id, label: client?.name }, campaign_id: { id, label: name } };
      }
      case TabNames.adsets: {
        const { id, name, campaign } = compareValues?.[index] ?? {};
        return { client_id: { id: campaign?.client?.id, label: campaign?.client?.name }, campaign_id: { id: campaign?.id, label: campaign?.name }, adset_id: { id, label: name } };
      }
      case TabNames.ads: {
        const { id, name,  adset } = compareValues?.[index] ?? {};
        return { client_id: { id: adset?.campaign?.client?.id, label: adset?.campaign?.client?.name }, campaign_id: { id: adset?.campaign?.id, label: adset?.campaign?.name }, adset_id: { id: adset?.id, label: adset?.name }, ad_id: { id, label: name } };
      }
      default:
        return { client_id: undefined };
    }
  }, [compareValues, activeTab]);

  const { control, handleSubmit, setValue } = useForm({
    defaultValues: {
      columns: [ 
        ...(compareValues ? compareValues.map((_: any, i: number): any => 
          fieldsLength < compareValues.length ? handleDefaultValues(i) : defaultFields
        ) : []) 
      ],
    },
  });

  const { append, remove, fields } = useFieldArray({
    control,
    name: 'columns',
  });

  useEffect(() => {
    setFieldsLength(fields.length);
  }, [fields]);

  /**
   * Mixpanel event tracking
   */
  const mixpanel = useMixpanel();
  const { account } = useAccount();
  const trackChangeCompareSelects = useCallback((type: string) => {
    try {
      if (!mixpanel) return;
      const mixpanelTitle = `Creative Affinity - ${capitalize(type)} Change Compare Selects`;
      const options = {
        type,
        userId: account?.id,
        userName: account?.name,
      };
      mixpanel.track(mixpanelTitle, options);
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${mixpanelTitle}`, { options });
    } catch (error) {
      console.error('Track Mixpanel error', error);
    }
  }, [account, mixpanel]);

  const onSubmit = useCallback((data: any) => {
    if (data.columns.length < 2) {
      return setError(`You must select at least 2 ${plural(activeTab as 'string')} to compare`);
    } else {
      setError(undefined);
    }
    switch (activeTab) {
      case TabNames.campaigns: {
        const ids = data.columns.map((column: any) => column.campaign_id.id);
        if (ids.length && ids.length >= 2) {
          setIds?.setCampaignIds(ids);
        } else {
          setError(`You must select at least 2 ${plural(activeTab as unknown as 'string')} to compare`);
        }
        break;
      }
      case TabNames.adsets: {
        const ids = data.columns.map((column: any) => column.adset_id.id);
        if (ids.length && ids.length >= 2) {
          setIds?.setAdsetIds(ids);
        } else {
          setError(`You must select at least 2 ${plural(activeTab as unknown as 'string')} to compare`);
        }
        break;
      }
      case TabNames.ads: {
        const ids = data.columns.map((column: any) => column?.ad_id?.id);
        if (ids.length && ids.length >= 2) {
          setIds?.setAdIds(ids);
        } else {
          setError(`You must select at least 2 ${plural(activeTab as unknown as 'string')} to compare`);
        }
        break;
      }
      default:
        return;
    }
    trackChangeCompareSelects(activeTab as unknown as 'string');
  }, [activeTab, setIds, trackChangeCompareSelects]);

  const handleAdd = useCallback(() => {
    if (fields.length >= 4) return;
    append(defaultFields);
  }, [append, defaultFields, fields.length]);

  const renderControllerColumns = useCallback(({ field, index }) => {
    return (
      <ControllerColumn
        control={control}
        numberOfColumns={fields.length}
        field={field}
        setValue={setValue}
        index={index}
        remove={remove}
      />
    );
  }, [control, fields.length, setValue, remove]);

  return (
    <Grid container spacing={2}>
      <Grid container spacing={2}>
        {fields.map((field, index) => renderControllerColumns({ field, index }))}
      </Grid>
      <Grid container alignItems='center' justifyContent='center'>
        {
          error && <Alert severity="error">{error}</Alert>
        }
      </Grid>
      <Grid container alignItems='center' justifyContent='center'>
        <Grid item xs={12} display='flex' justifyContent='center'>
          <Button
            size='large'
            type='button'
            variant='tertiary'
            onClick={() => {
              handleSubmit(event => {
                onSubmit(event);
              })().then(() => toggle?.());
            }}
          >
            Apply Changes
          </Button>
        </Grid>
        <Grid item xs={12} display='flex' justifyContent='center' sx={{ minHeight: '25px', marginTop: '20px' }}>
          {
            fields.length < 4 &&
              <Button
                startIcon={<PlusIcon />}
                onClick={handleAdd}
              >
                {capitalize(singular(activeTab as 'string'))}
              </Button>
          }
        </Grid>
      </Grid>
    </Grid>
  );
};
