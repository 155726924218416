/**
 * Create task
 */

import { Projection } from 'api/entityGraphQL';
import { Task, TaskChildren } from './types';
import { baseApi, cleanArguments } from '..';
import { PricingTier } from '../strategies/types';

export type CreateTaskMutation = {
  name: string;
  service_id: number;
  pricing_type_id?: number;
  snippet_summary?: string;
  strategy_frequency_id?: number;
  children?: TaskChildren[];
  pricing_tiers?: PricingTier[];
  pricing_version?: string | null;
  projection?: Projection;
}

export type CreateTaskResult = Task;

export const CREATE_TASK_ENDPOINT_KEY = 'createTask';

export const { useCreateTaskMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [CREATE_TASK_ENDPOINT_KEY]: build.mutation<CreateTaskResult, CreateTaskMutation>({
      transformResponse: (response: { createTask: Task }) => response.createTask,
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<CreateTaskMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              createTask: {
                __args: cleanArguments(__args),
                ...projection,
                id: true
              }
            }
          }
        };
      }
    })
  })
});
