import { Projection } from 'api/entityGraphQL';

export const requiredInformationProjection: Projection = {
  name: true,
  id: true,
  slug: true,
  is_needed: true
};

export const strategyMonthProjection: Projection = {
  cost: true,
  date: true,
  hour: true,
  hours: true,
  management_hours: true,
  suggested_hourly_rate: true,
  id: true,
  name: true,
  profit: true,
  retainer: true,
  recommended: true,
  suggestion: true,
  hourly_rate: true,
  costs: true,
  value: true,
  services: true
};

export const strategyCostProjection: Projection = {
  id: true,
  name: true,
  notes: true,
  months: strategyMonthProjection,
  created_at: true,
  updated_at: true,
  department: {
    name: true,
    id: true,
    slug: true,
  },
};

export const strategyTaskProjection: Projection = {
  id: true,
  name: true,
  notes: true,
  hours: true,
  projected_hours: true,
  is_configurable: true,
  hours_per_unit: true,
  unit_type: true,
  units: true,
  order: true,
  months: strategyMonthProjection,
  snippet: true,
  service: {
    id: true,
    name: true,
    department: {
      name: true,
      id: true,
    },
  },
  multiplier: {
    name: true,
    slug: true,
    id: true,
  },
  created_at: true,
  updated_at: true,
  control_type: true,
  minimum_hours: true,
  maximum_hours: true,
  recommended_hours: true,
  slug: true
};

export const strategyServiceSummaryProjection: Projection = {
  id: true,
  name: true,
  slug: true,
  months: {
    id: true,
    name: true,
    value: true,
    date: true,
    hours: true,
  },
  calculation_notes: true
};

export const strategyDepartmentSummaryProjection: Projection = {
  id: true,
  name: true,
  slug: true,
  months: {
    id: true,
    name: true,
    value: true,
    date: true,
    hours: true,
  },
  calculation_notes: true
};

export const strategyProjection: Projection = {
  actual_gross_profit: true,
  ad_percent: true,
  ad_spend: true,
  adjusted_commission_percent: true,
  account_manager: {
    name: true,
    id: true,
    avatar: true,
  },
  aging: true,
  audit: {
    id: true,
    name: true,
    audits: {
      id: true,
      strategy: {
        id: true,
        name: true,
        date: true,
        length: true,
        monthly_gross_profit: true,
        total_retainer: true,
        tasks: {
          name: true,
          service: {
            name: true,
            id: true,
          }
        },
        departments: {
          name: true,
          id: true,
        },
        client: {
          name: true,
        },
      }
    }
  },
  auto_added_strategy_packages: {
    name: true,
  },
  author: {
    name: true,
    id: true,
    commission_override: true,
    avatar: true,
  },
  id: true,
  name: true,
  blueprint_status: true,
  billing_name: true,
  billing_phone: true,
  billing_email: true,
  billing_address: true,
  billing_city: true,
  billing_state: true,
  billing_zip: true,
  billing_country: true,
  business_type: {
    name: true,
    id: true,
  },
  churn_reason: {
    churn_type: {
      name: true
    },
    created_by: {
      name: true,
      id: true,
    },
    early_churn_date: true,
    what_went_well: true,
    what_could_be_improved: true,
    client_escalation_link: true,
    created_at: true,
  },
  close_won_at: true,
  created_at: true,
  commission_flat_fee: true,
  commission_percent_adjustment: true,
  commission_percent: true,
  commission_version: true,
  costs: strategyCostProjection,
  client: {
    id: true,
    name: true,
    account_manager: {
      id: true,
    },
    business_type: {
      id: true,
      slug: true,
      name: true,
    },
    executive_sponsor: {
      id: true,
    },
    lead_source: {
      id: true,
    },
    industry: {
      id: true,
      name: true,
    },
    organization: {
      id: true,
      name: true,
    },
    tier: {
      name: true,
    },
    netsuite_id: true,
    salesforce_client_id: true,
    salesforce_client_name: true,
  },
  date: true,
  default_base_commission: true,
  departments: {
    name: true,
    slug: true,
    id: true,
    is_commission: true,
    reviewer: {
      id: true,
      name: true,
    },
    reviewer_status: true,
    reviewed_at: true,
    require_commission_approval: true,
  },
  departments_across_all_blueprint_entities: {
    name: true
  },
  department_summary:
  {
    ...strategyDepartmentSummaryProjection
  },
  deny_reason: true,
  detailed_reason: true,
  early_churn_date: true,
  embed_url: true,
  executive_sponsor: {
    name: true,
    id: true,
    avatar: true,
  },
  finance_summary: {
    department_name: true,
    service_name: true,
    type: true,
    months: {
      date: true,
      value: true
    }
  },
  finance_reviewer: {
    id: true,
    name: true,
  },
  finance_reviewer_status: true,
  finance_reviewed_at: true,
  follow_up_date: true,
  grade: {
    name: true,
    hourly_rate: true,
  },
  guaranteed_term: true,
  hourly_rate: true,
  is_outsource_needed: true,
  is_commission_needed: true,
  is_package_needed: true,
  lead_source: {
    name: true,
    id: true,
    slug: true,
  },
  lead_source_employee: {
    name: true,
    id: true,
  },
  length: true,
  lost_reason_type: true,
  monthly_gross_profit: true,
  months: strategyMonthProjection,
  multipliers: {
    hours: true,
    id: true,
    name: true,
    value: true,
  },
  notes: true,
  nova_is_primary: true,
  parent: {
    id: true,
    name: true,
    pricing_version: {
      slug: true,
    }
  },
  presentation: {
    deck_url: true,
  },
  pricing_version: {
    slug: true
  },
  partner: {
    name: true,
    company: true,
    id: true,
    percent: true,
  },
  packages: {
    id: true,
    name: true,
    units: true,
    value: true,
    value_per_month: true,
    tier: {
      id: true,
      name: true,
      price: true,
      snippet: true
    },
    task: {
      id: true,
      name: true,
      pricing_version: {
        slug: true
      },
      pricing_type: {
        slug: true
      },
      service: {
        id: true,
        name: true,
        department: {
          name: true,
        }
      },
      snippet: true,
      unit_type: true,
    },
    months: {
      id: true,
      name: true,
      date: true,
      value: true
    }
  },
  pillar: {
    id: true,
    name: true,
    slug: true,
  },
  proposal_url: true,
  required_information: requiredInformationProjection,
  revenue_calculation_version: true,
  reviewed_at: true,
  reviewer_status: true,
  reviewer: { id: true, name: true },
  salesforce_connection_status: true,
  salesforce_opportunity_id: true,
  salesforce_opportunity_name: true,
  salesforce_opportunity_link: true,
  salesforce_is_primary: true,
  salesforce_opportunity: {
    reason: true,
    explanation: true,
    close_date: true
  },
  service_summary: {
    ...strategyServiceSummaryProjection,
    department: { id: true, name: true },
  },
  status: true,
  sync_to_salesforce: true,
  target_hourly_rate: true,
  tasks: strategyTaskProjection,
  total_commission: true,
  total_commission_expense: true,
  total_executive: true,
  total_executive_expense: true,
  total_expenses: true,
  total_gross_profit: true,
  total_minimum: true,
  total_partner: true,
  total_partner_expense: true,
  total_profit: true,
  total_real_profit: true,
  total_hours: true,
  total_costs: true,
  total_retainer: true,
  type: true,
  users: {
    id: true,
    name: true,
    region: {
      id: true,
      name: true,
      slug: true,
    },
    commission_percent: true,
    strategy_commission: true,
    department: {
      name: true,
    },
    departments: {
      name: true,
      require_commission_approval: true,
    }
  },
  version_count: true, // includes deleted versions
  versions: {
    id: true,
    client: {
      name: true,
    },
    date: true,
    notes: true,
    pillar: {
      name: true,
    },
    nova_is_primary: true,
    salesforce_is_primary: true,
    status: true,
    version_name: true,
  },
  version_name: true,
  is_vabo: true,
  vabo_type: {
    id: true,
    name: true,
    slug: true,
    description: true,
    pricing_version: {
      id: true,
      name: true,
    }
  },
};
