import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Projection } from 'api/entityGraphQL';
import { StringParam, NumberParam } from 'use-query-params';
import { fetchClientsPaginated, fetchClientById } from './actions';
import { Client } from './client';
import { selectClientsByIds, selectClientById } from './selectors';
import { useEntitiesPaginated, useEntityByParamId, useEntityByParamIdRefresh } from '../_entity/hooks';
import { HookPaginatedConfig, HookPaginatedReturn } from '../types';

export const useClientsPaginated = ({
  page: defaultPage,
  limit: defaultLimit,
  filter: defaultFilter,
  projection,
  trackHistory = true,
}: HookPaginatedConfig = {}): HookPaginatedReturn & {
  clients: Partial<Client>[];
} => {
  /** Identify filter parameters explicitely for QueryString tracking */
  const filterParams = {
    name: StringParam,
    authorId: NumberParam,
  };
  /** dispatch action and return data */
  const {
    entities: { clients: clientIds },
    isLoading,
    error,
    pagination,
    filter,
    setFilter,
  } = useEntitiesPaginated(fetchClientsPaginated, {
    page: defaultPage,
    limit: defaultLimit,
    filter: defaultFilter,
    filterParams,
    projection,
    trackHistory,
  });

  /** redux data selector */
  const clients = useSelector(selectClientsByIds(clientIds));
  return { clients, isLoading, error, pagination, filter, setFilter };
};

export const useClientByParamId = (
  config: {
    projection?: Projection | any;
  } = {}
): Client => useEntityByParamId(selectClientById, fetchClientById, config, 'clientId');

export const useClientByParamIdRefresh = (
  config: {
    projection?: Projection;
  } = {}
): { client: Client, refreshData: () => void } => {
  const response = useEntityByParamIdRefresh(selectClientById, fetchClientById, config, 'clientId');
  return {
    client: response?.entity,
    refreshData: response?.refreshData
  };
};

export const useClientRefreshById = (
  clientId: number,
  config: { projection?: Projection } = {}
): { client: Client, refresh: (config?: { projection?: Projection }) => void } => {
  const dispatch = useDispatch();
  const refresh = (config?: { projection?: Projection }) => {
    dispatch(fetchClientById({ id: clientId, ...config }));
  };
  useEffect(() => {
    dispatch(fetchClientById({ id: clientId, ...config }));
  }, [clientId]); // eslint-disable-line
  return {
    client: useSelector(selectClientById(clientId)),
    refresh,
  };
};
