import { useCallback, useEffect } from 'react';
import { useSnackbar } from '@sprnova/nebula';
import { useUpdateClientIntegrationRequestMutation } from 'api/crudGraphQL/client_integration_request/updateClientIntegrationRequest';
import { useCreateFivetranConnectorMutation } from 'api/crudGraphQL/fivetran/createFivetranConnector';
import { useCreateFivetranConnectorPublicMutation } from 'api/crudGraphQL/public/fivetran/createFivetranConnectorPublic';
import isExternalUser from 'utils/helpers/isExternalUser';

type DataConnectorActionsReturnType = {
  handleConnect: (groupId?: string, datasource?: string, attributions?: string) => Promise<void>;
  isLoading?: boolean;
  isSuccess?: boolean;
  requiresDialog: (datasource?: string) => boolean
}
/**
 * useDataConnectorActions Hook
 *
 * This custom React hook manages the logic related to connecting a data source for a group.
 * It utilizes mutations from GraphQL to perform these operations and provides a loading state.
 * @param {string} [accessRequestId] - ID for the access request
 * @param {number} [userId] - User ID of the user who initiated the request
 * @returns {Object} Object containing:
 * - handleConnect: Function to trigger data connector creation
 * - isLoading: Boolean to indicate if operations are still pending
 */

export const useDataConnectorActions = (accessRequestId?: string, userId?: number): DataConnectorActionsReturnType => {
  // Snackbar for success/error message display
  const { addSnackbar } = useSnackbar();
  const isClient = isExternalUser();

  // GraphQL mutation (internal)
  const [createFivetranConnector, {
    data: connectorData,
    isLoading: isCreateConnectorLoading,
    isError: isCreateConnectorError,
    isSuccess: isCreateConnectorSuccess
  }] = useCreateFivetranConnectorMutation();

  // GraphQL mutation (external)
  const [createFivetranConnectorPublic, {
    data: connectorDataPublic,
    isLoading: isCreateConnectorLoadingPublic,
    isError: isCreateConnectorErrorPublic,
    isSuccess: isCreateConnectorSuccessPublic
  }] = useCreateFivetranConnectorPublicMutation();

  const [updateClientIntegrationRequest, {isLoading: updateRequestStatusLoading}] = useUpdateClientIntegrationRequestMutation();

  // mutation states
  const data = connectorData ?? connectorDataPublic ?? null;
  const isCreateLoading = isCreateConnectorLoading || isCreateConnectorLoadingPublic;
  const isError = isCreateConnectorError || isCreateConnectorErrorPublic;
  const isSuccess = isCreateConnectorSuccess || isCreateConnectorSuccessPublic;
  const isLoading = isCreateLoading || updateRequestStatusLoading;

  // Effect to handle updates and redirection after a successful connector creation
  useEffect(() => {
    const handleUpdateAndRedirect = async (): Promise<void> => {
      if (data && !isLoading && !isError) {
        const connectorUri = data?.connector_card_uri;
        if (connectorUri) {
          try {
            // Update request status
            if(accessRequestId && userId) {
              await updateClientIntegrationRequest({
                id: accessRequestId,
                status: 'complete',
                completed_by: userId,
              }).unwrap();
            }

            addSnackbar({
              variant: 'success',
              message: 'Connector successfully created',
              persist: false,
            });

            // Redirect to connector card url
            window.location.href = connectorUri;
          } catch (error) {
            console.error('Failed to update client integration request', error);
          }
        } else {
          console.error('No connector_card_uri returned', data);
        }
      }
    };

    handleUpdateAndRedirect();
  }, [data, isError, updateClientIntegrationRequest, accessRequestId, userId, isLoading, addSnackbar]);


  // Initiates the creation of a new Fivetran connector based on the group and data source.
  const handleConnect = useCallback(async (groupId?: string, datasource?: string, attributions?: string) => {
    if (!groupId || !datasource) {
      console.error('groupId is undefined');
      return;
    }

    if(isClient) {
      await createFivetranConnectorPublic({ group_id: groupId, service: datasource, is_created_by_client: isClient, attributions: attributions });
    } else {
      await createFivetranConnector({ group_id: groupId, service: datasource, is_created_by_client: isClient, attributions: attributions });
    }
  }, [createFivetranConnector, createFivetranConnectorPublic, isClient]);

  // Determine if datasource requires a dialog
  const requiresDialog = (datasourceSlug?: string): boolean => {
    const datasourcesWithDialog = ['paid-pinterest', 'google-ads', 'google-analytics-4', 'google-analytics', 'google-search-console'];
    if(datasourceSlug) return datasourcesWithDialog.includes(datasourceSlug);
    return false;
  };

  return { handleConnect, isLoading, isSuccess, requiresDialog };
};
