/**
 * Entities -> Rules -> Selectors
 */

import { EntityId } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { rulesAdapter } from './adapter';
import { selectEntitiesDenormalized } from '../_entity/selectors';

export const ruleSelectors = rulesAdapter.getSelectors(
  (state: RootState) => state.entities.data.rules
);

export const selectRulesByIds = (ruleIds: EntityId[]) => (state: RootState) => {
  const input = { rules: ruleIds };
  return selectEntitiesDenormalized(state, input).rules;
};

export const selectAllRules = (state: RootState) => {
  const ruleIds = ruleSelectors.selectIds(state);
  return selectRulesByIds(ruleIds)(state);
};

export const selectRuleById = (ruleId: number) => (state: RootState) => {
  return selectRulesByIds([ruleId])(state)[0];
};
