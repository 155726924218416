/**
 * Create strategy duplicate
 */

import { Projection } from 'api/entityGraphQL';
import { Strategy } from 'features/entitiesRedux';
import { baseApi, cleanArguments } from '../../..';

export type CreateStrategyDuplicateMutation = {
  id: number;
  audit_id?: number;
  client_id?: number;
  commission_flat_fee?: number;
  date?: string;
  guaranteed_term?: number;
  is_vabo?: boolean;
  lead_source_id?: number;
  length?: number;
  notes?: string;
  partner_id?: number;
  pillar_id?: number;
  salesforce_opportunity_id?: string;
  salesforce_opportunity_name?: string;
  status?: string;
  type?: string;
  vabo_type_id?: number;
  projection?: Projection;
};

export type CreateStrategyDuplicateResult = Strategy;

export const CREATE_STRATEGY_DUPLICATE_ENDPOINT_KEY = 'createStrategyDuplicate';

export const { useCreateStrategyDuplicateMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [CREATE_STRATEGY_DUPLICATE_ENDPOINT_KEY]: build.mutation<CreateStrategyDuplicateResult, CreateStrategyDuplicateMutation>({
      transformResponse: (response: { createStrategyDuplicate: Strategy }) => response.createStrategyDuplicate,
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<CreateStrategyDuplicateMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              createStrategyDuplicate: {
                __args: cleanArguments(__args),
                id: true
              }
            }
          }
        };
      }
    })
  })
});
