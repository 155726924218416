/**
 * View strategy -> Hourly Breakdown -> Table
 */
import React, {
  FC,
  useState,
  useMemo,
  useEffect,
  useCallback,
  memo,
  useRef,
} from 'react';
/**
  using package aliases to utilize newer/older versions of react-dnd, immutable-helper, and react-dnd-html5-backend to avoid refactoring
  and easily use documented features from ant-design without breaking other code
*/
import { HTML5Backend } from 'react-dnd-html5-backend-latest';
import { DndProvider, useDrag, useDrop } from 'react-dnd-latest';
import {
  DeleteOutlined,
  PlusCircleFilled,
} from '@ant-design/icons';
import { Tag } from 'antd';
import { useUpdateStrategyTaskMutation } from 'api/crudGraphQL/strategies/additional/task/updateStrategyTask';
import { useUpdateStrategyTaskMonthMutation } from 'api/crudGraphQL/strategies/additional/task_month/updateStrategyTaskMonth';
import { useUpdateStrategyTaskOrderMutation } from 'api/crudGraphQL/strategies/additional/task_order/updateStrategyTaskOrder';
import { useCreateStrategyTasksMutation } from 'api/crudGraphQL/strategies/additional/tasks/createStrategyTasks';
import { useDeleteStrategyTasksMutation } from 'api/crudGraphQL/strategies/additional/tasks/deleteStrategyTasks';
import classNames from 'classnames';
import { AddTasksModal } from 'components/AddTasksModal';
import { Button, ButtonProps } from 'components/Button';
import { Card } from 'components/Card';
import { DescriptionList } from 'components/DescriptionList';
import { NoResultsFound } from 'components/NoResultsFound';
import { NotesButton } from 'components/NotesButton';
import { notification } from 'components/notification';
import { Popconfirm } from 'components/Popconfirm';
import { Table } from 'components/Table';
import moment from 'moment';
import pluralize from 'pluralize';
import shouldTrackReUnlockedBlueprint from 'utils/blueprints/blueprintMixpanelUtil';
import shouldCellUpdate from 'utils/blueprints/tableUtil';
import { useMixpanelTrack } from 'utils';
import {
  Strategy,
  StrategyMonth,
  StrategyTask,
  TaskInput,
  User,
} from 'features/entitiesRedux';
import { HourField, AlertBar } from './components';
import DescriptionField from '../../../DescriptionField';
import css from './HourlyBreakdownTable.module.scss';
import sharedCss from '../../../../shared/styling.module.scss';

type MultiplierSlugProps = { slug: string; name: string };
export const MultiplierSlug = ({ slug, name }: MultiplierSlugProps): JSX.Element => {
  const colors: { [x: string]: string | undefined } = {
    senior: 'magenta',
    standard: undefined,
    junior: 'blue',
    default: undefined,
  };
  return <Tag color={colors[slug] || colors['default']}>{name}</Tag>;
};

interface DraggableBodyRowProps extends React.HTMLAttributes<HTMLTableRowElement> {
  index: number;
  moveRow: (dragIndex: number, hoverIndex: number) => void;
}

type HourFieldObjectInputType = {
  hourDraggingValue: number;
  taskId: number;
  monthId: number;
  strategyId: number;
}

const type = 'DraggableBodyRow';

type Props = {
  strategyId: number;
  tasksUnfiltered: StrategyTask[];
  isLocked: boolean;
  refetchAll: () => void;
  isFetchingStrategyTasks: boolean;
  setHoursPending: React.Dispatch<React.SetStateAction<number>>;
  hoursPending: number;
  setDataHasBeenSet: React.Dispatch<React.SetStateAction<boolean>>;
  dataHasBeenSet: boolean;
  strategy: Strategy;
  account: User | null;
};

type TaskMonthObjectType = {
  task_id: number;
  month_id: number;
}

const DraggableBodyRow = ({
  index,
  moveRow,
  className,
  style,
  ...restProps
}: DraggableBodyRowProps) => {
  const ref = useRef<HTMLTableRowElement>(null);

  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: type,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: (item: { index: number }) => {
      moveRow(item.index, index);

    },
  });
  const [, drag] = useDrag({
    type,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: 'move', ...style }}
      {...restProps}
    />
  );
};


const HourlyBreakdownTable: FC<Props> = ({
  tasksUnfiltered,
  strategyId,
  isLocked = false,
  setHoursPending,
  hoursPending,
  setDataHasBeenSet,
  dataHasBeenSet,
  isFetchingStrategyTasks,
  refetchAll,
  strategy,
  account
}) => {
  const [createStrategyTasksRequest] = useCreateStrategyTasksMutation();
  const [updateStrategyTasksRequest] = useUpdateStrategyTaskMutation();
  const [updateStrategyTaskMonth] = useUpdateStrategyTaskMonthMutation();

  const [excludedTaskIds] = useState<number[]>([1458, 1459]);
  /** delete multiple */
  const [deleteStrategyTasksRequest] = useDeleteStrategyTasksMutation();
  const [updateStrategyTaskOrder] = useUpdateStrategyTaskOrderMutation();
  const [modalOpen, setModalOpen] = useState(false);
  const [taskDataForTable, setTaskDataForTable] = useState<any>([]);
  const [enableDragAndDropTasks, setEnableDragAndDropTasks] = useState(true);
  const components = useMemo(() => {
    return {
      body: {
        row: DraggableBodyRow,
      }
    };
  }, []);


  /**
   * Because the taskData is used inside the column render function
   * and the cell doesn't always update when the taskData changes,
   * we need to use a ref to keep track of the latest taskData that is independent of closure issues
   *
   * With the ref, it keeps the latest data, regardless of the scope of the closure, unlike the state variable
   */
  const taskDataForTableRef = useRef<any>(taskDataForTable);
  taskDataForTableRef.current = taskDataForTable;

  const isDraggingHoursRef = useRef(false);

  const hourDraggingValueRef = useRef<number>(0);
  const taskMonthIdToTaskAndMonthObjectMapRef = useRef({});
  /**
   * This is used to save the order of the newly ordered tasks to the database
   * so the order persists on page refresh
   */
  const saveTaskOrder = useCallback((newTaskOrder) => {
    if (taskDataForTable?.length) {
      const taskIds = newTaskOrder.map((task: any) => task.id);
      updateStrategyTaskOrder({
        strategy_id: strategyId,
        tasks: taskIds,
      }).catch((error) => {
        console.error('Error saving sort order of tasks', error);
        notification.error({
          message: 'Error saving task order. Please contact support',
        });
      });
    }
  }, [strategyId, taskDataForTable, updateStrategyTaskOrder]);

  /**
   * This function is used to handle moving a task row in the table
   * and reordering the task data
   */
  const moveRow = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      if (dragIndex !== hoverIndex) {
        const newTaskOrder = [...taskDataForTable];
        const oldIndexedItem = newTaskOrder[dragIndex];
        const itemBeingSwapped = newTaskOrder[hoverIndex];
        newTaskOrder[dragIndex] = itemBeingSwapped;
        newTaskOrder[hoverIndex] = oldIndexedItem;
        setTaskDataForTable(newTaskOrder);
        saveTaskOrder(newTaskOrder);
      }
    },
    [saveTaskOrder, taskDataForTable],
  );
  const [isCreatingTasks, setIsCreatingTasks] = useState(false);
  const [disabledTaskSlugs] = useState([
    'am-consulting',
    'ad-consulting',
    'gd-consulting'
  ]);
  const [hasChanged, setHasChanged] = useState(false); // State that is set to true whenever a change is made in the Service hours table

  const mixpanel = useMixpanelTrack();

  const filterTasks = useCallback((tasksUnfiltered: StrategyTask[]) => {
    const tasksFiltered = excludedTaskIds?.length ? tasksUnfiltered.filter(value => {
      return !excludedTaskIds?.includes(value?.id);
    }) : tasksUnfiltered;

    return tasksFiltered;
  }, [excludedTaskIds]);

  /**
   * Handle create tasks
   */
  const handleCreateTasks = useCallback(async (
    tasks: Pick<TaskInput, 'units' | 'id'>[]
  ) => {
    setIsCreatingTasks(true);

    const taskInputs: Pick<
      TaskInput,
      'id' | 'units'
    >[] = tasks.map(({ units, id }) => ({ units: units || 0, id }));

    try {
      await createStrategyTasksRequest({
        id: strategyId,
        tasks: taskInputs,
      }).unwrap();

      notification.success({
        message: `${pluralize('task', taskInputs.length, true)} added. Recalculating blueprint info...`,
      });

      refetchAll();

    } catch (error) {
      console.error(error);
      notification.error({
        message: 'An error occurred while adding tasks',
      });
    }
    setModalOpen(false);
    setIsCreatingTasks(false);
  }, [createStrategyTasksRequest, strategyId, refetchAll]);

  /**
   * Delete task(s)
   */
  const handleDeleteTasks = useCallback(async (task_ids: number[]) => {
    try {
      await deleteStrategyTasksRequest({
        task_ids,
        strategy_id: strategyId
      });

      notification.success({
        message: `${task_ids?.length} Service${task_ids?.length > 1 ? 's' : ''} deleted. Recalculating blueprint info...`,
      });

      refetchAll();

      setSelectedRowKeys([]);
      // track "Tasks deleted" event
      try {
        const options = {
          blueprintId: strategyId,
          taskIds: task_ids,
          userName: account?.name,
        };
        if (process.env.NODE_ENV !== 'production') console.log('🛤 Track: Blueprint tasks deleted', { options });
        let eventTitle = 'Blueprint tasks deleted';
        if (shouldTrackReUnlockedBlueprint(strategy)) {
          eventTitle = 'Blueprint Re-Unlocked, edited blueprint services: tasks deleted';
        }
        mixpanel(eventTitle, options);
      } catch (error) {
        if (process.env.NODE_ENV !== 'production') console.error('Mixpanel error', error);
      }
    } catch (error) {
      console.error(error);
      notification.error({
        message: 'Error deleting tasks',
      });
    }
  }, [deleteStrategyTasksRequest, strategyId, refetchAll, account?.name, strategy, mixpanel]);

  /**
   * Update the value of a task
   */
  const handleFieldUpdate = useCallback(async (
    task_id: number,
    key: string,
    value: any,
    onComplete?: any,
  ) => {
    try {
      await updateStrategyTasksRequest({
        task_id,
        strategy_id: strategyId,
        [key]: value,
      }).unwrap();

      if (onComplete) onComplete();

    } catch (error) {
      console.error(`Error updating the ${key}-field on cost with id ${task_id}`, error);
    }
  }, [updateStrategyTasksRequest, strategyId]);

  /**
   * Return true if task name should be disabled,
   * false otherwise
   *
   * @param string | undefined taskName
   * @returns boolean
   */
  const shouldTaskBeDisabled = useCallback((slug: string | undefined): boolean => {
    if (slug === undefined) {
      return false;
    }
    return disabledTaskSlugs?.includes(slug);
  }, [disabledTaskSlugs]);

  const renderDescription = useCallback((value: string, { id }: { id: number }) => {
    const task = taskDataForTable?.find((task: any) => task.id === id);
    let className = 'drag-visible';

    if (task !== undefined) {
      const taskDisabled = shouldTaskBeDisabled(task?.slug);
      if (taskDisabled) {
        className += 'disabled-row';
      }
    }
    return <DescriptionField
      value={value}
      onUpdate={(value: string) => handleFieldUpdate(id, 'name', value)}
      isLocked={isLocked}
      className={className}
    />;
  }, [taskDataForTable, isLocked, shouldTaskBeDisabled, handleFieldUpdate]);

  /**
   * Get row class name based on disabled status
   *
   * @param TaskRowProps taskRow
   * @returns string
   */
  const getRowClassForTable = useCallback((taskRow: any): string => {
    return taskRow?.disabled ? 'disabled-row' : '';
  }, []);

  /**
   * Format table data
   */
  const formatData: (tasks: StrategyTask[]) => any[] = useCallback((tasks) => {
    if (!tasks) {
      return [];
    }

    return tasks
      .map((task: StrategyTask) => {
        let minimum_hours: string | number = task?.minimum_hours;
        if (
          task?.is_configurable === 'yes' &&
          task?.units > 0 &&
          task?.hours_per_unit > 0
        ) {
          minimum_hours = (task?.units * task?.hours_per_unit).toString();
        }
        const data: any = {
          key: task.id,
          department: task.service?.department?.name,
          minimum_hours: minimum_hours,
          disabled: shouldTaskBeDisabled(task?.slug),
          name: task.name,
          multiplier: task.multiplier ? (
            <MultiplierSlug {...task.multiplier} />
          ) : (
            ' - '
          ),
          order: task.order,
          notes: task.notes,
          id: task.id,
          raw: task,
        };

        task.months.forEach((month: StrategyMonth) => {
          data[`month-${month.name}`] = month;
        });

        return data;
      })
      .sort((a: any, b: any) => a.order - b.order);
  }, [shouldTaskBeDisabled]);

  useEffect(() => {
    setTaskDataForTable(formatData(filterTasks(tasksUnfiltered)));
  }, [tasksUnfiltered, filterTasks, formatData]);

  if (taskDataForTable !== undefined) {
    setDataHasBeenSet(true);
  }

  const renderRowActions = useCallback((value: any, row: any) => {
    return (
      <div className={css.rowActions}>
        <Popconfirm
          title="Are you sure you want to delete this service?"
          onConfirm={() => handleDeleteTasks([row.id])}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="text"
            icon={<DeleteOutlined />}
            aria-label="Delete service"
            disabled={isLocked}
          />
        </Popconfirm>
      </div>
    );
  }, [handleDeleteTasks, isLocked]);

  /**
   * Row selection
   */
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const hasSelected = useMemo(() => selectedRowKeys.length > 0, [selectedRowKeys]);

  const onSelectChange = useCallback((selectedRowKeys: any) => {
    setSelectedRowKeys(selectedRowKeys);
  }, []);

  const rowSelection = useMemo(() => {
    return {
      selectedRowKeys,
      fixed: true,
      columnWidth: 40,
      onChange: onSelectChange
    };
  }, [onSelectChange, selectedRowKeys]);

  const trackUpdateTask = useCallback((strategyId: number, taskId: number, monthId: number, value: number) => {
    try {
      const options = {
        blueprintId: strategyId,
        taskId,
        monthId,
        hours: value,
        userName: account?.name,
      };
      if (process.env.NODE_ENV !== 'production') console.log('🛤 Track: Blueprint task updated', { options });
      let eventTitle = 'Blueprint task updated';
      if (shouldTrackReUnlockedBlueprint(strategy)) {
        eventTitle = 'Blueprint Re-Unlocked, edited blueprint services: task updated';
      }
      mixpanel(eventTitle, options);
    } catch (error) {
      console.error('Mixpanel error', error);
    }
  }, [account?.name, mixpanel, strategy]);

  const updateTaskDataForTable = useCallback((month_id: number, task_id: number, value: number, isPasting) => {
    const taskToUpdate = taskDataForTableRef.current.find((task: any) => task.id === task_id);
    if (taskToUpdate) {
      let newMonthsObject = {};
      /**
       * If pasting, update all months that aren't the one you are copying from in the task row
       *
       * If doing a regular update of an hour field, only update the month changed for that task
       */
      newMonthsObject = taskToUpdate?.raw?.months.map((month: any) => {
        if (isPasting) {
          return { ...month, value: value };
        }
        else {
          if (month.id === month_id) {
            return { ...month, value: value };
          }
          return month;
        }

      });

      const updatedTaskObject = { ...taskToUpdate, raw: { ...taskToUpdate.raw, months: newMonthsObject } };
      const updatedTaskDataForTable = taskDataForTableRef.current.map((task: any) => {
        if (task.id === task_id) {
          return updatedTaskObject;
        }
        return task;
      });
      setTaskDataForTable(updatedTaskDataForTable);
    }

  }, []);

  const handleHourFieldUpdate = useCallback(
    async (value: number, task_id: number, month_id: number, strategy_id: number, isPasting = false) => {
      setHasChanged(true);
      try {
        if (strategy_id && month_id && task_id && taskDataForTableRef.current.length > 0) {

          updateTaskDataForTable(month_id, task_id, value, isPasting);

          setHoursPending(hoursPending => hoursPending + 1);
          updateStrategyTaskMonth({
            task_id,
            month_id,
            hours: value,
            strategy_id,
            // only save hours, don't recalculate the strategy each time an hour is edited to improve performance
            recalculate: false
          }).then(() => {
            trackUpdateTask(strategy_id, task_id, month_id, value);
            setHoursPending(hoursPending => hoursPending - 1);

          });

        }
      } catch (error) {
        console.error('Error updating strategy task month', error);
        notification.error({
          message:
            'An error occurred while updating the hours value. Please try again.',
        });
      }

    },
    [setHoursPending, trackUpdateTask, updateStrategyTaskMonth, updateTaskDataForTable]
  );

  const onHourFieldEnter = useCallback(() => {
    if (enableDragAndDropTasks) {
      console.count('disable drag and drop tasks');
      setEnableDragAndDropTasks(false);
    }
  }, [enableDragAndDropTasks]);

  //TODO keep for now in case we need drag and drop hours implementation later
  // const onHourFieldLeave = useCallback(() => {
  //   if (!isDraggingHoursRef.current && !enableDragAndDropTasks) {
  //     console.count('enable drag and drop tasks');
  //     setEnableDragAndDropTasks(true);
  //   }
  // }, [enableDragAndDropTasks]);

  // const onDragHoursStart = useCallback((taskId, monthId) => {
  //   const value: number = taskDataForTableRef.current.find((task: any) => task.id === taskId)?.raw?.months?.find((month: any) => month.id === monthId)?.value;
  //   isDraggingHoursRef.current = true;
  //   hourDraggingValueRef.current = value;
  // }, []);

  /**
   * Update the taskDataForTableRef months for that task with current month being updated and then use that
   * to modify the ref and state for the task data for table.
   * This way we can update task values as they are updated, rather than waiting
   * until the requests have all completed, providing a more real-time experience
   *
   * @param taskIndex
   * @param task
   * @param month
   * @param updatedMonthId
   * @param handleHourFieldUpdateObjectInputArray
   * @returns void
   */
  const updateTaskMonth = useCallback((taskIndex: number, task: any, month: any, updatedMonthId: number, handleHourFieldUpdateObjectInputArray: HourFieldObjectInputType[]) => {

    const updatedMonths = taskDataForTableRef.current[taskIndex]?.raw?.months?.map((month: StrategyMonth) => {
      if (month.id === updatedMonthId) {
        return { ...month, value: hourDraggingValueRef.current };
      }
      return month;
    });

    handleHourFieldUpdateObjectInputArray.push({ hourDraggingValue: hourDraggingValueRef.current, taskId: task.id, monthId: month.id, strategyId });

    taskDataForTableRef.current[taskIndex] = { ...task, raw: { ...task.raw, months: updatedMonths } };
    setTaskDataForTable(taskDataForTableRef.current);
  }, [strategyId]);

  /**
 * Call backend to update the hours for the task month object that was updated
 * via the drag and drop
 *
 *
 * @param handleHourFieldUpdateObjectInputArray HourFieldObjectInputType[]
 * @returns void
 *
 */
  const updateHourFieldsOnBackend = useCallback((handleHourFieldUpdateObjectInputArray: HourFieldObjectInputType[]) => {
    handleHourFieldUpdateObjectInputArray.map((handleHourFieldUpdateObjectInput: HourFieldObjectInputType) => {
      // update the task month on the backend with the new value
      const { hourDraggingValue, taskId, monthId, strategyId } = handleHourFieldUpdateObjectInput;
      handleHourFieldUpdate(hourDraggingValue, taskId, monthId, strategyId);


    });

  }, [handleHourFieldUpdate]);

  const onDragHoursEnd = useCallback(async (event) => {

    /**
     * For every task month that was updated, update the task month object with the values
     * tracked from the drag and drop
     */

    const handleHourFieldUpdateObjectInputArray: HourFieldObjectInputType[] = [];

    taskDataForTableRef.current?.map((task: any, taskIndex: number) => {
      task?.raw?.months?.map(async (month: StrategyMonth) => {
        for (const taskMonthKey in taskMonthIdToTaskAndMonthObjectMapRef.current as Map<string, TaskMonthObjectType>) {
          const currentTaskMonthObject = taskMonthIdToTaskAndMonthObjectMapRef.current[taskMonthKey as keyof typeof taskMonthIdToTaskAndMonthObjectMapRef.current];
          const { taskId: updatedTaskId, monthId: updatedMonthId } = currentTaskMonthObject;
          if (task.id === updatedTaskId && month.id === updatedMonthId) {
            updateTaskMonth(taskIndex, task, month, updatedMonthId, handleHourFieldUpdateObjectInputArray);
          }
        }
      });

    });

    taskMonthIdToTaskAndMonthObjectMapRef.current = {};
    isDraggingHoursRef.current = false;
    hourDraggingValueRef.current = 0;

    updateHourFieldsOnBackend(handleHourFieldUpdateObjectInputArray);

  }, [updateHourFieldsOnBackend, updateTaskMonth]);

  const handlePasteTaskHourForRow = useCallback((value: number, task_id_to_update: number, month_id_copying_value_from: number, strategy_id: number) => {
    const taskToUpdate = taskDataForTable?.filter((currentTask: any) => currentTask.id === task_id_to_update)[0];
    if (taskToUpdate) {
      taskToUpdate?.raw?.months?.map((month_copying_value_to: StrategyMonth) => {
        if (month_copying_value_to.id === month_id_copying_value_from || month_copying_value_to.value === value) {
          return;
        }
        handleHourFieldUpdate(value, task_id_to_update, month_copying_value_to.id, strategy_id, true);
      });
    }
  }, [handleHourFieldUpdate, taskDataForTable]);



  const renderAddNewButton = useCallback((props: ButtonProps) => (
    <Button
      icon={<PlusCircleFilled />}
      onClick={() => setModalOpen(true)}
      disabled={isLocked}
      {...props}
    >
      Add services
    </Button>
  ), [isLocked, setModalOpen]);

  /**
   * Using reduce function, we can sum the hours in each month for as total
   * @param sumOfHoursInEachMonthArray
   * @returns sum of hours in each month
   */
  const getHoursOfWorkSum = useCallback((sumOfHoursInEachMonthArray) => {
    return sumOfHoursInEachMonthArray.reduce((currentHoursSum: number, currentHoursValue: number) => {
      return currentHoursSum + currentHoursValue;
    });
  }, []);

  /**
   * Calculates total for each month and puts into array for easy consumption by
   * the ant design table
   * @returns array of totals for each month
   */
  const getSumOfHoursInEachMonthArray = useCallback(() => {
    const monthToHoursSumMap: any = {};
    // sum the months by using hashmap and indexing the month to add to the right sum
    for (let i = 0; i < taskDataForTable.length; i++) {
      const task: any = taskDataForTable[i];
      task?.raw?.months.map((month: any) => {
        if (monthToHoursSumMap[month.id]) {
          monthToHoursSumMap[month.id] += month.value;
        } else {
          monthToHoursSumMap[month.id] = month.value;
        }
      });
    }
    // put sums into easy to consume array format
    const monthSumsArray = [];
    for (const month in monthToHoursSumMap) (
      monthSumsArray.push(monthToHoursSumMap[month])
    );
    return monthSumsArray;
  }, [taskDataForTable]);

  const renderNotes = useCallback((value: string, { id }: { id: number }) => {
    return <NotesButton
      onUpdate={(notes: string) =>
        handleFieldUpdate(id, 'notes', notes)
      }
      value={value}
      isLocked={isLocked}
    />;
  }, [handleFieldUpdate, isLocked]);

  const renderSummary = useCallback(() => {

    const sumOfHoursInEachMonthArray = getSumOfHoursInEachMonthArray();

    return (
      <Table.Summary.Row className={css.summary}>
        <Table.Summary.Cell index={0}></Table.Summary.Cell>
        <Table.Summary.Cell index={1}>
          {renderAddNewButton({
            type: 'default',
            block: true,
          })}
        </Table.Summary.Cell>
        <Table.Summary.Cell index={2}></Table.Summary.Cell>
        <Table.Summary.Cell index={3}>Total</Table.Summary.Cell>
        <Table.Summary.Cell index={4}>{getHoursOfWorkSum(sumOfHoursInEachMonthArray)}</Table.Summary.Cell>
        {
          sumOfHoursInEachMonthArray.map((monthHoursSum, index) => {
            const keyAndIndex = index + 4;
            return (<Table.Summary.Cell key={keyAndIndex} index={keyAndIndex}>
              {monthHoursSum}
            </Table.Summary.Cell>);
          })
        }
        <Table.Summary.Cell index={99} />
      </Table.Summary.Row>
    );
  }, [getSumOfHoursInEachMonthArray, renderAddNewButton, getHoursOfWorkSum]);

  const onToggleModal = useCallback((bool: boolean) => setModalOpen(bool), []);

  const sortServices = useCallback((a: any, b: any) => {
    if (!a.department) {
      return 1;
    }
    else if (!b.department) {
      return -1;
    }
    else if (a.department < b.department) {
      return -1;
    }
    else if (a.department > b.department) {
      return 1;
    }
    return 0;
  }, []);

  const tableScroll = useMemo(() => { return { x: 1100, y: document.body.clientHeight - 200 }; }, []);

  const tableWithDragAndDropTasks = useMemo(() => <DndProvider backend={HTML5Backend}>
    <Table
      className={sharedCss.table}
      dataSource={taskDataForTable}
      scroll={tableScroll}
      pagination={false}
      summary={renderSummary}
      components={components}
      onRow={(_, index) => {
        const attr = {
          index,
          moveRow,
        };
        return attr as React.HTMLAttributes<any>;
      }}
      rowSelection={rowSelection}
      rowClassName={getRowClassForTable}
    >
      <Table.Column
        title="Description"
        key="name"
        dataIndex="name"
        render={renderDescription}
        width={300}
        fixed={document.body.clientWidth > 800}
        shouldCellUpdate={shouldCellUpdate}
      />
      <Table.Column
        title="Service"
        key="department"
        dataIndex="department"
        ellipsis
        width={160}
        sorter={sortServices}
        shouldCellUpdate={shouldCellUpdate}

      />
      <Table.Column
        title="Notes"
        key="notes"
        dataIndex="notes"
        render={renderNotes}
        width={75}
        shouldCellUpdate={shouldCellUpdate} />
      <Table.Column
        title="Min. Hours"
        key="minimum_hours"
        dataIndex="minimum_hours"
        width={100}
        shouldCellUpdate={shouldCellUpdate}
      />
      {taskDataForTable[0]?.raw?.months?.map((month: any, index: number) => {
        const key = `month-${month?.name}`;
        return (
          <Table.Column
            title={
              index === 0 ? (
                <div className={css.firstMonthWrapper}>
                  <div
                    className={classNames(css.firstMonth, css.muted)}
                  >
                    1st month
                  </div>
                  {moment(month?.date).format('MMM YYYY')}
                </div> // first month
              ) : (
                moment(month?.date).format('MMM YYYY')
              )
            }
            key={key}
            dataIndex={key}
            width={120}
            className={classNames({
              [css.firstMonthCell]: index === 0
            })}
            shouldCellUpdate={shouldCellUpdate}
            /**
         * Using refs heavily in the render function because if the cell does not update
         * for performance reasons because the underlying data has changed,
         * the cell and it's functions/objects will not rerender, so the data will be stale.
         *
         * Refs are mutable objects so by passing refs to children, when accessing them
         * their values will be up to date, only when accessing from the object.
         *
         * If we pass the value of the ref to a child, the value will be stale.
         */
            render={(_: any, task: any) => {
              const monthFromCurrentTask = task?.raw?.months?.find((currentMonth: any) => currentMonth?.id === month?.id);
              return (
                <div
                // onDragStart={() => onDragHoursStart(task?.id, month?.id)}
                // onDragEnd={onDragHoursEnd}
                // onMouseEnter={onHourFieldEnter}
                // onMouseLeave={onHourFieldLeave}
                // draggable
                >
                  <HourField
                    task={task}
                    month={monthFromCurrentTask}
                    strategyId={strategyId}
                    handleHourFieldUpdate={handleHourFieldUpdate}
                    handlePasteTaskHourForRow={handlePasteTaskHourForRow}
                    taskMonthIdToTaskAndMonthObjectMapRef={taskMonthIdToTaskAndMonthObjectMapRef}
                    isDraggingHoursRef={isDraggingHoursRef}
                    isLocked={isLocked}
                  />
                </div>
              );
            }
            }
          />
        );
      })}
      <Table.Column
        title=""
        key="actions"
        dataIndex="actions"
        width={50}
        render={renderRowActions}
        shouldCellUpdate={shouldCellUpdate}
      />
    </Table>
  </DndProvider>, [taskDataForTable, tableScroll, renderSummary, components, rowSelection, getRowClassForTable, renderDescription, sortServices, renderNotes, renderRowActions, moveRow, strategyId, handleHourFieldUpdate, handlePasteTaskHourForRow, isLocked]);

  /**
   * Function that renders the hourly breakdown table with all the tasks, months, and service hours.
   * 3 cases:
   *
   * 1. If data is loading, show a loading skeleton.
   * 2. If data is empty, show 'No results found' with a button to add Tasks.
   * 3. If data is not empty, show the table with all the tasks, months, and service hours.
   *
   * @returns JSX.Element
   */
  const renderHourlyBreakdownTable = useCallback(() => {

    if (dataHasBeenSet) {
      if (taskDataForTable?.length === 0) {
        return (
          <NoResultsFound
            title="No tasks"
            renderButton={() =>
              renderAddNewButton({
                type: 'primary',
                size: 'large',
              })
            }
          />
        );

      }

      return (
        <DescriptionList
          label={
            <>
              Services{' '}
              <span className={css.muted}>hours per service by month</span>
              <br></br>
            </>
          }
          extra={
            <div className={classNames(css.extra, { [css.visible]: hasSelected })}>
              <span>{`${selectedRowKeys.length} service${selectedRowKeys.length > 1 ? 's' : ''} selected`}</span>
              <Popconfirm
                title={`Are you sure you want to delete ${selectedRowKeys.length} service${selectedRowKeys.length > 1 ? 's' : ''}?`}
                onConfirm={() => handleDeleteTasks(selectedRowKeys)}
                okText="Yes"
                cancelText="No"
              >
                <Button
                  type="default"
                  icon={<DeleteOutlined />}
                  aria-label="Delete selected services"
                  disabled={!selectedRowKeys.length}
                >
                  Delete
                </Button>
              </Popconfirm>
            </div>
          }
        >
          {tableWithDragAndDropTasks}
        </DescriptionList >
      );
    }
  }
  , [dataHasBeenSet, handleDeleteTasks, hasSelected, renderAddNewButton, selectedRowKeys, tableWithDragAndDropTasks, taskDataForTable?.length]);

  return (
    <Card
      className={classNames(css.root, { [css.hasItems]: taskDataForTable?.length })}>
      {renderHourlyBreakdownTable()}
      <AddTasksModal
        open={modalOpen}
        onToggle={onToggleModal}
        onFinish={handleCreateTasks}
        creating={isCreatingTasks}
        excludedTaskIds={excludedTaskIds}
        strategy={strategy}
      />
      {hasChanged &&
        <AlertBar refetchAll={refetchAll} setHasChanged={setHasChanged} hasChanged={hasChanged} hoursPending={hoursPending} strategyId={strategyId} isFetchingStrategyTasks={isFetchingStrategyTasks} />
      }

    </Card>
  );
};

export default memo(HourlyBreakdownTable);
