/**
 * Entities -> Services -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { Service } from './service';
import { compareField } from '../../utils/compareField';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const servicesAdapter = createEntityAdapter<Service>({
  selectId: (service) => service.id,
  sortComparer: compareField('name'),
});
