/**
 * Entities -> Rules -> Reducers
 */

import { rulesAdapter } from './adapter';
import { EntitiesState } from '../../types';

export default {
  'deleteRule/fulfilled'(state: EntitiesState, action: { payload: number }) {
    state.isLoading = false;
    rulesAdapter.removeOne(state.data.rules, action.payload);
  },
};
