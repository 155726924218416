import React from 'react';
import { DialogActions } from '@mui/material';
import { Button } from '@sprnova/nebula';
import { FivetranIntegrationDialogue, IntegrationDialogueContent, IntegrationDialogueTitle } from '../../StyledComponents';

/**
 * Props for the ConfirmationDialog component.
 *
 * @property {boolean} open - Flag to control dialog visibility.
 * @property {(confirm: boolean) => void} onClose - Function to handle dialog close action.
 * @property {string} title - The title text to display in the dialog.
 * @property {string} content - The content text to display in the dialog.
 * @property {string} [confirmLabel] - Label for the confirm button.
 * @property {string} [cancelLabel] - Label for the cancel button.
 * @property {string} [size] - size for the dialog.
 */
type ConfirmationDialogProps = {
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  title: string;
  content: React.ReactNode;
  confirmLabel?: string;
  cancelLabel?: string;
  children?: JSX.Element;
  size?: 'xs' | 'sm' | 'md' | 'lg';
};


/**
 * ConfirmationDialog component.
 *
 * A reusable confirmation dialog component that displays a message to the user
 * and offers a choice to confirm or cancel the operation.
 *
 * @param {ConfirmationDialogProps} props - The properties to configure the component.
 * @returns {JSX.Element} The rendered component.
 */
const ConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
  confirmLabel = 'Yes, I\'m sure',
  cancelLabel = 'Cancel',
  children,
  size = 'xs'
}: ConfirmationDialogProps): JSX.Element => {
  return (
    <FivetranIntegrationDialogue  open={open} onClose={() => onClose()} variant='confirmation' size={size}>
      <IntegrationDialogueTitle variant='h2'>
        {title}
      </IntegrationDialogueTitle>
      <IntegrationDialogueContent>
        {content}
        {children}
      </IntegrationDialogueContent>
      <DialogActions>
        <Button onClick={() => onClose()} color="primary">
          {cancelLabel}
        </Button>
        <Button onClick={() => onConfirm?.()} color="primary" variant='contained'>
          {confirmLabel}
        </Button>
      </DialogActions>
    </FivetranIntegrationDialogue>
  );
};

export default ConfirmationDialog;
