import loadable from '@loadable/component';

const LoadableComponent = loadable(
  () => import('./AuditForm')
  /* , {
  fallback: <div>Loading...</div>,
  } */
);

export { LoadableComponent as AuditForm };
