import React, { useState, useCallback } from 'react';
import { FormControl, Select } from '@sprnova/nebula';

type PinterestAttributionDropdownProps = {
  defaultAttribution: string;
  onAttributionChange: (attribution: string) => void;
  disabled?: boolean;
}

type AttributionOption = {
  value: string;
  label: string;
}

/**
 * PinterestAttributionDropdown is a React component that renders a dropdown for selecting Pinterest attribution options.
 *
 * @param {PinterestAttributionDropdownProps} props - The props for the component.
 * @param {string} props.defaultAttribution - The default selected attribution value.
 * @param {(attribution: string) => void} props.onAttributionChange - Callback function that is called when the selected attribution changes.
 * @param {disabled?: boolean} props.disabled - Boolean determining whether the pinterest attributions dropdown is disabled
 *
 * @returns {JSX.Element} - The rendered Select component with attribution options.
 */
const PinterestAttributionDropdown = ({ defaultAttribution, onAttributionChange, disabled }: PinterestAttributionDropdownProps): JSX.Element => {
  const [selectedAttribution, setSelectedAttribution] = useState(defaultAttribution);

  const handleAttributionChange = useCallback((event) => {
    const newAttribution = event.target.value;
    setSelectedAttribution(newAttribution);
    onAttributionChange(newAttribution);
  }, [onAttributionChange]);

  const ATTRIBUTION_OPTIONS: AttributionOption[] = [
    { value: '30301', label: '30, 30, 1' },
    { value: '303030', label: '30, 30, 30' },
    { value: '771', label: '7, 7, 1' },
    { value: '711', label: '7, 1, 1' },
    { value: '111', label: '1, 1, 1' },
    { value: '777', label: '7, 7, 7' },
    { value: '30307', label: '30, 30, 7' },
    { value: '3071', label: '30, 7, 1' }
  ];

  return (
    <FormControl fullWidth>
      <Select
        id='attributions'
        label='Select Attributions'
        value={selectedAttribution}
        onChange={handleAttributionChange}
        sx={{ maxHeight: '40px' }}
        disabled={disabled}
      >
        {ATTRIBUTION_OPTIONS.map(option => (
          <Select.Item key={option.value} value={option.value}>{option.label}</Select.Item>
        ))}
      </Select>
    </FormControl>
  );
};

export default PinterestAttributionDropdown;
