import React, { createContext, useContext, useReducer } from 'react';
import { GetCustomerInsightsQuery } from 'api/crudGraphQL/customer_insights/getCustomerInsights';
import { isEqual } from 'lodash';

type CIQuery = Omit<GetCustomerInsightsQuery, 'projection'>;
type ReducerType = { type: 'add' | 'remove' | 'reset'; value?: Partial<CIQuery> };
export type CIFiltersReturnType = {
  filters: Partial<CIQuery>;
  setFilters: React.Dispatch<ReducerType>;
};

export const useCIFilters = (initialState?: Partial<CIQuery>): CIFiltersReturnType => {
  const [state, dispatch] = useReducer((state: Partial<CIQuery>, action: ReducerType): Partial<CIQuery> => {
    switch (action.type) {
      case 'add': {
        const newState: Partial<CIQuery> = { ...state };
        if (action.value) {
          Object.keys(action.value).forEach((key) => {
            // Check if the key already exists in the state and if the value is different
            if (action.value && !isEqual(newState[key as keyof CIQuery], action.value[key as keyof CIQuery])) {
              newState[key as keyof CIQuery] = action.value[key as keyof CIQuery] as undefined; // Typecast the value as undefined
            }
          });
        }
        return newState;
      }
      case 'reset': {
        return {};
      }
      default:
        return state;
    }
  }, { ...initialState } as Partial<CIQuery>);

  return {
    filters: state,
    setFilters: dispatch,
  };
};

export const CIFiltersContext = createContext<CIFiltersReturnType | undefined>(undefined);

export const CIFiltersProvider = (props: { children: React.ReactNode, filters: CIFiltersReturnType }): JSX.Element => (
  <CIFiltersContext.Provider value={props.filters}>
    {props.children}
  </CIFiltersContext.Provider>
);

export const useCIFiltersContext: () => CIFiltersReturnType | Record<string, any> = () => useContext(CIFiltersContext) || {};
