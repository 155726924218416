import React, { FC, ReactNode, memo } from 'react';
import { Redirect } from 'react-router';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import LoadingLayout from 'components/LoadingLayout/LoadingLayout';
import Cookies from 'js-cookie';
import useIsClientPortalPreviewUser from 'utils/hooks/useIsClientPortalPreviewUser';

type ProtectedBaseLayoutProps = {
  children?: ReactNode;
};

const ProtectedBaseLayoutWithAuthentication = withAuthenticationRequired<{ children?: ReactNode }>(
  ({children}) => <>{children}</>,
  {
    onRedirecting: () => <LoadingLayout />,
  });

const ProtectedBaseLayout: FC<ProtectedBaseLayoutProps> = ({
  children = null
}) => {
  const { logout } = useAuth0();
  const isClientPreviewUser = useIsClientPortalPreviewUser();

  // Confirm user is in the correct location.
  const cookie = Cookies.get('auth0_user_type') as 'internal' | 'external' | undefined;

  if (cookie === 'external' && !(window.location.pathname.includes('/c/') || window.location.pathname.includes('/p/'))) {
    return <Redirect to='/c/clients'/>;
  }

  if (cookie === 'internal' && window.location.pathname.includes('/c/')) {
    return <Redirect to='/dashboard'/>;
  }

  // Confirm user still has access token.
  const accessToken = Cookies.get('BEARER-TOKEN');

  if (!accessToken) {
    Cookies.remove('auth0_user_type');

    logout({
      logoutParams: {
        returnTo: window.location.origin,
      }
    });
  }

  if (isClientPreviewUser) {
    // Do not redirect the client preview user to the Auth0 login page.
    return <>{children}</>;
  } else {
    return (
      <ProtectedBaseLayoutWithAuthentication>
        {children}
      </ProtectedBaseLayoutWithAuthentication>
    );
  }
};

export default memo(ProtectedBaseLayout);
