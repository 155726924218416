export const tooltips = ({ tabName }: { tabName?: string }): Record<string, string> =>  {
  return {
    funnel: 'Strategy stage in the funnel for the campaign',
    score: 'Internal Score generated that determines the performance of the client\'s ad',
    ltv: 'Median Total Revenue attributed to each ad, ad set, and or campaign. It is calculated by analyzing customer data and their associated creatives',
    spend: 'Amount of money spent on the Ad',
    roas: 'Measures revenue generated per dollar spent on ads',
    revenue: `Total Revenue generated by the ${tabName}`,
    cpa: 'Average cost to acquire a customer through an ad',
    clicks: `The number of times users interact with an ${tabName}`,
    impressions: 'Number of times the ad was shown',
    conversions: `The desired actions taken by users after interacting with an ${tabName}`,
    stop_rate: 'Percentage of viewers who stop watching within the first few seconds',
    hold_rate: 'Percentage of viewers who watch the entire ad',
    ctr: 'Percentage of ad viewers who clicked on the ad',
    cvr: 'Percentage of clicks that lead to a desired action, like a purchase',
    cpc: 'Average cost each time someone clicks on the ad',
    cpm: 'Cost for every 1,000 views of the ad',
    last_updated: 'Date the data has last been updated',
    updated_at: 'Date the data has last been updated',
    last_refreshed: 'Date the data has last been updated',
  };
};
