import React, { memo, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Box, formatCurrency, Grid, Skeleton } from '@sprnova/nebula';
import { useGetScoreboardPublicQuery } from 'api/crudGraphQL/public/scoreboards/getScoreboardPublic';
import { useGetScoreboardQuery } from 'api/crudGraphQL/scoreboards/getScoreboard';
import { format, parseISO } from 'date-fns';
import { useFilterContext } from 'features/scoreboards/utils/Filters';
import CreateLongTermGoalDrawer from './CreateLongTermGoalDrawer';
import EditLongTermGoalDrawer from './EditLongTermGoalDrawer';
import LongTermChart from './LongTermChart';
import { pacingGoalsProjection } from '../../../projections';
import GoalCards from '../GoalCards';
import GoalTitle from '../GoalTitle';
import GoalView from '../GoalView';
import GridFormatting from '../GridFormatting';
import NoGoals from '../NoGoals';

export type LongTermProps = {
  isClient: boolean;
};
const LongTerm = ({ isClient }: LongTermProps): JSX.Element => {
  const [openCreateGoalDrawer, setOpenCreateGoalDrawer] = React.useState(false);
  const [openEditGoalDrawer, setOpenEditGoalDrawer] = React.useState(false);

  const { id } = useParams<{ [x: string]: string }>();
  const { filter } = useFilterContext();

  // Check to see if there are any pacing goals
  const { data: pacingGoalCheck, isLoading: pacingGoalCheckLoading } = (
    isClient ? useGetScoreboardPublicQuery : useGetScoreboardQuery
  )(
    {
      id: parseInt(id) || 0,
      projection: {
        pacing_goals: {
          id: true,
        },
      },
      goalPacingArgs: {
        type: 'long_term',
        limit: 1,
      },
    },
    { skip: !id }
  );

  const { data, isFetching } = (
    isClient ? useGetScoreboardPublicQuery : useGetScoreboardQuery
  )(
    {
      id: parseInt(id) || 0,
      projection: {
        pacing_goals: pacingGoalsProjection,
      },
      goalPacingArgs: {
        type: 'long_term',
        limit: 10,
        id: filter?.long_term?.id,
      },
    },
    { skip: !id || !filter?.long_term?.id }
  );

  const pacingGoal = useMemo(() => {
    if (!data) return;
    return data.pacing_goals[0];
  }, [data]);

  const goalCardsProps = useMemo(() => {
    return {
      isLoading: isFetching,
      metric: pacingGoal?.metric,
      metric_name: pacingGoal?.metric_name,
      format: pacingGoal?.indicator.format,
      actual_value: pacingGoal?.current_value,
      est_value: pacingGoal?.prediction,
      goal_for_value: pacingGoal?.percent_of_goal,
      goal_pace_value: pacingGoal?.percent_on_pace,
      actual_platforms: pacingGoal?.current_platform,
      est_platforms: pacingGoal?.prediction_platform,
      goal_for_platforms: pacingGoal?.percent_of_goal_platform,
      goal_pace_platforms: pacingGoal?.percent_on_pace_platform
    };
  }, [isFetching, pacingGoal]);

  const {
    refreshed_at: last_refreshed,
    metric_name,
    indicator,
  } = pacingGoal || {};
  const formatType = indicator?.format || '';
  const renderTitle = useMemo(() => {
    if (!pacingGoal) return <></>;
    if (isFetching)
      return <Skeleton width="450px" height="40px" />;
    const {
      metric_name,
      value,
      start,
      indicator: { format: type }
    } = pacingGoal;
    const formattedValue =
      type === '$' ? formatCurrency(value) : value.toLocaleString();
    const title = `Long Term Goal for ${metric_name} - ${formattedValue}`;
    const formattedDate = format(parseISO(start), 'MMMM yyyy');
    return <GoalTitle title={title} date={formattedDate} />;
  }, [pacingGoal, isFetching]);

  const renderChart = useMemo(() => {
    if (isFetching || !pacingGoal)
      return <Skeleton height={350} variant="rectangular" width="100%" />;
    return (
      <LongTermChart
        longTermData={pacingGoal?.data}
        lastUpdated={last_refreshed}
        format={formatType}
        metricName={metric_name}
      />
    );
  }, [pacingGoal, last_refreshed, formatType, metric_name, isFetching]);

  if (!pacingGoalCheckLoading && !pacingGoalCheck?.pacing_goals?.length) {
    return (
      <NoGoals
        type="long_term"
        isClient={isClient}
        openDrawer={openCreateGoalDrawer}
        onToggleDrawer={() => {
          setOpenCreateGoalDrawer(!openCreateGoalDrawer);
        }}
      />
    );
  }
  return (
    <>
      <Box>
        <GridFormatting
          view={<GoalView type="long_term" isClient={isClient} />}
          title={renderTitle}
          create={
            <CreateLongTermGoalDrawer
              open={openCreateGoalDrawer}
              onToggle={() => {
                setOpenCreateGoalDrawer(!openCreateGoalDrawer);
              }}
              isLoading={isFetching}
            />
          }
          edit={
            <EditLongTermGoalDrawer
              open={openEditGoalDrawer}
              onToggle={() => {
                setOpenEditGoalDrawer(!openEditGoalDrawer);
              }}
              onAlertClick={() => {
                setOpenEditGoalDrawer(false);
                setOpenCreateGoalDrawer(true);
              }}
              isLoading={isFetching}
            />
          }
          isClient={isClient}
        />
        <Grid padding="40px 0">
          <GoalCards {...goalCardsProps} />
        </Grid>
      </Box>
      <Box>{renderChart}</Box>
    </>
  );
};

export default memo(LongTerm);
