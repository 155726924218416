import loadable from '@loadable/component';

const LoadableComponent = loadable(
  () => import('./InputClient')
  /* , {
  fallback: <div>Loading...</div>,
  } */
);

export { LoadableComponent as InputClient };
