import { GetPillarsResult } from 'api/crudGraphQL/pillars/getPillars';
import { valueExists } from 'utils/form/valueExists';
import { compareByName } from 'utils/sort/sortingUtil';
import { LeadSource, Pillar } from 'features/entitiesRedux';
import { INVALID_DATE } from './constants';
import { CreatePackageBlueprintFormType } from './CreatePackageBasedBlueprintFormDataType';
import { BLUEPRINT_LEAD_SOURCE, BLUEPRINT_PILLAR } from '../constants';

/**
 * Get the helper text for the guaranteed term field when the form is submitted
 * @param {CreatePackageBlueprintFormType} blueprintFormData React hook form data from the create blueprint form
 * @param {boolean} formSubmitted is form submitted
 * @returns {string} The helper text for the guaranteed term field
 */
export const getHelperTextGuaranteedTerm = (blueprintFormData: CreatePackageBlueprintFormType, formSubmitted: boolean): string | undefined => {
  if (formSubmitted) {
    if (!blueprintFormData.length) {
      return 'Please select a contract length first';
    } else if (!blueprintFormData.guaranteed_term) {
      return 'Please select a guaranteed term';
    }
  }
};

/**
 * Get the helper text for the start date field when the form is submitted
 * @param {CreatePackageBlueprintFormType} blueprintFormData React hook form data from the create blueprint form
 * @param {boolean} formSubmitted is form submitted
 * @returns {string} The helper text for the guaranteed term field
 */
export const getHelperTextStartDate = (blueprintFormData: CreatePackageBlueprintFormType, formSubmitted: boolean): string | undefined => {
  if (formSubmitted) {
    if (!blueprintFormData.length) {
      return 'Please select a contract length first';
    } else if (!blueprintFormData.date) {
      return 'Please select a start date';
    }
  }
};

/**
 * Get all the months guaranteed for the blueprint based on its length
 *
 * @param {number} blueprintLength Length of the blueprint
 * @returns {number[] | []} Array of months guaranteed
 */
export const getMonthsGuaranteed = (blueprintLength: number | undefined): number[] | [] => {
  if (blueprintLength) return Array(blueprintLength).fill(0).map((_, i) => i + 1);
  return [];
};

export const getPillarOptions = (pillars: GetPillarsResult): Pillar[] => {
  return [...pillars].filter((pillar) => {
    return pillar.slug === BLUEPRINT_PILLAR.NewBusiness || pillar.slug === BLUEPRINT_PILLAR.ServiceExpansion || pillar.slug === BLUEPRINT_PILLAR.Resign;
  }).sort(compareByName);
};

/** Validate form */
export const isCreatePackageBlueprintFormValid = (
  blueprintFormData: CreatePackageBlueprintFormType,
  leadSourceSelected: LeadSource | undefined): boolean => {
  const fieldsToValidate = [
    {
      name: 'pillar_id',
      value: blueprintFormData.pillar_id
    },
    {
      name: 'date',
      value: blueprintFormData.date
    },
    {
      name: 'length',
      value: blueprintFormData.length
    },
    {
      name: 'type',
      value: blueprintFormData.type
    },
    {
      name: 'lead_source_id',
      value: blueprintFormData.lead_source_id
    },
    {
      name: 'client_id',
      value: blueprintFormData.client_id
    },
    {
      name: 'guaranteed_term',
      value: blueprintFormData.guaranteed_term
    },
    {
      name: 'is_vabo',
      value: blueprintFormData.is_vabo
    }
  ];

  const valueEnteredForAllFields = fieldsToValidate.every((field) => {
    if(field.name === 'is_vabo' && field.value === true) {
      // if is_vabo is true, then vaboTypeId is required, otherwise just make sure is_vabo has a value
      return valueExists(blueprintFormData.vabo_type_id);
    }
    return valueExists(field.value);
  });

  if (!valueEnteredForAllFields || blueprintFormData.date === INVALID_DATE
    || blueprintFormData.date === undefined) {
    return false;
  }

  /**
   * If type of lead is channel partner enforce user selecting a partner referral
   * If the type of lead is people, enforce user selecting a employee referral
   */
  if ((leadSourceSelected?.slug === BLUEPRINT_LEAD_SOURCE.ChannelPartner && !blueprintFormData.partner_id) ||
  (leadSourceSelected?.slug === BLUEPRINT_LEAD_SOURCE.People && !blueprintFormData.lead_source_employee_id)
  ) {
    return false;
  }
  return true;
};
