import React, { memo } from 'react';
import isExternalUser from 'utils/helpers/isExternalUser';
import InternalGoogleAdsDialog from 'features/clients/AddIntegrationPage/components/NovaCloudIntegrationList/components/DatasourceDialogs/GoogleAdsDialog/InternalGoogleAdsDialog';
import InternalGoogleAnalytics4Dialog from 'features/clients/AddIntegrationPage/components/NovaCloudIntegrationList/components/DatasourceDialogs/GoogleAnalytics4Dialog/InternalGoogleAnalytics4Dialog';
import InternalGoogleSearchConsoleDialog from 'features/clients/AddIntegrationPage/components/NovaCloudIntegrationList/components/DatasourceDialogs/GoogleSearchConsoleDialog/InternalGoogleSearchConsoleDialog';
import { DataSource } from 'features/entitiesRedux';
import { Client } from 'features/entitiesRedux/models/client';
import { ExternalClient } from 'features/entitiesRedux/models/external_client';
import FivetranConnectIntegrationButton from './FivetranConnectIntegrationButton';
import PinterestAdsDialog from '../../DatasourceDialogs/PinterestAdsDialog/PinterestAdsDialog';

type DialogIntegrationButtonProps = {
  /** The ID of the user. */
  userId?: number;
  /** The client information, if available. */
  client?: Client | ExternalClient;
  /** A boolean indicating if the modal should be open. */
  openModal?: boolean;
  /** A boolean used to control whether the button is rendered. */
  renderButton?: boolean;
  /** Action triggered on close state. */
  onClose?: () => void;
  /** The ID of the access request. */
  accessRequestId?: string;
  /** The content or children to render inside the button. */
  children?: string | React.ReactNode;
  /** The datasource associated with the integration. */
  datasource: DataSource;
};

/**
 * A component representing a button for connecting an integration.
 *
 * @param {DialogIntegrationButtonProps} props - The component's props.
 * @returns {JSX.Element} - The rendered button.
 */
const DialogIntegrationButton = ({
  userId,
  client,
  openModal,
  renderButton = true,
  onClose,
  accessRequestId,
  children,
  datasource
}: DialogIntegrationButtonProps): JSX.Element => {
  const isClient = isExternalUser();

  if (!client || !userId) {
    return <></>;
  }

  // Function to determine the selected integration based on the datasourceSlug
  const getSelectedIntegration = (slug: string): JSX.Element => {
    switch (slug) {
      case 'paid-pinterest':
        return (
          <PinterestAdsDialog
            client={client}
            openModal={openModal}
            renderButton={renderButton}
            onClose={onClose}
            accessRequestId={accessRequestId}
            userId={userId}
            buttonChildren={children}
          />
        );
      case 'google-ads':
        return isClient ? (
          <FivetranConnectIntegrationButton
            groupId={client?.group_id}
            datasource={datasource}
            userId={userId}
            client={client}
          >
            {children}
          </FivetranConnectIntegrationButton>
        ) : (
          <InternalGoogleAdsDialog
            client={client}
            openDialog={openModal}
            renderButton={renderButton}
            onClose={onClose}
            buttonChildren={children}
          />
        );
      case 'google-analytics-4':
        return isClient ? (
          <FivetranConnectIntegrationButton
            groupId={client?.group_id}
            datasource={datasource}
            userId={userId}
            client={client}
          >
            {children}
          </FivetranConnectIntegrationButton>
        ) : (
          <InternalGoogleAnalytics4Dialog
            client={client}
            openDialog={openModal}
            renderButton={renderButton}
            onClose={onClose}
            buttonChildren={children}
          />
        );
      case 'google-search-console':
        return isClient ? (
          <FivetranConnectIntegrationButton
            groupId={client?.group_id}
            datasource={datasource}
            userId={userId}
            client={client}
          >
            {children}
          </FivetranConnectIntegrationButton>
        ) : (
          <InternalGoogleSearchConsoleDialog
            client={client}
            openDialog={openModal}
            renderButton={renderButton}
            onClose={onClose}
            buttonChildren={children}
          />
        );
      default:
        return <></>;
    }
  };

  return (
    <>
      {getSelectedIntegration(datasource.slug || '')}
    </>
  );
};

export default memo(DialogIntegrationButton);
