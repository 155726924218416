
import { Projection } from 'api/entityGraphQL/types';
import { VaboType } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments } from '..';

export type GetVaboTypesQuery = {
  id?: number;
  name?: string;
  pricing_version?: string;
  projection: Projection;
};

export type GetVaboTypesResult = VaboType[];

export const GET_VABO_TYPE_ENDPOINT_KEY = 'getVaboTypes';

export const { useGetVaboTypesQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_VABO_TYPE_ENDPOINT_KEY]: build.query<GetVaboTypesResult, GetVaboTypesQuery>({
      transformResponse: (response: { vabo_types: GetVaboTypesResult }) => response.vabo_types,
      providesTags: (result) => {
        const data = result ?? [];

        return [
          tagTypes.VABO_TYPES,
          ...(data ? data.map((vabo_types: VaboType) => ({
            id: vabo_types.id,
            type: tagTypes.VABO_TYPE
          })) : [])
        ];
      },
      query: ({
        projection = { id: true },
        name,
        ...args
      }) => {
        const __args: Partial<GetVaboTypesQuery> = args;

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              vabo_types: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    })
  })
});
