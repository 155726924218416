import React, { ComponentClass, FunctionComponent } from 'react';
import { HomePage } from 'components/HomePage';
import { TermsOfServicePage } from 'components/TermsOfServicePage';
import { ClientLayout } from 'layouts/ClientLayout';
import { NebulaLayout  } from 'layouts/NebulaLayout';
import ProtectedBaseLayout from 'layouts/ProtectedBaseLayout/ProtectedBaseLayout';
import { LoadableComponent } from 'loadable__component';
import { ThemeConfig } from 'utils/hooks/useThemeContext';
import { AuditOverviewPage, AuditOverviewPageTitle } from 'features/audits/AuditOverviewPage';
import { AuditsListPage } from 'features/audits/AuditsListPage';
import { CreateAuditPage } from 'features/audits/CreateAuditPage';
import { CreateReappraisalPage } from 'features/audits/CreateReappraisalPage';
import EditAuditPage, {
  FormattedTitle as FormattedEditAuditTitle,
} from 'features/audits/EditAuditPage/EditAuditPage';
import { FormattedTitle as FormattedDiscoveryTitle } from 'features/audits/EditDiscoveryPage/EditDiscoveryPage';
import { FormattedTitle as FormattedForecastTitle } from 'features/audits/Forecasts/ViewForecastPage/ViewForecastPage';
import { ViewSurveyPage } from 'features/audits/ViewSurveyPage';
import LoginPage from 'features/authentication/Auth0Login/LoginPage';
import SignUpPage from 'features/authentication/Auth0SignUp/SignUpPage';
import { BlueprintCentralOverviewPage } from 'features/BlueprintCentral';
import { ClientCentralOverviewPage } from 'features/ClientCentral';
import {AccountDetailsPage, AccountDetailsPageTitle } from 'features/clients/AccountDetailsPage';
import { AddIntegrationPage } from 'features/clients/AddIntegrationPage';
import { ClientIntegrationOauthErrorPage } from 'features/clients/ClientIntegrationOauthErrorPage';
import { FormattedTitleClientIntegrationOauthErrorPage } from 'features/clients/ClientIntegrationOauthErrorPage/ClientIntegrationOauthErrorPage';
import { ClientIntegrationsPage } from 'features/clients/ClientIntegrationsPage';
import CreateIntegrationPage, { FormattedTitle as FormattedCreateIntegrationTitle } from 'features/clients/ClientIntegrationsPage/components/IntegrationForms/CreateIntegrationPage/CreateIntegrationPage';
import { CreateNovaCloudIntegrationPage, CreateNovaCloudIntegrationPageTitle } from 'features/clients/ClientIntegrationsPage/components/IntegrationForms/CreateNovaCloudIntegrationPage';
import EditIntegrationPage, { FormattedTitle as FormattedEditIntegrationTitle } from 'features/clients/ClientIntegrationsPage/components/IntegrationForms/EditIntegrationPage/EditIntegrationPage';
import { ClientOnboardingPage } from 'features/clients/ClientOnboardingPage';
import { ClientsListPage } from 'features/clients/ClientsListPage';
import { CreateClientPage } from 'features/clients/CreateClientPage';
import EditClientPage from 'features/clients/EditClientPage/EditClientPage';
import { ClientBaseReportingPage } from 'features/clients/External/ClientBaseReportingPage';
import { ClientCustomReportingPage } from 'features/clients/External/ClientCustomReportingPage';
import { ClientDataAccessPage } from 'features/clients/External/ClientDataAccessPage';
import { ClientHomePage } from 'features/clients/External/ClientHomePage';
import { ClientListPage } from 'features/clients/External/ClientListPage';
import { ClientStatusUpdatesPage } from 'features/clients/External/ClientStatusUpdatesPage';
import { InsightsAiPage } from 'features/clients/External/InsightsAiPage';
import { InsightsAIPage } from 'features/clients/InsightsAIPage';
import { InviteClientPage } from 'features/clients/InviteClientPage';
import MyCompanyPage from 'features/clients/MyCompanyPage/MyCompanyPage';
import { MyProfilePage } from 'features/clients/MyProfilePage';
import { ContactOverviewPage } from 'features/contacts';
import { ContactsListPage } from 'features/contacts/ContactsListPage';
import { CreateContactPage } from 'features/contacts/CreateContactPage';
import EditContactPage, {
  FormattedTitle as FormattedEditContactTitle,
} from 'features/contacts/EditContactPage/EditContactPage';
import { ErrorWrapper } from 'features/errors/TestError';
import { IntelligenceOverviewPage } from 'features/intelligence';
import { CohortAnalysisPage } from 'features/intelligence/cohort-analysis';
import { CreativeAffinityOverviewPage } from 'features/intelligence/creative-playbook/CreativeAffinity/CreativeAffinityOverviewPage';
import CreativePlaybookRoot from 'features/intelligence/creative-playbook/CreativePlaybookRoot';
import CreativePlaybookAds from 'features/intelligence/creative-playbook/ViewCreativePlaybook/components/CreativePlaybookAds';
import CreativePlaybookAdSets from 'features/intelligence/creative-playbook/ViewCreativePlaybook/components/CreativePlaybookAdSets';
import CreativePlaybookCampaigns from 'features/intelligence/creative-playbook/ViewCreativePlaybook/components/CreativePlaybookCampaigns';
import { CustomerInsightsPage } from 'features/intelligence/customer-insights/CustomerInsightsPage';
import { CreateHorizonPage } from 'features/intelligence/horizon/CreateHorizonPage';
import HistoricalOverviewPage from 'features/intelligence/horizon/HistoricalOverviewPage';
import { HorizonListPage } from 'features/intelligence/horizon/HorizonListPage';
import { routes as analystSurveyLibraryRoutes } from 'features/library/analyst_surveys/constants';
import {
  AnalystQuestionGroupsPage,
  AnalystSurveysDepartmentsPage,
  AnalystSurveysQuestionsPage,
  CreateAnalystQuestionGroupPage,
  CreateAnalystQuestionPage,
  EditAnalystQuestionGroupPage,
  EditAnalystQuestionPage,
} from 'features/library/analyst_surveys/pages';
import { FieldsPage } from 'features/library/fields';
import { OutsourceCostGuidePage, EditOutsourceCostGuidePage, AddOutsourceCostGuidePage } from 'features/library/outsource-cost-guide';
import CreatePackageStrategyPage from 'features/library/package-strategies/CreatePackageStrategyPage/CreatePackageStrategyPage';
import { ViewPackageStrategyPage } from 'features/library/package-strategies/ViewPackageStrategyPage';
import { PerformancePage } from 'features/performance';
import { AccountAssignmentsPage } from 'features/power-view/account-assignments';
import { ClientReportingPage } from 'features/power-view/client-reporting';
import { ServiceExpansionPage } from 'features/power-view/service-expansion';
import CreateScoreboardPage from 'features/scoreboards/CreateScoreboardPage/CreateScoreboardPage';
import EditScoreboardPage from 'features/scoreboards/EditScoreboardPage';
import ExternalScoreboard from 'features/scoreboards/ExternalScoreboard';
import ExternalScoreboardsListPage from 'features/scoreboards/ExternalScoreboardsListPage';
import Scoreboard, { Title as ScoreboardTitle } from 'features/scoreboards/Scoreboard';
import ScoreboardsListPage from 'features/scoreboards/ScoreboardsListPage';
import BlueprintsPackageReviewPage from 'features/strategies/BlueprintsPackageReviewPage';
import CreatePackageBasedBlueprint from 'features/strategies/CreatePackageBasedBlueprint/CreatePackageBasedBlueprint';
import { ViewBlueprintPage, ViewBlueprintPageTitle } from 'features/strategies/ViewBlueprintPage';
import { ViewStrategyPage, ViewStrategyPageTitle } from 'features/strategies/ViewStrategyPage';
import { ReviewsPage } from 'features/talent/pages/ReviewsPage';
import { TalentPulse } from 'features/talent/pages/TalentPulse';
import { UpdateSurveyPage } from 'features/talent/pages/UpdateSurveyPage';
import { TalentCentralOverviewPage } from 'features/TalentCentral';
import EditUserPage from 'features/users/EditUserPage';
import UserOverviewPage from 'features/users/UserOverviewPage';
import { UsersListPage } from 'features/users/UsersListPage';
import { SplashLayout } from './components';
import { ProspectLayout } from './components/ProspectLayout';
import { ClientDiscoveryThankYou } from './features/audits/ClientDiscoveryWizard/components/ClientDiscoveryThankYou';
import { CreateClientFacingDiscovery } from './features/audits/CreateClientFacingDiscovery';
import { CreateDiscoveryPage } from './features/audits/CreateDiscoveryPage';
import { CreatePresentationPage } from './features/audits/CreatePresentationPage';
import { EditClientFacingDiscovery } from './features/audits/EditClientFacingDiscovery';
import { EditDiscoveryPage } from './features/audits/EditDiscoveryPage';
import { BacktestsPage } from './features/audits/Forecasts/BacktestsPage';
import { CreateForecastPage } from './features/audits/Forecasts/CreateForecastPage';
import { EditForecastPage } from './features/audits/Forecasts/EditForecastPage';
import { ForecastsListPage } from './features/audits/Forecasts/ForecastsListPage';
import { ViewForecastPage } from './features/audits/Forecasts/ViewForecastPage';
import { PresentationHistoryPage } from './features/audits/PresentationHistoryPage';
import { SurveyScorecardPage } from './features/audits/SurveyScorecardPage';
import { ViewPresentationPage } from './features/audits/ViewPresentationPage';
import { ViewPresentationPageClientFacing } from './features/audits/ViewPresentationPageClientFacing';
import { ClientDocumentsPage } from './features/clients/External/ClientDocumentsPage';
import { DashboardPage } from './features/dashboard/DashboardPage';
import HorizonRoot from './features/intelligence/horizon/HorizonRoot';
import {
  BudgetScenarios,
  ClientDetails, ForecastOutput,
  HistoricalOverview
} from './features/intelligence/horizon/ViewHorizonPage/components';
import {
  LibraryRoot,
  DepartmentsPage,
  CreateDepartmentPage,
  EditDepartmentPage,
  ServicesPage,
  CreateServicePage,
  EditServicePage,
  TasksPage,
  CreateTaskPage,
  EditTaskPage,
  DemoClientPage,
} from './features/library';
import EditFieldsPage from './features/library/fields/EditFieldsPage';
import EditPackageStrategyPage
  from './features/library/package-strategies/EditPackageStrategyPage/EditPackageStrategyPage';
import {
  CreateRulePage,
  EditRulePage,
  RulesPage
} from './features/library/rules';
import {
  PartnersPage,
  EditPartnerPage,
  ViewPartnerPage,
  CreatePartnerPage,
  PartnersDashboardPage,
  PartnersListPage,
} from './features/partners';
import { SplashPage } from './features/splash/SplashPage';
import {
  BlueprintsReviewPage,
  CreateStrategyPage,
  StrategyProposalPage,
  StrategiesListPage,
  PackageBlueprintsListPage,
  ContractAiPage,
  BlueprintReportingPage,
} from './features/strategies';
import {
  CreateTalentSurveyPage,
  CycleDetailsPage,
  CyclesListPage,
  DepartmentDashboardPage,
  DevelopmentDashboardPage,
  EditTalentSurveyPage,
  HedgehogPage,
  PerformanceDashboardPage,
  ReviewDashboardPage,
  TalentSurveysPage,
  ViewTalentSurveyPage,
  Vital5Page,
} from './features/talent/pages';
import { redirects } from './redirects';
import { LABELS_TYPES } from './utils';

export interface RouteConfig {
  path: string;
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: FunctionComponent | ComponentClass | LoadableComponent<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  layout?: FunctionComponent | ComponentClass | LoadableComponent<any>;
  layoutProps?: { background?: boolean };
  exact?: boolean;
  strict?: boolean;
  TitleComponent?: FunctionComponent;
  theme?: ThemeConfig;
}

/**
 * The order of the routes matters. Routes are matched in order and the first match is picked.
 *
 * For example:
 *
 * /blueprints/package/new must come before /blueprints/package/:id because if they are reversed,
 * the router will match /blueprints/package/:id first and then try to match 'new' to an id which will fail.
 * instead we want to match /blueprints/package/new first and then /blueprints/package/:id so we can still use both paths.
 */
const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    exact: true,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: DashboardPage,
    layout: NebulaLayout,
    exact: true,
  },

  /**
   * Test Error
   */
  {
    path: '/error',
    name: 'Error',
    component: ErrorWrapper,
    layout: NebulaLayout,
    exact: true,
  },

  /**
   * Accounts (formerly known as "Clients")
   */
  {
    path: '/power-view/accounts',
    name: LABELS_TYPES.CAPITALIZE_CLIENTS,
    component: ClientsListPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/power-view/accounts/new',
    name: `New ${LABELS_TYPES.CAPITALIZE_CLIENT}`,
    component: CreateClientPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/power-view/accounts/:clientId/edit',
    name: `Edit ${LABELS_TYPES.CAPITALIZE_CLIENT}`,
    component: EditClientPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/power-view/accounts/:clientId',
    name: `${LABELS_TYPES.CAPITALIZE_CLIENT} Details`,
    component: AccountDetailsPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: AccountDetailsPageTitle,
  },
  /**
   * Client Onboarding Form
   */
  {
    path: '/power-view/onboard',
    name: `${LABELS_TYPES.CAPITALIZE_CLIENT} Onboarding Form`,
    component: ClientOnboardingPage,
    layout: NebulaLayout,
    exact: true,
  },
  /**
   * Client Integrations
   */
  /** Client Facing */
  {
    path: '/c/clients',
    name: `Available ${LABELS_TYPES.CAPITALIZE_CLIENTS}`,
    component: ClientListPage,
    exact: true,
  },
  {
    path: '/c/:clientId/',
    name: 'Home',
    component: ClientHomePage,
    layout: ClientLayout,
    exact: true,
    theme:'client'
  },
  {
    path: '/c/:clientId/profile',
    name: 'My Profile',
    component: MyProfilePage,
    layout: ClientLayout,
    theme:'client',
    exact: true,
  },
  {
    path: '/c/:clientId/integrations',
    name: 'Integrations',
    component: ClientDataAccessPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,

  },
  {
    path: '/c/:clientId/integrations/add',
    name: 'Add Integration',
    component: AddIntegrationPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
  },
  {
    path: '/c/:clientId/custom-reports',
    name: 'Custom Reports',
    component: ClientCustomReportingPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
  },
  {
    path: '/c/:clientId/reports',
    name: 'Reports',
    component: ClientBaseReportingPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
  },
  {
    path: '/c/:clientId/company',
    name: 'My Company',
    component: MyCompanyPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
  },
  {
    path: '/c/:clientId/insights-ai',
    name: 'Insights AI',
    component: InsightsAiPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
  },
  {
    path: '/c/:clientId/integrations/add/:slug',
    name: 'Create Integration',
    component: CreateIntegrationPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
    TitleComponent: FormattedCreateIntegrationTitle,
  },
  {
    path: '/power-view/accounts/c/:clientId/integrations/nova-cloud',
    name: 'nova Cloud Selection',
    component: CreateNovaCloudIntegrationPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
    TitleComponent: CreateNovaCloudIntegrationPageTitle,
  },
  {
    path: '/c/:clientId/integrations/:integrationId',
    name: 'Edit Integration',
    component: EditIntegrationPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
    TitleComponent: FormattedEditIntegrationTitle,
  },
  {
    path: '/c/:clientId/integrations/oauthError/:datasourceId',
    name: 'Integration Oauth Failed',
    component: ClientIntegrationOauthErrorPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
    TitleComponent: FormattedTitleClientIntegrationOauthErrorPage
  },
  {
    path: '/c/:clientId/documents',
    name: 'Documents',
    component: ClientDocumentsPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
  },
  {
    path: '/c/:clientId/weekly-updates',
    name: 'Weekly Updates',
    component: ClientStatusUpdatesPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
  },
  /** Internal Facing */
  {
    path: '/power-view/accounts/:clientId/integrations',
    name: 'Integrations',
    component: ClientIntegrationsPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/power-view/accounts/:clientId/integrations/invite',
    name: `Invite ${LABELS_TYPES.CAPITALIZE_CLIENT}`,
    component: InviteClientPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/power-view/accounts/:clientId/integrations/add',
    name: 'Add Integration',
    component: AddIntegrationPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/power-view/accounts/:clientId/integrations/add/:slug',
    name: 'Create Integration',
    component: CreateIntegrationPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: FormattedCreateIntegrationTitle,
  },
  {
    path: '/power-view/accounts/:clientId/integrations/:integrationId',
    name: 'Edit Integration',
    component: EditIntegrationPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: FormattedEditIntegrationTitle,
  },
  {
    path: '/power-view/accounts/:clientId/integrations/oauthError/:datasourceId',
    name: 'Integration Oauth Failed',
    component: ClientIntegrationOauthErrorPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: FormattedTitleClientIntegrationOauthErrorPage
  },
  /**
   * Scoreboards
   */
  {
    path: '/scoreboards',
    name: 'Scoreboards',
    component: ScoreboardsListPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/scoreboards/new',
    name: 'New Scoreboard',
    component: CreateScoreboardPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/scoreboards/:id/edit',
    name: 'Edit Scoreboard',
    component: EditScoreboardPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/scoreboards/:id',
    name: 'Scoreboard Overview',
    component: Scoreboard,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: ScoreboardTitle,
  },
  /**
   * External scoreboards
   */
  {
    path: '/c/:clientId/scoreboards',
    name: 'Scoreboards',
    component: ExternalScoreboardsListPage,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
  },
  {
    path: '/c/:clientId/scoreboards/:id',
    name: 'Scoreboard Overview',
    component: ExternalScoreboard,
    layout: ClientLayout,
    theme: 'client',
    exact: true,
  },
  /**
   * Contacts
   */
  {
    path: '/contacts/new',
    name: 'New Contact',
    component: CreateContactPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/contacts/:id/edit',
    name: 'Edit Contact',
    component: EditContactPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/contacts/:id',
    name: 'Contact Overview',
    component: ContactOverviewPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: FormattedEditContactTitle,
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: ContactsListPage,
    layout: NebulaLayout,
    exact: true,
  },
  /**
   * Appraisals
   */
  {
    path: '/appraisals/new/reappraisal',
    name: 'New Re-Appraisal',
    component: CreateReappraisalPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/appraisals/new',
    name: 'New Appraisal',
    component: CreateAuditPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/appraisals/:id',
    name: 'Appraisal Overview',
    component: AuditOverviewPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: AuditOverviewPageTitle,
  },
  {
    path: '/appraisals/:id/edit',
    name: 'Edit Appraisal',
    component: EditAuditPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: FormattedEditAuditTitle,
  },
  {
    path: '/appraisals',
    name: 'Appraisals',
    component: AuditsListPage,
    layout: NebulaLayout,
    exact: true,
  },
  /**
   * Discoveries
   */
  {
    path: '/appraisals/new/discovery',
    name: 'New Discovery',
    component: CreateDiscoveryPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/appraisals/:id/discovery',
    name: 'Edit Discovery',
    component: EditDiscoveryPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: FormattedDiscoveryTitle,
  },
  /**
   * Client-Facing Discoveries
   */
  {
    path: '/get-started',
    name: 'New Discovery',
    component: CreateClientFacingDiscovery,
    layout: ProspectLayout,
    theme: 'client',
    exact: true,
  },
  {
    path: '/get-started/:id/thank-you',
    name: 'Thank you for submitting your discovery',
    component: ClientDiscoveryThankYou,
    layout: ProspectLayout,
    theme: 'client',
    exact: true,
  },
  {
    path: '/get-started/:id',
    name: 'Edit Discovery',
    component: EditClientFacingDiscovery,
    layout: ProspectLayout,
    theme: 'client',
    exact: true,
  },
  /**
   * Analyst Surveys
   */
  {
    path: '/appraisals/:id/survey',
    name: 'Analyst Survey',
    component: ViewSurveyPage,
    layout: NebulaLayout,
    exact: true,
  },
  /**
   * Scorecards (read-only version of analyst survey)
   */
  {
    path: '/appraisals/:id/scorecard',
    name: 'Marketing Efficiency Scorecard',
    component: SurveyScorecardPage,
    layout: NebulaLayout,
    exact: true,
  },
  /**
   * Presentation Decks (for Appraisals)
   */
  {
    path: '/appraisals/presentation/new',
    name: 'New Presentation',
    component: CreatePresentationPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/appraisals/:id/presentation/new',
    name: 'New Presentation',
    component: CreatePresentationPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/appraisals/:id/presentation/history',
    name: 'Presentation History',
    component: PresentationHistoryPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/appraisals/:id/presentation/regenerate',
    name: 'Regenerate Presentation',
    component: CreatePresentationPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/appraisals/:id/presentation/view',
    name: 'View Presentation',
    component: ViewPresentationPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/c/:clientId/appraisals/:id/presentation',
    name: 'View Presentation',
    component: ViewPresentationPageClientFacing,
    layout: ProtectedBaseLayout,
    exact: true,
  },
  /**
   * Forecasts
   */
  {
    path: '/appraisals/:id/forecasts',
    name: 'Forecasts',
    component: ForecastsListPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/appraisals/:id/forecasts/new',
    name: 'Create Forecast',
    component: CreateForecastPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/appraisals/:id/forecasts/:forecastId/edit',
    name: 'Edit Forecast',
    component: EditForecastPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/appraisals/:id/forecasts/:forecastId',
    name: 'Forecast Analysis',
    component: ViewForecastPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: FormattedForecastTitle,
  },
  {
    path: '/forecasts/backtests',
    name: 'Forecast Backtests',
    component: BacktestsPage,
    layout: NebulaLayout,
    exact: true,
  },

  /**
   * Users
   */
  {
    path: '/users',
    name: 'Users',
    component: UsersListPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/users/:id/edit',
    name: 'Edit User',
    component: EditUserPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/users/:id',
    name: 'User Overview',
    component: UserOverviewPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: UserOverviewPage.Title,
  },

  /**
   * Blueprints
   */
  {
    path: '/blueprints',
    name: 'Blueprints',
    component: BlueprintCentralOverviewPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/blueprints/hourly',
    name: 'Blueprints (Hourly)',
    component: StrategiesListPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: StrategiesListPage.Title,
  },
  {
    path: '/blueprints/package',
    name: 'Blueprints (Package)',
    component: PackageBlueprintsListPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: PackageBlueprintsListPage.Title,
  },
  {
    path: '/blueprints/contract-ai',
    name: 'Contract AI',
    component: ContractAiPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: ContractAiPage.Title,
  },
  {
    path: '/blueprints/new',
    name: 'Create Blueprint',
    component: CreateStrategyPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: CreateStrategyPage.Title,
  },
  {
    path: '/blueprints/reporting',
    name: 'Blueprint Reporting',
    component: BlueprintReportingPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: BlueprintReportingPage.Title,
  },
  {
    path: '/blueprints/review',
    name: 'Blueprints Review Queue',
    component: BlueprintsReviewPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: BlueprintsReviewPage.Title,
  },
  {
    path: '/blueprints/package/review',
    name: 'Blueprints Package Review Queue',
    component: BlueprintsPackageReviewPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: BlueprintsPackageReviewPage.Title,
  },
  {
    path: '/blueprints/:id',
    name: 'Blueprint Overview',
    component: ViewStrategyPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: ViewStrategyPageTitle,
  },
  {
    path: '/blueprints/package/new',
    name: 'Create Package Based Blueprint',
    component: CreatePackageBasedBlueprint,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>New Blueprint</span>,
  },
  {
    path: '/blueprints/package/:id',
    name: 'Blueprint Package Overview',
    component: ViewBlueprintPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: ViewBlueprintPageTitle
  },
  {
    path: '/blueprints/:id/proposal',
    name: 'Blueprint Proposal',
    component: StrategyProposalPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: StrategyProposalPage.Title,
  },

  /**
   * Library
   */
  {
    path: '/library',
    name: 'Library',
    component: LibraryRoot,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Library</span>,
  },

  /* Analyst Surveys Library */
  {
    path: analystSurveyLibraryRoutes.DEPARTMENTS,
    name: 'Departments | Analyst Survey Criteria',
    component: AnalystSurveysDepartmentsPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Analyst Surveys</span>,
  },
  {
    path: analystSurveyLibraryRoutes.QUESTIONS,
    name: 'Questions | Analyst Survey Criteria',
    component: AnalystSurveysQuestionsPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: AnalystSurveysQuestionsPage.Title,
  },
  {
    path: analystSurveyLibraryRoutes.QUESTIONS_NEW,
    name: 'Create Question | Analyst Survey Criteria',
    component: CreateAnalystQuestionPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>New Question</span>,
  },
  {
    path: analystSurveyLibraryRoutes.QUESTIONS_EDIT,
    name: 'Edit Question | Analyst Survey Criteria',
    component: EditAnalystQuestionPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: EditAnalystQuestionPage.Title,
  },
  {
    path: analystSurveyLibraryRoutes.QUESTION_GROUPS,
    name: 'Question Groups | Analyst Survey Criteria',
    component: AnalystQuestionGroupsPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: AnalystQuestionGroupsPage.Title,
  },
  {
    path: analystSurveyLibraryRoutes.QUESTION_GROUPS_NEW,
    name: 'Create Question Group | Analyst Survey Criteria',
    component: CreateAnalystQuestionGroupPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>New Question Group</span>,
  },
  {
    path: analystSurveyLibraryRoutes.QUESTION_GROUPS_EDIT,
    name: 'Edit Question Group | Analyst Survey Criteria',
    component: EditAnalystQuestionGroupPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Edit</span>,
  },

  /* Departments */
  {
    path: '/library/departments',
    name: 'Departments',
    component: DepartmentsPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Departments</span>,
  },
  {
    path: '/library/departments/new',
    name: 'New department',
    component: CreateDepartmentPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>New</span>,
  },
  {
    path: '/library/departments/:id/edit',
    name: 'Edit department',
    component: EditDepartmentPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: EditDepartmentPage.Title,
  },

  /* Services */
  {
    path: '/library/services',
    name: 'Services',
    component: ServicesPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Services</span>,
  },
  {
    path: '/library/services/new',
    name: 'New Service',
    component: CreateServicePage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>New</span>,
  },
  {
    path: '/library/services/:id/edit',
    name: 'Edit Service',
    component: EditServicePage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: EditServicePage.Title,
  },
  /* Outsource Cost Guide */
  {
    path: '/library/outsource-cost-guide',
    name: 'Outsource Cost Guide',
    component: OutsourceCostGuidePage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Outsource Cost Guide</span>,
  },
  {
    path: '/library/outsource-cost-guide/new',
    name: 'Add Outsource Cost Guide',
    component: AddOutsourceCostGuidePage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: AddOutsourceCostGuidePage.Title,
  },
  {
    path: '/library/outsource-cost-guide/:id/edit',
    name: 'Edit Outsource Cost Guide',
    component: EditOutsourceCostGuidePage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: EditOutsourceCostGuidePage.Title,
  },
  /* Tasks */
  {
    path: '/library/tasks',
    name: 'Strategies',
    component: TasksPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Strategies</span>,
  },
  {
    path: '/library/tasks/new',
    name: 'New Strategy',
    component: CreateTaskPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>New</span>,
  },
  {
    path: '/library/tasks/:id/edit',
    name: 'Edit Task',
    component: EditTaskPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: EditTaskPage.Title,
  },
  {
    path: '/library/package-strategies/new',
    name: 'New Strategy',
    component: CreatePackageStrategyPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>New Strategy</span>,
  },
  {
    path: '/library/package-strategies/:id',
    name: 'Strategy',
    component: ViewPackageStrategyPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Strategy</span>,
  },
  {
    path: '/library/package-strategies/:id/edit',
    name: 'Edit Strategy',
    component: EditPackageStrategyPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Edit Strategy</span>,
  },

  /* Rules */
  {
    path: '/library/rules',
    name: 'Rules',
    component: RulesPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Rules</span>,
  },
  {
    path: '/library/rules/new',
    name: 'New Rule',
    component: CreateRulePage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>New Rule</span>,
  },
  {
    path: '/library/rules/:id/edit',
    name: 'Edit Rule',
    component: EditRulePage,
    layout: NebulaLayout,
    exact: true,
    //TitleComponent: EditRulePage.Title,
  },

  /* Fields */
  {
    path: '/library/fields',
    name: 'Fields',
    component: FieldsPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Fields</span>,
  },
  {
    path: '/library/fields/resign',
    name: 'Edit Re-Sign %',
    component: EditFieldsPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: EditFieldsPage.Title,
  },
  {
    path: '/library/fields/rapport',
    name: 'Edit Rapport',
    component: EditFieldsPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: EditFieldsPage.Title,
  },
  {
    path: '/library/fields/pacing-to-goal',
    name: 'Edit Pacing to Goal',
    component: EditFieldsPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: EditFieldsPage.Title,
  },
  {
    path: '/library/fields/sales-division',
    name: 'Edit Sales Division',
    component: EditFieldsPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: EditFieldsPage.Title,
  },
  /* Demo Client */
  {
    path: '/library/nova-demo-client',
    name: `nova Demo ${LABELS_TYPES.CAPITALIZE_CLIENT}`,
    component: DemoClientPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>nova Demo Client</span>,
  },
  /**
   * Partners
   */
  {
    path: '/partners',
    name: 'Partners',
    component: PartnersPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <>Referral Partners</>,
  },
  {
    path: '/partners/new',
    name: 'Create partner',
    component: CreatePartnerPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: CreatePartnerPage.Title,
  },
  {
    path: '/partners/:id',
    name: 'View Partner',
    component: ViewPartnerPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: ViewPartnerPage.Title,
  },
  {
    path: '/partners/:id/edit',
    name: 'Edit Partner',
    component: EditPartnerPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: EditPartnerPage.Title,
  },

  /**
   * Partner dashboard
   */
  {
    path: '/p/partners',
    name: 'Partners',
    component: PartnersListPage,
    exact: true,
    layout: ClientLayout,
    theme: 'client',
  },
  {
    path: '/p/:id',
    name: 'Partners Dashboard',
    component: PartnersDashboardPage,
    layout: ClientLayout,
    exact: true,
    theme: 'client',
  },

  /**
   * Client Central
   */
  {
    path: '/power-view',
    name: 'Power View',
    component: ClientCentralOverviewPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/insights-ai',
    name: 'Insights AI',
    component: InsightsAIPage,
    layout: NebulaLayout,
    exact: true,
  },

  /**
   * Intelligence
   */
  {
    path: '/intelligence',
    name: 'Intelligence',
    component: IntelligenceOverviewPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/intelligence/cohorts',
    name: 'Cohorts',
    component: CohortAnalysisPage,
    layout: NebulaLayout,
    exact: true,
  },

  /**
   * nova Talent
   */
  {
    path: '/talent',
    name: 'nova Talent',
    component: TalentCentralOverviewPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/talent/reviews',
    name: 'Reviews',
    component: ReviewsPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
    TitleComponent: () => <span>Reviews</span>,
  },
  {
    path: '/talent/update/reviews',
    name: 'Update Review',
    component: UpdateSurveyPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
  },
  {
    path: '/talent/reviews/:id',
    name: 'View Review',
    component: ViewTalentSurveyPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
    TitleComponent: ViewTalentSurveyPage.Title,
  },
  {
    path: '/talent/reviews/:id/edit',
    name: 'Edit Review',
    component: EditTalentSurveyPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
  },
  {
    path: '/talent/reviews/new',
    name: 'Create Review',
    component: CreateTalentSurveyPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
  },
  {
    path: '/talent/review/log',
    name: 'Review Log',
    component: TalentSurveysPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
    TitleComponent: () => <span>Review Log</span>,
  },
  {
    path: '/talent/reporting/review',
    name: 'Review Dashboard',
    component: ReviewDashboardPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
  },
  {
    path: '/talent/reporting/development',
    name: 'Development Dashboard',
    component: DevelopmentDashboardPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
  },
  {
    path: '/talent/reporting/performance',
    name: 'Performance Dashboard',
    component: PerformanceDashboardPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
  },
  {
    path: '/talent/reporting/department',
    name: 'Department Dashboard',
    component: DepartmentDashboardPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
  },
  {
    path: '/talent/cycles',
    name: 'Cycles',
    component: CyclesListPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
  },
  {
    path: '/talent/cycles/:id',
    name: 'Cycle Details',
    component: CycleDetailsPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
  },
  {
    path: '/talent/vital-5',
    name: 'Vital 5',
    component: Vital5Page,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
  },
  {
    path: '/talent/hedgehog',
    name: 'Hedgehog',
    component: HedgehogPage,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
  },
  {
    path: '/talent/pulse',
    name: 'Pulse',
    component: TalentPulse,
    layout: NebulaLayout,
    theme: 'talent',
    exact: true,
  },
  /**
   * Splash Page
   */
  {
    path: '/splash',
    name: 'SPRnova',
    component: SplashPage,
    layout: SplashLayout,
    exact: true,
  },
  {
    path: '/terms-of-use',
    name: 'Terms of Service',
    component: TermsOfServicePage,
    exact: true,
  },
  /**
   * Authentication
   */
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    exact: true,
  },
  {
    path: '/signup',
    name: 'Sign Up',
    component: SignUpPage,
    exact: true,
  },
  /**
   * Intelligence
   */
  {
    path: '/intelligence/forecasts',
    name: 'Forecasts',
    component: HorizonListPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/intelligence/forecasts/new',
    name: 'Create Forecast',
    component: CreateHorizonPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/intelligence/forecasts/:id',
    name: 'View Forecast',
    component: HorizonRoot,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: HorizonRoot.Title
  },
  {
    path: '/intelligence/forecasts/:id/client-details',
    name: `${LABELS_TYPES.CAPITALIZE_CLIENT} Details`,
    component: ClientDetails,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>${LABELS_TYPES.CAPITALIZE_CLIENT} Details</span>
  },
  {
    path: '/intelligence/forecasts/:id/historical-overview',
    name: 'Historical Overview',
    component: HistoricalOverview,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Historical Overview</span>
  },
  {
    path: '/intelligence/forecasts/:id/budget-scenarios',
    name: 'Budget Scenarios',
    component: BudgetScenarios,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Budget Scenarios</span>
  },
  {
    path: '/intelligence/forecasts/:id/forecast-output',
    name: 'Forecast Output',
    component: ForecastOutput,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: () => <span>Forecast Output</span>
  },
  {
    path: '/intelligence/forecasts/:id/historical-overview-page',
    name: 'Historical Overview',
    component: HistoricalOverviewPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: HistoricalOverviewPage.Title
  },
  {
    path: '/intelligence/customer-insights',
    name: 'Customer Insights',
    component: CustomerInsightsPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/intelligence/creative-affinity',
    name: 'Creative Affinity',
    // component: CreativePlaybookRoot,
    component: CreativeAffinityOverviewPage,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: CreativePlaybookRoot.Title
  },
  {
    path: '/intelligence/creative-affinity/ads',
    name: 'Creative Affinity Ads',
    component: CreativePlaybookAds,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: CreativePlaybookAds.Title
  },
  {
    path: '/intelligence/creative-affinity/ad-sets',
    name: 'Creative Affinity Ad Sets',
    component: CreativePlaybookAdSets,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: CreativePlaybookAdSets.Title
  },
  {
    path: '/intelligence/creative-affinity/campaigns',
    name: 'Creative Affinity Campaigns',
    component: CreativePlaybookCampaigns,
    layout: NebulaLayout,
    exact: true,
    TitleComponent: CreativePlaybookCampaigns.Title
  },
  /**
   * Power View
   */
  {
    path: '/power-view/account-assignments',
    name: `${LABELS_TYPES.CAPITALIZE_CLIENT} Assignments`,
    component: AccountAssignmentsPage,
    layout: NebulaLayout,
    exact: true,
  },
  /**
   * Performance Page
   */
  {
    path: '/power-view/performance',
    name: 'Performance',
    component: PerformancePage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/power-view/service-expansion',
    name: 'Service Expansion',
    component: ServiceExpansionPage,
    layout: NebulaLayout,
    exact: true,
  },
  {
    path: '/power-view/client-reporting',
    name: `${LABELS_TYPES.CAPITALIZE_CLIENT} Reporting`,
    component: ClientReportingPage,
    layout: NebulaLayout,
    exact: true,
  },
  ...redirects,
];

export { routes };
