/**
 * Update client
 */

import { Projection } from 'api/entityGraphQL';
import { Client, YesNo } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments } from '..';
import dashboardTagType from '../am_dashboards/tagTypes';

export type UpdateClientMutation = {
  id: number;
  name?: string;
  legal_name?: string;
  website?: string;
  logo?: string;
  logo_path?: string;
  description?: string;
  status?: 'prospect' | 'audit' | 'strategy' | 'reporting' | 'archive'
  business_type_id?: number;
  industry_id?: number;
  lead_source_id?: number;
  account_manager_id?: number;
  executive_sponsor_id?: number;
  group_director_id?: number;
  am_specialist_id?: number;
  organization_id?: number;
  tier_id?: number;
  dataq_id?: string;
  dataq_name?: string;
  timezone_id?: number;
  is_active?: YesNo;
  salesforce_client_id?: string | null;
  netsuite_id?: number;
  team_members?: number[];
  collaborators?: number[];
  acquisition_id?: number;
  contract_term?: string | null;
  contract_term_start_date?: string | null;
  projection?: Projection;
}

export type UpdateClientResult = Client;

export const { useUpdateClientMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    updateClient: build.mutation<UpdateClientResult, UpdateClientMutation>({
      transformResponse: (response: { updateClient: Client }) => response.updateClient,
      invalidatesTags: result => [
        result?.id ? { id: result.id, type: tagTypes.CLIENT } : tagTypes.CLIENT,
        tagTypes.CLIENTS, dashboardTagType.AM_DASHBOARD, dashboardTagType.AM_DASHBOARDS
      ],
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<UpdateClientMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              updateClient: {
                __args: cleanArguments(__args),
                ...projection,
                id: true
              }
            }
          }
        };
      }
    })
  })
});
