import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Role } from 'api/accessControl';
import { novaGraphQLPublicClient } from 'api/entityGraphQL/NovaGraphQLPublicClient';
import { getClient } from 'api/entityGraphQL/utils';
import { RootState } from 'app/store';
import { User } from 'features/entitiesRedux/models';
import { FeatureFlag } from 'features/entitiesRedux/models/feature_flag';

/**
 * Types
 */
interface GlobalState {
  account: {
    data: User | null;
    isLoading: boolean;
    error: string | null;
  };
  featureFlags: {
    data: FeatureFlag[] | [];
    isLoading: boolean;
    error: string | null;
  };
  apiClient: 'public' | 'private';
}

/**
 * State
 */
export const globalInitialState: GlobalState = {
  account: {
    data: null,
    isLoading: false,
    error: null,
  },
  featureFlags: {
    data: [],
    isLoading: false,
    error: null
  },
  apiClient: 'private',
};

/**
 * Anonymous account used when fetchMe() fails
 */
const anonymousAccount = {
  //TODO: we should do something more explicit than setting id to 0
  id: 0,
  name: 'anonymous',
  email: '',
  avatar: '',
  permissions: [],
  roles: [Role.anonymous],
  roles_list: [],
  accounts: []
};

/**
 * Async Actions
 */
export const fetchMe = createAsyncThunk('users/fetchMe', async () => {
  const { me } = await getClient().fetchMe();
  return me;
});

export const fetchFeatureFlagsExternal = createAsyncThunk('featureFlags/fetchFeatureFlagsExternal', async (clientId?: number) => {
  const { feature_flags } = await novaGraphQLPublicClient.fetchFeatureFlags(clientId);
  return feature_flags;
});

/**
 * Reducer
 */
export const globalSlice = createSlice({
  name: 'global',
  initialState: globalInitialState,
  reducers: {
    /**
     * Synchronous reducers
     */
    setApiPrivate: state => {state.apiClient = 'private';},
    setApiPublic: state => {state.apiClient = 'public';},
  },
  extraReducers: {
    [String(fetchMe.pending)]: (state /* , action */) => {
      state.account.isLoading = true;
      state.account.error = null;
    },
    [String(fetchMe.fulfilled)]: (state, action) => {
      state.account.data = action.payload;
      state.account.isLoading = false;
      state.account.error = null;
    },
    [String(fetchMe.rejected)]: (state, action) => {
      state.account.data = anonymousAccount;
      state.account.isLoading = false;
      state.account.error =
        action?.payload?.error?.message || 'Error loading account';
    },
    [String(fetchFeatureFlagsExternal.pending)]: (state /* , action */) => {
      state.featureFlags.isLoading = true;
      state.featureFlags.error = null;
    },
    [String(fetchFeatureFlagsExternal.fulfilled)]: (state, action) => {
      state.featureFlags.data = action.payload;
      state.featureFlags.isLoading = false;
      state.featureFlags.error = null;
    },
    [String(fetchFeatureFlagsExternal.rejected)]: (state, action) => {
      state.featureFlags.data = [];
      state.featureFlags.isLoading = false;
      state.featureFlags.error =
        action?.payload?.error?.message || 'Error loading account';
    },
  },
});

export const { setApiPrivate, setApiPublic } = globalSlice.actions;

/**
 * Selectors
 */

export const selectAccount = (state: RootState) => state.global.account.data;
export const selectAccountMeta = (state: RootState) => [
  state.global.account.isLoading,
  state.global.account.error,
];
export const selectApiClient = (state: RootState) => {
  return state.global.apiClient;
};
export const selectFeatureFlags = (state: RootState) => state.global.featureFlags.data;
export const selectFeatureFlagsMeta = (state: RootState) => [
  state.global.featureFlags.isLoading,
  state.global.featureFlags.error,
];

/**
 * Export
 */
export default globalSlice.reducer;
