/**
 * Entities -> Strategys -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { Strategy } from './strategy';
import { compareField } from '../../utils/compareField';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const strategiesAdapter = createEntityAdapter<Strategy>({
  selectId: (strategy) => strategy.id,
  sortComparer: compareField('name'),
});
