/**
 * Entities -> Services -> Hooks
 */

import { useGetServicesQuery } from 'api/crudGraphQL/services/getServices';
import { Projection } from 'api/entityGraphQL';
import { fetchServiceById } from './actions';
import { selectServiceById } from './selectors';
import { FetchServicesFilter, Service } from './service';
import { useEntityByParamId } from '../_entity/hooks';

/**
 * Get paginated services
 */
export const useServices = ({
  projection = undefined,
  department_id,
  pricing_version
}: {
  filter?: FetchServicesFilter,
  projection?: Projection;
  department_id?: number;
  pricing_version?: string;
} = {}): {
  services: Service[];
  loading: boolean;
  error: string | undefined;
} => {
  const { data: services = [], isLoading, error } = useGetServicesQuery({
    department_id,
    pricing_version,
    projection: projection ?? {
      id: true,
      name: true,
    }
  });

  // if there's no error message provided but there's an error, provide a default error message
  const errorMessage = error && error.message ? error.message : 'No error message provided';

  return {
    services,
    loading: isLoading,
    /**
     * We only want to return the error message if there is an error because
     * if we are always returning a value, the code using this query will think there's an error,
     * causing the ui to throw an exception or not load dependent data, which we don't want
     *
     * */
    error: error ? errorMessage : undefined
  };
};

/**
 * Get services by param ID
 */
export const useServiceByParamId = () => useEntityByParamId(selectServiceById, fetchServiceById);
