/**
 * Update scoreboard widgets
 */

import { Projection } from 'api/entityGraphQL';
import { Report, YesNo } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments } from '..';

export type ScoreboardWidgetInput = {
  indicator_ids: number[];
  is_active: YesNo;
  settings: string;
  size: number;
  title: string;
  widget_id: number;
};
export type UpdateScoreboardWidgetsMutation = {
  report_id: number;
  widgets?: ScoreboardWidgetInput[];
  projection?: Projection;
}

export type UpdateScoreboardWidgetsResult = Report;

export const UPDATE_SCOREBOARD_WIDGETS = 'updateScoreboardWidgets';

export const { useUpdateScoreboardWidgetsMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [UPDATE_SCOREBOARD_WIDGETS]: build.mutation<UpdateScoreboardWidgetsResult, UpdateScoreboardWidgetsMutation>({
      transformResponse: (response: { updateReportWidgets: Report }) => response.updateReportWidgets,
      invalidatesTags: result => [
        result?.id ? { id: result.id, type: tagTypes.SCOREBOARD } : tagTypes.SCOREBOARD,
        tagTypes.SCOREBOARDS,
        tagTypes.SCOREBOARD,
      ],
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<UpdateScoreboardWidgetsMutation> = {
          ...values
        };
        return {
          body: {
            mutation: {
              updateReportWidgets: {
                __args: cleanArguments(__args),
                ...projection,
                id: true
              }
            }
          }
        };
      }
    })
  })
});
