/**
 * Entities -> Fields -> Reducers
 */

import { fieldsAdapter } from './adapter';
import { EntitiesState } from '../../types';

export default {
  'deleteChurn/fulfilled'(state: EntitiesState, action: { payload: number }) {
    state.isLoading = false;
    fieldsAdapter.removeOne(state.data.dashboard_churns, action.payload);
  },
  'deleteRapport/fulfilled'(state: EntitiesState, action: { payload: number }) {
    state.isLoading = false;
    fieldsAdapter.removeOne(state.data.dashboard_rapports, action.payload);
  },
  'deletePace/fulfilled'(state: EntitiesState, action: { payload: number }) {
    state.isLoading = false;
    fieldsAdapter.removeOne(state.data.dashboard_paces, action.payload);
  },
};
