import { RootState } from 'app/store';
import { ExternalClient } from './external_client';
import { selectEntitiesDenormalized } from '../_entity/selectors';

export const selectExternalClientsByIds = (clientIds: number[]) => (
  state: RootState
) => {
  const input = { external_clients: clientIds };

  return selectEntitiesDenormalized(state, input).external_clients;
};

export const selectExternalClientById = (clientId: number) => (state: RootState) => {
  const clients: ExternalClient[] = selectExternalClientsByIds([clientId])(state);

  return clients[0];
};
