import React from 'react';
import loadable from '@loadable/component';
import { Skeleton } from 'antd';

const LoadableComponent = loadable(() => import('./SearchInput'), {
  fallback: (
     
    <Skeleton.Input style={{ width: '189px' }} active size={'default'} />
  )
});

export { LoadableComponent as SearchInput };
