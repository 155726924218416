/**
 * Components -> Filters
 */

import React, { FC, forwardRef, ReactNode, useCallback, useEffect, useMemo } from 'react';
import { FormInstance } from 'antd/lib/form';
import classNames from 'classnames';
import { Button } from 'components/Button';
import { Form } from 'components/Form';
import { FormItem } from 'components/FormItem';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import omitBy from 'lodash/omitBy';
import { FieldData } from 'rc-field-form/lib/interface';
import { useQueryParams, StringParam, NumberParam, DelimitedNumericArrayParam, DelimitedArrayParam } from 'use-query-params';
import css from './Filters.module.scss';

export type FilterValues = Record<string, unknown>;

export type FiltersProps = {
  className?: string;
  fields: {
    label?: string | React.ReactNode;
    name: string;
    columnClass?: string;
    render: ReactNode;
    urlParameterType?: typeof StringParam | typeof NumberParam | typeof DelimitedNumericArrayParam | typeof DelimitedArrayParam;
    type?: 'select' | 'input' | 'checkbox';
    helperText?: string;
    /**
     * Function used to clear non antd form fields (e.g. state variables)
     * Pass in a function that has setState calls to clear values
     */
    optionalClearFunction?: () => void;
  }[];
  form: FormInstance;
  hasMargin?: boolean;
  initialValues?: FilterValues,
  onFieldsChange?: (changedFields: FieldData[], allFields: FieldData[]) => void;
  onReset?: () => void;
  onValuesChange: (values: FilterValues) => void;
  ref?: React.Ref<FormInstance<FilterValues>>;
  values?: Record<string, unknown>;
  clearButtonClass?: string;
  withClearButton?: boolean;
  withActionFooter?: boolean;
  hideResetButton?: boolean;
  resetButtonClass?: string;
  switchButtonClassName?: string;
}

export const Filters: FC<FiltersProps> = forwardRef(({
  className,
  fields,
  form,
  hasMargin,
  initialValues: initialValuesProp = {},
  onFieldsChange,
  onReset,
  onValuesChange,
  values,
  clearButtonClass,
  withClearButton = false,
  withActionFooter = false,
  hideResetButton = false,
  resetButtonClass,
  switchButtonClassName
}, ref) => {
  /**
   * Utility
   */
  const omitEmptyValues = useCallback((values) => omitBy(values, val => !val), []);
  const getHasValues = useCallback((values: FilterValues) => !!Object.keys(omitEmptyValues(values)).length, []);
  const getFieldHasQueryParamEnabled = useCallback(fieldName => !!fields.find(field => field.name === fieldName)?.urlParameterType, [fields]);

  /**
   * Query params
   */
  const [queryParams, setQueryParams] = useQueryParams(
    fields.reduce((acc, field) => {
      if (field.urlParameterType) {
        return {
          ...acc,
          [field.name]: field.urlParameterType,
        };
      }

      return acc;
    }, {})
  );

  /**
   * Sync values with URL params
   * Remove empty values from query params by setting them as undefined
   */
  const handleSyncUrlQueryParamsWithValues = (values: FilterValues) => {
    const fieldsWithUrlParams = Object.entries(values)
      .filter(([fieldName]) => getFieldHasQueryParamEnabled(fieldName));

    if (fieldsWithUrlParams.length) {
      const newParams = fieldsWithUrlParams.reduce((acc, [key, val]) => ({
        ...acc,
        [key]: !val ? undefined : val,
      }), {});

      setQueryParams(
        newParams
      );
    }
  };

  /**
   * Initial values
   */
  const initialValues = useMemo(() => (
    {
      ...omitEmptyValues(initialValuesProp),
      ...omitEmptyValues(queryParams), // Note: Param values takes precedence over initial values prop
    }
  ), [queryParams, initialValuesProp]);

  useEffect(() => {
    // Set query params based on initial values on mount
    handleSyncUrlQueryParamsWithValues(initialValuesProp);

    // Fire onChange to update values based on query params
    onValuesChange(omitEmptyValues(queryParams));
  }, []);


  /**
   * Listen for external changes to form values
   */
  useEffect(() => {
    if (values && !isEqual(values, form.getFieldsValue())) {
      form.setFieldsValue(values);
      handleSyncUrlQueryParamsWithValues(values);
    }
  }, [values, form]);

  /**
   * On value change
   */
  const handleValuesChange = debounce((values: FilterValues) => {
    onValuesChange(omitEmptyValues(values));

    if (!withActionFooter) {
      // Sync fields with query params
      handleSyncUrlQueryParamsWithValues(values);
    }
  }, 400);

  /**
   * Reset
   */
  const handleReset = useCallback(() => {
    const resetValues = fields.reduce((acc, field) => ({ ...acc, [field.name]: undefined }), {});
    form.setFieldsValue(resetValues);
    handleValuesChange(resetValues);
    onReset?.();
  }, []);

  /**
   * Reset a single field
   *
   * @param fieldName - name of the field you are resetting
   */
  const handleSingleFieldReset = useCallback((fieldName) => {
    const resetSingleFieldValue = {
      [fieldName]: undefined
    };
    form.setFieldsValue(resetSingleFieldValue);
    handleValuesChange(resetSingleFieldValue);
    onReset?.();
  }, []);

  /**
   * Fields
   */
  const renderFields = useMemo(() => (
    <div className={css.columns}>
      {fields.map(({ name, label, render, columnClass, type, helperText, optionalClearFunction }) => {
        const helperTextElement = helperText ? <div style={{
          fontSize: '0.75rem',
          color: '#989898',
          marginTop: '0.25rem',
          marginBottom: '0.5rem'
        }}>{helperText}</div> : null;
        return (
          <div key={name} className={classNames(css.column, columnClass)}>
            <div className={clearButtonClass} hidden={!withClearButton || (type === 'checkbox')}>
              <span onClick={() => {
                handleSingleFieldReset(name);
                if (optionalClearFunction) {
                  optionalClearFunction();
                }
              }}>
                Clear
              </span>
            </div>
            <FormItem
              smallLabel
              label={label}
              name={name}
              className={classNames(
                css.formItem,
                type === 'checkbox' ? switchButtonClassName : null
              )}
            >
              {render}
            </FormItem>
            {helperTextElement}
          </div>
        );
      })}
      {onReset ? (
        <FormItem shouldUpdate noStyle>
          {() => {
            const hasValues = getHasValues(form.getFieldsValue());

            return (
              <div
                className={classNames(css.column, css.column__reset, resetButtonClass, {
                  [css.show]: hasValues && !hideResetButton,
                })}
              >
                <Button
                  className={css.resetButton}
                  type="text"
                  onClick={handleReset}
                >
                  Reset
                </Button>
              </div>
            );
          }}
        </FormItem>
      ) : null}
    </div>
  ), [fields, handleReset]);

  return (
    <>
      <Form
        ref={ref}
        className={
          classNames(
            css.root,
            { [css.hasMargin]: hasMargin },
            className,
          )
        }
        form={form}
        initialValues={initialValues}
        layout="vertical"
        onValuesChange={(_, values) => handleValuesChange(values)}
        onFieldsChange={onFieldsChange}
      >
        {renderFields}
      </Form>
    </>
  );
});

Filters.displayName = 'Filters';
