/**
 * Entities -> Organizations -> Selectors
 */

import { EntityId } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { organizationsAdapter } from './adapter';
import { selectEntitiesDenormalized } from '../_entity/selectors';

export const organizationsSelectors = organizationsAdapter.getSelectors(
  (state: RootState) => state.entities.data.organizations
);

export const selectAllOrganizations = (state: RootState) => {
  const organizationIds = organizationsSelectors.selectIds(state);
  return selectOrganizationsByIds(organizationIds)(state);
};

export const selectOrganizationsByIds = (organizationIds: EntityId[]) => (
  state: RootState
) => {
  const input = { organizations: organizationIds };
  return selectEntitiesDenormalized(state, input).organizations;
};

export const selectOrganizationById = (organizationId: number) => (
  state: RootState
) => {
  return selectOrganizationsByIds([organizationId])(state)[0];
};
