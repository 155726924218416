import React, { useCallback, useState, memo } from 'react';
import { TextField, Box, Button, Card, CardActions, CardHeader, EditIcon, Grid, Typography, Select, useForm } from '@sprnova/nebula';
import { useGetAnnualRevenuesPublicQuery } from 'api/crudGraphQL/public/company/getAnnualRevenuesPublic';
import { useGetEmployeeSizesPublicQuery } from 'api/crudGraphQL/public/company/getEmployeeSizesPublic';
import { useGetIndustriesPublicQuery } from 'api/crudGraphQL/public/industries/getIndustriesPublic';
import { novaGraphQLPublicClient } from 'api/entityGraphQL';
import { ExternalClient } from 'features/entitiesRedux';
import css from './CompanyOverview.module.scss';

type CompanyOverviewProps = {
  client: ExternalClient;
  onError?: (error: string) => void;
  onTriggerClientRefresh?: () => void;
};

const CompanyOverview: React.FC<CompanyOverviewProps> = ({ client, onError, onTriggerClientRefresh }) => {
  const [editCompany, setEditCompany] = useState<boolean>(false);
  const { data: employee_sizes = [] } = useGetEmployeeSizesPublicQuery({});
  const { data: annual_revenues = [] } = useGetAnnualRevenuesPublicQuery({});
  const { data: industries = [] } = useGetIndustriesPublicQuery({});

  const { register, handleSubmit } = useForm({
    defaultValues: {
      website: client?.website,
      id: client?.id,
      industry_id: client?.industry?.id,
      employee_size_id: client?.employee_size?.id,
      annual_revenue_id : client?.annual_revenue?.id,
    },
  });

  const onSubmit = useCallback(async({id, website,industry_id,employee_size_id,annual_revenue_id} :
    {
      id: number,
      website?: string,
      industry_id?:number
      employee_size_id?:number,
      annual_revenue_id?:number,
    }
  ) => {
    try {
      await novaGraphQLPublicClient.updateExternalClient({id, website,industry_id,employee_size_id,annual_revenue_id});
      setEditCompany(false);
      onTriggerClientRefresh?.();
    }
    catch (e) {
      const error = e;
      const message = error?.response?.errors?.[0]?.message || 'An unknown error occurred. Please try again.';
      onError?.(message);
    }
  }, [onError, onTriggerClientRefresh]);

  const header = (
    <CardHeader
      title="Company Overview"
      end={
        <CardActions
          actions={!editCompany ?
            [
              <Button onClick={() => setEditCompany(true)} variant="tertiary" key="action-1">
                <EditIcon/> Edit
              </Button>,
            ] : [
              <Button onClick={() => setEditCompany(false)} variant="secondary" key="action-2">
                Cancel
              </Button>,
              <Button type="submit" variant="primary" key="action-3">
                Save
              </Button>
            ]
          }
        />
      }
    />);

  return (
    <Box style={{marginBottom: '75px'}}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Card
          header={header}
        >
          <Grid container spacing={3} className={css.container__information}>
            <Grid item xs={6}>
              {editCompany ? (
                <Select
                  id="Industry"
                  label="Industry"
                  {...register('industry_id')}
                  defaultValue={client?.industry?.id}
                  required
                >
                  {industries.map((industry) => (
                    <Select.Item value={industry.id} key={industry.id}>{industry.name}</Select.Item>
                  ))}
                </Select>
              ) : (
                <>
                  <Typography paragraph>Industry</Typography>
                  <Typography paragraph>{client?.industry?.name}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              {editCompany ? (
                <TextField
                  id="Website-URL"
                  label="Website URL"
                  defaultValue={client?.website}
                  {...register('website')}
                />
              ) : (
                <>
                  <Typography paragraph>Website URL</Typography>
                  <Typography paragraph>{client?.website}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              {editCompany ? (
                <Select
                  id="company-size"
                  label="Company Size"
                  {...register('employee_size_id')}
                  defaultValue={client?.employee_size?.id}
                  required
                >
                  {employee_sizes.map((company) => (
                    <Select.Item value={company.id} key={company.id}>{company.name}</Select.Item>
                  ))}
                </Select>
              ) : (
                <>
                  <Typography paragraph>Company Size</Typography>
                  <Typography paragraph>{client?.employee_size?.name}</Typography>
                </>
              )}
            </Grid>
            <Grid item xs={6}>
              {editCompany ? (
                <Select
                  id="annual-revenue"
                  label="Annual Revenue"
                  {...register('annual_revenue_id')}
                  defaultValue={client?.annual_revenue?.id}
                  required
                >
                  {annual_revenues.map((revenue) => (
                    <Select.Item value={revenue.id} key={revenue.id}>{revenue.name}</Select.Item>
                  ))}
                </Select>
              ) : (
                <>
                  <Typography paragraph>Annual Revenue</Typography>
                  <Typography paragraph>{client?.annual_revenue?.name}</Typography>
                </>
              )}

            </Grid>
          </Grid>
        </Card>
      </form>
    </Box>
  );
};

export default memo(CompanyOverview);
