/**
 * Update audit
 */

import { Projection } from 'api/entityGraphQL/types';
import { Audit } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments } from '..';

export type UpdateAuditMutation = {
  id?: number;
  audit_id?: number;
  audit_ids?: number[];
  strategy_id?: number;
  business_type_id?: number;
  industry_id?: number;
  lead_source_id?: number;
  pillar_id?: number;
  name?: string;
  status?: string;
  result?: string;
  notes?: string;
  create_discovery?: string;
  salesforce_opportunity_id?: string | null;
  salesforce_stage_id?: number | null;
  salesforce_type?: string | null;
  salesforce_reason?: string | null;
  salesforce_explanation?: string | null;
  salesforce_close_date?: string | null;
  salesforce_meeting_date?: string | null;
  salesforce_strategy_id?: number | null;
  projection?: Projection;
}

export type UpdateAuditResult = Audit;

export const { useUpdateAuditMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    updateAudit: build.mutation<UpdateAuditResult, UpdateAuditMutation>({
      transformResponse: (response: { updateAudit: Audit }) => response.updateAudit,
      invalidatesTags: [tagTypes.AUDITS],
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<UpdateAuditMutation> = values;
        return ({
          body: {
            mutation: {
              updateAudit: {
                __args: cleanArguments(__args),
                ...projection,
                id: true
              }
            }
          }
        }
        );
      }
    }),
  }),
});
