import React, { memo, useCallback, useEffect, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Button, ButtonProps, Drawer, DrawerFooter, DrawerView, PlusIcon, useForm, useSnackbar } from '@sprnova/nebula';
import { useCreatePacingGoalMutation } from 'api/crudGraphQL/scoreboards/pacing_goals/createPacingGoal';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { add, format } from 'date-fns';
import { useAccount } from 'features/global';
import { track } from 'features/scoreboards/components/mixpanelEvents';
import { useFilterContext } from 'features/scoreboards/utils/Filters';
import LongTermGoalForm, { LongTermFormType } from './LongTermGoalForm';

type CreateLongTermGoalDrawerProps = {
  open: boolean;
  onToggle: () => void;
  isLoading?: boolean;
  buttonProps?: ButtonProps;
  title?: string;
};
const CreateLongTermGoalDrawer = ({  open, onToggle, isLoading, buttonProps, title = 'New Long Term Goal' }: CreateLongTermGoalDrawerProps): JSX.Element => {
  const { addSnackbar } = useSnackbar();
  const { id } = useParams<{ [x: string]: string }>();
  const { filter, setFilter } = useFilterContext();
  const [submitting, setSubmitting] = useState(false);
  const mixpanel = useMixpanel();
  const { account } = useAccount();

  const [createPacingGoal] = useCreatePacingGoalMutation();

  const {
    control,
    reset,
    trigger,
    getValues,
    setValue,
    register,
    unregister,
    formState: { isValid },
  } = useForm<LongTermFormType>({
    defaultValues: {
      metric: {},
      goal: undefined,
      start_date: undefined,
      metrics: [],
    },
  });

  useEffect(() => {
    register('start_date', { required: 'This is a required field' });
    return (): void => unregister('start_date');
  }, [register, unregister]);

  const handleClearValues = useCallback(() => {
    reset();
    setValue('metric', undefined);
    setValue('total', undefined);
  }, [reset, setValue]);

  const handleSubmit = useCallback(async (e): Promise<void> => {
    e?.preventDefault();
    trigger();
    try {
      setSubmitting(true);
      const { metric, goal, metrics, start_date, total, typeOfGoal } = getValues();
      const valueArgs = {
        value: typeOfGoal === 'one' ? Number(goal) : undefined,
        term: typeOfGoal === 'one' ? total : undefined,
        start: start_date ? format(start_date, 'yyyy-MM-dd') : undefined,
        months: (typeOfGoal === 'month' && start_date) ? metrics.map((m, i: number) => ({ date: format(add(start_date, { months: i }), 'yyyy-MM-dd'), value: Number(m.goal) })) : undefined,
      };
      if (id && metric) {
        const result = await createPacingGoal({
          report_id: parseInt(id),
          type: 'long_term',
          metric: metric?.id,
          ...valueArgs,
        }).unwrap();
        addSnackbar({
          variant: 'success',
          message: 'Long term goal created',
          persist: false,
        });
        setFilter({ type: 'add', value: { long_term: { id: result?.id, type: 'create' } } });
      }
      onToggle();
      const { id: accountId, name } = account ?? {};
      track({ mixpanel, type: 'new-pacing-goal', options: {
        long_term_pacing_metric: metric?.id,
        long_term_pacing_id: filter?.long_term?.id,
        scoreboard_id: id,
        user_id: accountId,
        user_name: name,
      } });
    } catch (error) {
      addSnackbar({
        variant: 'error',
        message: 'Error creating long term goal',
        persist: false,
      });
      console.error('Error creating goal', error);
    } finally {
      handleClearValues();
      setSubmitting(false);
    }
  }, [account, addSnackbar, createPacingGoal, filter?.long_term?.id, getValues, handleClearValues, id, mixpanel, onToggle, setFilter, trigger]);

  const renderForm = useMemo(() => {
    return (
      <DrawerView
        component="form"
        onSubmit={handleSubmit}
        title='Create Long Term Goal'
        onClose={() => {
          handleClearValues();
          onToggle();
        }}
        footer={
          <DrawerFooter
            primaryButtonProps={{
              children: 'Save',
              type: 'submit',
              disabled: isLoading || submitting || !isValid,
            }}
            secondaryButtonProps={{
              children: 'Cancel',
              onClick: (): void => {
                handleClearValues();
                onToggle();
              },
              disabled: isLoading || submitting
            }}
          />
        }
      >
        <LongTermGoalForm control={control} trigger={trigger} setValue={setValue} />
      </DrawerView>
    );
  }, [handleSubmit, isLoading, submitting, isValid, control, trigger, setValue, handleClearValues, onToggle]);

  return (
    <>
      <Button
        variant='tertiary'
        size='large'
        startIcon={<PlusIcon />}
        onClick={onToggle}
        {...buttonProps}
        disabled={isLoading}
        sx={{width: '250px'}}
      >
        {title}
      </Button>
      <Drawer open={open} onClose={onToggle} isBlank>
        {renderForm}
      </Drawer>
    </>
  );
};

export default memo(CreateLongTermGoalDrawer);
