/**
 * TextEditor
 *
 * You can use this component to add a text editor to your form.
 *
 * Usage Notes:
 *
 * In order to capture the content of the texteditor in the parent component,
 * use the onChange prop. The onChange prop will return the html content of the text editor as a string.
 *
 */

import React, { Component, memo } from 'react';
import ReactQuill from 'react-quill';
import classnames from 'classnames';
import { User } from 'features/entitiesRedux';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention';
import Quill from 'quill';
import css from './TextEditor.module.scss';
const Delta = Quill.import('delta');
const Inline = Quill.import('blots/inline');

class StrikeBlot extends Inline {
  static blotName = 'strike';
  static tagName = 'del'; //strike format in odt file and google doc work only with del element
}
Quill.register(StrikeBlot, true);
type StyleType = 'color' | 'background' | 'bold' | 'italic' | 'underline' | 'strike';
type Props = ReactQuill.ReactQuillProps & {
  className?: string;
  disabled?: boolean;
  users?: Partial<User>[] | any;
  small?: boolean;
  toolbar?: boolean;
  bgColor?: string;
  toolbarHidden?: boolean;
  toolbarNoBorder?: boolean;
  removeStyles?: StyleType[];
  removeOnPaste?: StyleType[];
};

// Suppress warnings
Quill.debug('error');

type State = object;

/**
 * TODO add inline styles for both text and headers so that when the snippet is returned from the onChange event handler,
 * we can store and retrieve that snippet with the styles included in the snippet
 */
class TextEditor extends Component<Props, State> {
  quill: any = undefined;
  getModules = () => {
    const colors = [
      'black',
      'white',
      'rgb(255, 114, 114)', // brand
      '#4a1fff', // brand-salmon
      'rgb(255, 255, 0)', // yellow (highlight)
    ];
    const textStyleSection = ['bold', 'italic', 'underline', 'strike'];
    const filteredTextStyleSection  = textStyleSection.filter((x: any) => !this.props.removeStyles?.includes(x));
    const textColorSection = [{ color: colors }, { background: colors }];
    const filteredTextColorSections = textColorSection.filter((x: any) => !this.props.removeStyles?.includes(Object.keys(x)[0] as StyleType));

    return {
      toolbar: this.props.toolbar === false ? false : {
        container: [
          [{ header: [2, 3, false] }],
          filteredTextStyleSection,
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          filteredTextColorSections,
          ['link', 'image'],
          ['clean'],
        ],
        handlers: {
          image: this.imageHandler,
        },
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        source: this.mentionHandler,
      },
      clipboard: this.props.removeOnPaste ? {
        matchers: [
          [ Node.ELEMENT_NODE, (node: HTMLElement, delta: any) => {
            /**
             * This feature is used to strip styles when a user pasted.
             * It is useful for removing styles that are copied over from Asana
             * or Slack.
             * WARNING: It breaks mention (@) functionality; use removeStyles
             * instead if mention functionality is required.
             */
            const valuesToRemove: any = {};
            if (this.props.removeOnPaste) this.props.removeOnPaste.map((x: any) => valuesToRemove[x] = true);
            return delta.compose(new Delta().retain(delta.length(), valuesToRemove));
          }
          ]
        ]
      } : {}
    };
  };

  getFormats = () => {
    const formats = [
      'header',
      'bold',
      'italic',
      'underline',
      'strike',
      'blockquote',
      'list',
      'bullet',
      'indent',
      'link',
      'image',
      'color',
      'background',
    ];
    const filteredFormats = formats.filter((x: any) => !this.props.removeStyles?.includes(x));
    if (this.props.users) {
      return [...filteredFormats, 'mention'];
    }
    return filteredFormats;
  };

  imageHandler = () => {
    const range = this.quill.getEditorSelection();
    const value = prompt('Insert URL for the image');
    if (value) {
      this.quill.editor.insertEmbed(range.index, 'image', value);
    }
  };

  mentionHandler = async (searchTerm: any, renderList: any, mentionChar: any, onSelect: any) => {
    if (this.props.users) {
      let values;

      if (mentionChar === '@') {
        values = this.props.users.map((user: User) => ( { id: user.id, value: user.name } ));
      }

      if (searchTerm.length === 0) {
        renderList(values, searchTerm);
      } else {
        const matches = values.filter((value: any) => value.value.toLowerCase().includes(searchTerm.toLowerCase()));
        renderList(matches, searchTerm);
      }
    }
  };

  render() {
    const { className, disabled, small, bgColor, toolbarHidden, toolbarNoBorder, ...rest } = this.props;

    return (
      <ReactQuill
        ref={(node: any) => {
          this.quill = node;
        }}
        theme='snow'
        formats={this.getFormats()}
        modules={this.getModules()}
        bounds='.quill'
        className={
          classnames(
            css.root,
            className,
            {
              [css.disabled]: disabled,
              [css.small]: small,
              [css.toolbarHidden]: toolbarHidden,
              [css.toolbarNoBorder]: toolbarNoBorder,
            })}
        readOnly={disabled}
        style={{ background: bgColor }}
        {...rest}
      />
    );
  }
}

export default memo(TextEditor);
