/**
 * Entities -> Organizations -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { Organization } from './organization';
import { compareField } from '../../utils/compareField';

/**
  * Redux toolkit entity adapter for storing/caching entities by id
  */
export const organizationsAdapter = createEntityAdapter<Organization>({
  selectId: (organization) => organization.id,
  sortComparer: compareField('name'),
});
