import React, { memo, useCallback, useMemo, useReducer, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ActionMenu, Alert, AlertTitle, ArrowRightIcon, Button, Drawer, DrawerFooter, DrawerView, DropdownButton, EditIcon, useForm, useSnackbar } from '@sprnova/nebula';
import { useGetScoreboardQuery } from 'api/crudGraphQL/scoreboards/getScoreboard';
import { useUpdatePacingGoalMutation } from 'api/crudGraphQL/scoreboards/pacing_goals/updatePacingGoal';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import Trash from 'components/nebula/icons/Trash';
import { add, format, parseISO } from 'date-fns';
import { useAccount } from 'features/global';
import { track } from 'features/scoreboards/components/mixpanelEvents';
import { useFilterContext } from 'features/scoreboards/utils/Filters';
import LongTermGoalForm from './LongTermGoalForm';
import DeleteGoalModal from '../DeleteGoalModal';

type EditLongTermGoalDrawerProps = {
  open: boolean;
  onToggle: () => void;
  onAlertClick: () => void;
  isLoading: boolean;
};
const EditLongTermGoalDrawer = ({ open, onToggle, onAlertClick, isLoading }: EditLongTermGoalDrawerProps): JSX.Element => {
  const { addSnackbar } = useSnackbar();
  const { id } = useParams<{ [x: string]: string }>();
  const { filter, setFilter } = useFilterContext();
  const [openDeleteModal, toggleDeleteModal] = useReducer((state) => !state, false);
  const [submitting, setSubmitting] = useState(false);
  const mixpanel = useMixpanel();
  const { account } = useAccount();

  const { data } = useGetScoreboardQuery({
    id: parseInt(id),
    projection: {
      id: false,
      pacing_goals: {
        id: true,
        metric: true,
        metric_name: true,
        goal_value: true,
        start: true,
        data: {
          date: true,
          goal: true,
        }
      }
    },
    goalPacingArgs: {
      type: 'long_term',
      limit: 1,
      id: filter?.long_term?.id
    }
  }, { skip: !id || !filter?.long_term?.id });

  const [updatePacingGoal] = useUpdatePacingGoalMutation();

  const {
    control,
    reset,
    trigger,
    getValues,
    setValue,
  } = useForm<any>({
    defaultValues: {
      metric: '',
      goal: undefined,
      start_date: undefined,
      metrics: [],
    },
    values: {
      metric: {
        label: data?.pacing_goals?.[0]?.metric_name,
        id: data?.pacing_goals?.[0]?.metric,
      },
      goal: data?.pacing_goals?.[0]?.goal_value,
      total: data?.pacing_goals?.[0]?.data?.length,
      metrics: data?.pacing_goals?.[0]?.data?.map((m: any) => ({ goal: m.value })) || [],
      start_date: data?.pacing_goals?.[0]?.start && parseISO(data.pacing_goals[0].start),
      typeOfGoal: data?.pacing_goals?.[0]?.data && data?.pacing_goals?.[0]?.data?.length > 1 ? 'month' : 'one',
    }
  });

  const handleSubmit = useCallback(async (e: React.FormEvent<HTMLDivElement> | undefined): Promise<void> => {
    e?.preventDefault();
    trigger();
    try {
      setSubmitting(true);
      const { metric, goal, start_date, metrics, total, typeOfGoal } = getValues();
      const valueArgs = {
        value: typeOfGoal === 'one' ? parseInt(goal) : undefined,
        term: total,
        start: start_date ? format(start_date, 'yyyy-MM-dd') : undefined,
        months: (typeOfGoal === 'month' && start_date) ? metrics.map((m: any, i: number) => ({ date: format(add(start_date, { months: i }), 'yyyy-MM-dd'), value: parseInt(m.goal) })) : undefined,
      };
      const result = await updatePacingGoal({
        id: parseInt(filter?.long_term?.id),
        type: 'long_term',
        metric: metric.id,
        ...valueArgs,
      }).unwrap();
      addSnackbar({
        variant: 'success',
        message: 'Updated long term goal',
        persist: false,
      });
      setFilter({ type: 'add', value: { long_term: { id: result.id, type: 'edit' } } });
      reset();
      onToggle();
      const { id: accountId, name } = account ?? {};
      track({
        mixpanel, type: 'edit-pacing-goal', options: {
          long_term_pacing_metric: metric.id,
          long_term_pacing_id: filter?.long_term?.id,
          scoreboard_id: id,
          user_id: accountId,
          user_name: name,
        }
      });
    } catch (error) {
      addSnackbar({
        variant: 'success',
        message: 'Error updating long term goal',
        persist: false,
      });
      console.error('Error creating goal', error);
    } finally {
      setSubmitting(false);
    }
  }, [trigger, getValues, updatePacingGoal, filter?.long_term?.id, onToggle, addSnackbar, setFilter, reset, account, mixpanel, id]);

  const commonWidth = { minWidth: '250px', maxWidth: '250px' };

  const renderForm = useMemo(() => (
    <DrawerView
      component="form"
      onSubmit={handleSubmit}
      title='Edit Long Term Goal'
      onClose={onToggle}
      footer={
        <DrawerFooter
          primaryButtonProps={{
            children: 'Save',
            type: 'submit',
            disabled: isLoading || submitting
          }}
          secondaryButtonProps={{
            children: 'Cancel',
            onClick: (): void => {
              reset();
              onToggle();
            },
            disabled: isLoading || submitting
          }}
        />
      }
    >
      <Alert
        severity='warning'
        action={
          <Button
            className='tertiary'
            color='primary'
            endIcon={<ArrowRightIcon />}
            size='small'
            variant='outlined'
            sx={{ width: '240px' }}
            onClick={onAlertClick}
          >
            Create New Long Term Goal
          </Button>
        }
        sx={{ mb: 4 }}
      >
        <AlertTitle>
          This field is uneditable
        </AlertTitle>
        In order to change the Metric you must create a New Long Term Goal
      </Alert>
      <LongTermGoalForm control={control} trigger={trigger} defaultValues={data?.pacing_goals[0]} setValue={setValue} />
    </DrawerView>
  ), [handleSubmit, isLoading, submitting, control, onToggle, onAlertClick, trigger, data?.pacing_goals, setValue, reset]);

  return (
    <>
      <DropdownButton
        placement='bottom-end'
        variant='tertiary'
        size='large'
        startIcon={<EditIcon />}
        disabled={isLoading}
        label='Edit Long Term Goal'
        sx={{ ...commonWidth }}
      >
        <ActionMenu sx={{ ...commonWidth }}>
          <ActionMenu.Item
            startIcon={<EditIcon />}
            onClick={onToggle}
          >
            Edit Goal
          </ActionMenu.Item>
          <ActionMenu.Item
            startIcon={<Trash />}
            onClick={toggleDeleteModal}
          >
            Delete Goal
          </ActionMenu.Item>
        </ActionMenu>
      </DropdownButton>
      <Drawer open={open} onClose={onToggle} isBlank>
        {renderForm}
      </Drawer>
      <DeleteGoalModal
        open={openDeleteModal}
        toggle={toggleDeleteModal}
        type='long_term'
        goalId={filter?.long_term?.id}
      />
    </>
  );
};

export default memo(EditLongTermGoalDrawer);
