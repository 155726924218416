/**
 * Get Scoreboard (report) selector indicators
 * Returns a list of all indicators and their active status
 * for a given scoreboard
 */
import { Projection } from 'api/entityGraphQL';
import { YesNo } from 'features/entitiesRedux';
import { baseApi, cleanArguments } from '../..';
import tagTypes from '../tagTypes';

export type GetScoreboardSelectorIndicatorsQuery = {
  report_id: number;
  category?: string;
  projection?: Projection;
}

export type ScoreboardSelectorIndicator = {
  category: string;
  equation: string;
  format: string;
  formula: string;
  indicator_id: number;
  is_active: YesNo;
  name: string;
  order_display: number;
  settings: string;
  slug: string;
  tooltip: string;
  required_group: string;
};

export type GetScoreboardSelectorIndicatorsResult = ScoreboardSelectorIndicator[];

export const GET_SCOREBOARD_SELECTOR_INDICATORS_ENDPOINT_KEY = 'getScoreboardSelectorIndicators';

export const { useGetScoreboardSelectorIndicatorsQuery, util } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_SCOREBOARD_SELECTOR_INDICATORS_ENDPOINT_KEY]: build.query<GetScoreboardSelectorIndicatorsResult, GetScoreboardSelectorIndicatorsQuery>({
      transformResponse: (response: { report_selector_indicators: GetScoreboardSelectorIndicatorsResult}) => response.report_selector_indicators,
      providesTags: (indicators = []) => {
        return [
          tagTypes.SELECTOR_INDICATORS,
          ...(indicators ? indicators.map((indicator: ScoreboardSelectorIndicator) => ({
            id: indicator.indicator_id,
            type: tagTypes.SELECTOR_INDICATOR
          })) : [])
        ];
      },
      query: ({
        report_id,
        category,
        projection = { id: true },
      }) => {

        return {
          body: {
            query: {
              report_selector_indicators: {
                __args: cleanArguments({
                  report_id,
                  category,
                }),
                ...projection
              }
            }
          }
        };
      }
    }),
  }),
});
