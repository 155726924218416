import { EntityId } from '@reduxjs/toolkit';
import { RootState } from 'app/store';
import { datasourcesAdapter } from './adapter';
import { selectEntitiesDenormalized } from '../_entity/selectors';

export const datasourceSelectors = datasourcesAdapter.getSelectors(
  (state: RootState) => state.entities.data.datasources
);

export const selectAllDatasources = (state: RootState) => {
  const datasourcesIds = datasourceSelectors.selectIds(state);
  return selectDataSourcesByIds(datasourcesIds)(state);
};

export const selectDataSourcesByIds = (datasourceIds: EntityId[]) => (
  state: RootState
) => {
  const input = { datasources: datasourceIds };
  return selectEntitiesDenormalized(state, input).datasources;
};

export const selectDataSourcesById = (datasourceId: number) => (
  state: RootState
) => {
  return selectDataSourcesByIds([datasourceId])(state)[0];
};
