import React from 'react';

export const HourlyPricingIcon = () => (
  <svg width="112" height="112" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="20.2393" y="102.374" width="16" height="116.715" rx="8" transform="rotate(-134.624 20.2393 102.374)"
      fill="#D9D9D9"/>
    <rect x="51.1323" y="102.675" width="16" height="70.7602" rx="8" transform="rotate(-134.624 51.1323 102.675)"
      fill="#D9D9D9"/>
    <rect x="20.519" y="69.2256" width="16" height="63.4417" rx="8" transform="rotate(-134.624 20.519 69.2256)"
      fill="#D9D9D9"/>
    <rect x="26.2725" y="86.2715" width="60" height="60" rx="10" transform="rotate(-90.2601 26.2725 86.2715)"
      fill="#A94882"/>
    <rect x="41.1362" y="71.1357" width="30" height="30" rx="10" transform="rotate(-90.2601 41.1362 71.1357)"
      fill="#FFA600"/>
    <circle cx="22" cy="21" r="8" transform="rotate(-90 22 21)" fill="#4A1FFF"/>
    <circle cx="92" cy="88" r="8" transform="rotate(-90 92 88)" fill="#4A1FFF"/>
  </svg>

);
