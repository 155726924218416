/**
 * Entities -> Users -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { User } from './user';
import { compareField } from '../../utils/compareField';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const usersAdapter = createEntityAdapter<User>({
  selectId: (user) => user.id,
  sortComparer: compareField('name'),
});
