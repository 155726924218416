import { SurveyScoreType } from 'features/talent/constants';
import { TalentSurveyType } from 'features/talent/types';

/**
 * Get if the survey has comment boxes under each answer
 * @param {TalentSurveyType} surveyType
 * @param {string} scoreType
 * @returns boolean
 */
export const surveyHasCommentBox = (surveyType: TalentSurveyType, scoreType: string): boolean => {
  const surveyTypesSlugsWithCommentBox = ['manager']; // Surveys shared_slug that need to have comment boxes under each answer
  const requiredField = scoreType === SurveyScoreType.Regular;
  return surveyTypesSlugsWithCommentBox.includes(surveyType.shared_slug) && requiredField;
};
