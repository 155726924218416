/**
 * DiscoveryForm -> SectionBuilder
 */


import React, { FC, useMemo } from 'react';
import { FormInstance } from 'antd/lib/form';
import { Topic, Question } from 'features/audits/auditsSlice';
import { ScrollForm } from 'components';
import { ConditionalField } from './components';
import * as fieldComponents from './fields';
import { FieldProps } from './types';
import { DiscoveryFormValues } from '../../useInitialValues';
const components: { [key: string]: FC<FieldProps>; } = fieldComponents;

// Check if this field is a "other" / conditional field
// It should only be rendered if another related field value is "other"
// See the <ConditionalField> for more info
const isConditionalField = (fieldName: string) => {
  const name = fieldName || '';
  // Should start with 'question_' and end with '_other'
  return name.includes('question_') && name.indexOf('_other') - name.length === -6;
};

/**
 * FieldRenderer: Renders the fields for a question
 */

type FieldsRendererProps = {
  fields: string[];
  type: string;
  name: string;
  options: string[];
  format: string;
  placeholder: string;
  form: FormInstance;
  onValuesChange: (changedValues: DiscoveryFormValues) => Promise<void>;
};

const FieldsRenderer: FC<FieldsRendererProps> = ({
  type,
  fields,
  name,
  options,
  form,
  format,
  placeholder, onValuesChange
}) => {
  const Component = components[type] || components.not_found;

  return (
    <Component
      type={type}
      fields={fields}
      name={name}
      options={options}
      form={form}
      format={format}
      placeholder={placeholder}
      onValuesChange={onValuesChange}
    />
  );
};

/**
 * QuestionRenderer: Renders the questions of a topic
 */

type QuestionRendererProps = {
  questions: Question[];
  form: FormInstance;
  onValuesChange: (changedValues: DiscoveryFormValues) => Promise<void>;
};

const QuestionsRenderer: FC<QuestionRendererProps> = ({ questions, form, onValuesChange }) => {
  const renderQuestions = useMemo(() => {
    const render = ({ 
      question: label,
      subtext,
      id,
      fields,
      type,
      group_name,
      options,
      format,
      placeholder 
    }: Question) => {
      const renderBlock = (
        <ScrollForm.Block key={id} label={label} subtext={subtext} groupName={group_name}>
          <FieldsRenderer
            type={type}
            fields={fields}
            name={group_name}
            options={options}
            form={form}
            onValuesChange={onValuesChange}
            format={format}
            placeholder={placeholder}
          />
        </ScrollForm.Block>
      );

      if (isConditionalField(group_name)) {
        return (
          <ConditionalField name={group_name} key={id}>
            {renderBlock}
          </ConditionalField>
        );
      }

      return renderBlock;
    };

    return questions?.map(render);
  }, [questions, form]);

  return <>{renderQuestions}</>;
};

/**
 * SectionBuilder: Builds out the sections from an array of topics
 */

type SectionBuilderProps = {
  topics: Topic[];
  form: FormInstance;
  onValuesChange: (changedValues: DiscoveryFormValues) => Promise<void>;
};

const SectionBuilder: FC<SectionBuilderProps> = ({ topics, form, onValuesChange }) => {
  const renderSections = useMemo(() => {
    return topics?.filter(t => t?.questions?.length)?.map(topic => {
      const { slug, name, questions, count, total } = topic;
      const activeQuestions = questions?.filter(q => q.is_active === 'yes');
      return (
        <ScrollForm.Section
          id={slug}
          key={slug}
          label={name}
          progress={[count, total]}
        >
          <QuestionsRenderer
            questions={activeQuestions}
            form={form}
            onValuesChange={onValuesChange}
          />
        </ScrollForm.Section>
      );
    });
  }, [topics, form]);

  return <>{renderSections}</>;
};

export default SectionBuilder;