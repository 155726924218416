import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button } from '@sprnova/nebula';
import { useDataConnectorActions } from 'features/clients/ClientIntegrationsPage/components/DataAccessRequestsTab/components/FivetranIntegrationRequestList/components/components/hooks/useDataConnectorActions';
import ConfirmationDialog from 'features/clients/ClientIntegrationsPage/components/ManageIntegrationsTab/components/CloudIntegrationTableRow/components/ConfirmationDialog';
import { LoadingIndicator } from 'features/clients/ClientIntegrationsPage/components/ManageIntegrationsTab/components/StyledComponents';
import { Client } from 'features/entitiesRedux/models/client';
import { ExternalClient } from 'features/entitiesRedux/models/external_client';
import PinterestAttributionDropdown from './PinterestAdsDropdown';
import { usePinterestLogic } from './usePinterestLogic';

type PinterestAdsDialogProps = {
  /** The client information, if available. */
  client?: Client | ExternalClient;
  /** A boolean indicating if the modal should be open. */
  openModal?: boolean;
  /** A boolean used to control whether the button is rendered. */
  renderButton?: boolean;
  /** Action triggered on close state. */
  onClose?: () => void;
  /** The ID of the access request. */
  accessRequestId?: string;
  /** The ID of the user. */
  userId?: number;
  /** The content or children to render inside the button. */
  buttonChildren?: string | React.ReactNode;
};


/**
 * PinterestAdsDialog is a React component that renders a modal dialog for configuring the Pinterest Ads integration.
 * @returns {JSX.Element} The rendered component.
 */
const PinterestAdsDialog: React.FC<PinterestAdsDialogProps> = ({
  client,
  openModal = false,
  renderButton = true,
  onClose,
  accessRequestId,
  userId,
  buttonChildren = 'Connect'
}) => {
  const PINTEREST_SLUG = 'paid-pinterest';

  // State for managing dialog
  const [dialogOpen, setDialogOpen] = useState<boolean>(false);
  // Custom hook to handle logic creating connector
  const { handleConnect, isLoading } = useDataConnectorActions(accessRequestId, userId);

  // Sync dialog visibility state with openModal prop
  useEffect(() => {
    setDialogOpen(openModal);
  }, [openModal]);

  // Extract the title for the dialog from the Pinterest logic hook
  const { pinterestDialogTitle, defaultAttribution } = usePinterestLogic();

  // State to keep track of the selected value in the dropdown
  const [selectedDropdownValue, setSelectedDropdownValue] = useState<string>(defaultAttribution);

  // State to determine if the dropdown should be disabled
  const [isDropdownDisabled, setIsDropdownDisabled] = useState<boolean>(true);

  useEffect(() => {
    // Check if a fivetran_connector with datasource_id 1009 (pinterest ads) exists
    const hasConnector = client?.fivetran_connectors?.some(connector => connector.data_source_id === 1009);
    setIsDropdownDisabled(!hasConnector); // Enable the dropdown if the connector exists
  }, [client]);

  // Render dialog content with specific instructions for Pinterest Ads
  const renderPinterestDialogContent = useMemo(() => (
    <div>
      <p>The Pinterest Ads integration requires that you select an Attribution Window before connecting. The format for the Attribution Windows are as follows:</p>
      <ul>
        <li>Click, Engagement, Views (in days)</li>
        <li><strong>Ex:</strong> &quot;30, 30, 1&quot; means &quot;30-day Click, 30-day Engagement, 1-day View&quot;</li>
      </ul>
      <p>If you’re setting up the connection for the first time, you must use the 7, 1, 1 Attribution Window during the initial setup. If a different Attribution Window is needed, please proceed with setting up 7, 1, 1 first, then come back to this page to add an additional Attribution Window.</p>
    </div>
  ), []);

  // Call this when the confirmation action is triggered
  const handleConfirm = useCallback(() => {
    // Call the onConfirm prop with the selected value or a default if the dropdown is disabled
    if(isDropdownDisabled) {
      /**
       * default attribution is 7/1/1
       * for more info: https://fivetran.com/docs/rest-api/api-reference/api-configuration/connector-configuration#configparameters_413
       */
      handleConnect(client?.group_id, PINTEREST_SLUG, defaultAttribution);
    } else {
      handleConnect(client?.group_id, PINTEREST_SLUG, selectedDropdownValue);
    }
    setDialogOpen(false); // Then close the dialog
  }, [client?.group_id, defaultAttribution, handleConnect, isDropdownDisabled, selectedDropdownValue]);

  // Function called when dialog is closed
  const handleOnClose = useCallback(() => {
    setDialogOpen(false);
    if (onClose) onClose();
  },[onClose]);

  // Loading state
  if(isLoading) return <LoadingIndicator />;

  return (
    <>
      {renderButton &&
        <Button onClick={() => setDialogOpen(true)} variant='primary' color='primary' size='small'>
          {buttonChildren}
        </Button>
      }

      <ConfirmationDialog
        open={dialogOpen}
        onClose={handleOnClose}
        onConfirm={handleConfirm}
        title={pinterestDialogTitle}
        content={renderPinterestDialogContent}
        confirmLabel='Connect'
        size='sm'
      >
        <PinterestAttributionDropdown
          defaultAttribution={defaultAttribution}
          onAttributionChange={(newAttribution): void => {
            setSelectedDropdownValue(newAttribution);
          }}
          disabled={isDropdownDisabled}
        />
      </ConfirmationDialog>
    </>
  );
};

export default PinterestAdsDialog;
