import React from 'react';
import { ListItem, ListItemAvatar} from '@mui/material';
import { DonutValue } from './DonutValue';
import { NonPercentageValue } from './NonPercentageValue';
import { listItemStyles, listItemAvatarIndexStyles, listItemAvatarValueStyles } from './WidgetSubCard.styles';


export interface WidgetSubCardType {
  index?: number,
  statistics?: string | number | null,
  valueType?: string,
  color?: string,
  children?: JSX.Element,
  height?: string,
}

export const WidgetSubCard = ({
  index = 1,
  statistics,
  valueType,
  color,
  children,
  height
}: WidgetSubCardType): JSX.Element => {
  return (
    <ListItem sx={listItemStyles({ index })}>
      <ListItemAvatar sx={listItemAvatarIndexStyles}>
        {index}
      </ListItemAvatar>
      <ListItemAvatar sx={listItemAvatarValueStyles}>
        {valueType === '%' ?
          <DonutValue value={Number(statistics)} color={color}/> :
          <NonPercentageValue value={Number(statistics)} valueType={valueType}/>}
      </ListItemAvatar>
      {children}
    </ListItem>
  );
};

