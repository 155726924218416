import { useSelector } from 'react-redux';
import { fetchContactsPaginated, fetchContactById } from './actions';
import { Contact } from './contact';
import { selectContactsByIds, selectContactById } from './selectors';
import { useEntitiesPaginated, useEntityByParamId } from '../_entity/hooks';
import { HookPaginatedConfig, HookPaginatedReturn } from '../types';

export const useContactsPaginated = ({
  page: defaultPage,
  limit: defaultLimit,
  filter: defaultFilter,
  projection,
  trackHistory = true,
}: HookPaginatedConfig = {}): HookPaginatedReturn & {
  contacts: Partial<Contact>[];
} => {
  const {
    entities: { contacts: contactIds },
    isLoading,
    error,
    pagination,
    filter,
    setFilter,
  } = useEntitiesPaginated(fetchContactsPaginated, {
    page: defaultPage,
    limit: defaultLimit,
    filter: defaultFilter,
    projection,
    trackHistory,
  });
  const contacts = useSelector(selectContactsByIds(contactIds));

  return { contacts, isLoading, error, pagination, filter, setFilter };
};

export const useContactByParamId = () =>
  useEntityByParamId(selectContactById, fetchContactById);
