import { Projection } from 'api/entityGraphQL';
import { IntegrationRequest  } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi } from '..';

export type UpdateClientIntegrationRequestMutation = {
  id: string;
  status: string;
  completed_by?: number;
  projection?: Projection;
}

export type UpdateClientIntegrationRequestResult = IntegrationRequest;

/**
 * The mutation input type for updating a client integration request.
 *
 * @param {UpdateClientIntegrationRequestMutation} args - The arguments for the mutation.
 * - `id` (string): The ID of the integration request to update.
 * - `status` (string): The new status of the integration request.
 * - `completed_by` (number | undefined): The ID of the user who completed the request.
 * - `projection` (Projection | undefined): A Projection object specifying which fields to include in the response.
 *`
 * @returns {IntegrationRequest} The updated IntegrationRequest object.
 *
 * @example
 * const [updateRequest, { data, error, isLoading }] = useUpdateClientIntegrationRequestMutation();
 * updateRequest({ id: '123', status: 'completed', completed_by: 456 });
 */
export const { useUpdateClientIntegrationRequestMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    updateClientIntegrationRequest: build.mutation<UpdateClientIntegrationRequestResult, UpdateClientIntegrationRequestMutation>({
      query: ({
        projection = { id: true},
        id,
        status,
        completed_by
      }) => (
        {
          body: {
            mutation: {
              updateClientIntegrationRequest: {
                __args: {
                  id,
                  status,
                  completed_by
                },
                ...projection,
              }
            }
          }
        }
      )
    })
  })
});
