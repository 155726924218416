import React, { FC, Suspense, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { AppLayout } from '@sprnova/nebula';
import { Role } from 'api/accessControl';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import { PageSkeleton } from 'components/Skeleton/components/PageSkeleton';
import { Footer } from 'layouts/components/Footer';
import ProtectedBaseLayout from 'layouts/ProtectedBaseLayout/ProtectedBaseLayout';
import { useAccount } from 'features/global';
import { Sidebar } from './components';
import { Routes, Header } from '../components';

export const NebulaLayout: FC = () => {
  const { account } = useAccount();

  const location = useLocation();

  // Mixpanel
  const mixpanel = useMixpanel();
  useEffect(() => {
    if (account) {
      /**
       * This is required to set the user's identity and profile properties
       * Without it, users will show up as "(not set)" in Mixpanel
       */
      // Set Identity
      if (mixpanel?.identify && account?.id) mixpanel.identify(String(account?.id));

      const isClient = (account?.roles || []).includes(Role['client']);
      const isAnonymous = (account?.roles || []).includes(Role['anonymous']);
      const anonymousEmail = 'anonymous@novapower.io';
      const anonymousName = 'Anonymous';
      const email = !isAnonymous ? account?.email : anonymousEmail;
      const name = !isAnonymous ? account?.name : anonymousName;

      // Set profile properties
      const options = {
        $email: email, // only reserved properties need the $
        $created: account?.created_at, // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
        $name: name,
        $avatar: account?.avatar,
        is_client: isClient.toString(),
        is_anonymous: isAnonymous.toString(),
        department: account?.department?.name,
        departmentId: account?.department?.id,
        departmentSlug: account?.department?.slug,
        organization: account?.organization?.name,
        organizationId: account?.organization?.id,
        organizationSlug: account?.organization?.slug,
      };

      const doSetPeople =
        mixpanel?.people?.set &&
        !!account &&
        Object.prototype.hasOwnProperty.call(account || {}, 'id');

      if (doSetPeople) mixpanel.people.set(options);
    }
  }, [account, mixpanel]);

  return (
    <ProtectedBaseLayout>
      <AppLayout
        header={<Header />}
        sidebar={<Sidebar />}
        footer={!location.pathname.includes('/insights-ai') ? <Footer /> : null}
      >
        <Suspense fallback={<PageSkeleton />}>
          <Routes />
        </Suspense>
      </AppLayout>
    </ProtectedBaseLayout>
  );
};
