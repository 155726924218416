/**
 * Create scoreboard (report)
 */

import { Projection } from 'api/entityGraphQL/types';
import tagTypes from './tagTypes';
import { Scoreboard } from './types';
import { baseApi, cleanArguments } from '..';
import clientTagTypes from '../clients/tagTypes';

export type CreateScoreboardMutation = {
  client_id: number;
  name?: string;
  business_type_id?: number;
  website?: string;
  logo?: string;
  indicator_ids?: number[];
  range?: string;
  excluded_data_source_ids?: number[];
  projection?: Projection;
}

export type CreateScoreboardResult = Scoreboard;

export const CREATE_SCOREBOARD = 'createScoreboard';

export const { useCreateScoreboardMutation } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [CREATE_SCOREBOARD]: build.mutation<CreateScoreboardResult, CreateScoreboardMutation>({
      transformResponse: (response: { createScoreboard: Scoreboard }) => response.createScoreboard,
      invalidatesTags: [
        tagTypes.SCOREBOARDS,
        clientTagTypes.CLIENTS,
        clientTagTypes.CLIENT,
      ],
      query: ({ projection = {}, ...values }) => {
        const __args: Partial<CreateScoreboardMutation> = values;
        return ({
          body: {
            mutation: {
              createReport: {
                __args: cleanArguments(__args),
                ...projection,
                id: true
              }
            }
          }
        }
        );
      }
    }),
  }),
});
