/**
 * Get indicators
 */
import { Projection } from 'api/entityGraphQL/types';
import { BusinessType, IndicatorData, IndicatorPlatform } from 'features/entitiesRedux';
import tagTypes from './tagTypes';
import { baseApi, cleanArguments } from '..';
import { Scoreboard } from '../scoreboards/types';

export type Indicator = {
  id: number;
  business_types: BusinessType[];
  category: string;
  compared_value: number;
  data: IndicatorData[];
  date: string;
  equation: string;
  format: string;
  is_filterable: string;
  min_date: string;
  name: string;
  platform: IndicatorPlatform[];
  settings: string;
  slug: string;
  tooltip: string;
  value: number;
  widgets: Scoreboard['widgets'];
  created_at: string;
  updated_at: string;
};

export type GetIndicatorsQuery = {
  id?: number;
  name?: string;
  business_type_id?: number;
  goal_pacing?: string;
  projection?: Projection;
};

export type GetIndicatorsResult = Indicator[];

export const GET_INDICATORS_ENDPOINT_KEY = 'getIndicators';

export const { useGetIndicatorsQuery } = baseApi.injectEndpoints({
  endpoints: (build) => ({
    [GET_INDICATORS_ENDPOINT_KEY]: build.query<GetIndicatorsResult, GetIndicatorsQuery>({
      transformResponse: (response: { indicators: GetIndicatorsResult }) => response.indicators,
      providesTags: (result) => {
        return [tagTypes.INDICATORS];
      },
      query: ({
        projection = { id: true },
        name,
        ...args
      }) => {
        const __args: Partial<GetIndicatorsQuery> = {
          ...args
        };

        if (name) {
          __args.name = `*${name}*`;
        }

        return {
          body: {
            query: {
              indicators: {
                __args: cleanArguments(__args),
                ...projection,
              }
            }
          }
        };
      }
    })
  })
});
