import React, { useEffect, useState, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { Skeleton } from 'antd';
import { formatErrorToHumanReadable } from 'utils';
import { syncNovaToSalesforce, salesforceDataToSync } from 'features/entitiesRedux';
import { Modal, notification, Table, Tooltip } from 'components';
import { separateArrayByType } from './helpers';
import css from './SalesforceSyncModal.module.scss';

interface SalesforceSyncModalProps {
  id?: number;
  loading?: boolean;
  open?: boolean;
  sync?: boolean;
  salesforceOpportunityId?: string | null;
  salesforceIsPrimary?: boolean;
  setOpenSyncModal?: React.Dispatch<React.SetStateAction<boolean>>;
  refetch?: () => void;
}
export const SalesforceSyncModal = ({ id = 0, loading, open, setOpenSyncModal, sync, salesforceOpportunityId, salesforceIsPrimary, refetch }: SalesforceSyncModalProps) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>();
  const [disabled, setDisabled] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [dataSource, setDataSource] = useState<Partial<{ from: ReactNode, to: ReactNode, field: ReactNode }>[]>([
    {
      field: <Skeleton.Input size='small' active />,
      from: <Skeleton.Input size='small' active />,
      to: <Skeleton.Input size='small' active />
    }
  ]);

  useEffect(() => {
    if (id && salesforceOpportunityId && salesforceIsPrimary) {
      (async () => {
        const resultAction: any = await dispatch(salesforceDataToSync(String(id)));

        if (salesforceDataToSync.fulfilled.match(resultAction)) {
          setDisabled(false);
          setError(null);
          const source = separateArrayByType(resultAction?.payload?.result);
          setDataSource(source);
        }

        if (salesforceDataToSync.rejected.match(resultAction)) {
          const { error } = resultAction;
          const message = error.message && formatErrorToHumanReadable(error.message);
          setTimeout(() => setError(message), 3000);
          setDataSource([]);
          setDisabled(true);
        }
      })();
    }
  }, [id, open]);

  const handleSync = async () => {
    if (id) {
      setDisabled(true);
      setSubmitting(true);
      const resultAction: any = await dispatch(syncNovaToSalesforce(id));

      if (syncNovaToSalesforce.fulfilled.match(resultAction)) {
        notification.success({
          message: 'Successfully synced nova to Salesforce'
        });
        /** Refetch strategy */
        if (refetch) refetch();
      }

      if (syncNovaToSalesforce.rejected.match(resultAction)) {
        notification.error({
          message: 'Error syncing nova to Salesforce'
        });
      }
      if (setOpenSyncModal) setOpenSyncModal(false);
      setDisabled(false);
      setSubmitting(false);
    }
  };

  const createTooltip = (value: string | number) => {
    return <Tooltip placement='topLeft' title={value}>{value}</Tooltip>;
  };

  const columns = [
    { title: 'Field', dataIndex: 'field', key: 'field' },
    {
      title: 'From',
      dataIndex: 'from',
      key: 'from',
      ellipsis: { showTitle: true },
      render: (value: string | number) => createTooltip(value)
    },
    {
      title: 'To',
      dataIndex: 'to',
      key: 'to',
      ellipsis: { showTitle: true },
      render: (value: string | number) => createTooltip(value)
    },
  ];
  return (
    <Modal
      visible={open}
      destroyOnClose
      okText={submitting ? 'Please wait...' : 'Confirm and sync'}
      okButtonProps={{
        disabled: disabled
      }}
      onOk={handleSync}
      onCancel={() => setOpenSyncModal && setOpenSyncModal(false)}
      width={600}
      title={
        <div className={css.titleContainer}>
          <div className={css.titleContainer_title}>Confirm your request</div>
          <div className={css.titleContainer_subtitle}>The following data will be synced by Salesforce:</div>
        </div>
      }
    >
      {
        error ?
          <div className={css.error}>{error}</div> :
          <Table dataSource={dataSource} columns={columns} loading={loading} />
      }
    </Modal>
  );
};
