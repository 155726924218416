import { createAsyncThunk } from '@reduxjs/toolkit';
import { novaGraphQLClient, Pagination, Projection, updateClientMutation } from 'api/entityGraphQL';
import { filterUndefinedNullEmpty } from 'api/entityGraphQL/utils';
import { normalizeEntities } from 'features/entitiesRedux/utils';
import { Dashboard, DashboardAdditional, FetchDashboardsFilter, Note } from './dashboard';
import { selectAllDashboards, selectDashboardById } from './selectors';
import { Client } from '../client';
import { ActionFetchManyPaginatedConfig } from '../types';


/**
 * Fetch dashboard by ID
 */
export const fetchDashboardById = createAsyncThunk(
  'entities/fetchDashboardById',
  async ({ id, client_id, projection }: { id?: number; client_id?: number; projection?: Projection; }): Promise<any> => {
    const { dashboards } = await novaGraphQLClient.fetchDashboardById({
      id,
      client_id,
      projection
    });
    return {
      ...normalizeEntities({ dashboards })
    };
  }
);

/**
 * Fetch dashboards
 */
export const fetchDashboards = createAsyncThunk(
  'entities/fetchDashboards',
  async ({ pagination, projection }: { pagination?: Pagination; projection?: Projection }): Promise<any> => {
    const { dashboards, ...rest } = await novaGraphQLClient.fetchDashboards({ args: {}, pagination, projection });
    return {
      ...normalizeEntities({ dashboards }),
      pagination: rest
    };
  }
);

/**
 * Fetch dashboards paginated
 */
export const fetchDashboardsPaginated = createAsyncThunk(
  'entities/fetchDashboardsPaginated',
  async ({
    filter,
    pagination,
    projection
  }: ActionFetchManyPaginatedConfig & {
    filter?: FetchDashboardsFilter;
  }): Promise<ActionFetchManyPaginatedConfig> => {
    const {
      id,
      client_id,
      dashboard_pace_ids,
      dashboard_rapport_ids,
      dashboard_churn_ids,
      account_manager_ids,
      executive_sponsor_ids,
      notice_date,
      referrals,
      organization_id,
      is_active,
      page,
      limit,
      sort
    } = filter ?? {};
    const { dashboards, ...paginatedResponse } = await novaGraphQLClient.fetchDashboards({
      args: {
        ...filter,
        id,
        client_id,
        dashboard_pace_ids,
        dashboard_rapport_ids,
        dashboard_churn_ids,
        account_manager_ids,
        executive_sponsor_ids,
        notice_date,
        referrals,
        organization_id,
        is_active,
        page,
        limit,
        sort
      },
      pagination,
      projection
    });
    return {
      ...normalizeEntities({ dashboards }),
      pagination: paginatedResponse
    };
  }
);

/** Create dashboard */
export const createDashboard = createAsyncThunk(
  'entities/createDashboard',
  async ({
    client_id,
    is_active,
    dashboard_pace_id,
    dashboard_rapport_id,
    dashboard_churn_id,
    escalation,
    contract,
    notice_date,
    start_date,
    end_date,
    retainer,
    renewal_terms,
    monthly_goal_forecast,
    referrals,
    organization_id,
  }: Partial<DashboardAdditional> & Partial<Dashboard>) => {
    const args = {
      client_id,
      is_active,
      dashboard_pace_id,
      dashboard_rapport_id,
      dashboard_churn_id,
      escalation,
      contract,
      notice_date,
      start_date,
      end_date,
      retainer,
      renewal_terms,
      monthly_goal_forecast,
      referrals,
      organization_id,
    };
    if (client_id) {
      const response = await novaGraphQLClient.createDashboard(args);
      return { ...normalizeEntities(response) };
    }
  }
);

/** Update dashboard */
export const updateDashboard = createAsyncThunk(
  'entities/updateDashboard',
  async ({
    id,
    is_active,
    dashboard_pace_id,
    dashboard_rapport_id,
    dashboard_churn_id,
    escalation,
    comment,
    contract,
    notice_date,
    start_date,
    end_date,
    retainer,
    renewal_terms,
    monthly_goal_forecast,
    referrals,
    organization_id,
  }: Partial<DashboardAdditional> & Partial<Dashboard>) => {
    const args = {
      id,
      is_active,
      dashboard_pace_id,
      dashboard_rapport_id,
      dashboard_churn_id,
      escalation,
      comment,
      contract,
      notice_date,
      start_date,
      end_date,
      retainer,
      renewal_terms,
      monthly_goal_forecast,
      referrals,
      organization_id,
    };
    if (id) {
      const response = await novaGraphQLClient.updateDashboard(filterUndefinedNullEmpty(args));
      return { ...normalizeEntities(response) };
    }
  }
);

/** Update client */
export const updateClient = createAsyncThunk(
  'entities/updateClient',
  async (client: Partial<Client> & { id: number }) => {
    const response = await updateClientMutation({
      id: client.id,
      account_manager_id: client?.account_manager_id,
      executive_sponsor_id: client?.executive_sponsor_id,
    }, { projection: { id: true, account_manager: { id: true, name: true, avatar: true } } });
    return { ...normalizeEntities(response) };
  }
);

/** Fetch notes */
// export const fetchDashboardNotes = createAsyncThunk(
//   'entities/fetchDashboardNotes',
//   async ({
//     id,
//     dashboard_id,
//     date,
//     date_start,
//     date_end,
//     author_id,
//     pagination,
//     projection
//   }: {
//     id?: number;
//     dashboard_id?: number;
//     date?: string;
//     date_start?: string;
//     date_end?: string;
//     author_id?: number;
//     pagination?: Pagination;
//     projection?: Projection;
//   }): Promise<any> => {
//     const { dashboard_notes, ...rest } = await novaGraphQLClient.fetchDashboardNotes({ args: { id, dashboard_id, date, date_start, date_end, author_id }, pagination, projection });
//     return {
//       ...normalizeEntities({ dashboard_notes }),
//       pagination: rest
//     };
//   }
// );

/** Create note */
export const createDashboardNote = createAsyncThunk(
  'entities/createDashboardNote',
  async (note: Partial<Note> & { dashboard_id: number; parent_id?: number; author_id: number }) => {
    const response = await novaGraphQLClient.createDashboardNote({
      dashboard_id: note.dashboard_id,
      parent_id: note.parent_id,
      author_id: note.author_id,
      title: note.title,
      text: note.text,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Update note */
export const updateDashboardNote = createAsyncThunk(
  'entities/updateDashboardNote',
  async (note: Partial<Note> & { id: number; date?: string; text: string }) => {
    const response = await novaGraphQLClient.updateDashboardNote({
      id: note.id,
      date: note.date,
      text: note.text,
    });
    return { ...normalizeEntities(response) };
  }
);

/** Delete note */
export const deleteDashboardNote = createAsyncThunk(
  'entities/deleteDashboardNote',
  async ({ dashboardId, id }: { dashboardId: number; id: number; }, { getState }: { getState: any }) => {
    await novaGraphQLClient.deleteDashboardNote({ id });
    const dashboard = selectDashboardById(dashboardId)(getState());
    const dashboards = selectAllDashboards(getState());

    const filtered = () => {
      const isParent = !!dashboard?.notes?.filter((note: Note) => note.id === id)?.length;
      if (isParent) {
        return dashboard?.notes?.filter((note: Note) => note.id !== id);
      } else {
        const notes = dashboard?.notes?.filter((note: Note) => note.id === dashboardId)[0];
        notes?.children?.filter((child: any) => child.id !== id);
        return notes;
      }
    };
    dashboards.filter((x: any) => x.id === dashboardId)[0].notes = filtered();

    return { ...normalizeEntities({ dashboards }) };
  }
);
