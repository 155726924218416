import React, { useCallback, memo } from 'react';
import { Box, NavigationInsightsFilledIcon, Typography } from '@sprnova/nebula';
import { useGetMePublicQuery } from 'api/crudGraphQL/public/me/getMePublic';
import { useMixpanel } from 'components/MixpanelProvider/hooks/useMixpanel';
import useScrollPosition from 'utils/hooks/useScrollPosition';
import { ExternalClient } from 'features/entitiesRedux';
import NovaGptButton from '../../../NovaGptButton/NovaGptButton';

type NovaGptBannerProps = {
  client?: ExternalClient;
  scrollThreshold?: number;
  page?: string;
}

const NovaGptBanner = ({ client, scrollThreshold = 30, page }: NovaGptBannerProps): JSX.Element  => {
  const { data: account } = useGetMePublicQuery({
    projection: {
      id: true,
      name: true,
      email: true,
    }
  });
  const mixpanel = useMixpanel();
  const scrollPosition = useScrollPosition();

  const handleTrackButtonClick = useCallback((): void => {
    try {
      const trackingName = 'novaGPT Bottom Banner novaGPT Click';
      const options = {
        clientId: client?.id,
        clientName: client?.name,
        accountId: account?.id,
        accountName: account?.name,
        accountEmail: account?.email,
        isPdmEmployee: true,
        page: page

      };
      if (process.env.NODE_ENV !== 'production') console.log(`🛤 Track: ${trackingName}`, options);
      if (mixpanel?.track) {
        mixpanel.track(trackingName, options);
      }
    } catch (error) {
      console.error('Mixpanel error', error);
    }
  },[account?.email, account?.id, account?.name, client?.id, client?.name, mixpanel, page]);

  const showBanner = scrollPosition >= scrollThreshold;
  return (
    <Box sx={{
      background: 'linear-gradient(270deg, #A795EF 0%, #795BF7 20.5%, #6947FA 40%, #4A1FFF 100%)',
      boxShadow: '0px 4px 8px 3px rgba(0, 0, 0, 0.15), 0px 1px 3px 0px rgba(0, 0, 0, 0.20)',
      borderRadius: '1em',
      color: 'white',
      position: 'sticky',
      bottom: '5%',
      padding: '20px',
      width: '80%',
      margin: '16px auto 0',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      transition: 'all .3s',
      opacity: showBanner ? 1 : 0,

    }}>
      <div style={{display: 'flex', alignItems: 'center', width: '70%'}}>
        <NavigationInsightsFilledIcon />
        <div style={{marginLeft: '16px'}}>
          <Typography fontSize="1rem">Chat with your data with novaGPT</Typography>
          <Typography fontSize="0.75rem">Get insights instantly and boost your marketing success.</Typography>
        </div>
      </div>
      <div style={{display: 'flex', alignItems: 'center'}}>
        <div
          style={{marginRight: '16px'}}>
          <NovaGptButton size='small' hideIcon variant='plain' handleMixPanelEvent={handleTrackButtonClick} />
        </div>
        <NavigationInsightsFilledIcon />
      </div>
    </Box>
  );
};

export default memo(NovaGptBanner);
