import React, { useEffect, useMemo, memo } from 'react';
import { ClockCircleOutlined } from '@ant-design/icons';
import { styled } from '@mui/material';
import { WarningIcon } from '@sprnova/nebula';
import { Col, Popover as AntdPopover, Row } from 'antd';
import classNames from 'classnames';
import isExternalUser from 'utils/helpers/isExternalUser';
import { Report } from 'features/entitiesRedux';
import { titleChange } from 'features/reports/ReportTabs/components/utils/titleChange';
import { TooltipPopover, Popover } from 'components';
import { Value, Platform, Percent, formattedValue, DataSource, NoIndicator } from './utils';
import { hasOverlappingWebAnalytics } from '../utils';
import { TinyGraph, GoalFilter } from '.';
import css from './KPICard.module.scss';


type KPICardProps = {
  indicator?: any;
  isActive?: boolean;
  isRefreshing?: boolean;
  setActive?: React.Dispatch<React.SetStateAction<number>>;
  report?: Report;
  style?: any;
  secondary?: boolean;
  triggerLoading?: () => void;
}

const KPICardWarningIcon = memo(styled(WarningIcon)(({ theme }) => ({
  color: `${theme.palette.warning.dark}`
})));

export const KPICard = ({
  report,
  indicator,
  isActive,
  isRefreshing,
  setActive,
  style,
  secondary,
  triggerLoading,
}: KPICardProps): JSX.Element => {
  const isClient = isExternalUser();
  const valueIsNull = indicator?.value === null;
  const dataSource = report?.fusion_integrations;
  const metricData = { ...indicator, dataSource };
  const overlappingAnalyticsPopoverContent = 'The selected comparison includes the date that Google Analytics was transitioned from UA to GA4. Since you are comparing data from before and after the transition date, you may notice differences in reporting metrics such as conversion volume, cost-per-conversion, and conversion attribution.';

  const refreshStatus: Report['refresh_status'] = useMemo(() => {
    return report?.refresh_status || null;
  }, [report]);

  const handleClick = () => {
    if (indicator && setActive) setActive(indicator?.id);
  };

  const hasOverlappingAnalytics = useMemo(() => hasOverlappingWebAnalytics([indicator]), [indicator]);

  useEffect(() => {
    /**
     * Triggers loading there are no integrations;
     * this fixes issues that occur after scoreboard creation.
     */
    if (!hasRequiredIntegration({ report, slug: indicator?.slug })) {
      if (triggerLoading) triggerLoading();
    }
  }, []);

  const renderSuggestedActions = useMemo(() => {
    /**
     * Suggest actions if indicator.value is null or indicator has no integration
     * using hasRequiredIntegration()
     */
    if (refreshStatus && (valueIsNull || !hasRequiredIntegration({ report, slug: indicator?.slug }))) {
      return (
        <div className={css.noIndicatorContainer}>
          <NoIndicator
            isClient={isClient}
            slug={indicator?.slug}
            clientId={report?.client?.id}
            clientStatus={report?.client?.status}
          />
        </div>
      );
    }
    if (!isRefreshing && refreshStatus === 'pending') {
      return (
        <>
          <ClockCircleOutlined className={css.icon} />
          <span className={css.loadingText}>
            <em>nova</em> is hard at work! loading your data. This process can take up to 30 minutes for first-time imports.
          </span>
        </>
      );
    }
    return (
      <Value
        hasPlatform={!!indicator?.platform?.length}
        format={indicator?.format}
        value={indicator?.value}
        slug={indicator?.slug}
      />
    );
  }, [indicator?.format, indicator?.platform?.length, indicator?.slug, indicator?.value, isClient, isRefreshing, refreshStatus, report, valueIsNull]);

  return (
    <AntdPopover
      title='Platforms'
      trigger='hover'
      placement='bottomLeft'
      destroyTooltipOnHide
      overlayStyle={{ display: indicator.platform && indicator?.platform?.length ? 'block' : 'none' }}
      overlayClassName={css.popover}
      content={<Platform platform={indicator?.platform} format={indicator?.format} />}
    >
      <Col
        onClick={handleClick}
        className={css.kpiCard}
        style={style}
        xs={secondary ? 24 : 24}
        lg={secondary ? 24 : 12}
        xl={secondary ? 24 : 8}
      >
        <Row justify='space-between' className={classNames(css.kpiCard_indicator, { [css.kpiCard_indicator_isClient]: isClient, [css.active]: isActive, [css.secondary]: secondary })}>
          {
            isClient ? (
              <TinyGraph
                className={classNames(css.tinyGraph, { [css.secondary]: secondary, [css.active]: isActive })}
                data={indicator?.data}
              />
            ) : (
              <></>
            )
          }
          <Col className={css.kpiCard_indicator_left}>
            <Row
              align='middle'
              className={css.kpiCard_indicator_left_title}
            >
              {indicator?.name ? titleChange(indicator?.name) : 'Undefined KPI Metric'}
              &nbsp;
              {indicator?.name ?
                <TooltipPopover
                  kpiterm={{
                    title: titleChange(indicator?.name),
                    content: indicator?.tooltip,
                    equation: (indicator?.slug === 'conversion-rate' && report?.business_type?.slug === 'ecomm')
                      ? 'Transactions/Sessions'
                      : indicator?.equation
                  }}
                  extra={<DataSource metricData={metricData} />}
                /> :
                <></> }
            </Row>
            <Row
              className={css.kpiCard_indicator_left_value}
            >
              {renderSuggestedActions}
            </Row>
            <Row align="middle">
              {
                refreshStatus === 'complete' && !valueIsNull && hasRequiredIntegration({ report, slug: indicator?.slug }) && indicator?.compared_value?.[1] ? (
                  <>
                    <Percent
                      comparedValues={indicator?.compared_value}
                      slug={indicator?.slug}
                    />
                    <span className={css.kpiCard_indicator_left_vs}>
                      vs. {formattedValue(indicator?.format, indicator.compared_value[1], indicator?.slug)}
                    </span>
                  </>
                ) : <div className={css.kpiCard_indicator_left_empty} />
              }
            </Row>
          </Col>
          {
            (hasRequiredIntegration({ report, slug: indicator?.slug })) ?
              <Col className={css.kpiCard_indicator_right}>
                {
                  !valueIsNull
                    ? <GoalFilter slug={indicator?.slug} report={report} iconOnly />
                    : <></>
                }
                {
                  !valueIsNull && hasOverlappingAnalytics && (
                    <Popover
                      content={overlappingAnalyticsPopoverContent}
                      placement="bottomRight"
                      overlayStyle={{width: '450px'}}
                    >
                      <KPICardWarningIcon />
                    </Popover>
                  )
                }
                <div className={classNames(css.activeIndicator, {[css.kpiCard_active]: isActive, [css.activeIndicator_isClient]: isClient, [css.secondary]: secondary})} />
              </Col> :
              <></>
          }
        </Row>
      </Col>
    </AntdPopover>
  );
};

/* TODO: Add "hasOtherIntegration" where relevant */
const hasRequiredIntegration = ({ report, slug }: { report?: Report, slug?: string }): boolean => {
  const hasAdvertisingIntegration = !!report?.fusion_integrations?.filter((item: any) => item?.data_source?.group === 'Advertising')?.length;
  const hasWebAnalyticsIntegration = !!report?.fusion_integrations?.filter((item: any) => item?.data_source?.group === 'WebAnalytics')?.length;
  const hasECommerceIntegration = !!report?.fusion_integrations?.filter((item: any) => item?.data_source?.group === 'E-commerce')?.length;

  switch (slug?.toLowerCase()) {
    case 'cost-per-lead':
    case 'roas':
    case 'spend':
    case 'acos':
    case 'cac':
      return hasAdvertisingIntegration;
    case 'leads':
    case 'revenue':
    case 'sessions':
    case 'conversion-rate':
    case 'transactions':
      return hasWebAnalyticsIntegration;
    case 'customers':
    case 'avg-order-value':
    case 'number-of-orders':
    case 'total-revenue':
    case 'lost-revenue':
    case 'repurchase-rate':
    case 'avg-repurchase-timeframe':
    case 'avg-customer-lifetime-value':
    case 'total-sales':
    case 'net-sales':
      return hasECommerceIntegration;
    case 'opportunities':
    case 'sqls':
    default:
      return true;
  }
};
