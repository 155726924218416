import { MAX_CONTRACT_LENGTH } from '../constants';

export const valuePresentStyles = {
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #000000', borderRadius: 1
  },
  '&fieldset.notchedOutline': {
    border: '1px solid #000000 !important',
  },
  '&:hover fieldset': {
    border: '2px solid #0288D1 !important',
  },
  'label': {
    color: 'black',
  },
  '&:hover label': {
    color: '#0288D1',
  },
  '&:focus-within fieldset, &:focus-visible fieldset': {
    border: '2px solid #0288D1 !important',
  },
};

export const INVALID_DATE = 'Invalid Date';

/**
* Formatted contract length options:
* Max 36 months
*/
export const monthsContract = Array(MAX_CONTRACT_LENGTH).fill(0).map((_, i) => i + 1);

/**
* For an unknown reason in the CreatePackageBasedBlueprint file, the AutoComplete dropdowns are having padding added to them
* from what appears to be the emotion css library
*
* This is a workaround to remove the extra padding
*/
export const autoCompletePaddingStyles: Record<string, Record<string, string>> =
  {
    '& .MuiOutlinedInput-root': {
      paddingTop: '0px',
      paddingBottom: '0px'
    }
  };
