import React, { useCallback, useState } from 'react';
import { Button, styled } from '@mui/material';
import DropDownListIcon from 'components/Icon/svg/nebula/DropDownListIcon';
import SaveIcon from 'components/Icon/svg/nebula/SaveIcon';

interface Link {
  name: string;
  handler: () => void;
}

export interface ExportDropdownButtonType {
  links: Link[];
}

export const ExportDropdownButton = ({
  links
}: ExportDropdownButtonType): JSX.Element => {
  const [showPopover, setShowPopover] = useState(false);

  const handleButtonClick = useCallback(() => {
    setShowPopover(!showPopover);
  }, [showPopover]);

  const StyledButton = styled(Button)`
    display: flex;
    height: 48px;
    padding: 12px;
    align-items: center;
    align-self: stretch;
    justify-content: flex-start;
    border-radius: 4px;
    border: 2px solid #4A1FFF;
    background: #FFFFFF;
    color: #4A1FFF;
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  `;

  const StyledDropdownButton = styled(Button)`
    display: flex;
    height: 48px;
    padding: 12px;
    align-items: center;
    align-self: stretch;
    justify-content: flex-start;
    background: #FFFFFF;
    color: #4A1FFF;
    font-size: 14px;
    font-family: Inter;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-decoration: none !important;
  `;

  return (
    <div style={{ width: '100%', position: 'relative' }}>
      <StyledButton
        style={{ width: '100%', padding: '0 6px' }}
        onClick={handleButtonClick}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          '&:hover': {
            textDecoration: 'none !important',
            backgroundColor: '#E1DAFF !important',
          }
        }}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <SaveIcon height='24px' width='24px'/>
          <div style={{ paddingLeft: '8px', textTransform: 'none' }}>Export</div>
        </div>
        <DropDownListIcon height='24px' width='24px'/>
      </StyledButton>
      {showPopover && (
        <div
          style={{
            top: '100%',
            left: 0,
            width: '100%',
            backgroundColor: '#f1f1f1',
            borderRadius: '4px',
          }}
        >
          {links.map((link, index) => (
            <StyledDropdownButton key={index} onClick={link.handler} sx={{ width: '100%' }}>{link.name}</StyledDropdownButton>
          ))}
        </div>
      )}
    </div>
  );
};
