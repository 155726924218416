import { PricingVersion } from 'api/crudGraphQL/strategies/types';
import { Strategy } from 'features/entitiesRedux';
import { PricingVersion as PricingVersionEnum } from 'features/library/constants';

type PricingVersionSubset = Pick<PricingVersion, 'slug'>;

/**
 * Get the blueprint path based on the BP pricing version
 * @param blueprint Strategy
 * @returns The path to the blueprint
*/
export const getBlueprintPath = (blueprint: Pick<Strategy, 'id'> & { pricing_version: PricingVersionSubset }): string => {
  if (blueprint.pricing_version?.slug === PricingVersionEnum.PACKAGE) {
    return `/blueprints/package/${blueprint.id}`;
  } else {
    return `/blueprints/${blueprint.id}`;
  }
};
