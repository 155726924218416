import React, { memo } from 'react';
import { Grid } from '@sprnova/nebula';

type GridFormattingProps = {
  view: JSX.Element;
  title: JSX.Element;
  create: JSX.Element;
  edit: JSX.Element;
  isClient: boolean;
}
const GridFormatting = ({ view, title, create, edit, isClient }: GridFormattingProps): JSX.Element => {
  const spacing = 6;
  return (
    <Grid container spacing={1}>
      <Grid item xs={9.5}>
        <Grid container spacing={spacing}>
          <Grid item xs={12}>
            {view}
          </Grid>
          <Grid item xs={12}>
            {title}
          </Grid>
        </Grid>
      </Grid>
      {
        !isClient && (
          <Grid item xs={2.5}>
            <Grid container spacing={spacing}>
              <Grid item xs={12} display='flex' justifyContent='end'>
                {create}
              </Grid>
              <Grid item xs={12} display='flex' justifyContent='end'>
                {edit}
              </Grid>
            </Grid>
          </Grid>
        )
      }
    </Grid>
  );
};

export default memo(GridFormatting);
