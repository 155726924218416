/**
 * Entities -> Contacts -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { Contact } from './contact';
import { compareField } from '../../utils/compareField';

/**
 * Redux toolkit entity adapter for storing/caching entities by id
 */
export const contactsAdapter = createEntityAdapter<Contact>({
  selectId: (contact) => contact.id,
  sortComparer: compareField('name'),
});
