import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { SendOutlined } from '@ant-design/icons';
import { Result } from 'antd';
import { Client } from 'features/entitiesRedux';
import { Button } from 'components';
import ExternalClientMissingDataRequests from './ExternalClientMissingDataRequests';
import css from './IntegrationRequestMissingAlert.module.scss';

interface IntegrationRequestMissingAlertProps {
  clientId?: Client['id'];
  clientName?: Client['name'];
  alreadyHaveInvite?: boolean;
  userType: 'external' | 'internal';
}

export const IntegrationRequestMissingAlert = ({
  clientId,
  clientName,
  alreadyHaveInvite,
  userType
}: IntegrationRequestMissingAlertProps): JSX.Element => {
  return (
    <div className={css.root}>
      {userType === 'external' ? (
        <ExternalClientMissingDataRequests />
      ) : (
        <Result
          icon={<></>}
          title={
            <>
              <p>{alreadyHaveInvite ? 'No data access has been requested.' : `${clientName} is not using the nova Client Portal!`}<br/></p>
              <p className={css.subText}>
                { alreadyHaveInvite ? 'Get started by clicking the button below' :
                  'Please invite the client in order to send them data access requests.' }
              </p>
            </>
          }
          extra={
            <Link to={`/power-view/accounts/${clientId}/integrations/${alreadyHaveInvite ? 'add' : 'invite'}`} key="invite">
              <Button
                type="primary"
                size="large"
                icon={!alreadyHaveInvite && <SendOutlined />}
              >
                {alreadyHaveInvite ? 'Request Access' : 'Invite Client'}
              </Button>
            </Link>
          }
        />
      )}
    </div>
  );
};
