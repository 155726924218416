/**
 * Entities -> Tasks -> Adapter
 */

import { createEntityAdapter } from '@reduxjs/toolkit';
import { Task } from './task';
import { compareField } from '../../utils/compareField';

/**
  * Redux toolkit entity adapter for storing/caching entities by id
  */
export const tasksAdapter = createEntityAdapter<Task>({
  selectId: (task) => task.id,
  sortComparer: compareField('name'),
});
